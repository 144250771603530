import React from 'react';
import {Trans} from 'react-i18next';
import {connect} from 'react-redux';
import FacebookIcon from '../../assets/images/icons/social-media-icons/ic-social-fb.svg';
import InstagramIcon from '../../assets/images/icons/social-media-icons/ic-social-ig.svg';
import TwitterIcon from '../../assets/images/icons/social-media-icons/ic-social-tw.svg';
import {
  FACEBOOK_URL,
  FAQ_URL,
  INSTAGRAM_URL,
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
  TWITTER_URL
} from '../../constants/values';
import State from '../../store/state';
import ExternalLink from '../shared/external-link/ExternalLink';
import styles from './Footer.module.scss';

function Footer(props) {
  const linkClass = styles['faq-and-legal__link'];
  const socialLinkClass = styles['social-media__link'];

  return (
    <footer className={`${styles.footer} ${props.isLoggedIn ? '' : styles['footer--logout-user']}`}>
      <div className={`${styles.container} ${props.isLoggedIn ? '' : styles['container--logout-user']}`}>
        <div className={styles.copyright}>
          <Trans>FOOTER.COPYRIGHT</Trans>
        </div>
        {
          props.isLoggedIn && (
            <>
              <div className={styles['faq-and-legal']}>
                <ExternalLink className={linkClass} href={FAQ_URL}>
                  <Trans>FOOTER.FAQ</Trans>
                </ExternalLink>
                <ExternalLink className={linkClass} href={TERMS_AND_CONDITIONS_URL}>
                  <Trans>FOOTER.TERMS</Trans>
                </ExternalLink>
                <ExternalLink className={linkClass} href={PRIVACY_POLICY_URL}>
                  <Trans>FOOTER.PRIVACY_POLICY</Trans>
                </ExternalLink>
              </div>
              <div className={styles['social-media']}>
                <ExternalLink className={socialLinkClass} href={FACEBOOK_URL}>
                  <img src={FacebookIcon} alt="facebook" />
                </ExternalLink>
                <ExternalLink className={socialLinkClass} href={TWITTER_URL}>
                  <img src={TwitterIcon} alt="twitter" />
                </ExternalLink>
                <ExternalLink className={socialLinkClass} href={INSTAGRAM_URL}>
                  <img src={InstagramIcon} alt="instagram" />
                </ExternalLink>
              </div>
            </>
          )
        }
      </div>
    </footer>
  );
}

const mapStateToProps = (state: State) => ({
  isLoggedIn: state.public.isLoggedIn
});

export default connect(mapStateToProps, null)(Footer);
