import {AuthenticatedSystemProperties, PublicSystemProperties} from '../model/SystemProperties';
import HttpService from './http.service';

const SYSTEM_SETUP_URL = '/common/system/setup';

class SystemPropertiesService {
  getPublicSystemSetup(): Promise<PublicSystemProperties> {
    return HttpService.get<PublicSystemProperties>(`${SYSTEM_SETUP_URL}/public`)
      .catch(() => ({} as PublicSystemProperties));
  }

  getAuthenticatedSystemSetup(): Promise<AuthenticatedSystemProperties> {
    return HttpService.get<AuthenticatedSystemProperties>(`${SYSTEM_SETUP_URL}/authenticated`)
      .catch(() => ({} as AuthenticatedSystemProperties));
  }
}

export default new SystemPropertiesService();
