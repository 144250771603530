import DayJsUtils from '@date-io/dayjs';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@material-ui/core';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import dayjs, {Dayjs} from 'dayjs';
import {Formik, FormikProps} from 'formik';
import {mapValues, toNumber} from 'lodash';
import React, {ReactElement, useContext} from 'react';
import {Trans} from 'react-i18next';
import * as Yup from 'yup';
import {API_DATE_FORMAT, DATE_PICKER_FORMAT} from '../../../constants/date';
import {REQUIRED_FIELD_MESSAGE_CODE} from '../../../constants/values';
import {DictionaryCode} from '../../../shared/model/Dictionary';
import {VerificationService} from '../../../shared/services/verification.service';
import {DictionaryHelper} from '../../../utils/dictionary-helper';
import {getUndefinedIfEmpty} from '../../../utils/undefined-if-empty';
import PhoneTextField from '../../shared/fields/PhoneTextField';
import {VerificationStep} from '../steps/VerificationStep';
import {VerificationStepContent} from '../steps/VerificationStepContent';
import {VerificationStepFooter} from '../steps/VerificationStepFooter';
import {VerificationContext} from '../Verification';
import styles from './VerificationPersonalDetails.module.scss';

const ValidationSchema = Yup.object({
  firstName: Yup.string().required(REQUIRED_FIELD_MESSAGE_CODE),
  middleName: Yup.string(),
  lastName: Yup.string().required(REQUIRED_FIELD_MESSAGE_CODE),
  genderId: Yup.string().required(REQUIRED_FIELD_MESSAGE_CODE),
  birthDate: Yup.string()
    .required(REQUIRED_FIELD_MESSAGE_CODE)
    .test('isDateValid',
      'SHARED.ERROR.INVALID_DATE',
      value => value ? dayjs(value).isValid() : false
    ).nullable(),
  birthPlace: Yup.string().required(REQUIRED_FIELD_MESSAGE_CODE),
  mobileNo: Yup.string(),
  email: Yup.string().email().required(REQUIRED_FIELD_MESSAGE_CODE)
}).defined();

type FormFields = Yup.InferType<typeof ValidationSchema>;

const VerificationPersonalDetails = () => {
  const {application, setApplication, dictionaries} = useContext(VerificationContext);
  const genderOptions = DictionaryHelper.getDictionaryByCode(dictionaries, DictionaryCode.GENDER);

  const submit = (values: FormFields) => {
    // Prevent from sending empty strings to the backend
    const mappedValues = mapValues(values, value => getUndefinedIfEmpty(value));
    const applicationData = {
      ...application,
      ...mappedValues,
      genderId: mappedValues.genderId ? toNumber(mappedValues.genderId) : undefined
    };

    return VerificationService.updateProspectApplication(application.id, applicationData)
      .then(() => setApplication(applicationData));
  };

  const RouteTrans = ({children}) => <Trans>{`VERIFICATION.PERSONAL_DETAILS.${children}`}</Trans>;

  const PersonalDetailsForm = ({
                                 validateForm,
                                 values,
                                 errors,
                                 handleChange,
                                 handleBlur,
                                 submitForm,
                                 isValid,
                                 setFieldValue
                               }: FormikProps<FormFields>): ReactElement => {
    const handleBirthDateChange = (date: Dayjs | null) => {
      const newValue = !!date ? date.format(API_DATE_FORMAT) : null;
      setFieldValue('birthDate', newValue);
    };

    return (
      <form>
        <VerificationStepContent description={<RouteTrans>DESCRIPTION</RouteTrans>}>
          <div className={styles['form-container']}>
            <FormControl className={styles.input}>
              <InputLabel id="citizenship-id">
                <RouteTrans>NATIONALITY</RouteTrans>
              </InputLabel>
              {/* Select always disabled */}
              <Select
                disabled
                labelId="citizenship-id"
                name="citizenshipId"
                displayEmpty
                onBlur={handleBlur}
                onChange={handleChange}
                value={1}>
                {
                  <MenuItem value={1}>
                    <RouteTrans>FILIPINO_AND_NOT_US_CITIZEN</RouteTrans>
                  </MenuItem>
                }
              </Select>
            </FormControl>
            <div className={styles.label}>
              <RouteTrans>FULL_NAME</RouteTrans>
            </div>
            <TextField
              required
              className={styles.input}
              label={<RouteTrans>FIRST_NAME</RouteTrans>}
              type="text"
              name="firstName"
              onChange={handleChange}
              error={!!errors.firstName}
              helperText={errors.firstName && <Trans>{errors.firstName}</Trans>}
              onBlur={handleBlur}
              value={values.firstName}
            />
            <TextField
              required
              className={`${styles.input} ${styles['input--half']}`}
              label={<RouteTrans>LAST_NAME</RouteTrans>}
              type="text"
              name="lastName"
              onChange={handleChange}
              error={!!errors.lastName}
              helperText={errors.lastName && <Trans>{errors.lastName}</Trans>}
              onBlur={handleBlur}
              value={values.lastName}
            />
            <TextField
              className={`${styles.input} ${styles['input--half']}`}
              label={<RouteTrans>MIDDLE_NAME</RouteTrans>}
              type="text"
              name="middleName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.middleName}
            />
            <FormControl required className={styles.input} error={!!errors.genderId}>
              <FormLabel classes={{root: styles['radio-label']}}>
                <RouteTrans>GENDER</RouteTrans>
              </FormLabel>
              <RadioGroup
                classes={{root: styles['radio-group']}}
                name="genderId"
                onChange={handleChange}
                value={values.genderId}>
                {
                  genderOptions?.map(value =>
                    <FormControlLabel value={String(value.id)} control={<Radio />}
                                      key={value.code}
                                      label={<RouteTrans>{value.code}</RouteTrans>} />
                  )
                }
              </RadioGroup>
              <FormHelperText><Trans>{errors.genderId}</Trans></FormHelperText>
            </FormControl>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <KeyboardDatePicker
                className={`${styles.input} ${styles['input--half']}`}
                required
                label={<Trans>SHARED.COMMON.DATE_OF_BIRTH</Trans>}
                disableToolbar
                variant="inline"
                format={DATE_PICKER_FORMAT}
                name="birthDate"
                error={!!errors.birthDate}
                helperText={<Trans>{errors.birthDate}</Trans>}
                value={values.birthDate}
                onChange={handleBirthDateChange}
                KeyboardButtonProps={{'aria-label': 'change date'}}
                onBlur={handleBlur}
                PopoverProps={{
                  classes: {
                    root: styles['date-picker']
                  }
                }}
              />
            </MuiPickersUtilsProvider>
            <TextField
              className={`${styles.input} ${styles['input--half']}`}
              label={<RouteTrans>PLACE_OF_BIRTH</RouteTrans>}
              type="text"
              name="birthPlace"
              error={!!errors.birthPlace}
              helperText={<Trans>{errors.birthPlace}</Trans>}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.birthPlace}
            />
            <div className={styles.label}>
              <RouteTrans>CONTACT_INFORMATION</RouteTrans>
            </div>
            <PhoneTextField
              disabled
              className={`${styles.input} ${styles['input--half']}`}
              label={<Trans>SHARED.COMMON.MOBILE_NO</Trans>}
              name="mobileNo"
              value={values.mobileNo} />
            <TextField
              required
              inputMode="email"
              className={`${styles.input} ${styles['input--half']}`}
              label={<Trans>SHARED.COMMON.EMAIL</Trans>}
              type="text"
              name="email"
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email && <Trans>{errors.email}</Trans>}
              onBlur={handleBlur}
              value={values.email}
            />
          </div>
        </VerificationStepContent>
        <VerificationStepFooter validateForm={validateForm} submit={submitForm} isSubmitDisabled={!isValid} />
      </form>
    );
  };

  return (
    <VerificationStep>
      <Formik
        <FormFields>
        onSubmit={submit}
        validationSchema={ValidationSchema}
        initialValues={
          {
            firstName: application.firstName ?? '',
            lastName: application.lastName ?? '',
            middleName: application.middleName ?? '',
            genderId: String(application.genderId ?? ''),
            birthDate: application.birthDate ?? null,
            birthPlace: application.birthPlace ?? '',
            mobileNo: application.mobileNo ?? '',
            email: application.email ?? ''
          }
        }
        children={PersonalDetailsForm}
      />
    </VerificationStep>
  );
};

export default VerificationPersonalDetails;
