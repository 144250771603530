/* common strings used to build routes paths */

export enum RoutesParts {
  ACCOUNT = '/account', /* Only on mobile */
  AMOUNT = '/amount',
  CANCEL = '/cancel',
  CARDLESS_WITHDRAWAL = '/cardless-withdrawal',
  CONFIRM = '/confirm',
  DASHBOARD = '/dashboard',
  DELETE_CONFIRMATION = '/delete-confirmation',
  LOGIN = '/login',
  GOALS = '/goals',
  HISTORY = '/history',
  JUMIO = '/jumio',
  OTP = '/otp',
  OVERVIEW = '/overview',
  CREATE = '/create',
  CREATE_FORM = '/create-form',
  PAYMENT_DETAILS = '/payment-details',
  OPERATION_DETAILS = '/operation-details',
  PHONE_VALIDATION = '/phone-validation',
  PRODUCT_SELECTION = '/product-selection',
  REFERENCE_NUMBER = '/reference-number',
  REGISTRATION = '/registration',
  RESET_PASSWORD = '/reset-password',
  SET_PASSWORD = '/set-password',
  SUCCESS = '/success',
  TRANSFER = '/transfer',
  USER_DATA = '/user-data',
  WITHDRAW = '/withdraw',
  REFERRAL = '/referral',
  SETTINGS = '/settings'
}
