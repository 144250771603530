import {RoutesParts} from '../../../../constants/routes-parts';
import {DashboardRoutesPaths} from '../../../../routes/routes-paths/dashboard.routes-paths';

export const BuyLoadRoutesPaths = {
  PHONE_VALIDATION: DashboardRoutesPaths.BUY_LOAD + RoutesParts.PHONE_VALIDATION,
  PRODUCT_SELECTION: DashboardRoutesPaths.BUY_LOAD + RoutesParts.PRODUCT_SELECTION,
  CONFIRM: DashboardRoutesPaths.BUY_LOAD + RoutesParts.CONFIRM,
  OTP: DashboardRoutesPaths.BUY_LOAD + RoutesParts.OTP,
  SUCCESS: DashboardRoutesPaths.BUY_LOAD + RoutesParts.SUCCESS
};
