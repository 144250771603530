export enum ApplicationConditionGroup {
  PROFILE_DATA = 'PROFILE_DATA',
  CBS_VERIFICATION = 'CBS_VERIFICATION',
  COMPLIANCE_VERIFICATION = 'COMPLIANCE_VERIFICATION'
}

export enum ApplicationConditionStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

export interface ProspectApplicationConditionTO<T_GROUP = string, T_STATUS = string> {
  errorCodes?: string[];
  errorMessages?: string[];
  group: T_GROUP;
  name: string;
  status: T_STATUS;
}

export interface ProspectApplicationCondition extends ProspectApplicationConditionTO<ApplicationConditionGroup, ApplicationConditionStatus> {
}
