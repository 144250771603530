import {IconButton, InputAdornment} from '@material-ui/core';
import React from 'react';
import {ReactComponent as PasswordVisible} from '../../../assets/images/icons/password-visible.svg';
import {ReactComponent as PasswordNotVisible} from '../../../assets/images/icons/password-not-visible.svg';

interface PasswordVisibilityButtonProps {
  isVisible: boolean;
  setIsVisible: (state: boolean) => void;
}

export const PasswordVisibilityButton = ({isVisible, setIsVisible}: PasswordVisibilityButtonProps) => {
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setIsVisible(!isVisible)}
        onMouseDown={handleMouseDownPassword}
      >
        {isVisible ? <PasswordNotVisible/> : <PasswordVisible/>}
      </IconButton>
    </InputAdornment>
  );
};
