import React from 'react';
import Otp from '../../shared/otp/Otp';
import PopupWithMemoryRouter, {PopupMemoryRouterRouteProps} from '../../shared/popups/PopupWithMemoryRouter';
import {TelemedicineProduct} from '../model/TelemedicineProduct';
import {TelemedicineRoutesPaths} from '../telemedicine.routes-paths';
import OperationDetailsPopupContent from './OperationDetailsPopupContent';
import OperationOverviewPopupContentWrapper from './OperationOverviewPopupContentWrapper';
import PlanOverviewPopupContent from './PlanOverviewPopupContent';

interface BuyPopupProps {
  product: TelemedicineProduct;
  isPopupOpen: boolean;
  onPopupClose: () => void;
}

const BuyPopup = ({product, isPopupOpen, onPopupClose}: BuyPopupProps) => {

  const routes: PopupMemoryRouterRouteProps[] = [
    {
      path: TelemedicineRoutesPaths.TELEMEDICINE_PLAN_OVERVIEW,
      key: 'TELEMEDICINE_PLAN_OVERVIEW',
      render: () => <PlanOverviewPopupContent product={product} />
    },
    {
      path: TelemedicineRoutesPaths.TELEMEDICINE_OPERATION_DETAILS,
      key: 'TELEMEDICINE_OPERATION_DETAILS',
      render: () => <OperationDetailsPopupContent product={product} />
    },
    {
      path: TelemedicineRoutesPaths.TELEMEDICINE_OTP,
      key: 'TELEMEDICINE_OTP',
      component: Otp
    },
    {
      path: TelemedicineRoutesPaths.TELEMEDICINE_OPERATION_OVERVIEW,
      key: 'TELEMEDICINE_OPERATION_OVERVIEW',
      hideDefaultPopupCloseButton: true,
      render: () => <OperationOverviewPopupContentWrapper onBackToHome={onPopupClose} />
    }
  ];

  return (
    <PopupWithMemoryRouter open={isPopupOpen} handleClose={onPopupClose} routes={routes} />
  );
};

export default BuyPopup;
