import {CancelTokenSource} from 'axios';
import {CommandOutput} from '../../shared/model/command/CommandOutput';
import HttpService from '../../shared/services/http.service';
import {
  fromTelemedicineOperationsTO,
  TelemedicineOperations,
  TelemedicineOperationsTO
} from './model/TelemedicineOperation';
import {fromTelemedicineProductTO, TelemedicineProduct, TelemedicineProductTO} from './model/TelemedicineProduct';

const TELEMEDICINE_URL = '/customer/addons/telemedicine';
const TELEMEDICINE_OPERATIONS_URL = `${TELEMEDICINE_URL}/operations`;
const TELEMEDICINE_PRODUCTS_URL = `${TELEMEDICINE_URL}/products`;

export const TelemedicineService = {
  getTelemedicineOperations: (cancelTokenSource?: CancelTokenSource | null): Promise<TelemedicineOperations> =>
    HttpService
      .get<TelemedicineOperationsTO>(TELEMEDICINE_OPERATIONS_URL, cancelTokenSource)
      .then(telemedicineOperationsTO => fromTelemedicineOperationsTO(telemedicineOperationsTO)),

  getTelemedicineProducts: (cancelTokenSource?: CancelTokenSource | null): Promise<TelemedicineProduct[]> =>
    HttpService
      .get<TelemedicineProductTO[]>(TELEMEDICINE_PRODUCTS_URL, cancelTokenSource)
      .then(telemedicineProductsTO =>
        telemedicineProductsTO.map(telemedicineProductTO => fromTelemedicineProductTO(telemedicineProductTO))),

  processTelemedicinePayment: (productId: number, telemedicineProductId: number): Promise<CommandOutput> =>
    HttpService.post<CommandOutput>(TELEMEDICINE_URL, {productId, telemedicineProductId})
};
