import React, {useState} from 'react';
import {RecipientDetails} from '../../../shared/model/Recipient';
import {CardlessWithdrawalRoutes} from '../../shared/cardless-withdrawal/cardless-withdrawal.routes';
import PopupWithMemoryRouter from '../../shared/popups/PopupWithMemoryRouter';
import {KeyedRouteProps} from '../../shared/routes/model/Routes';

interface CardlessAtmWithdrawalPopupProps {
  isPopupOpen: boolean;
  onPopupClose: () => void;
}

interface CardlessAtmWithdrawalPopupContextType {
  recipientDetails?: RecipientDetails;
}

export const CardlessAtmWithdrawalPopupContext = React.createContext<CardlessAtmWithdrawalPopupContextType>({});

export default function CardlessAtmWithdrawalPopup({isPopupOpen, onPopupClose}: CardlessAtmWithdrawalPopupProps) {

  const [recipientFormInitState, setRecipientFormInitState] = useState<RecipientDetails | undefined>();

  const routes: KeyedRouteProps[] = [
    CardlessWithdrawalRoutes.NON_CUSTOMER_CARDLESS_WITHDRAWAL,
    CardlessWithdrawalRoutes.CARDLESS_WITHDRAWAL_RECIPIENT_DETAILS,
    CardlessWithdrawalRoutes.CARDLESS_WITHDRAWAL_CONFIRM,
    CardlessWithdrawalRoutes.CARDLESS_WITHDRAWAL_OTP,
    CardlessWithdrawalRoutes.CARDLESS_WITHDRAWAL_SUCCESS
  ];

  const handleClose = () => {
    onPopupClose();
    setRecipientFormInitState(undefined);
  };

  return (
    <CardlessAtmWithdrawalPopupContext.Provider value={{recipientDetails: recipientFormInitState}}>
      <PopupWithMemoryRouter handleClose={handleClose} open={isPopupOpen} routes={routes} />
    </CardlessAtmWithdrawalPopupContext.Provider>
  );
}
