import {Tabs} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import isEmpty from 'lodash/isEmpty';
import React, {useContext, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router';
import LOGO_GLOBE from '../../../../../assets/images/logos/buy-load-logo/logo-globe.png';
import LOGO_SMART from '../../../../../assets/images/logos/buy-load-logo/logo-smart.png';
import LOGO_SUN from '../../../../../assets/images/logos/buy-load-logo/logo-sun.png';
import LOGO_TNT from '../../../../../assets/images/logos/buy-load-logo/logo-tnt.png';
import {PaymentProduct} from '../../../../../shared/model/PaymentProduct';
import {ErrorHelper} from '../../../../../utils/error-helper';
import {StringHelper} from '../../../../../utils/string-helper';
import {BuyLoadServicePopupContext} from '../../Services';
import {BuyLoadPaymentService, BuyLoadProductsParams} from '../buy-load-payment.service';
import {BuyLoadRoutesPaths} from '../buy-load.routes-paths';
import {BuyLoadProductConfirmLocationProps} from '../confirm/BuyLoadProductConfirm';
import {BrandType, CarrierType, VerifiedMobileNumber} from '../model/VerifiedMobileNumber';
import styles from './BuyLoadProducts.module.scss';
import BuyLoadProductEmptyState from './empty-state/BuyLoadProductEmptyState';
import {ProductsState, ProductStateActionType, useProductState} from './ProductsStateHook';
import BuyLoadPromosOrGamingProducts from './promos/BuyLoadPromosOrGamingProducts';
import BuyLoadRegularProducts from './regular/BuyLoadRegularProducts';

enum BuyLoadProductType {
  REGULAR = 'REGULAR',
  PROMOS = 'PROMOS',
  GAMING = 'GAMING'
}

const TOP_UP_PAYMENT_GROUP = 'TOP_UP';
const EPIN_PAYMENT_GROUP = 'EPIN';

export default function BuyLoadProducts() {
  const locationState = (useLocation<VerifiedMobileNumber>()).state;
  const [currentTab, setCurrentTab] = useState<BuyLoadProductType>(BuyLoadProductType.REGULAR);
  const [regularProductsState, changeRegularProductsState] = useProductState();
  const [promosProductsState, changePromosProductsState] = useProductState();
  const [gamingProductsState, changeGamingProductsState] = useProductState();
  const cancelToken = useContext(BuyLoadServicePopupContext).cancelToken;

  const {t} = useTranslation();
  const history = useHistory();
  const popupContext = useContext(BuyLoadServicePopupContext);

  const renderTab = () => {
    switch (currentTab) {
      case BuyLoadProductType.REGULAR:
        fetchRegularProducts();
        return isEmpty(regularProductsState.products) && regularProductsState.isLoaded
          ? <EmptyState />
          : <BuyLoadRegularProducts regularProducts={regularProductsState.products}
                                    isLoading={regularProductsState.isLoading}
                                    onProductClick={handleProductClick} />;
      case BuyLoadProductType.PROMOS:
        fetchPromosProducts();
        return isEmpty(promosProductsState.products) && promosProductsState.isLoaded
          ? <EmptyState />
          : <BuyLoadPromosOrGamingProducts products={promosProductsState.products}
                                           isLoading={promosProductsState.isLoading}
                                           onProductClick={handleProductClick} />;
      case BuyLoadProductType.GAMING:
        fetchGamingProducts();
        return isEmpty(gamingProductsState.products) && gamingProductsState.isLoaded
          ? <EmptyState />
          : <BuyLoadPromosOrGamingProducts products={gamingProductsState.products}
                                           isLoading={gamingProductsState.isLoading}
                                           onProductClick={handleProductClick} />;
    }
  };

  const fetchRegularProducts = () => {
    const params: BuyLoadProductsParams = {
      group: TOP_UP_PAYMENT_GROUP,
      category: BuyLoadProductType.REGULAR,
      carrier: locationState.carrier
    };

    fetchProducts(regularProductsState, changeRegularProductsState, params);
  };

  const fetchPromosProducts = () => {
    const params: BuyLoadProductsParams = {
      group: TOP_UP_PAYMENT_GROUP,
      category: BuyLoadProductType.PROMOS,
      carrier: locationState.carrier,
      brand: locationState.brand
    };
    fetchProducts(promosProductsState, changePromosProductsState, params);
  };

  const fetchGamingProducts = () => {
    const params: BuyLoadProductsParams = {
      group: EPIN_PAYMENT_GROUP
    };
    fetchProducts(gamingProductsState, changeGamingProductsState, params);
  };

  const fetchProducts = (state: ProductsState, changeStateFn: any, params: BuyLoadProductsParams) => {
    if (!state.isLoaded && !state.isLoading) {
      changeStateFn({type: ProductStateActionType.LOADING});
      BuyLoadPaymentService.getPaymentProducts(params, cancelToken)
        .then(products => {
          changeStateFn({
            type: ProductStateActionType.LOADED,
            products
          });
        })
        .catch(error => {
          if (ErrorHelper.isAbortOrSessionRestrictionError(error)) {
            return;
          }

          changeStateFn({
            type: ProductStateActionType.LOADED,
            products: []
          });
        });
    }
  };

  const handleProductClick = (product: PaymentProduct) => {
    cancelFetchRequestIfLoading();
    history.push(BuyLoadRoutesPaths.CONFIRM, {
      mobileNo: locationState.input,
      mobileNoLocalFormat: locationState.localFormat,
      amount: parseFloat(product.maxAmount),
      product
    } as BuyLoadProductConfirmLocationProps);
  };

  const cancelFetchRequestIfLoading = () => {
    if (cancelToken && isFetchingAnyProductData()) {
      console.warn('cancelled');
      cancelToken.cancel('Amount choosed');
    }
  };

  const isFetchingAnyProductData = () =>
    regularProductsState.isLoading || gamingProductsState.isLoading || promosProductsState.isLoading;

  const EmptyState = () => {
    let link = t('BUY_LOAD.PRODUCTS.EMPTY_STATE.LINK');
    let onClick = () => setCurrentTab(BuyLoadProductType.REGULAR);

    if (currentTab === BuyLoadProductType.REGULAR) {
      link = t('SHARED.BACK_LINKS.BACK_HOME');
      onClick = () => popupContext.closePopup();
    }

    return <BuyLoadProductEmptyState description={t(`BUY_LOAD.PRODUCTS.EMPTY_STATE.${currentTab}`)}
                                     link={link}
                                     onClick={onClick} />;
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: BuyLoadProductType) => {
    setCurrentTab(newValue);
  };

  const carrierLogoSrc = getLogoSrc(locationState.carrier, locationState.brand);

  return (<>
    <div className={styles.title}>
      <Trans>BUY_LOAD.HEADER</Trans>
    </div>
    <div className={styles.container}>
      <div className={styles.logo}>
        {carrierLogoSrc && <img src={carrierLogoSrc} alt="carrier-logo" />}
      </div>
      <div className={styles.details}>
        <div className={styles.label}>
          <Trans>BUY_LOAD.PRODUCTS.LOAD_NUMBER</Trans>
        </div>
        <div className={styles.mobile}>{StringHelper.formatPhoneNumber(locationState.input)}</div>
      </div>
    </div>
    <Tabs indicatorColor="primary"
          textColor="primary"
          value={currentTab}
          onChange={handleChange}
          classes={{root: styles.tabs}}>
      <Tab label={<Trans>BUY_LOAD.PRODUCTS.REGULAR</Trans>}
           classes={{root: styles.tab}}
           value={BuyLoadProductType.REGULAR}
      />
      <Tab label={<Trans>BUY_LOAD.PRODUCTS.PROMOS</Trans>}
           classes={{root: styles.tab}}
           value={BuyLoadProductType.PROMOS}
      />
      <Tab label={<Trans>BUY_LOAD.PRODUCTS.GAMING</Trans>}
           classes={{root: styles.tab}}
           value={BuyLoadProductType.GAMING}
      />
    </Tabs>
    <div className={styles['tab-content']}>
      {renderTab()}
    </div>
  </>);
}

const getLogoSrc = (carrier: string, brand: string): string | undefined => {
  switch (carrier) {
    case CarrierType.SMART:
      return brand === BrandType.TNT ? LOGO_TNT : LOGO_SMART;
    case CarrierType.GLOBE:
      return LOGO_GLOBE;
    case CarrierType.SUN:
      return LOGO_SUN;
    default:
      return undefined;
  }
};