import React from 'react';
import PopupWithMemoryRouter, {PopupMemoryRouterRouteProps} from '../../shared/popups/PopupWithMemoryRouter';
import {OngoingVerificationPopupRoutes} from './ongoing-verification-popup.routes-paths';
import OngoingVerificationFailurePopupContent from './OngoingVerificationFailurePopupContent';
import OngoingVerificationPopupContent from './OngoingVerificationPopupContent';
import OngoingVerificationSuccessPopupContent from './OngoingVerificationSuccessPopupContent';

interface OngoingVerificationPopupProps {
  isOpen: boolean;
  onPopupClose: () => void;
}

const OngoingVerificationPopup = ({isOpen, onPopupClose}: OngoingVerificationPopupProps) => {
  const routes: PopupMemoryRouterRouteProps[] = [
    {
      path: OngoingVerificationPopupRoutes.ONGOING_VERIFICATION,
      key: 'ONGOING_VERIFICATION',
      hideDefaultPopupCloseButton: true,
      render: () => <OngoingVerificationPopupContent onPopupClose={onPopupClose} />
    },
    {
      path: OngoingVerificationPopupRoutes.ONGOING_VERIFICATION_FAILURE,
      key: 'ONGOING_VERIFICATION_FAILURE',
      hideDefaultPopupCloseButton: true,
      component: OngoingVerificationFailurePopupContent
    },
    {
      path: OngoingVerificationPopupRoutes.ONGOING_VERIFICATION_SUCCESS,
      key: 'ONGOING_VERIFICATION_SUCCESS',
      hideDefaultPopupCloseButton: true,
      render: () => <OngoingVerificationSuccessPopupContent onBackButtonClick={onPopupClose} />
    }
  ];

  return <PopupWithMemoryRouter open={isOpen} handleClose={onPopupClose} routes={routes} />;
};

export default OngoingVerificationPopup;
