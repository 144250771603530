import {isNil} from 'lodash';
import React, {createContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import {Redirect} from 'react-router-dom';
import {AlertSnackbar} from './components/shared/alert-snackbar/AlertSnackbar';
import {
  AlertSnackbarAction,
  AlertSnackbarActionType,
  useAlertSnackbar
} from './components/shared/alert-snackbar/alertSnackBarHook';
import RenderRoutes from './components/shared/routes/RenderRoutes';
import ROUTES from './routes/routes';
import {RoutesPaths} from './routes/routes-paths/routes-paths';
import {checkIfProspectApplicationIsSealed, checkSession} from './store/actions';
import {getIsLoggedIn, getIsPasswordChangeRequired, getIsProspectApplicationSealed} from './store/selectors';
import './styles/styles.scss';

interface AppSnackbarContextType {
  dispatchAction: (action: AlertSnackbarAction) => void;
}

interface MainRouterContextType {
  globalHistory?: any;
}

export const AppSnackbarContext = createContext<AppSnackbarContextType>({
  dispatchAction: () => {
  }
});
export const MainRouterContext = createContext<MainRouterContextType>({});

const App = () => {
  const [snackbarState, dispatchSnackbarAction] = useAlertSnackbar();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const prospectApplicationSealed = useSelector(getIsProspectApplicationSealed);
  const globalHistory = useHistory();

  useEffect(() => {
    if (isNil(isLoggedIn)) {
      dispatch(checkSession());
    } else if (isNil(prospectApplicationSealed)) {
      dispatch(checkIfProspectApplicationIsSealed());
    }
  }, [dispatch, isLoggedIn, prospectApplicationSealed]);

  const getRoutesToRender = () => ROUTES.filter(route => isLoggedIn || route.public);

  const App = (
    <>
      <AlertSnackbar handleClose={() => dispatchSnackbarAction({type: AlertSnackbarActionType.HIDE})}
                     message={snackbarState.message}
                     open={snackbarState.isOpen}
                     severity={snackbarState.severity}
                     mainSnackbar />
      <AppSnackbarContext.Provider value={{dispatchAction: dispatchSnackbarAction}}>
        <MainRouterContext.Provider value={{globalHistory}}>
          <RenderRoutes routes={getRoutesToRender()} />
        </MainRouterContext.Provider>
      </AppSnackbarContext.Provider>
      <RedirectToDashboardOrLoginIfNeeded />
    </>
  );

  return <>{!isNil(isLoggedIn) && App}</>;
};

function RedirectToDashboardOrLoginIfNeeded() {
  const originPath = useLocation().pathname;
  const isPasswordChangeRequired = useSelector(getIsPasswordChangeRequired);
  const isLoggedIn = useSelector(getIsLoggedIn);

  if (!isLoggedIn) {
    return <Redirect to={RoutesPaths.LOGIN} />;
  }

  if (!originPath || originPath === RoutesPaths.ROOT || originPath === RoutesPaths.LOGIN || isPasswordChangeRequired) {
    return <Redirect to={RoutesPaths.DASHBOARD} />;
  }

  return null;
}

export default App;
