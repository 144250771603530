import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';
import {getSteps, verificationStepsHelper} from './verification-steps';
import styles from './VerificationStep.module.scss';
import {VerificationStepper} from './VerificationStepper';

interface VerificationStepProps {
  children: React.ReactElement | React.ReactElement[];
}

export const VerificationStep = ({children}: VerificationStepProps) => {
  const {t} = useTranslation();
  const activeStepPath = useLocation().pathname;
  const steps = getSteps(t);
  const stepIndex = verificationStepsHelper.getStepIndex(steps, activeStepPath);
  const stepTitle = steps[stepIndex] ? steps[stepIndex].label : '';

  return (
    <>
      <h1 className={styles.header}>
        <Trans>VERIFICATION.TITLE</Trans>
      </h1>
      <div className={styles.description}>
        <Trans>VERIFICATION.DESCRIPTION</Trans>
      </div>
      <div className={styles.box}>
        <div className={styles['box__content']}>
          <div className={styles.stepper}>
            <VerificationStepper activeStepPath={activeStepPath} />
          </div>
          <div className={styles.step}>
            <h2 className={styles['step__header']}>{stepTitle}</h2>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};
