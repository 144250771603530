import React, {useRef, useState} from 'react';
import {connect} from 'react-redux';
import {ReactComponent as DropDownArrow} from '../../../assets/images/icons/dropdown-arrow.svg';
import Face from '../../../assets/images/icons/user-face.svg';
import UserData from '../../../shared/model/UserData';
import State from '../../../store/state';
import {useHandleClickOutsideComponent} from '../../../utils/hooks/useHandleClickOutsideComponent';
import AccountOptions from '../account-options/AccountOptions';
import styles from './AccountDropdown.module.scss';

interface AccountDropdownProps {
  className: string;
  userData?: UserData;
}

const AccountDropdown = ({className, userData}: AccountDropdownProps) => {
  const [modalOpen, showModal] = useState(false);
  const buttonRef = useRef(null);
  const modalRef = useRef(null);

  useHandleClickOutsideComponent(modalRef, buttonRef, () => {
    showModal(false);
  });

  return (
    <div className={`${styles.dropdown} ${className}`}>
      <div ref={buttonRef} className={styles['user-button']} onClick={() => showModal(!modalOpen)}>
        <img src={Face} alt="user-settings" />
        <div className={styles['user-short-name']}>{userData?.shortName}</div>
        <DropDownArrow />
      </div>
      <div ref={modalRef} className={`${styles['dropdown__modal']} ${modalOpen ? styles['dropdown__modal--show'] : ''}`}>
        <AccountOptions hideOptionsModal={() => showModal(false)} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: State, ownProps) => ({
  userData: state.sessionData,
  ...ownProps
});

export default connect(mapStateToProps)(AccountDropdown);
