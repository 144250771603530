import {Button, Link} from '@material-ui/core';
import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import OtpInput from 'react-otp-input';
import {connect} from 'react-redux';
import {useLocation} from 'react-router';
import {CommandConfirmationParams} from '../../../shared/model/command/CommandConfirmationParams';
import {CommandOutputData} from '../../../shared/model/command/CommandOutput';
import {CommandService} from '../../../shared/services/command.service';
import {HttpError} from '../../../shared/services/http.service';
import {fetchUserPrimaryAccount} from '../../../store/actions';
import State from '../../../store/state';
import {useCountDownTimerHook} from '../../../utils/hooks/countDownTimerHook';
import Loader from '../loader/Loader';
import styles from './Otp.module.scss';

const OTP_LENGTH = 6;
const OTP_SHOW_SECONDS_BREAKPOINT = 60;
const INVALID_OTP_ERROR_CODE = 'INVALID_OTP';

export interface OtpLocationProps {
  commandId: number,
  payload: string,
  confirmationRecipient: string,
  onSuccess: (confirmationOutput) => void
  // Function invoked when error other than INVALID_OTP
  onError: (error) => void
}

interface OtpComponentProps {
  singleBoxStylesVariant?: boolean;
}

interface OtpStateProps {
  otpLifetimeInSeconds: number;
  isAnonymous: boolean;
}

interface OtpDispatchProps {
  refreshPrimaryAccount: () => void
}

type OtpProps = OtpComponentProps & OtpStateProps & OtpDispatchProps;

const Otp = <T_OUTPUT extends CommandOutputData>({
                                                   otpLifetimeInSeconds,
                                                   isAnonymous,
                                                   singleBoxStylesVariant = false,
                                                   refreshPrimaryAccount
                                                 }: OtpProps) => {
  const {commandId, payload, confirmationRecipient, onSuccess, onError} = useLocation<OtpLocationProps>().state;
  const {t} = useTranslation();
  const [otp, setOtp] = useState('');
  const [resendOtp, setResendOtp] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [seconds, isActive, activateTimer, reset] = useCountDownTimerHook(otpLifetimeInSeconds);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const handleChange = otp => {
    setOtp(otp);
  };

  function isCountDownRunning() {
    return isActive && seconds > 0;
  }

  function clearError() {
    setErrorOccurred(false);
  }

  function handleError(error: HttpError<any>) {
    setErrorOccurred(true);
    setIsSubmitting(false);

    if (error.error.errorCode !== INVALID_OTP_ERROR_CODE) {
      onError(error);
    }
  }

  function resendOtpCode() {
    CommandService.resentOtp(commandId, isAnonymous)
      .then(() => {
        reset();
        activateTimer();
        setResendOtp(true);
      })
      .catch(error => handleError(error));
  }

  function submit() {
    if (otp.length !== OTP_LENGTH) {
      return;
    }

    setIsSubmitting(true);

    const confirmParams: CommandConfirmationParams = {
      commandId,
      payload,
      secret: otp
    };

    CommandService.confirm<T_OUTPUT>(confirmParams, isAnonymous)
      .then(result => {
        refreshPrimaryAccount();
        onSuccess(result.output);
      })
      .catch(error => handleError(error));
  }

  const renderResendLink = () => (
    <>
      <Trans>OTP.RESEND_LINK_AGAIN</Trans>
      {seconds <= OTP_SHOW_SECONDS_BREAKPOINT && <span>{t('OTP.TIMER', {seconds})}</span>}
    </>
  );

  const renderOtpResendLink = () => resendOtp ? renderResendLink() : <Trans>OTP.RESEND_LINK</Trans>;

  return (
    <div className={styles.container}>
      <div className={`${styles.content} ${singleBoxStylesVariant ? styles['content--no-margins'] : ''}`}>
        <div className={`${styles.header} ${singleBoxStylesVariant ? styles['header--bigger-font'] : ''}`}>
          <Trans>OTP.HEADER</Trans>
        </div>
        <div className={styles.description}>
          <Trans>OTP.DESCRIPTION</Trans>
          <span className={styles['description__phone']}>{confirmationRecipient}</span>
        </div>
        <div onClick={() => clearError()}>
          <OtpInput
            isInputNum
            value={otp}
            onChange={handleChange}
            numInputs={OTP_LENGTH}
            containerStyle={styles['input-container']}
            inputStyle={`${styles.input} ${errorOccurred ? styles['input--error'] : ''}`}
          />
        </div>
        <div className={styles.info}>
          {errorOccurred ?
            <div className={styles['error-text']}>
              <Trans>OTP.INVALID</Trans>
            </div>
            :
            <Link onClick={resendOtpCode}
                  color="primary"
                  className={`${styles['resend-link']} ${isCountDownRunning() ? styles['resend-link--disabled'] : ''}`}>
              {renderOtpResendLink()}
            </Link>
          }
        </div>
        <Button type="submit"
                className={styles.submit}
                onClick={submit}
                disabled={otp.length !== OTP_LENGTH || isSubmitting}>
          <Loader buttonSpinner loaded={!isSubmitting}>
            <Trans>SHARED.COMMON.CONFIRM</Trans>
          </Loader>
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State): OtpStateProps => ({
  otpLifetimeInSeconds: state.public.systemProperties?.otpLifetimeInSeconds ?? 0,
  isAnonymous: !(state.public.isLoggedIn || state.sessionData?.anonymous)
});

const mapDispatchToProps = (dispatch): OtpDispatchProps => ({
  refreshPrimaryAccount: () => dispatch(fetchUserPrimaryAccount(true))
});

export default connect<OtpStateProps, OtpDispatchProps, {}, State>(mapStateToProps, mapDispatchToProps)(Otp);
