import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {PublicSystemProperties} from '../../../shared/model/SystemProperties';
import UserData from '../../../shared/model/UserData';
import {HttpError} from '../../../shared/services/http.service';
import State from '../../../store/state';
import encrypt from '../../../utils/credentials-encryption';
import {ErrorHelper} from '../../../utils/error-helper';
import {checkIfCustomer, checkIfProspect} from '../../../utils/user-group-checks';
import {AlertSnackbar} from '../../shared/alert-snackbar/AlertSnackbar';
import Loader from '../../shared/loader/Loader';
import SetPasswordForm from '../../shared/set-password-form/SetPasswordForm';
import {SettingsRoutesPaths} from '../settings.routes-paths';
import {SettingsService} from '../settings.service';
import styles from './NewPasswordPopupContent.module.scss';

interface NewPasswordPopupContentLocationProps {
  otpErrorMessage?: string;
  oldPassword: string;
}

interface NewPasswordPopupContentContentStateProps {
  userData: UserData;
  systemProperties: PublicSystemProperties;
}

type NewPasswordPopupContentProps = NewPasswordPopupContentContentStateProps;

const NewPasswordPopupContent = ({userData, systemProperties}: NewPasswordPopupContentProps) => {
  const location = useLocation<NewPasswordPopupContentLocationProps>();
  const {otpErrorMessage, oldPassword} = location.state ?? {};
  const [t] = useTranslation();
  const isCustomer = checkIfCustomer(userData);
  const isProspect = checkIfProspect(userData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(otpErrorMessage);
  const [showError, setShowError] = useState(!!otpErrorMessage);
  const history = useHistory();

  const handleOtpSuccess = () => {
    history.push(SettingsRoutesPaths.CHANGE_PASSWORD_SUCCESS);
  };

  const handleOtpError = (error: HttpError<any>) => {
    // Reload current view with error
    history.push(location.pathname, {
      ...location.state,
      otpErrorMessage: ErrorHelper.getErrorMessageTranslation(t, error)
    } as NewPasswordPopupContentLocationProps);
  };

  const onSubmit = async (newPassword: string) => {
    setIsSubmitting(true);

    const newPasswordPayload = {
      oldSecret: encrypt(oldPassword, systemProperties.credentialsEncryptionRsaPublicKey),
      newSecret: encrypt(newPassword, systemProperties.credentialsEncryptionRsaPublicKey)
    };

    const passwordChangeMethod = isCustomer ? SettingsService.changeCustomerPassword
      : isProspect ? SettingsService.changeProspectPassword : null;

    if (!passwordChangeMethod) {
      return;
    }

    passwordChangeMethod(newPasswordPayload).then(output => {
        history.push(SettingsRoutesPaths.CHANGE_PASSWORD_OTP, {
          ...output,
          onSuccess: handleOtpSuccess,
          onError: handleOtpError
        });
      })
      .catch(error => {
        setErrorMessage(ErrorHelper.getErrorMessageTranslation(t, error));
        setShowError(true);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div className={styles.container}>
      <AlertSnackbar handleClose={() => setShowError(false)}
                     open={showError}
                     message={errorMessage} />
      {
        isSubmitting
          ? <Loader className={styles.loader} />
          : <SetPasswordForm onSubmit={onSubmit} />
      }
    </div>
  );
};

const mapStateToProps = (state: State): NewPasswordPopupContentProps => ({
  userData: state.sessionData ?? ({} as UserData),
  systemProperties: state.public.systemProperties ?? ({} as PublicSystemProperties)
});

export default connect<NewPasswordPopupContentContentStateProps, {}, {}, State>(mapStateToProps)(
  NewPasswordPopupContent);
