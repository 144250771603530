import {StringHelper} from '../../../utils/string-helper';

export enum BillCategoryCodes {
  AIRLINES = 'AIRLINES',
  CABLE = 'CABLE',
  ELECTRICITY = 'ELECTRICITY',
  GOVERNMENT = 'GOVERNMENT',
  INSURANCE = 'INSURANCE',
  SSS_CONTRIBUTION = 'SSS_CONTRIBUTION',
  UTILITIES = 'UTILITIES',
  WATER = 'WATER',
  OTHERS = 'OTHERS',
}

export const mapStringToCategory = (code: string): BillCategoryCodes | string => {
  const standarizedCode = StringHelper.standarizeStringCode(code);
  return Object.keys(BillCategoryCodes).find(key => BillCategoryCodes[key] === standarizedCode) ?? standarizedCode;
};


