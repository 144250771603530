import React from 'react';
import {useLocation} from 'react-router';
import {TelemedicineOperation} from '../model/TelemedicineOperation';
import OperationOverviewPopupContent from '../operation-overview/OperationOverviewPopupContent';

interface OperationOverallPopupContentWrapperLocationProps {
  operation: TelemedicineOperation
}

interface OperationOverallPopupContentWrapperProps {
  onBackToHome: () => void
}

const OperationOverviewPopupContentWrapper = ({onBackToHome}: OperationOverallPopupContentWrapperProps) => {

  const {operation} = useLocation<OperationOverallPopupContentWrapperLocationProps>().state;

  return <OperationOverviewPopupContent operation={operation}
                                        onBackToHome={onBackToHome} />;
};

export default OperationOverviewPopupContentWrapper;
