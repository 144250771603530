import {CancelTokenSource} from 'axios';
import {CommandOutput} from '../../shared/model/command/CommandOutput';
import HttpService, {ErrorBody, HttpError} from '../../shared/services/http.service';
import Goal, {goalFromGoalTO, GoalPayload, GoalTO} from './model/Goal';

const CUSTOMER_GOALS_URL = `/customer/savings-goals`;
const PROSPECT_GOALS_URL = `/prospect/savings-goals`;
const IMAGES_URL_PART = `/images`;
const CUSTOMER_GOALS_IMAGE_UPLOAD_URL = CUSTOMER_GOALS_URL + IMAGES_URL_PART;
const PROSPECT_GOALS_IMAGE_UPLOAD_URL = PROSPECT_GOALS_URL + IMAGES_URL_PART;

export const GoalsService = {
  createGoalForCustomer: (data: GoalPayload): Promise<CommandOutput> =>
    createGoal(CUSTOMER_GOALS_URL, data),

  createGoalForProspect: (data: GoalPayload): Promise<CommandOutput> =>
    createGoal(PROSPECT_GOALS_URL, data),

  getCustomerGoals: (cancelTokenSource?: CancelTokenSource | null): Promise<Goal[]> =>
    getGoals(CUSTOMER_GOALS_URL, cancelTokenSource),

  getProspectGoals: (cancelTokenSource?: CancelTokenSource | null): Promise<Goal[]> =>
    getGoals(PROSPECT_GOALS_URL, cancelTokenSource),

  deleteCustomerGoal: (id: number): Promise<CommandOutput> => deleteGoal(`${CUSTOMER_GOALS_URL}/${id}`),

  deleteProspectGoal: (id: number): Promise<CommandOutput> => deleteGoal(`${PROSPECT_GOALS_URL}/${id}`),

  uploadCustomerGoalImage: (file: File): Promise<number | null | HttpError<ErrorBody>> =>
    uploadGoalImage(CUSTOMER_GOALS_IMAGE_UPLOAD_URL, file),

  uploadProspectGoalImage: (file: File): Promise<number | null | HttpError<ErrorBody>> =>
    uploadGoalImage(PROSPECT_GOALS_IMAGE_UPLOAD_URL, file)
};

const createGoal = (url: string, data: GoalPayload): Promise<CommandOutput> =>
  HttpService
    .post<CommandOutput>(url, data)

const getGoals = (url: string, cancelTokenSource?: CancelTokenSource | null): Promise<Goal[]> =>
  HttpService
    .get<GoalTO[]>(url, cancelTokenSource)
    .then(goalsTO => goalsTO.map(goalTO => goalFromGoalTO(goalTO)))

const deleteGoal = (url: string): Promise<CommandOutput> =>
  HttpService
    .delete<CommandOutput>(url)

const uploadGoalImage = (url: string, file: File): Promise<number | null | HttpError<ErrorBody>> =>
  HttpService
    .postFile<{fileId: number}>(url, file)
    .then(response => response.fileId)
