import {useEffect, useState} from 'react';

const ONE_SEC_INTERVAL = 1000;

export function useCountDownTimerHook(initState: number): [number, boolean, Function, Function] {
  const [seconds, setSeconds] = useState(initState);
  const [isActive, setIsActive] = useState(false);

  function activateCountDown() {
    setIsActive(true);
  }

  function reset() {
    setIsActive(false);
    setSeconds(initState);
  }

  useEffect(() => {
    let interval;
    if (isActive) {
      interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds => seconds - 1);
        } else {
          setIsActive(false);
          clearInterval(interval);
        }
      }, ONE_SEC_INTERVAL);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return [seconds, isActive, activateCountDown, reset];
}
