import {useEffect, useState} from 'react';
import {OperationDetails} from '../../shared/model/operations/OperationDetails';
import {CustomerOperationsService} from '../../shared/services/customer-operations.service';

export function useCustomerOperationDetailsHook(productId: number, operationId: number): OperationDetails | undefined {

  const [operationDetails, setOperationDetails] = useState<OperationDetails>();

  const getActivityDetails = async (productId: number, transactionId: number) => {
    const operationDetails = await CustomerOperationsService.getOperationDetails(productId, transactionId);
    setOperationDetails(operationDetails);
  };

  useEffect(() => {
    getActivityDetails(productId, operationId);
  }, [productId, operationId]);

  return operationDetails;
}
