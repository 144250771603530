import React from 'react';
import {Trans} from 'react-i18next';
import DiskarTechLogo from '../../../assets/images/logos/diskartech-logo.svg';
import LoginPageHero from '../../../assets/images/login-page-hero.svg';
import styles from './WelcomeScreenWrapper.module.scss';

interface WelcomeScreenWrapperProps {
  children: React.ReactElement;
}

export default function WelcomeScreenWrapper({children}: WelcomeScreenWrapperProps) {
  return (
    <div className={styles.container}>
      <img src={DiskarTechLogo} alt="DiskarTech" className={styles.logo} />
      <img src={LoginPageHero} alt="" className={styles['hero-image']} />
      <div className={styles.children}>
        {children}
      </div>
      <div className={styles.copyright}>
        <Trans>FOOTER.COPYRIGHT</Trans>
      </div>
    </div>
  );
};