import {RoutesPaths} from '../../routes/routes-paths/routes-paths';
import {RoutesParts} from '../../constants/routes-parts';

export const ResetPasswordRoutesPaths = {
  RESET_PASSWORD_PERSONAL_DATA_FORM: RoutesPaths.RESET_PASSWORD + '/personal-data',
  RESET_PASSWORD_SECURITY_CHECK: RoutesPaths.RESET_PASSWORD + '/security-check',
  RESET_PASSWORD_OTP: RoutesPaths.RESET_PASSWORD + RoutesParts.OTP,
  RESET_PASSWORD_SET_PASSWORD: RoutesPaths.RESET_PASSWORD + RoutesParts.SET_PASSWORD,
  RESET_PASSWORD_SUCCESS: RoutesPaths.RESET_PASSWORD + RoutesParts.SUCCESS
};
