import {Button} from '@material-ui/core';
import React from 'react';
import {Trans} from 'react-i18next';
import {connect} from 'react-redux';
import {useHistory} from 'react-router';
import GoalHero from '../../../assets/images/hero-goal.svg';
import UserData from '../../../shared/model/UserData';
import State from '../../../store/state';
import {StringHelper} from '../../../utils/string-helper';
import {GoalsRoutesPaths} from '../goals.routes-paths';
import styles from './CreateIntroPopupContent.module.scss';

interface CreateIntroPopupStateProps {
  userData?: UserData;
}

interface CreateIntroPopupContentComponentProps {
  onCreateGoalAbort: () => void;
}

type CreateIntroPopupContentProps = CreateIntroPopupContentComponentProps & CreateIntroPopupStateProps

const CreateIntroPopupContent = ({userData, onCreateGoalAbort}: CreateIntroPopupContentProps) => {

  const history = useHistory();

  return (
    <div className={styles.content}>
      <div className={styles.container}>
        <div className={styles.image}>
          <img src={GoalHero} alt="goal" />
        </div>
        <div className={styles.header}>
          <Trans>GOALS.CREATE_INTRO.HEADER</Trans>
        </div>
        <div className={styles.text}>
          <Trans tOptions={{userName: StringHelper.capitalizeFirstLetter(userData!.shortName)}}>
            GOALS.CREATE_INTRO.TEXT
          </Trans>
        </div>
        <Button className={styles['add-goal']} onClick={() => history.push(GoalsRoutesPaths.CREATE_FORM_PATH)}>
          <Trans>GOALS.CREATE_INTRO.ADD_GOAL_BUTTON</Trans>
        </Button>
        <Button className={styles.abort} variant="text" onClick={onCreateGoalAbort}>
          <Trans>GOALS.CREATE_INTRO.DECLINE_BUTTON</Trans>
        </Button>
      </div>
    </div>
  );
};
const mapStateToProps = (state: State,
                         componentProps: CreateIntroPopupContentComponentProps): CreateIntroPopupContentProps => ({
  userData: state.sessionData,
  ...componentProps
});

export default connect<CreateIntroPopupStateProps, {}, CreateIntroPopupContentComponentProps, State>(mapStateToProps)(
  CreateIntroPopupContent);
