import {OperationAttribute} from '../../../../shared/model/operations/OperationDetails';

// Technical attributes which should not be displayed
const EXCLUDED_ATTRIBUTES = [
  'EXTERNAL_PAYMENT_PROVIDER',
  'EXTERNAL_PAYMENT_GROUP',
  'EXTERNAL_PAYMENT_SERVICE_CODE',
  'EXTERNAL_TAG',
  'PARTNER_MERCHANT_CODE',
  'PARTNER_NAME',
  'EXTERNAL_PAYMENT_SERVICE_CHARGE',
  'EXTERNAL_PAYMENT_BANK_CHARGE'
];

export const filterExcludedAttributes = (attributes: OperationAttribute[]): OperationAttribute[] => {
  return attributes.filter(attribute =>
    !EXCLUDED_ATTRIBUTES.find(excludedAttribute => excludedAttribute === attribute.type)
  );
};
