export enum OperationCategory {
  CASH = 'CASH',
  CHECK = 'CHECK',
  TRANSFER = 'TRANSFER',
  INTEREST = 'INTEREST',
  MEMO = 'MEMO',
  FEE = 'FEE',
  HOLD = 'HOLD',
  OTHER = 'OTHER'
}
