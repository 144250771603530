import {JumioRoutesPaths} from './jumio/jumio.routes-paths';

export const VerificationRoutesPaths = {
  ELIGIBILITY: '/verification-eligibility',
  PERSONAL_DETAILS: '/verification-personal-details',
  ADDRESS: '/verification-address',
  ADDITIONAL_DETAILS: '/verification-additional-details',
  TERMS_AND_CONDITIONS: '/verification-terms-and-conditions',
  DATA_AND_PERSONALIZATION: '/verification-data-and-personalization',
  ID_DOCUMENT: '/verification-id-document',
  STEPS_SUMMARY: '/verification-steps-summary',
  JUMIO: JumioRoutesPaths.JUMIO
};
