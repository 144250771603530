import {Button} from '@material-ui/core';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import ReferralHero from '../../assets/images/hero-referral.svg';
import {ErrorHelper} from '../../utils/error-helper';
import {cleanUp} from '../../utils/handle-abort-controller-clean-up';
import Columns from '../shared/columns/Columns';
import MainContainer from '../shared/containers/MainContainer';
import CurrencyFormat from '../shared/currency-format/CurrencyFormat';
import Loader from '../shared/loader/Loader';
import Popup from '../shared/popups/Popup';
import PopupCloseButton from '../shared/popups/PopupCloseButton';
import Progress from '../shared/progress/Progress';
import ProgramDetailsPopupContent from './ProgramDetailsPopupContent';
import styles from './Referral.module.scss';
import {ReferralService} from './referral.service';

const MAX_SUM_OF_MONTHLY_REFERRAL_BONUSES = 30000;

const Referral = () => {

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [referralCode, setReferralCode] = useState<number>();
  const [sumOfMonthlyReferralBonuses, setSumOfMonthlyReferralBonuses] = useState<number>(0);
  const [isPlanDetailsOpen, setIsPlanDetailsOpen] = useState<boolean>(false);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    const userProfilePromise = ReferralService.getUserProfile(cancelTokenSource);
    const sumPromise = ReferralService.getSumOfMonthlyReferralBonuses(cancelTokenSource);

    Promise.all([userProfilePromise, sumPromise]).then(values => {
      const userProfile = values[0];
      const sum = values[1];

      setReferralCode(userProfile.referrerNumber);
      setSumOfMonthlyReferralBonuses(sum);

      setIsLoaded(true);
    }).catch(error => {
      if (ErrorHelper.isAbortOrSessionRestrictionError(error)) {
        return;
      }
      setReferralCode(undefined);
      setSumOfMonthlyReferralBonuses(0);
      setIsLoaded(true);
    });

    return cleanUp(cancelTokenSource);
  }, []);

  const copyCodeToClipboard = async () => {
    await navigator.clipboard.writeText(String(referralCode));
  };

  return (
    <>
      <MainContainer>
        <Columns
          heading="REFERRAL.TITLE"
          mainColumn={
            !isLoaded
              ? <Loader />
              : (
                <div className={styles['main-container']}>
                  <div className={styles['intro-header']}><Trans>REFERRAL.INTRO.TITLE</Trans></div>
                  <div className={styles['intro-text']}><Trans>REFERRAL.INTRO.TEXT</Trans></div>
                  <div className={styles['information-link']} onClick={() => setIsPlanDetailsOpen(true)}>
                    <Trans>REFERRAL.INFO_LINK_LABEL</Trans>
                  </div>
                  <div className={styles['referral-code-container']}>
                    <div className={styles['code-row']}>
                      <img src={ReferralHero} alt={'referral hero'} />
                      <div className={styles['code-container']}>
                        <div className={styles['code-container__code']}>
                          {referralCode}
                        </div>
                        <div className={styles['code-container__code-label']}>
                          <Trans>REFERRAL.CODE_LABEL</Trans>
                        </div>
                      </div>
                      <Button classes={{root: styles['code-row__copy']}} onClick={copyCodeToClipboard}>
                        <Trans>REFERRAL.COPY_CODE_LABEL</Trans>
                      </Button>
                    </div>
                    <div className={styles['progress']}>
                      <div className={styles['progress-container']}>
                        <div className={styles['progress-container__info']}>
                          <Trans>REFERRAL.PROGRESS_LABEL</Trans>
                        </div>
                        <Progress className={styles['progress-container__progress']}
                                  progress={sumOfMonthlyReferralBonuses} />
                      </div>
                      <div className={styles['progress-info-container']}>
                        <CurrencyFormat amount={sumOfMonthlyReferralBonuses} />
                        <CurrencyFormat amount={MAX_SUM_OF_MONTHLY_REFERRAL_BONUSES} />
                      </div>
                    </div>
                  </div>
                </div>
              )
          }
        />
      </MainContainer>
      <Popup open={isPlanDetailsOpen}>
        <>
          <PopupCloseButton handleClose={() => setIsPlanDetailsOpen(false)} />
          <ProgramDetailsPopupContent />
        </>
      </Popup>
    </>
  );
};

export default Referral;
