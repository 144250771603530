import {Button, TextField} from '@material-ui/core';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {isNumber} from 'lodash';
import React, {ReactElement, useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {useLocation} from 'react-router-dom';
import {HttpError} from '../../../shared/services/http.service';
import {fetchUserPrimaryAccount} from '../../../store/actions';
import {getUserAccount} from '../../../store/selectors';
import {ErrorHelper} from '../../../utils/error-helper';
import {AlertSnackbar} from '../../shared/alert-snackbar/AlertSnackbar';
import AmountTextField from '../../shared/fields/AmountTextField';
import Loader from '../../shared/loader/Loader';
import {TransferSuccessLocationProps} from '../../shared/transfer/success/TransferSuccess';
import {InternalTransferParams, TransferService} from '../transfer.service';
import {InternalTransferRoutesPaths} from './internal-transfer.routes-paths';
import styles from './InternalTransferPopupContent.module.scss';

interface FormFields {
  recipient: string;
  name: string;
  amount: number | '';
}

const formInitState = {recipient: '', name: '', amount: ''} as FormFields;

export interface InternalTransferConfirmationOutput {
  operationId: string;
}

interface InternalTransferLocationProps {
  error: HttpError<any>;
}

const InternalTransferPopupContent = () => {
  const history = useHistory();
  const location = useLocation<InternalTransferLocationProps>();
  const {t} = useTranslation();
  const [showError, setShowError] = useState(!!location.state?.error);
  const [error, setError] = useState<HttpError<any> | undefined>(location.state?.error);
  const userAccount = useSelector(getUserAccount);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserPrimaryAccount());
  }, [dispatch]);

  const InternalTransferForm = ({
                                  values,
                                  errors,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting
                                }: FormikProps<FormFields>): ReactElement => (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles['input-container']}>
        <TextField
          required
          error={!!errors.recipient}
          label={<Trans>TRANSFER.LABELS.RECIPIENT</Trans>}
          type="text"
          name="recipient"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.recipient}
          helperText={errors.recipient ? `${errors.recipient}` : ''}
        />
        <TextField
          required
          label={<Trans>TRANSFER.LABELS.NAME</Trans>}
          type="text"
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
        />
        <AmountTextField
          required
          // translation hook used as placeholder requires string
          placeholder={t('TRANSFER.LABELS.ZERO_AMOUNT')}
          name="amount"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.amount}
          helperText={t('TRANSFER.LABELS.FEES')} />
      </div>
      <div className={styles.actions}>
        <Button type="submit" className={styles.submit} disabled={isSubmitting}>
          <Loader buttonSpinner loaded={!isSubmitting}>
            <Trans>SHARED.COMMON.SUBMIT</Trans>
          </Loader>
        </Button>
      </div>
    </form>
  );

  const getErrorMessage = () => ErrorHelper.getErrorMessageTranslation(t, error!);

  function submit(values: FormFields, actions: FormikHelpers<FormFields>) {
    const accountId = userAccount!.id;

    const internalTransferParams: InternalTransferParams = {
      amount: isNumber(values.amount) ? values.amount : 0,
      //TODO - Check - According BE code this value can be product number or mobile number
      // but it doesn't work as mobile number
      recipientAccountNumber: values.recipient,
      recipientName: values.name,
      sourceId: accountId,
      updateCustomerContacts: false
    };

    TransferService.validateInternalTransfer(internalTransferParams)
      .then(() => makeInternalTransfer())
      .catch(error => {
        setError(error);
        setShowError(true);
      })
      .finally(() => actions.setSubmitting(false));

    const makeInternalTransfer = () =>
      TransferService.makeInternalTransfer(internalTransferParams).then(
        output => {
          const {commandId, payload, confirmationRecipient} = output;
          history.push(InternalTransferRoutesPaths.OTP, {
            commandId,
            confirmationRecipient,
            payload,
            onSuccess: redirectToSuccessMessage,
            onError: handleOtpError
          });
        });

    const redirectToSuccessMessage = (confirmationOutput: InternalTransferConfirmationOutput) => {
      history.push(InternalTransferRoutesPaths.SUCCESS, {
        operationId: parseInt(confirmationOutput.operationId),
        sourceProductId: accountId
      } as TransferSuccessLocationProps);
    };
    const handleOtpError = (error) => {
      history.push(InternalTransferRoutesPaths.INTERNAL_TRANSFER, {
        error
      });
    };
  }

  return (
    !userAccount
      ? <Loader />
      : <div className={styles.container}>
        <div className={styles.title}>
          <Trans>TRANSFER.INNER.TITLE</Trans>
        </div>
        <AlertSnackbar handleClose={() => setShowError(false)}
                       open={showError}
                       message={getErrorMessage()} />
        <Formik
          <FormFields>
          initialValues={formInitState}
          onSubmit={submit}
          children={InternalTransferForm}
        />
      </div>
  );
};

export default InternalTransferPopupContent;
