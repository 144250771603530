import {Button} from '@material-ui/core';
import React, {useContext} from 'react';
import {Trans} from 'react-i18next';
import {connect} from 'react-redux';
import {useLocation} from 'react-router';
import {MainRouterContext} from '../../../App';
import HeroFailed from '../../../assets/images/hero-verification-failed.svg';
import {DashboardRoutesPaths} from '../../../routes/routes-paths/dashboard.routes-paths';
import {VerificationService} from '../../../shared/services/verification.service';
import {applicationUnsealed} from '../../../store/actions';
import State from '../../../store/state';
import {ListParagraph} from '../../shared/list-paragraph/ListParagraph';
import styles from './OngoingVerificationFailurePopupContent.module.scss';

interface OngoingVerificationFailurePopupLocationProps {
  failureMessages: string[];
  applicationId: number;
}

interface OngoingVerificationFailurePopupDispatchProps {
  unsealProspectApplication: () => any
}

const OngoingVerificationFailurePopupContent = ({unsealProspectApplication}: OngoingVerificationFailurePopupDispatchProps) => {

  const mainRouterContext = useContext(MainRouterContext);
  const locationState = useLocation<OngoingVerificationFailurePopupLocationProps>()?.state;
  const {failureMessages, applicationId} = locationState ?? {failureMessages: [], applicationId: 0};

  const retryProspectApplication = () => {
    VerificationService.retryProspectApplication(applicationId).then(async () => {
        await unsealProspectApplication();
        mainRouterContext.globalHistory.push(DashboardRoutesPaths.VERIFICATION);
      }
    );
  };

  return (
    <div className={styles.container}>
      <img className={styles.image} src={HeroFailed} alt={'hero progress'} />
      <div className={styles.list}>
        <ListParagraph titleClassName={styles['list__title']}
                       listItemClassName={styles['list__item']}
                       title={<Trans>DASHBOARD.ONGOING_VERIFICATION_POPUP.FAILURE.TITLE</Trans>}
                       items={failureMessages} />
      </div>
      <Button className={styles.button} onClick={retryProspectApplication}>
        <Trans>DASHBOARD.ONGOING_VERIFICATION_POPUP.FAILURE.BUTTON_LABEL</Trans>
      </Button>
    </div>
  );
};

const mapDispatchToProps = (dispatch): OngoingVerificationFailurePopupDispatchProps => ({
  unsealProspectApplication: () => dispatch(applicationUnsealed())
});

export default connect<null, OngoingVerificationFailurePopupDispatchProps, {}, State>(null,
  mapDispatchToProps)(
  OngoingVerificationFailurePopupContent);

