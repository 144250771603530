import {Button} from '@material-ui/core';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {isNumber} from 'lodash';
import React, {ReactElement, useEffect} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {CURRENCY_CODE} from '../../../constants/currency';
import {APP_LOCALE_CODE} from '../../../constants/values';
import {OperationGroup, PartnerMerchant} from '../../../shared/model/operations/partners/PartnerMerchant';
import {PartnerOperationsService} from '../../../shared/services/partner-operations.service';
import {fetchAuthenticatedSystemProperties, fetchUserPrimaryAccount} from '../../../store/actions';
import {getAuthenticatedSystemProperties, getUserAccount} from '../../../store/selectors';
import {ErrorHelper} from '../../../utils/error-helper';
import {FeeHelper} from '../../../utils/fee-helper';
import {AlertSnackbar} from '../../shared/alert-snackbar/AlertSnackbar';
import AmountTextField from '../../shared/fields/AmountTextField';
import {ListParagraph} from '../../shared/list-paragraph/ListParagraph';
import Loader from '../../shared/loader/Loader';
import PartnerIcon from '../../shared/partners/partner-icon/PartnerIcon';
import {DepositRoutesPaths} from '../deposit.routes-paths';
import {DepositReferenceNumberLocationProps} from '../reference-number/DepositReferenceNumber';
import styles from './DepositAmount.module.scss';

interface FormFields {
  amount: number | '';
}

const formInitState = {
  amount: ''
} as FormFields;

interface DepositAmountProps {
  partnerMerchant: PartnerMerchant;
}

function DepositAmount({partnerMerchant}: DepositAmountProps) {
  const history = useHistory();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const authenticatedSystemProperties = useSelector(getAuthenticatedSystemProperties);
  const userAccount = useSelector(getUserAccount);
  const isLoaded = userAccount && authenticatedSystemProperties;

  useEffect(() => {
    dispatch(fetchAuthenticatedSystemProperties());
    dispatch(fetchUserPrimaryAccount());
  }, [dispatch]);

  const DepositAmountForm = ({
                               values,
                               handleChange,
                               handleBlur,
                               handleSubmit,
                               isSubmitting
                             }: FormikProps<FormFields>): ReactElement => (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles['input-container']}>
        <AmountTextField
          required
          label={<Trans>SHARED.COMMON.AMOUNT</Trans>}
          name="amount"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.amount}
          helperText={t('SHARED.COMMON.FEES_MAY_APPLY')}
        />
      </div>
      <div className={styles.actions}>
        <Button type="submit" className={styles.submit} disabled={isSubmitting}>
          <Loader buttonSpinner loaded={!isSubmitting}>
            <Trans>SHARED.COMMON.SUBMIT</Trans>
          </Loader>
        </Button>
      </div>
    </form>
  );

  function submit(values: FormFields, actions: FormikHelpers<FormFields>) {
    const depositParams = {
      amount: isNumber(values.amount) ? values.amount : 0,
      partner: partnerMerchant.partner,
      productId: userAccount!.id,
      merchantId: partnerMerchant.id
    };

    PartnerOperationsService.initiatePartnerDeposit(depositParams)
      .then(commandOutput => {
        // TODO provide valid commandOutput type
        const {id, referenceNumber, validUntil} = commandOutput.output as any;
        calculateFeesAndNavigateToReferenceNumber(id, referenceNumber, validUntil);
      })
      .catch(error => handleError(error));

    const calculateFeesAndNavigateToReferenceNumber = (operationId: number,
                                                       referenceNumber: number,
                                                       validUntil: string) => {
      PartnerOperationsService.calculatePartnerDepositFees(depositParams)
        .then(fees =>
          history.push(DepositRoutesPaths.PARTNER_DEPOSIT_REFERENCE_NUMBER, {
            amount: depositParams.amount,
            operationId,
            partnerMerchant,
            referenceNumber,
            validUntil,
            fee: FeeHelper.getTotalFee(fees)
          } as DepositReferenceNumberLocationProps)
        );
    };

    const handleError = error => {
      setErrorMessage(ErrorHelper.getErrorMessageTranslation(t, error));
      setShowError(true);
      actions.setSubmitting(false);
    };
  }

  const PartnerIconWithLabel = partnerMerchant && (
    <div className={styles['icon-wrapper']}>
      <PartnerIcon merchantCode={partnerMerchant.merchantCode} label={partnerMerchant.merchantName} />
      {partnerMerchant.merchantName}
    </div>
  );

  const maxDepositAmount = (): string | null => {
    const partnerMerchantSettings = authenticatedSystemProperties?.partnerOperationMerchants.find(
      partnerMerchantSettings => partnerMerchantSettings.id === partnerMerchant.id
    );
    const operationSettings = partnerMerchantSettings?.operations.find(
      operationSettings => operationSettings.operationGroup === OperationGroup.DEPOSIT
    );
    const maxDepositAmount = operationSettings?.maxOperationAmount;

    return maxDepositAmount
      ? new Intl.NumberFormat(
        APP_LOCALE_CODE,
        {style: 'currency', currency: CURRENCY_CODE, currencyDisplay: 'code'}
      ).format(maxDepositAmount)
      : null;
  };

  return (
    !isLoaded
      ? <Loader />
      : <>
        <div className={styles.title}>
          <Trans>DEPOSIT.HEADER</Trans>
        </div>
        <AlertSnackbar handleClose={() => setShowError(false)} open={showError} message={errorMessage} />
        <div className={styles.container}>
          <div className={styles.column}>
            {PartnerIconWithLabel}
            <Formik
              <FormFields>
              initialValues={formInitState}
              onSubmit={submit}
              children={DepositAmountForm}
            />
          </div>
          <div className={styles.column}>
            <div className={styles.reminders}>
              <ListParagraph
                titleClassName={styles['reminders__title']}
                title={<Trans>DEPOSIT.REMINDERS.TITLE</Trans>}
                items={[
                  ...maxDepositAmount()
                    ? [<Trans tOptions={{amount: maxDepositAmount()}}>DEPOSIT.REMINDERS.POINT_1</Trans>]
                    : [],
                  <Trans>DEPOSIT.REMINDERS.POINT_2</Trans>,
                  <Trans>DEPOSIT.REMINDERS.POINT_3</Trans>
                ]} />
            </div>
          </div>
        </div>
      </>
  );
}

export default DepositAmount;
