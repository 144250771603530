import {useTranslation} from 'react-i18next';
import {BILL_CATEGORY_TRANSLATION_PATH} from './constants';

export function useBillCategoryTranslationOrCodeHook(categoryGroup: string) {
  const {t, i18n} = useTranslation();

  const categoryTranslationPath = `${BILL_CATEGORY_TRANSLATION_PATH}.${categoryGroup}`;
  return i18n.exists(categoryTranslationPath)
    ? t(categoryTranslationPath)
    : categoryGroup;
}
