import {isNil} from 'lodash';
import React, {useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import trophy from '../../../assets/images/icons/trophy.svg';
import {blobToBase64String} from '../../../utils/file-utils';
import CurrencyFormat from '../../shared/currency-format/CurrencyFormat';
import Progress from '../../shared/progress/Progress';
import Goal from '../model/Goal';
import styles from './GoalRow.module.scss';

interface GoalRowProps {
  goal: Goal;
  onClick: () => void;
}

const GoalRow = ({goal, onClick}: GoalRowProps) => {

  const [image, setImage] = useState<string>('');
  const imageBlob = goal.imageFile;

  useEffect(() => {
    if (!isNil(imageBlob)) {
      blobToBase64String(imageBlob).then(imageString => setImage(imageString));
    } else {
      setImage(trophy);
    }
  }, [imageBlob]);

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles['image-container']}>
        <img className={styles['image-container__image']} src={image} alt={' '} />
      </div>
      <div className={styles['basic-info']}>
        <div className={styles.name}>
          {goal.name}
        </div>
        <div className={styles.amount}>
          <CurrencyFormat amount={goal.amount} />
        </div>
      </div>
      <div className={styles['progress-container']}>
        {/* info text changes color to white when progress bigger than 60% */}
        <div className={`${styles['progress-container__info']} ${
          goal.progress < 60 ? '' : styles['progress-container__info--nearly-finished']
        }`}>
          {goal.progress}%&nbsp;<Trans>GOALS.PROGRESS_COMPLETE</Trans>
        </div>
        <Progress progress={goal.progress} />
      </div>
    </div>
  );
};

export default GoalRow;
