import axios from 'axios';
import {isEmptyArray} from 'formik';
import {isNil} from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {useLocation} from 'react-router';
import {AppSnackbarContext} from '../../App';
import UserData from '../../shared/model/UserData';
import {FileService} from '../../shared/services/file.service';
import State from '../../store/state';
import {ErrorHelper} from '../../utils/error-helper';
import {cleanUp} from '../../utils/handle-abort-controller-clean-up';
import {checkIfCustomer, checkIfProspect} from '../../utils/user-group-checks';
import {AlertSnackbarActionType} from '../shared/alert-snackbar/alertSnackBarHook';
import Columns from '../shared/columns/Columns';
import MainContainer from '../shared/containers/MainContainer';
import Loader from '../shared/loader/Loader';
import CreatePopup from './create/CreatePopup';
import GoalsEmptyState from './empty-state/GoalsEmptyState';
import GoalOverviewPopup from './goal-overview-popup/GoalOverviewPopup';
import GoalRow from './goal-row/GoalRow';
import {GoalsService} from './goals.service';
import Goal from './model/Goal';

interface GoalsStateProps {
  userData: UserData;
}

type GoalsProps = GoalsStateProps;

export interface GoalsLocationProps {
  isCreateStarted: boolean;
}

const Goals = ({userData}: GoalsProps) => {

  const [goals, setGoals] = useState<Goal[]>([]);
  const [goalOverview, setGoalOverview] = useState<Goal>({} as Goal);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isOverviewPopupOpen, setIsOverviewPopupOpen] = useState<boolean>(false);
  const locationState = useLocation<GoalsLocationProps>()?.state;
  const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(locationState?.isCreateStarted ?? false);
  const isCustomer = checkIfCustomer(userData);
  const isProspect = checkIfProspect(userData);
  const appSnackbarContext = useContext(AppSnackbarContext);
  const [t] = useTranslation();

  useEffect(() => {
    if (isLoaded) {
      return;
    }

    const cancelTokenSource = axios.CancelToken.source();

    (async () => {
      const goals = isCustomer ? await GoalsService.getCustomerGoals(cancelTokenSource) :
        isProspect ? await GoalsService.getProspectGoals(cancelTokenSource) :
          null;

      if (!isNil(goals) && !isEmptyArray(goals)) {
        for (let i = 0; i < goals.length; i++) {
          if (!isNil(goals[i].imageId)) {
            goals[i].imageFile = await FileService.getFileById(goals[i].imageId, cancelTokenSource);
          }
        }

        setGoals(goals);
      }
    })()
      .then(() => setIsLoaded(true))
      .catch(error => {
        if (ErrorHelper.isAbortOrSessionRestrictionError(error)) {
          return;
        }
        setGoals([] as Goal[]);
        setIsLoaded(true);
      });

    return cleanUp(cancelTokenSource);
  }, [isCustomer, isLoaded, isProspect, userData]);

  const openGoalOverviewPopup = (id: number) => {
    const goal = goals.find(goal => goal.id === id);
    setGoalOverview(goal ?? ({} as Goal));
    setIsOverviewPopupOpen(true);
  };

  const onGoalAdded = () => {
    setGoals([]);
    setIsLoaded(false);
    setIsCreatePopupOpen(false);

    appSnackbarContext.dispatchAction({
      type: AlertSnackbarActionType.SHOW,
      message: t('GOALS.CREATE_SUCCESS_MESSAGE')
    });
  };

  const onGoalDeleted = () => {
    setGoals([]);
    setIsLoaded(false);
    setIsOverviewPopupOpen(false);
  };

  const GoalsList = isNil(goals) || isEmptyArray(goals)
    ? <GoalsEmptyState onCreateButtonClick={() => setIsCreatePopupOpen(true)} />
    : <>
      {
        goals.map(goal => <GoalRow goal={goal} key={goal.id} onClick={() => openGoalOverviewPopup(goal.id)} />)
      }
    </>;

  return (
    <>
      <MainContainer>
        <Columns
          heading="GOALS.HEADER"
          mainColumn={
            isLoaded ? GoalsList : <Loader />
          }
        />
      </MainContainer>
      <GoalOverviewPopup goal={goalOverview}
                         isPopupOpen={isOverviewPopupOpen}
                         onPopupClose={() => setIsOverviewPopupOpen(false)}
                         onGoalDeleted={onGoalDeleted} />
      <CreatePopup isPopupOpen={isCreatePopupOpen}
                   onPopupClose={() => setIsCreatePopupOpen(false)}
                   onCreateGoalSuccess={onGoalAdded} />
    </>
  );
};

const mapStateToProps = (state: State): GoalsStateProps => ({
  userData: state.sessionData ?? {} as UserData
});

export default connect<GoalsStateProps, {}, {}, State>(mapStateToProps)(Goals);
