import React from 'react';
import styles from './BuyLoadProductEmptyState.module.scss';

interface EmptyStateProps{
  description: string;
  link: string;
  onClick: () => void;
}

export default function BuyLoadProductEmptyState({description, link, onClick}: EmptyStateProps) {
  return (
    <div className={styles.container}>
      {description}
      <div className={styles.link} onClick={onClick}>{link}</div>
    </div>
  );
}
