import React from 'react';
import styles from './MainContainer.module.scss';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';

interface MainContainerProps {
  className?: string;
  children: React.ReactElement;
}

const MainContainer = ({className, children}: MainContainerProps) => (
  <>
    <Header />
    <main className={`${styles.container} ${className ?? ''}`}>{children}</main>
    <Footer />
  </>
);

export default MainContainer;
