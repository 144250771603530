import {Address, AddressTO} from './Address';
import {IncomeSource, IncomeSourceTO} from './IncomeSource';
import {ProspectApplicationCondition, ProspectApplicationConditionTO} from './ProspectAppliactionCondition';

export enum EkycStatus {
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED'
}

export enum ProspectApplicationStatus {
  INITIATED = 'INITIATED',
  SEALED = 'SEALED',
  SENT_FOR_APPROVAL = 'SENT_FOR_APPROVAL',
  APPROVED = 'APPROVED'
}

export enum ProspectApplicationMode {
  REGULAR = 'REGULAR',
  EXPRESS = 'EXPRESS'
}

export interface ProspectApplicationProcessCommandData {
  applicationStatus: ProspectApplicationStatus;
  customerChannelActivated: boolean;
}

export interface CustomAttribute {
  code: string;
  value: string;
}

export interface ApplicationTO<T_STATUS = string, T_MODE = string, T_INCOME = IncomeSourceTO, T_ADDRESS = AddressTO> {
  status: T_STATUS;
  alias?: string;
  birthDate?: string;
  birthPlace?: string;
  citizenshipId?: number;
  civilStatusId?: number;
  countryOfWorkId?: number;
  creditScoringAgreement?: boolean;
  customAttributes?: CustomAttribute[];
  customerPhotoId?: number;
  customerSignatureId?: number;
  documentExpirationDate?: string;
  documentFileIds?: number[];
  documentNumber?: string;
  documentTypeId?: number;
  email?: string;
  firstName?: string;
  genderId?: number;
  gsis?: string;
  id: number;
  incomeSources?: T_INCOME[];
  institutionCode?: string;
  institutionIdNumber?: string;
  institutionMember?: boolean;
  lastName?: string;
  middleName?: string;
  mobileNo?: string;
  mode?: T_MODE;
  mothersFirstName?: string;
  mothersLastName?: string;
  mothersMiddleName?: string;
  nickname?: string;
  primaryAddress?: T_ADDRESS;
  productDefinitionId?: number;
  profileSharingAgreement?: boolean;
  profilingAgreement?: boolean;
  promosAndRewardsAgreement?: boolean;
  referrerNumber?: string;
  sealed?: boolean;
  secondaryAddress?: T_ADDRESS;
  secondaryMobileNo?: string;
  sss?: string;
  thirdPartyPromosAgreement?: boolean;
  tin?: string;
  titleId?: number;
  uuid?: string;
}

export interface Application extends ApplicationTO<ProspectApplicationStatus, ProspectApplicationMode, IncomeSource, Address> {
}

export interface ProspectApplicationTO<T_APPLICATION = ApplicationTO, T_CONDITION = ProspectApplicationConditionTO> {
  application: T_APPLICATION;
  conditions: T_CONDITION[];
  conditionsFetched: boolean;
}

export interface ProspectApplication extends ProspectApplicationTO<Application, ProspectApplicationCondition> {
}
