import {Tabs} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import React from 'react';
import {Trans} from 'react-i18next';
import {Link, withRouter} from 'react-router-dom';
import styles from './NavBar.module.scss';
import {RoutesPaths} from '../../../routes/routes-paths/routes-paths';
import includes from 'lodash/includes';

import {ReactComponent as ACCOUNT_ICON} from '../../../assets/images/icons/navbar-icons/account.svg';
import {ReactComponent as GOALS_ICON} from '../../../assets/images/icons/navbar-icons/goal.svg';
import {ReactComponent as HISTORY_ICON} from '../../../assets/images/icons/navbar-icons/history.svg';
import {ReactComponent as HOME_ICON} from '../../../assets/images/icons/navbar-icons/home.svg';

function NavBar(props) {
  const tabLinks = [RoutesPaths.DASHBOARD, RoutesPaths.HISTORY, RoutesPaths.GOALS, RoutesPaths.ACCOUNT];
  const path = props.location.pathname;
  const currentLocation = includes(tabLinks, path) ? path: tabLinks[0];

  return (
    <Tabs value={currentLocation}
          indicatorColor="primary"
          textColor="primary"
          classes={{root: styles.tabs, indicator: styles['tabs__indicator']}}>
      <Tab label={<Trans>HEADER.NAV_BAR.HOME</Trans>}
           value={tabLinks[0]}
           to={tabLinks[0]}
           component={Link}
           icon={<HOME_ICON />}
           classes={{root: styles.tab}}
      />
      <Tab label={<Trans>HEADER.NAV_BAR.HISTORY</Trans>}
           value={tabLinks[1]}
           to={tabLinks[1]}
           component={Link}
           icon={<HISTORY_ICON />}
           classes={{root: styles.tab}}
      />
      <Tab label={<Trans>HEADER.NAV_BAR.GOALS</Trans>}
           value={tabLinks[2]}
           to={tabLinks[2]}
           component={Link}
           icon={<GOALS_ICON />}
           classes={{root: styles.tab}}
      />
      { /* Tab visible only on mobile */}
      <Tab label={<Trans>HEADER.NAV_BAR.ACCOUNT</Trans>}
           value={tabLinks[3]}
           to={tabLinks[3]}
           component={Link}
           icon={<ACCOUNT_ICON />}
           classes={{root: `${styles.tab} ${styles['tab--account']}`}}
      />
    </Tabs>
  );
}

export default withRouter(NavBar);
