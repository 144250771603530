import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {connect} from 'react-redux';
import {OperationBasicData} from '../../../shared/model/operations/OperationBasicData';
import {CustomerOperationsService} from '../../../shared/services/customer-operations.service';
import State from '../../../store/state';
import {ErrorHelper} from '../../../utils/error-helper';
import {cleanUp} from '../../../utils/handle-abort-controller-clean-up';
import {checkIfNotProspect} from '../../../utils/user-group-checks';
import ActivitiesEmptyState from '../../shared/activities/empty-state/ActivitiesEmptyState';
import Loader from '../../shared/loader/Loader';
import RecentActivitiesHeader from './header/RecentActivitiesHeader';
import styles from './RecentActivities.module.scss';
import RecentActivitiesTable from './table/RecentActivitiesTable';

const ACTIVITIES_LIMIT = 5;

const RecentActivities = props => {
  const [activities, setActivities] = useState<OperationBasicData[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const isNotProspect = checkIfNotProspect(props.userData);

  useEffect(() => {
    // get activities of account if user is not Prospect
    if (isNotProspect) {
      const cancelTokenSource = axios.CancelToken.source();

      CustomerOperationsService.getCustomerOperations({pageSize: ACTIVITIES_LIMIT}, cancelTokenSource)
        .then(operations => {
          setIsLoaded(true);
          setActivities(operations.items);
        })
        .catch(error => {
          if (ErrorHelper.isAbortError(error)) {
            return;
          }
          setIsLoaded(true);
          setActivities([] as OperationBasicData[]);
        });

      return cleanUp(cancelTokenSource);
    } else {
      setIsLoaded(true);
    }
  }, [isNotProspect]);

  const emptyState = isNotProspect
    ? <ActivitiesEmptyState text={<Trans>ACTIVITIES.NO_ACTIVITIES_YET</Trans>}
                            linkText={<Trans>ACTIVITIES.NO_ACTIVITIES_YET_LINK</Trans>} />
    : <ActivitiesEmptyState text={<Trans>ACTIVITIES.PROSPECT_NOT_ACTIVATED</Trans>} />;

  return (
    <div className={styles.container}>
      <RecentActivitiesHeader linkVisible={activities?.length > 0} />
      <Loader loaded={isLoaded}>
        {
          (activities?.length === 0) ? emptyState :
            <div className={styles.activities}>
              <RecentActivitiesTable activities={activities} />
            </div>
        }
      </Loader>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  userData: state.sessionData
});

export default connect(mapStateToProps, null)(RecentActivities);
