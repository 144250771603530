import React from 'react';
import Otp from '../../shared/otp/Otp';
import PopupWithMemoryRouter from '../../shared/popups/PopupWithMemoryRouter';
import {KeyedRouteProps} from '../../shared/routes/model/Routes';
import TransferSuccess from '../../shared/transfer/success/TransferSuccess';
import InternalTransfer from './InternalTransferPopupContent';
import {InternalTransferRoutesPaths} from './internal-transfer.routes-paths';

interface InternalTransferPopupProps {
  isPopupOpen: boolean;
  onPopupClose: () => void;
}

export default function InternalTransferPopup({isPopupOpen, onPopupClose}: InternalTransferPopupProps) {

  const routes: KeyedRouteProps[] = [
    {
      path: InternalTransferRoutesPaths.INTERNAL_TRANSFER,
      key: 'INTERNAL_TRANSFER',
      component: InternalTransfer
    },
    {
      path: InternalTransferRoutesPaths.OTP,
      key: 'INTERNAL_TRANSFER_OTP',
      component: Otp
    },
    {
      path: InternalTransferRoutesPaths.SUCCESS,
      key: 'INTERNAL_TRANSFER_SUCCESS',
      component: TransferSuccess
    }
  ];

  return <PopupWithMemoryRouter handleClose={onPopupClose} open={isPopupOpen} routes={routes} />;
}
