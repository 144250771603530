import {PaymentGroup} from '../../../shared/model/PaymentProduct';

export interface PaymentParamsTO<T_PAYMENT_GROUP = string> {
  amount: number;
  paymentGroup: T_PAYMENT_GROUP;
  productId: number;
  serviceCode: string;
  dynamicFieldValues: Object;
}

export interface PaymentValidationTO<T_INPUT = PaymentParamsTO> {
  errorMessages: string[];
  paymentValid: boolean;
  input: T_INPUT;
}

export interface PaymentParams extends PaymentParamsTO<PaymentGroup> {
}

export interface PaymentValidation extends PaymentValidationTO<PaymentParams> {
}

export const fromPaymentValidationTO = (paymentValidationTO: PaymentValidationTO): PaymentValidation => ({
  ...paymentValidationTO,
  input: {
    ...paymentValidationTO.input,
    paymentGroup: PaymentGroup[paymentValidationTO.input.paymentGroup]
  }
});
