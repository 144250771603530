import {StylesProvider, ThemeProvider} from '@material-ui/core';
import i18n from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom';
import {initReactI18next} from 'react-i18next';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import translationENPH from './i18n/en_PH.json';
import * as serviceWorker from './serviceWorker';
import {DictionaryService} from './shared/services/dictionary.service';
import {fetchSystemProperties} from './store/actions';
import store from './store/store';
import {theme} from './Theme';

const languageCode = 'en_PH';

i18n
  .use(initReactI18next)
  .init({
    lng: languageCode,
    fallbackLng: languageCode,
    interpolation: {
      escapeValue: false
    },
    resources: {
      [languageCode]: {
        translation: translationENPH
      }
    }
  });

// React app is running as async function to ensure execution order of necessary requests before start
(async (): Promise<void> => {

  await store.dispatch(fetchSystemProperties());
  const operationNames = await DictionaryService.getTranslations();

  i18n.addResourceBundle(languageCode, 'translation', operationNames, true);

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </StylesProvider>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
