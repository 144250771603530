import React from 'react';
import {Trans} from 'react-i18next';
import styles from './Heading.module.scss';

interface HeadingProps {
  title: string;
}

const Heading = ({title}: HeadingProps) => (
  <h1 className={styles.header}>
    <Trans>{title}</Trans>
  </h1>
);

export default Heading;
