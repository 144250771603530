import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import PendingWithdrawalsIcon from '../../../assets/images/icons/withdrawal-icons/pending-withdrawals.svg';
import {DashboardRoutesPaths} from '../../../routes/routes-paths/dashboard.routes-paths';
import {OptionRow} from '../../shared/option-row/OptionRow';
import {Withdrawal} from './model/Withdrawal';

interface PendingWithdrawalsOptionProps {
  withdrawals: Withdrawal[]
}

export const PendingWithdrawalsOption = ({withdrawals} : PendingWithdrawalsOptionProps) => {
  const history = useHistory();
  const [t] = useTranslation();

  const navigateToPendingWithdrawals = () => {
    history.push(DashboardRoutesPaths.PENDING_WITHDRAWALS);
  }

  return (
    <>
      <OptionRow onClick={navigateToPendingWithdrawals}
                 iconSrc={PendingWithdrawalsIcon}
                 title={t('WITHDRAW.PENDING_WITHDRAWALS.TITLE')}
                 description={t('WITHDRAW.PENDING_WITHDRAWALS.DESCRIPTION')} />
    </>
  );
};
