import React, {useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FAQ_URL, PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL} from '../../constants/values';
import {getIsPasswordChangeRequired} from '../../store/selectors';
import Columns from '../shared/columns/Columns';
import ExternalLink from '../shared/external-link/ExternalLink';
import MainContainer from '../shared/containers/MainContainer';
import PasswordChangePopup from './password-change/PasswordChangePopup';
import styles from './Settings.module.scss';

const Settings = () => {

  const isPasswordChangeRequired = useSelector(getIsPasswordChangeRequired);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsPopupOpen(!!isPasswordChangeRequired);
  }, [isPasswordChangeRequired]);

  return (
    <>
      <MainContainer>
        <Columns
          heading="SETTINGS.TITLE"
          mainColumn={
            <>
              <div className={styles.title}>
                <Trans>SETTINGS.SECURITY.TITLE</Trans>
              </div>
              <div className={styles.option} onClick={() => setIsPopupOpen(true)}>
                <Trans>SETTINGS.SECURITY.CHANGE_PASSWORD_LABEL</Trans>
              </div>
              <div className={styles.title}>
                <Trans>SETTINGS.ABOUT.TITLE</Trans>
              </div>
              <ExternalLink className={styles.option} href={PRIVACY_POLICY_URL}>
                <Trans>SETTINGS.ABOUT.PRIVACY_POLICY_LABEL</Trans>
              </ExternalLink>
              <ExternalLink className={styles.option} href={TERMS_AND_CONDITIONS_URL}>
                <Trans>SETTINGS.ABOUT.TERMS_AND_CONDITIONS_LABEL</Trans>
              </ExternalLink>
              <ExternalLink className={styles.option} href={FAQ_URL}>
                <Trans>SETTINGS.ABOUT.FAQ_LABEL</Trans>
              </ExternalLink>
            </>
          }
        />
      </MainContainer>
      <PasswordChangePopup isPopupOpen={isPopupOpen} onPopupClose={() => setIsPopupOpen(false)} />
    </>
  );
};

export default Settings;
