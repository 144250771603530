import React from 'react';
import {exactRoutes} from '../../../utils/exact-routes';
import {JSXHelper} from '../../../utils/jsx-helper';
import {KeyedRouteProps} from '../routes/model/Routes';
import Popup from './Popup';
import PopupCloseButton from './PopupCloseButton';
import MemoryRouterWithRedirect from '../routes/MemoryRouterWithRedirect';

export interface PopupMemoryRouterRouteProps extends KeyedRouteProps {
  hideDefaultPopupCloseButton?: boolean;
}

interface PopupWithMemoryRouterProps {
  contentWrapperClass?: string;
  handleClose: () => void;
  open: boolean;
  routes: PopupMemoryRouterRouteProps[];
}

const PopupWithMemoryRouter = ({contentWrapperClass = '', handleClose, open, routes}: PopupWithMemoryRouterProps) => {
  const routesToRender = exactRoutes(
    routes.map(route =>
      ({
        ...route,
        render: props => (
          <>
            { !route.hideDefaultPopupCloseButton && <PopupCloseButton handleClose={handleClose} /> }
            { route.render ? route.render(props) : JSXHelper.renderComponentFromType(route.component, props) }
          </>
        )
    })
  ));

  return (
    <Popup open={open}>
      <div className={contentWrapperClass}>
        <MemoryRouterWithRedirect routes={routesToRender} />
      </div>
    </Popup>
  );
};

export default PopupWithMemoryRouter;
