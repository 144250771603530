import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import {Formik, FormikProps} from 'formik';
import {mapValues, toNumber} from 'lodash';
import React, {ReactElement, useContext} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {INCORRECT_FORMAT_MESSAGE_CODE, REQUIRED_FIELD_MESSAGE_CODE} from '../../../constants/values';
import {DictionaryCode} from '../../../shared/model/Dictionary';
import {IncomeSourceType} from '../../../shared/model/verification/IncomeSource';
import {VerificationService} from '../../../shared/services/verification.service';
import {DictionaryHelper} from '../../../utils/dictionary-helper';
import {getUndefinedIfEmpty} from '../../../utils/undefined-if-empty';
import Loader from '../../shared/loader/Loader';
import {VerificationStep} from '../steps/VerificationStep';
import {VerificationStepContent} from '../steps/VerificationStepContent';
import {VerificationStepFooter} from '../steps/VerificationStepFooter';
import {VerificationContext} from '../Verification';
import styles from './VerificationAdditionalDetails.module.scss';

const CHECKBOX_ERROR_KEY = 'income_source_form_group';

const ValidationSchema = Yup.object({
  salaryIncome: Yup.boolean(),
  commissionIncome: Yup.boolean(),
  businessIncome: Yup.boolean(),
  dividendsIncome: Yup.boolean(),
  proceedsIncome: Yup.boolean(),
  remittancesIncome: Yup.boolean(),
  natureOfWorkId: Yup.string().optional(),
  gsis: Yup.string().matches(new RegExp('^[0-9]{11}$'), INCORRECT_FORMAT_MESSAGE_CODE),
  sss: Yup.string().matches(new RegExp('^[0-9]{10}$'), INCORRECT_FORMAT_MESSAGE_CODE),
  tin: Yup.string().matches(new RegExp('^[0-9]{9,12}$'), INCORRECT_FORMAT_MESSAGE_CODE)
}).test(
  '',
  '',
  checkbox => {
    if (checkbox?.salaryIncome ||
      checkbox?.commissionIncome ||
      checkbox?.businessIncome ||
      checkbox?.dividendsIncome ||
      checkbox?.proceedsIncome ||
      checkbox?.remittancesIncome) {
      return true;
    }

    return new Yup.ValidationError(
      REQUIRED_FIELD_MESSAGE_CODE,
      null,
      CHECKBOX_ERROR_KEY
    );
  }
).defined();

type FormFields = Yup.InferType<typeof ValidationSchema>;

export const VerificationAdditionalDetails = () => {
  const {t} = useTranslation();
  const {application, dictionaries, setApplication} = useContext(VerificationContext);
  const natureOfWorkOptions = DictionaryHelper.getDictionaryByCode(dictionaries,
    DictionaryCode.NATURE_OF_WORK_BUSINESS);

  const TRANS_PREFIX = 'VERIFICATION.ADDITIONAL_DETAILS';
  const RouteTrans = ({children}) => <Trans>{`${TRANS_PREFIX}.${children}`}</Trans>;

  const incomeSourcesLabels = {
    salaryIncome: t(`${TRANS_PREFIX}.SALARY`),
    commissionIncome: t(`${TRANS_PREFIX}.COMMISSION`),
    businessIncome: t(`${TRANS_PREFIX}.BUSINESS`),
    dividendsIncome: t(`${TRANS_PREFIX}.DIVIDENDS`),
    proceedsIncome: t(`${TRANS_PREFIX}.PROCEEDS`),
    remittancesIncome: t(`${TRANS_PREFIX}.REMITTANCES`)
  };

  const incomeSourcesKeys = Object.keys(incomeSourcesLabels);
  const chosenIncomeSourcesKeys = (values: FormFields) => incomeSourcesKeys.filter(key => values[key]);

  const submit = (values: FormFields) => {
    // Prevent from sending empty strings to the backend
    const mappedValues = mapValues(values, value => getUndefinedIfEmpty(value));
    const {natureOfWorkId, gsis, sss, tin} = mappedValues;
    const sharedIncomeSourceProperties = {
      natureOfWorkId: natureOfWorkId ? toNumber(natureOfWorkId) : undefined,
      primary: false,
      type: IncomeSourceType.OTHER
    };

    const incomeSources = chosenIncomeSourcesKeys(values).map(
      key => ({
        ...sharedIncomeSourceProperties,
        description: incomeSourcesLabels[key]
      })
    );

    const undefinedWhenEmptyString = (value?: string) => value && value.length ? value : undefined;

    const applicationData = {
      ...application,
      incomeSources,
      gsis: undefinedWhenEmptyString(gsis),
      sss: undefinedWhenEmptyString(sss),
      tin: undefinedWhenEmptyString(tin)
    };

    return VerificationService.updateProspectApplication(application.id, applicationData)
      .then(() => setApplication(applicationData));
  };

  const AdditionalDetailsForm = ({values, errors, handleChange, handleBlur, submitForm, validateForm, isValid}: FormikProps<FormFields>): ReactElement =>
    (
      <form>
        <VerificationStepContent description={<RouteTrans>DESCRIPTION</RouteTrans>}>
          <FormControl component="fieldset"
                       className={
                         `${styles.checkboxes} ${errors[CHECKBOX_ERROR_KEY] ? styles['checkboxes--error'] : ''}`
                       }
                       error={errors[CHECKBOX_ERROR_KEY]}>
            <FormLabel classes={{root: styles['checkboxes__top-label']}} component="legend">
              <RouteTrans>SOURCE_OF_FUNDS</RouteTrans>
            </FormLabel>
            <FormGroup>
              {
                incomeSourcesKeys.map(key =>
                  <FormControlLabel
                    control={<Checkbox checked={values[key]} onChange={handleChange} name={key} />}
                    label={incomeSourcesLabels[key]}
                    classes={{label: styles.label}}
                    key={key} />
                )
              }
            </FormGroup>
            <FormHelperText>
              <Trans>{errors[CHECKBOX_ERROR_KEY]}</Trans>
            </FormHelperText>
          </FormControl>
          <FormControl className={styles.input}>
            <InputLabel id="nature-of-work-label">
              <RouteTrans>NATURE_OF_WORK_OPTIONAL</RouteTrans>
            </InputLabel>
            <Select
              required
              labelId="nature-of-work-label"
              type="number"
              name="natureOfWorkId"
              displayEmpty
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.natureOfWorkId}>
              {
                natureOfWorkOptions.map((natureOfWork, index) => (
                  <MenuItem value={natureOfWork.id} key={index}>
                    {natureOfWork.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          {/* TODO Provide input masks for textfields (only numbers) */}
          <TextField
            className={styles.input}
            label={<RouteTrans>TIN_OPTIONAL</RouteTrans>}
            type="text"
            name="tin"
            error={!!errors.tin}
            helperText={errors.tin && <Trans>{errors.tin}</Trans>}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.tin}
          />
          <TextField
            className={styles.input}
            label={<RouteTrans>SSS_OPTIONAL</RouteTrans>}
            type="text"
            name="sss"
            error={!!errors.sss}
            helperText={errors.sss && <Trans>{errors.sss}</Trans>}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.sss}
          />
          <TextField
            className={styles.input}
            label={<RouteTrans>GSIS_OPTIONAL</RouteTrans>}
            type="text"
            name="gsis"
            error={!!errors.gsis}
            helperText={errors.gsis && <Trans>{errors.gsis}</Trans>}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.gsis}
          />
        </VerificationStepContent>
        <VerificationStepFooter validateForm={validateForm} submit={submitForm} isSubmitDisabled={!isValid} />
      </form>
    );

  const applicationIncomeSources = application.incomeSources ?? [];
  const initialNatureOfWorkId: string = applicationIncomeSources.length && applicationIncomeSources[0].natureOfWorkId
    ? String(applicationIncomeSources[0].natureOfWorkId)
    : '';
  let initialIncomeSources = {
    salaryIncome: false,
    commissionIncome: false,
    businessIncome: false,
    dividendsIncome: false,
    proceedsIncome: false,
    remittancesIncome: false
  };

  applicationIncomeSources.forEach(source => {
    incomeSourcesKeys.forEach(key => {
      if (source.description === incomeSourcesLabels[key]) {
        initialIncomeSources[key] = true;
        return;
      }
    });
  });

  return (
    <VerificationStep>
      {
        natureOfWorkOptions.length === 0 ? <Loader />
          : <Formik
            <FormFields>
            onSubmit={submit}
            validationSchema={ValidationSchema}
            initialValues={{
              ...initialIncomeSources,
              natureOfWorkId: initialNatureOfWorkId,
              gsis: application.gsis ?? '',
              sss: application.sss ?? '',
              tin: application.tin ?? ''
            }}
            children={AdditionalDetailsForm}
          />
      }
    </VerificationStep>
  );
};
