import React from 'react';
import {Trans} from 'react-i18next';
import ReferralHero from '../../../../assets/images/hero-referral.svg';
import styles from './InviteCodePopup.module.scss';

export default function InviteCodePopup() {
  return (
    <div className={styles.container}>
      <img src={ReferralHero} alt={'referral hero'} className={styles['referral-hero']} />
      <h1 className={styles.header}><Trans>REGISTER.INVITE_CODE_POPUP.TITLE</Trans></h1>
      <p className={styles.paragraph}><Trans>REGISTER.INVITE_CODE_POPUP.PARAGRAPH_1</Trans></p>
      <p className={styles.paragraph}><Trans>REGISTER.INVITE_CODE_POPUP.PARAGRAPH_2</Trans></p>
      <p className={styles.paragraph}><Trans>REGISTER.INVITE_CODE_POPUP.PARAGRAPH_3</Trans></p>
    </div>
  );
}