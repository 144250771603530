import {Button} from '@material-ui/core';
import React, {useContext} from 'react';
import {Trans} from 'react-i18next';
import {useLocation} from 'react-router';
import {MainRouterContext} from '../../../../App';
import StatusActive from '../../../../assets/images/icons/status-icons/status-active.svg';
import {DATE_WITH_TIME_FORMAT} from '../../../../constants/date';
import {RoutesPaths} from '../../../../routes/routes-paths/routes-paths';
import {useCustomerOperationDetailsHook} from '../../../../utils/hooks/getCustomerOperationDetailsHook';
import CurrencyFormat from '../../currency-format/CurrencyFormat';
import Loader from '../../loader/Loader';
import OperationDetail from '../../operation/operation-detail/OperationDetail';
import styles from './CardlessWithdrawalSuccess.module.scss';

interface CardlessWithdrawalSuccessLocationState {
  accountId: number;
  fee: number;
  confirmationOutput: {
    operationId: number;
    referenceNumber: number;
  };
}

export default function CardlessWithdrawalSuccess() {
  const locationState = useLocation<CardlessWithdrawalSuccessLocationState>().state;
  const mainRouter = useContext(MainRouterContext);
  const operationDetails = useCustomerOperationDetailsHook(
    locationState.accountId,
    locationState.confirmationOutput.operationId
  );

  const getTotalAmount = () => {
    const amount = operationDetails?.amount ?? 0;
    return amount < 0 ? amount - locationState.fee : amount + locationState.fee;
  };

  const descriptionAndButton = (
    <>
      <div className={styles.description}>
        <Trans>CARDLESS_WITHDRAWAL.SUCCESS.DESCRIPTION</Trans>
      </div>
      <Button onClick={() => mainRouter.globalHistory.push(RoutesPaths.DASHBOARD)} className={styles['back-button']}>
        <Trans>SHARED.BACK_LINKS.BACK_HOME</Trans>
      </Button>
    </>
  );

  return (
    <div className={styles.container}>
      <div className={`${styles.column} ${styles['column--left']}`}>
        <img src={StatusActive} alt="withdrawal_success" className={styles.icon} />
        <div className={styles.title}>
          <Trans>CARDLESS_WITHDRAWAL.SUCCESS.TITLE</Trans>
        </div>
        <div className={styles['only-desktop']}>
          {descriptionAndButton}
        </div>
      </div>
      <div className={styles.column}>
        <Loader loaded={!!operationDetails}>
          <div className={styles['withdraw-details']}>
            <OperationDetail title={'REFERENCE_NUMBER.FULL_LABEL'}
                             value={locationState.confirmationOutput.referenceNumber}
                             className={styles.detail} />
            <OperationDetail title={'CARDLESS_WITHDRAWAL.AMOUNT_TO_WITHDRAW'}
                             value={<CurrencyFormat amount={operationDetails?.amount ?? 0} />}
                             className={styles.detail} />
            <OperationDetail title={'SHARED.COMMON.SERVICE_FEE'}
                             value={<CurrencyFormat amount={locationState.fee} />}
                             className={styles.detail} />
            {operationDetails &&
            <OperationDetail title={'SHARED.COMMON.TRANSACTION_DATE'}
                             value={operationDetails.registeredOnTimestamp.format(DATE_WITH_TIME_FORMAT)}
                             className={styles.detail} />}
            <OperationDetail summary
                             title={'SHARED.COMMON.TOTAL_AMOUNT'}
                             className={styles.detail}
                             value={
                               <div className={`${styles.amount} ${getTotalAmount() < 0
                                 ? styles['amount--negative']
                                 : ''}`}>
                                 <CurrencyFormat amount={getTotalAmount()} />
                               </div>
                             } />
          </div>
        </Loader>
      </div>
      <div className={styles['only-mobile']}>
        {descriptionAndButton}
      </div>
    </div>
  );
}
