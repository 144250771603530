import {AccountDetails} from '../shared/model/Account';
import {AuthenticatedSystemProperties, PublicSystemProperties} from '../shared/model/SystemProperties';
import UserData from '../shared/model/UserData';

export enum Actions {
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
  USER_PRIMARY_ACCOUNT_FETCHED = 'USER_PRIMARY_ACCOUNT_FETCHED',
  USER_PRIMARY_ACCOUNT_STARTED_LOADING = 'USER_PRIMARY_ACCOUNT_STARTED_LOADING',
  SYSTEM_PROPERTIES_FETCHED = 'SYSTEM_PROPERTIES_FETCHED',
  AUTHENTICATED_SYSTEM_PROPERTIES_STARTED_LOADING = 'AUTHENTICATED_SYSTEM_PROPERTIES_STARTED_LOADING',
  AUTHENTICATED_SYSTEM_PROPERTIES_FETCHED = 'AUTHENTICATED_SYSTEM_PROPERTIES_FETCHED',
  APPLICATION_SEAL_CHECKED = 'APPLICATION_SEAL_CHECKED',
  APPLICATION_UNSEALED = 'APPLICATION_UNSEALED',
  PASSWORD_CHANGE_REQUIRED = 'PASSWORD_CHANGE_REQUIRED'
}

interface LoggedInAction {
  type: typeof Actions.LOGGED_IN;
  payload: UserData;
}

interface LoggedOutAction {
  type: typeof Actions.LOGGED_OUT;
  payload: {
    reasonCode?: string
  };
}

interface UserPrimaryAccountStartedLoading {
  type: typeof Actions.USER_PRIMARY_ACCOUNT_STARTED_LOADING;
}

interface UserPrimaryAccountFetched {
  type: typeof Actions.USER_PRIMARY_ACCOUNT_FETCHED;
  payload?: AccountDetails;
}

interface SystemPropertiesFetched {
  type: typeof Actions.SYSTEM_PROPERTIES_FETCHED;
  payload: PublicSystemProperties;
}

interface AuthenticatedSystemPropertiesStartedLoading {
  type: typeof Actions.AUTHENTICATED_SYSTEM_PROPERTIES_STARTED_LOADING;
}

interface AuthenticatedSystemPropertiesFetched {
  type: typeof Actions.AUTHENTICATED_SYSTEM_PROPERTIES_FETCHED;
  payload: AuthenticatedSystemProperties;
}

interface ApplicationSealCheckedAction {
  type: typeof Actions.APPLICATION_SEAL_CHECKED;
  isSealed: boolean;
}

interface ApplicationUnsealedAction {
  type: typeof Actions.APPLICATION_UNSEALED;
}

interface PasswordChangeRequiredAction {
  type: typeof Actions.PASSWORD_CHANGE_REQUIRED;
}

export type ActionTypes = LoggedInAction
  | LoggedOutAction
  | UserPrimaryAccountStartedLoading
  | UserPrimaryAccountFetched
  | SystemPropertiesFetched
  | AuthenticatedSystemPropertiesStartedLoading
  | AuthenticatedSystemPropertiesFetched
  | ApplicationSealCheckedAction
  | ApplicationUnsealedAction
  | PasswordChangeRequiredAction;
