import {TFunction} from 'i18next';
import React, {ReactNode} from 'react';
import {DICTIONARY_ERROR_CODE_PREFIX} from '../constants/values';
import {HttpError} from '../shared/services/http.service';

export const ABORT_ERROR_NAME = 'AbortError';
export const SESSION_RESTRICTED_ERROR_CODE = 'SESSION_RESTRICTED';

export const ErrorHelper = {
  getErrorMessageTranslation: (t: TFunction, e: Error): string =>
    checkIfClientError(e)
      ? t(DICTIONARY_ERROR_CODE_PREFIX + e.error.errorCode, {defaultValue: e.error.errorMessage})
      : t('SHARED.ERROR.COMMUNICATION_FAIL'),

  getMultipleErrorMessages: (t: TFunction, e: Error): ReactNode[] =>
    checkIfClientError(e) && e.error.errorMessages
      ? e.error.errorMessages.map((message, index) => <p key={index}>{message}</p>)
      : [t('SHARED.ERROR.COMMUNICATION_FAIL')],

  getRawErrorMessage: (e: Error): string => {
    if (checkIfClientError(e)) {
      return e.error.errorMessage;
    }

    throw e;
  },

  isAbortOrSessionRestrictionError: (httpError: HttpError<any>) => {
    return isAbortError || httpError.error.errorCode === SESSION_RESTRICTED_ERROR_CODE;
  },

  isAbortError: (error: Error) => isAbortError(error)
};

const isAbortError = (error: Error) => error.name === ABORT_ERROR_NAME;

const checkIfClientError = (e: Error): e is HttpError<any> =>
  e instanceof HttpError && (e.response.status === 400 || e.response.status === 401);
