import {Button, Radio} from '@material-ui/core';
import React from 'react';
import {Trans} from 'react-i18next';
import {useHistory, useLocation} from 'react-router';
import {ResetPasswordConfirmationType, ResetSecretConfirmationOption} from '../model/ResetPassword';
import {ResetPasswordRoutesPaths} from '../reset-password.routes-paths';
import styles from './ResetPasswordSecurityCheck.module.scss';

export interface ResetPasswordSecurityCheckLocationProps {
  allowedConfirmationOptions: ResetSecretConfirmationOption[];
  resetToken: string;
}

export default function ResetPasswordSecurityCheck() {
  const history = useHistory();

  const {allowedConfirmationOptions, resetToken} = useLocation<ResetPasswordSecurityCheckLocationProps>().state;
  const otpOption = allowedConfirmationOptions.find(
    option => option.confirmationType === ResetPasswordConfirmationType.SMS
  );

  const navigateToSetPassword = () => {
    history.push(ResetPasswordRoutesPaths.RESET_PASSWORD_SET_PASSWORD, {
      // Currently confirmationType is always SMS
      confirmationType: ResetPasswordConfirmationType.SMS,
      resetToken
    })
  }

  return (
    <>
      <h1>
        <Trans>RESET_PASSWORD.SECURITY_CHECK.TITLE</Trans>
      </h1>
      <p className={styles.description}>
        <Trans>RESET_PASSWORD.SECURITY_CHECK.DESCRIPTION</Trans>
      </p>
      {
        otpOption &&
          <div className={styles.option}>
						<Radio checked={true} className={styles['option__radiobutton']} />
						<div className={styles['option__label']}>
							<Trans>RESET_PASSWORD.SECURITY_CHECK.OTP_OPTION</Trans>
              <span className={styles['option__recipient']}>{otpOption.confirmationRecipient}</span>
            </div>
          </div>
      }
      <Button type="submit" className={styles.button} onClick={navigateToSetPassword} >
        <Trans>SHARED.COMMON.SUBMIT</Trans>
      </Button>
    </>
  );
}
