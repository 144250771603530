import {TFunction} from 'i18next';
import {VerificationRoutesPaths} from '../verification.routes-paths';

interface VerificationStep {
  path: string;
  label: string;
}

export const getSteps = (t: TFunction):  VerificationStep[] => (
  [
    {
      path: VerificationRoutesPaths.ELIGIBILITY,
      label: t('VERIFICATION.ELIGIBILITY.TITLE')
    },
    {
      path: VerificationRoutesPaths.PERSONAL_DETAILS,
      label: t('VERIFICATION.PERSONAL_DETAILS.TITLE')
    },
    {
      path: VerificationRoutesPaths.ADDRESS,
      label: t('VERIFICATION.ADDRESS.TITLE')
    },
    {
      path: VerificationRoutesPaths.ADDITIONAL_DETAILS,
      label: t('VERIFICATION.ADDITIONAL_DETAILS.TITLE')
    },
    {
      path: VerificationRoutesPaths.TERMS_AND_CONDITIONS,
      label: t('VERIFICATION.TERMS_AND_CONDITIONS.TITLE')
    },
    {
      path: VerificationRoutesPaths.DATA_AND_PERSONALIZATION,
      label: t('VERIFICATION.DATA_AND_PERSONALIZATION.TITLE')
    },
    {
      path: VerificationRoutesPaths.ID_DOCUMENT,
      label: t('VERIFICATION.ID_DOCUMENT.TITLE')
    }
  ]
);

export const verificationStepsHelper = {
  getStepIndex: (steps: VerificationStep[], stepPath: string): number => (
    steps.findIndex(step => step.path === stepPath)
  )
}
