import React from 'react';
import {Trans} from 'react-i18next';
import styles from './GoalsEmptyState.module.scss';

interface GoalsEmptyStateProps {
  onCreateButtonClick: () => void;
}

const GoalsEmptyState = ({onCreateButtonClick}: GoalsEmptyStateProps) => (
  <div className={styles.container}>
    <div className={styles['add-goal']} onClick={onCreateButtonClick}>
      <Trans>GOALS.EMPTY_STATE.ADD_GOAL</Trans>
    </div>
  </div>
);

export default GoalsEmptyState;
