import {Button} from '@material-ui/core';
import React from 'react';
import {Trans} from 'react-i18next';
import {useDispatch} from 'react-redux';
import HeroSuccess from '../../../assets/images/hero-success.svg';
import {logout} from '../../../store/actions';
import styles from './PasswordChangeSuccessPopupContent.module.scss';

const PasswordChangeSuccessPopupContent = () => {

  const dispatch = useDispatch();

  return (
    <div className={styles['main-container']}>
      <img src={HeroSuccess} alt="hero success" />
      <div className={styles['success-title']}>
        <Trans>SETTINGS.CHANGE_PASSWORD.SUCCESS.TITLE</Trans>
      </div>
      <div className={styles['success-message']}>
        <Trans>SETTINGS.CHANGE_PASSWORD.SUCCESS.MESSAGE</Trans>
      </div>
      <Button className={styles.button} onClick={() => dispatch(logout())}>
        <Trans>SETTINGS.CHANGE_PASSWORD.SUCCESS.BUTTON_LABEL</Trans>
      </Button>
    </div>
  );
};

export default PasswordChangeSuccessPopupContent;
