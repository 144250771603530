import {Button} from '@material-ui/core';
import React from 'react';
import {Trans} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {ReactComponent as ArrowBack} from '../../../../assets/images/icons/arrow-back.svg';
import CurrencyFormat from '../../currency-format/CurrencyFormat';
import Loader from '../../loader/Loader';
import styles from './OperationConfirmationPopupContent.module.scss';

interface OperationConfirmationPopupContentProps {
  operationDetails: React.ReactElement;
  snackBar?: React.ReactElement;
  onProceed: () => void;
  cashValue: number;
  isSubmitting: boolean;
  amountTranslationCode?: string;
}

export default function OperationConfirmationPopupContent({
    operationDetails,
    onProceed,
    cashValue,
    isSubmitting,
    snackBar,
    amountTranslationCode
  }: OperationConfirmationPopupContentProps) {

  const history = useHistory();

  const goBack = () => {
    if (!isSubmitting) {
      history.goBack();
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        <div className={`${styles['arrow-back']} ${isSubmitting ? styles['arrow-back--disabled'] : ''}`}
             onClick={goBack}>
          <ArrowBack />
        </div>
        <Trans>SHARED.OPERATIONS.CONFIRM_DETAILS_HEADER</Trans>
      </h1>
      {snackBar}
      <div className={styles.details}>
        {operationDetails}
      </div>
      <div className={styles['total-amount']}>
        <div className={styles[`total-amount__title`]}>
          <Trans>{amountTranslationCode ?? 'SHARED.COMMON.TOTAL_AMOUNT'}</Trans>
        </div>
        <div className={styles[`total-amount__value`]}>
          <CurrencyFormat amount={cashValue} /></div>
      </div>
      <div className={styles.actions}>
        <Button disabled={isSubmitting}
                type="submit"
                className={styles.submit}
                onClick={onProceed}>
          <Loader buttonSpinner loaded={!isSubmitting}>
            <Trans>SHARED.COMMON.SUBMIT</Trans>
          </Loader>
        </Button>
      </div>
    </div>
  );
}
