import {CancelTokenSource} from 'axios';
import HttpService from './http.service';

const FILES_URL = '/common/files';

export const FileService = {
  getFileById: function(fileId: number, cancelTokenSource?: CancelTokenSource | null): Promise<Blob | null> {
    return HttpService.get<Blob>(`${FILES_URL}/${fileId}`, cancelTokenSource, {responseType: 'blob'});
  }
};
