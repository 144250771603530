import dayjs from 'dayjs';
import {isNil} from 'lodash';
import React, {useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {useHistory} from 'react-router';
import trophy from '../../../assets/images/icons/trophy.svg';
import {PERIOD_DATE_FORMAT} from '../../../constants/date';
import {blobToBase64String} from '../../../utils/file-utils';
import CurrencyFormat from '../../shared/currency-format/CurrencyFormat';
import AmountPerMonth from '../amount-per-month/AmountPerMonth';
import Progress from '../../shared/progress/Progress';
import {GoalsRoutesPaths} from '../goals.routes-paths';
import Goal from '../model/Goal';
import styles from './GoalOverivewPopupContent.module.scss';

interface GoalOverviewPopupContentProps {
  goal: Goal;
}

const GoalOverviewPopupContent = ({goal}: GoalOverviewPopupContentProps) => {

  const [image, setImage] = useState<string>('');
  const history = useHistory();
  const imageBlob = goal.imageFile;

  useEffect(() => {
    if (!isNil(imageBlob)) {
      blobToBase64String(imageBlob).then(imageString => setImage(imageString));
    }
  }, [imageBlob]);

  const openConfirmationPopup = () => {
    history.push(GoalsRoutesPaths.DELETE_CONFIRMATION);
  };

  const deleteLink =
    <div className={styles['delete-link-container__link']} onClick={openConfirmationPopup}>
      <Trans>GOALS.OVERVIEW.DELETE_BUTTON_LABEL</Trans>
    </div>;

  return (
    <div className={styles['main-container']}>
      <div className={styles.title}>
        <Trans>GOALS.OVERVIEW.HEADER</Trans>
      </div>
      <div className={styles.details}>
        <div className={`${styles.detail} ${styles['detail--name']}`}>
          <div className={styles['detail__label']}>
            <Trans>GOALS.OVERVIEW.NAME_LABEL</Trans>
          </div>
          <div className={styles['detail__value']}>
            {goal.name}
          </div>
        </div>
        <div className={`${styles.detail} ${styles['detail--amount']}`}>
          <div className={styles['detail__label']}>
            <Trans>GOALS.OVERVIEW.AMOUNT_LABEL</Trans>
          </div>
          <div className={styles['detail__value']}>
            <CurrencyFormat amount={goal.amount} />
          </div>
        </div>
        <div className={`${styles.detail} ${styles['detail--duration']}`}>
          <div className={styles['detail__label']}>
            <Trans>GOALS.OVERVIEW.DURATION_LABEL</Trans>
          </div>
          <div className={styles['detail__value']}>
            {`${goal.durationInMonths} months`}
          </div>
        </div>
        <div className={`${styles.detail} ${styles['detail--dates']}`}>
          <div className={styles['detail__label']}>
            <Trans>GOALS.OVERVIEW.PERIOD_LABEL</Trans>
          </div>
          <div className={styles['detail__value']}>
            {`${dayjs(goal.startDate).format(PERIOD_DATE_FORMAT)} - ${dayjs(goal.endDate).format(PERIOD_DATE_FORMAT)}`}
          </div>
        </div>
        <div className={`${styles['delete-link-container']} ${styles['delete-link-container--desktop']}`}>
          {deleteLink}
        </div>
        <div className={styles['progress-container']}>
          <Progress progress={goal.progress} biggerVariant />
          <div className={`${styles['progress-container__info']} 
            ${goal.progress < 60 ? '' : styles['progress-container__info--nearly-finished']}`}>
            {`${goal.progress}% `}<Trans>GOALS.PROGRESS_COMPLETE</Trans>
          </div>
        </div>
        <div className={styles['image-container']}>
          {
            image
              ? <img className={styles.image} src={image} alt={' '} />
              : <img className={styles.icon} src={trophy} alt="goal" />
          }
        </div>
        <div className={styles['amount-per-month-container']}>
          <AmountPerMonth amountPerMonth={goal.monthlyAmount} />
        </div>
      </div>
      <div className={`${styles['delete-link-container']} ${styles['delete-link-container--mobile']}`}>
        {deleteLink}
      </div>
    </div>
  );
};

export default GoalOverviewPopupContent;
