import {JumioDocumentType} from '../components/verification/jumio/JumioService';

const PASSPORT_DOCUMENT_TYPE_ID = 1045;
const DRIVING_LICENCE_DOCUMENT_TYPE_ID = 1046;

export const JumioHelper = {
  mapDocumentTypeIdToJumioDocumentType: (documentTypeId?: number): JumioDocumentType => {
    switch (documentTypeId) {
      case DRIVING_LICENCE_DOCUMENT_TYPE_ID: return JumioDocumentType.DRIVING_LICENSE;
      case PASSPORT_DOCUMENT_TYPE_ID: return JumioDocumentType.PASSPORT;
      default: return JumioDocumentType.ID_CARD;
    }
  }
};
