import React from 'react';
import {Trans} from 'react-i18next';
import styles from './TransWithNoWrapSpan.module.scss';

interface TransWithNoWrapSpanProps {
  children: string;
}

export const TransWithNoWrapSpan = ({children}: TransWithNoWrapSpanProps) => (
  <Trans components={{spanTag: <span className={styles['no-wrap-word']} />}}>
    {children}
  </Trans>
);
