import React from 'react';
import {CURRENCY_CODE} from '../../../../../../constants/currency';
import {PaymentProduct} from '../../../../../../shared/model/PaymentProduct';
import Loader from '../../../../../shared/loader/Loader';
import styles from './BuyLoadRegularProducts.module.scss';

interface BuyLoadRegularProductsProps {
  regularProducts: PaymentProduct[],
  isLoading: boolean,
  onProductClick: (product: PaymentProduct) => void;
}

export default function BuyLoadRegularProducts({regularProducts, isLoading, onProductClick}: BuyLoadRegularProductsProps) {

  return (isLoading
      ? <Loader />
      : <div className={styles.products}>
        {
          regularProducts.map(regularProduct =>
            <div key={regularProduct.id} className={styles.product} onClick={() => onProductClick(regularProduct)}>
              <div className={styles.currency}>{CURRENCY_CODE}</div>
              <div className={styles.amount}> {regularProduct.maxAmount}</div>
            </div>)
        }
      </div>
  );
}