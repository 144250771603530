import axios from 'axios';
import {isEmpty, isNil, last} from 'lodash';
import React, {useEffect, useReducer, useState} from 'react';
import {Trans} from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useSelector} from 'react-redux';
import {OperationBasicData, OperationsBasicData} from '../../../shared/model/operations/OperationBasicData';
import {CustomerOperationsService} from '../../../shared/services/customer-operations.service';
import {getUserData} from '../../../store/selectors';
import {ErrorHelper} from '../../../utils/error-helper';
import {cleanUp} from '../../../utils/handle-abort-controller-clean-up';
import {checkIfNotProspect} from '../../../utils/user-group-checks';
import ActivitiesEmptyState from '../../shared/activities/empty-state/ActivitiesEmptyState';
import ActivitiesRow from '../../shared/activities/row/ActivitiesRow';
import {ActivitiesRowVariant} from '../../shared/activities/row/ActivitiesRowVariant';
import Loader from '../../shared/loader/Loader';
import styles from './Activities.module.scss';

const initialActivitiesState: OperationsBasicData = {hasMoreItems: true, items: []};

const reducer = (currentState: OperationsBasicData, newData: OperationsBasicData): OperationsBasicData => ({
  ...newData,
  items: [...currentState.items, ...newData?.items ?? []]
});

const Activities = () => {
  const [activities, updateActivities] = useReducer(reducer, initialActivitiesState);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const userData = useSelector(getUserData);
  const isNotProspect = checkIfNotProspect(userData!);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    // get activities of account if user is not prospect
    if (isNotProspect) {
      CustomerOperationsService.getCustomerOperations({}, cancelTokenSource)
        .then(operations => {
          setIsLoaded(true);
          updateActivities(operations);
        })
        .catch(error => {
          if (ErrorHelper.isAbortOrSessionRestrictionError(error)) {
            return;
          }
          updateActivities(({items: [], hasMoreItems: false}));
        });
    } else {
      setIsLoaded(true);
    }

    return cleanUp(cancelTokenSource);
  }, [isNotProspect]);

  const getActivities = (startCriteria?: number) => {
    CustomerOperationsService.getCustomerOperations({startCriteria})
      .then(operations => updateActivities(operations));
  };

  const areActivitiesEmpty = () => isNil(activities) || isEmpty(activities.items) || !isNotProspect;
  const getLastActivityId = () => activities ? last(activities.items)?.rownum : undefined;

  const emptyState = isNotProspect
    ? <ActivitiesEmptyState text={<Trans>ACTIVITIES.NO_ACTIVITIES_YET</Trans>}
                            linkText={<Trans>ACTIVITIES.NO_ACTIVITIES_YET_LINK</Trans>} />
    : <ActivitiesEmptyState text={<Trans>ACTIVITIES.PROSPECT_NOT_ACTIVATED</Trans>} />;

  const endMessage = <div className={styles['no-more-items']}><Trans>HISTORY.NO_MORE_ITEMS</Trans></div>;

  return (
    <Loader loaded={isLoaded}>
      {
        areActivitiesEmpty() ? emptyState :
          <InfiniteScroll
            dataLength={activities.items.length}
            next={() => getActivities(getLastActivityId())}
            hasMore={activities.hasMoreItems}
            loader={<Loader />}
            endMessage={endMessage}
          >
            {
              activities.items.map((operationBasicData: OperationBasicData) =>
                <ActivitiesRow key={operationBasicData.id}
                               activity={operationBasicData}
                               variant={ActivitiesRowVariant.BORDERED} />
              )
            }
          </InfiniteScroll>
      }
    </Loader>
  );
};

export default Activities;
