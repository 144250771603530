import React from 'react';
import {useTranslation} from 'react-i18next';
import styles from './ReferenceNumberHeaderPartnerIcon.module.scss';
import PartnerIcon from '../partners/partner-icon/PartnerIcon';
import {PartnerMerchant} from '../../../shared/model/operations/partners/PartnerMerchant';

interface ReferenceNumberHeaderPartnerIconProps {
  partnerMerchant: PartnerMerchant;
};

export default function ReferenceNumberHeaderPartnerIcon({partnerMerchant}: ReferenceNumberHeaderPartnerIconProps) {
  const {t} = useTranslation();
  return (
    <div className={styles['icon-wrapper']}>
      {t('REFERENCE_NUMBER.HEADER', { merchant: partnerMerchant.merchantName }) }
      <PartnerIcon merchantCode={partnerMerchant.merchantCode} label={partnerMerchant.merchantName} />
    </div>
  );
}

