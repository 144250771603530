import React from 'react';
import {MemoryRouter, Redirect} from 'react-router';
import {KeyedRouteProps} from './model/Routes';
import RenderRoutes from './RenderRoutes';

interface MemoryRouterWithRedirectProps {
  routes: KeyedRouteProps[]
}

const MemoryRouterWithRedirect = ({routes}: MemoryRouterWithRedirectProps) => (
  <MemoryRouter>
    <RenderRoutes routes={routes}/>
    {/* Redirect to first route */}
    <Redirect to={routes[0].path as string}/>
  </MemoryRouter>
);

export default MemoryRouterWithRedirect;