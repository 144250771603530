import React from 'react';
import {ReactComponent as StatusIcon} from '../../../assets/images/icons/status-icons/status-inactive.svg';
import styles from './SetPasswordRule.module.scss';

interface SetPasswordRuleProps {
  isRuleChecked: boolean;
  label: string | React.ReactElement;
}

export const SetPasswordRule = ({isRuleChecked, label}: SetPasswordRuleProps) => (
  <div className={styles.rule}>
    <StatusIcon className={`${styles.icon} ${isRuleChecked ? styles['icon--checked'] : ''}`} />
    {label}
  </div>
);
