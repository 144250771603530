import dayjs, {Dayjs} from 'dayjs';

export enum CustomerStatus {
  PROSPECT,
  REJECTED,
  ACTIVE,
  CLOSED
}

export interface UserProfileTO<T_DATE = string, T_STATUS = string> {
  customerNumber: number,
  effectiveName: string,
  firstLoginTimestamp: T_DATE,
  id: number,
  initials: string,
  referrerNumber: number,
  shortName: string,
  status: T_STATUS
}

export interface UserProfile extends UserProfileTO<Dayjs, CustomerStatus> {
}

export const fromUserProfileTO = (userProfileTo: UserProfileTO) => ({
  ...userProfileTo,
  firstLoginTimestamp: dayjs(userProfileTo.firstLoginTimestamp),
  status: CustomerStatus[userProfileTo.status]
});
