export enum OperationGroup {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL'
}

export enum MerchantCode {
  EXPRESSPAY = 'EXPRESSPAY',
  CVM_PAWNSHOP = 'CVM_PAWNSHOP',
  EBIZ = 'EBIZ',
  GAISANO = 'GAISANO',
  ECPAY = 'ECPAY',
  SEVEN_ELEVEN = 'SEVEN_ELEVEN',
  BAYAD_CENTER = 'BAYAD_CENTER',
  TAMBUNTING_PAWNSHOP = 'TAMBUNTING_PAWNSHOP',
  NATCCO = 'NATCCO',
  RD_PAWNSHOP = 'RD_PAWNSHOP',
  USSC = 'USSC',
  H_LHUILLIER_PAWNSHOP = 'H_LHUILLIER_PAWNSHOP',
  ECPAY_SEVEN_ELEVEN = 'ECPAY_SEVEN_ELEVEN'
}

export enum Partner {
  ECPAY = 'ECPAY',
  SEVEN_ELEVEN = 'SEVEN_ELEVEN',
  BAYAD_CENTER = 'BAYAD_CENTER'
}

export interface PartnerMerchantTO<T_MERCHANT_CODE = string, T_OPERATION_GROUP = string, T_MERCHANT_OPERATION = PartnerMerchantOperationTO, T_PARTNER = string, > {
  defaultMerchant: boolean;
  enabled: boolean;
  id: number;
  merchantCode: T_MERCHANT_CODE;
  merchantName: string;
  operationGroups: T_OPERATION_GROUP[];
  operations: T_MERCHANT_OPERATION[];
  orderNo: number;
  partner: T_PARTNER;
}

export interface PartnerMerchant
  extends PartnerMerchantTO<MerchantCode, OperationGroup, PartnerMerchantOperation, Partner> {
}

export const fromPartnerMerchantTO = (partnerMerchantTO: PartnerMerchantTO): PartnerMerchant => ({
  ...partnerMerchantTO,
  merchantCode: MerchantCode[partnerMerchantTO.merchantCode],
  operationGroups: partnerMerchantTO.operationGroups.map(operationGroup => OperationGroup[operationGroup]),
  operations: partnerMerchantTO.operations.map(operation => fromPartnerMerchantOperationTO(operation)),
  partner: Partner[partnerMerchantTO.partner]
});

export interface PartnerMerchantOperationTO<T_OPERATION_GROUP = string> {
  enabled: boolean;
  maxOperationAmount?: number;
  minOperationAmount?: number;
  operationGroup: T_OPERATION_GROUP;
}

export interface PartnerMerchantOperation extends PartnerMerchantOperationTO<OperationGroup> {
}

export const fromPartnerMerchantOperationTO = (partnerMerchantOperationTO: PartnerMerchantOperationTO): PartnerMerchantOperation => ({
  ...partnerMerchantOperationTO,
  operationGroup: OperationGroup[partnerMerchantOperationTO.operationGroup]
});
