import State from './state';
import {Actions, ActionTypes} from './types';

const initialState: State = {
  isUserAccountLoading: false,
  isAuthenticatedSystemPropertiesLoading: false,
  public: {
    systemProperties: {
      prospectApplicationsEnabled: false
    }
  }
};

export const rootReducer = (state: State = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case Actions.LOGGED_IN:
      return {
        ...state,
        public: {
          ...state.public,
          isLoggedIn: !action.payload.anonymous
        },
        sessionData: action.payload
      };
    case Actions.LOGGED_OUT:
      return {
        isUserAccountLoading: false,
        isAuthenticatedSystemPropertiesLoading: false,
        public: {
          ...state.public,
          isLoggedIn: false,
          logoutReasonCode: action.payload.reasonCode
        }
      };
    case Actions.USER_PRIMARY_ACCOUNT_STARTED_LOADING:
      return {
        ...state,
        isUserAccountLoading: true
      };
    case Actions.USER_PRIMARY_ACCOUNT_FETCHED:
      return {
        ...state,
        isUserAccountLoading: false,
        userPrimaryAccount: action.payload
      };
    case Actions.SYSTEM_PROPERTIES_FETCHED:
      return {
        ...state,
        public: {
          ...state.public,
          systemProperties: action.payload
        }
      };
    case Actions.AUTHENTICATED_SYSTEM_PROPERTIES_STARTED_LOADING:
      return {
        ...state,
        isAuthenticatedSystemPropertiesLoading: true
      };
    case Actions.AUTHENTICATED_SYSTEM_PROPERTIES_FETCHED:
      return {
        ...state,
        isAuthenticatedSystemPropertiesLoading: false,
        authenticatedSystemProperties: action.payload
      };
    case Actions.APPLICATION_SEAL_CHECKED:
      return {
        ...state,
        prospectApplicationSealed: action.isSealed
      };
    case Actions.APPLICATION_UNSEALED:
      return {
        ...state,
        prospectApplicationSealed: false
      };
    case Actions.PASSWORD_CHANGE_REQUIRED:
      return {
        ...state,
        isPasswordChangeRequired: true
      };
    default:
      return {
        ...state
      };
  }
};
