import {RoutesParts} from '../../constants/routes-parts';

export const RoutesPaths = {
  ROOT: '/',
  ACCOUNT: RoutesParts.ACCOUNT, /* Only on mobile */
  CARDLESS_WITHDRAWAL: RoutesParts.CARDLESS_WITHDRAWAL,
  DASHBOARD: RoutesParts.DASHBOARD,
  LOGIN: RoutesParts.LOGIN,
  GOALS: RoutesParts.GOALS,
  HISTORY: RoutesParts.HISTORY,
  REGISTRATION: RoutesParts.REGISTRATION,
  RESET_PASSWORD: RoutesParts.RESET_PASSWORD,
  SETTINGS: RoutesParts.SETTINGS,
};
