import HttpService from '../../../shared/services/http.service';

const JUMIO_INIT_VERIFICATION_URL = 'addons/jumio/verification-urls'; // URL to server localized in SGP

export enum JumioDocumentType {
  PASSPORT = 'PASSPORT',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  ID_CARD = 'ID_CARD'
}

export const JumioService = {
  initVerificationProcess: (documentType: JumioDocumentType) => HttpService.post<string>(
    `${JUMIO_INIT_VERIFICATION_URL}?documentType=${documentType}`)
};
