import isString from 'lodash/isString';
import {HttpError} from '../../../shared/services/http.service';
import {ErrorHelper} from '../../../utils/error-helper';
import {RegisterService} from '../register.service';

const ALIAS_FIELD_NAME = 'alias';
const MOBILE_NUMBER_FIELD_NAME = 'mobileNo';
const REFERRER_NUMBER_FIELD_NAME = 'referrerNumber';
const REQUIRED_ERROR = 'Required';

interface ValidationResult {
  type: ValidationResultType,
  field: string,
  message?: string
}

export enum ValidationResultType {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED'
}

export const validateFields = async (values) => {
  const validateAliasPromise = validateAlias(values.alias);
  const validateMobileNoPromise = validateMobileNo(values.mobileNo);
  const validateReferrerNumberPromise = validateReferrerNumber(values.referrerNumber);

  return Promise.all([validateAliasPromise, validateMobileNoPromise, validateReferrerNumberPromise]);
};

const validateAlias = async (alias: string): Promise<ValidationResult> => {
  if (!alias) {
    return Promise.reject(generateRejectMessage(ALIAS_FIELD_NAME, REQUIRED_ERROR));
  }

  return RegisterService.validateProspectAlias(alias)
    .then(() => generateAcceptedMessage(ALIAS_FIELD_NAME))
    .catch(error => generateRejectMessage(ALIAS_FIELD_NAME, error));
};

const validateMobileNo = async (mobileNo: string): Promise<ValidationResult> => {
  if (!mobileNo) {
    return Promise.reject(generateRejectMessage(MOBILE_NUMBER_FIELD_NAME, REQUIRED_ERROR));
  }

  return RegisterService.validateProspectMobileNumber(mobileNo)
    .then(() => generateAcceptedMessage(MOBILE_NUMBER_FIELD_NAME))
    .catch(error => generateRejectMessage(MOBILE_NUMBER_FIELD_NAME, error));
};

const validateReferrerNumber = async (referrerNo: string): Promise<ValidationResult> => {
  if (!!referrerNo) {
    return RegisterService.validateProspectReferrerNumber(referrerNo)
      .then(() => generateAcceptedMessage(REFERRER_NUMBER_FIELD_NAME))
      .catch(error => generateRejectMessage(REFERRER_NUMBER_FIELD_NAME, error));
  }
  return Promise.resolve(generateAcceptedMessage(REFERRER_NUMBER_FIELD_NAME));
};

const generateAcceptedMessage = (fieldName: string): ValidationResult => ({
  type: ValidationResultType.ACCEPTED,
  field: fieldName
});

const generateRejectMessage = (fieldName: string, error: HttpError<any> | string): ValidationResult => ({
  type: ValidationResultType.REJECTED,
  field: fieldName,
  message: isString(error) ? error : ErrorHelper.getRawErrorMessage(error)
});