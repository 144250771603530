import dayjs from 'dayjs';
import React from 'react';
import {Trans} from 'react-i18next';
import {DATE_WITH_TIME_FORMAT} from '../../../constants/date';
import CurrencyFormat from '../currency-format/CurrencyFormat';
import Loader from '../loader/Loader';
import OperationDetail from '../operation/operation-detail/OperationDetail';
import styles from './ReferenceNumberOperationDetails.module.scss';

interface ReferenceNumberOperationDetailsProps {
  editAmount: () => void;
  amount: number;
  fee: number;
  isEditLoading: boolean;
  validUntil: string; // TODO convert it to dayJs in type mapper
}

export const ReferenceNumberOperationDetails = ({fee, editAmount, isEditLoading, amount, validUntil}: ReferenceNumberOperationDetailsProps) => {
  const Amount = (
    <div className={styles.amount}>
      <CurrencyFormat amount={fee} />
      <div onClick={editAmount} className={styles.link}>
        <Loader buttonSpinner primaryColorVariant loaded={!isEditLoading}>
          <Trans>SHARED.COMMON.EDIT_AMOUNT</Trans>
        </Loader>
      </div>
    </div>
  );

  return (
    <>
      <OperationDetail title={'SHARED.COMMON.AMOUNT'} value={<CurrencyFormat amount={amount} />}
                       className={styles.detail} />
      <OperationDetail title={'REFERENCE_NUMBER.BCF'}
                       value={Amount}
                       className={styles.detail} />
      <OperationDetail title={'REFERENCE_NUMBER.VALID_UNTIL'}
                       value={dayjs(validUntil).format(DATE_WITH_TIME_FORMAT)}
                       className={styles.detail} />
    </>
  );
}