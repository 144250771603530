import {CancelTokenSource} from 'axios';
import HttpService from '../../shared/services/http.service';
import {fromUserProfileTO, UserProfile, UserProfileTO} from './model/UserProfile';

const USER_PROFILE_URL = '/customer/profile';
const SUM_OF_MONTHLY_REFERRAL_BONUSES_URL = '/customer/referrals/bonuses/monthly-sum';

export const ReferralService = {
  getUserProfile: (cancelTokenSource: CancelTokenSource): Promise<UserProfile> =>
    HttpService.get<UserProfileTO>(USER_PROFILE_URL, cancelTokenSource)
      .then(userProfileTO => fromUserProfileTO(userProfileTO)),

  getSumOfMonthlyReferralBonuses: (cancelTokenSource: CancelTokenSource): Promise<number> =>
    HttpService.get<number>(SUM_OF_MONTHLY_REFERRAL_BONUSES_URL, cancelTokenSource)
};
