import React, {useEffect} from 'react';
import {Trans} from 'react-i18next';
import {connect, useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {ReactComponent as Invite} from '../../../assets/images/icons/invite.svg';
import {ReactComponent as Logout} from '../../../assets/images/icons/logout.svg';
import {ReactComponent as Settings} from '../../../assets/images/icons/settings.svg';
import Face from '../../../assets/images/icons/user-face.svg';
import {DashboardRoutesPaths} from '../../../routes/routes-paths/dashboard.routes-paths';
import UserData from '../../../shared/model/UserData';
import {fetchUserPrimaryAccount, logout} from '../../../store/actions';
import {getUserAccount} from '../../../store/selectors';
import State from '../../../store/state';
import {checkIfNotProspect} from '../../../utils/user-group-checks';
import Loader from '../../shared/loader/Loader';
import styles from './AccountOptions.module.scss';

interface AccountOptionsStateProps {
  userData: UserData;
}

interface AccountOptionsComponentProps {
  hideOptionsModal?: () => void;
}

interface AccountOptionsDispatchProps {
  logout: () => void;
}

type AccountOptionsProps = AccountOptionsComponentProps & AccountOptionsStateProps & AccountOptionsDispatchProps;

const AccountOptions = ({userData, logout, hideOptionsModal}: AccountOptionsProps) => {
  const history = useHistory();
  const userAccount = useSelector(getUserAccount);
  const dispatch = useDispatch();
  const effectiveName = userData?.effectiveName;
  const shortName = userData?.shortName;
  const productNumber = userAccount?.productNumber;

  useEffect(() => {
    dispatch(fetchUserPrimaryAccount());
  }, [dispatch]);

  const navigateTo = (path: string) => {
    history.push(path);
    hideOptionsModal?.();
  };

  return (
    !userAccount && checkIfNotProspect(userData)
      ? <Loader />
      : <>
        <div className={styles['user-info-wrapper']}>
          <div className={styles.icon}>
            <img src={Face} alt="user-settings" />
          </div>
          <div className={styles['user-info']}>
            <span className={`${effectiveName ? styles['user-info__name'] : ''}`}>{effectiveName ?? shortName}</span>
            {productNumber && <span className={styles['user-info__account']}>{productNumber}</span>}
          </div>
        </div>
        <div className={styles.option} onClick={() => navigateTo(DashboardRoutesPaths.REFERRAL)}>
          <div className={styles.icon}><Invite /></div>
          <span className={styles['option__label']}><Trans>{'HEADER.USER_OPTIONS.INVITE_FRIENDS'}</Trans></span>
        </div>
        <div className={styles.option} onClick={() => navigateTo(DashboardRoutesPaths.SETTINGS)}>
          <div className={styles.icon}><Settings /></div>
          <span className={styles['option__label']}><Trans>{'HEADER.USER_OPTIONS.SETTINGS'}</Trans></span>
        </div>
        <div className={styles.option} onClick={logout}>
          <div className={styles.icon}><Logout /></div>
          <span className={styles['option__label']}><Trans>{'HEADER.USER_OPTIONS.LOG_OUT'}</Trans></span>
        </div>
      </>
  );
};

const mapStateToProps = (state: State, componentProps: AccountOptionsComponentProps): AccountOptionsStateProps => ({
  userData: state.sessionData!,
  ...componentProps
});

const mapDispatchToProps = (dispatch): AccountOptionsDispatchProps => ({
  logout: () => dispatch(logout())
});

export default connect<AccountOptionsStateProps,
  AccountOptionsDispatchProps,
  AccountOptionsComponentProps,
  State>(mapStateToProps, mapDispatchToProps)(AccountOptions);
