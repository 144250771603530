import {CancelTokenSource} from 'axios';
import HttpService from '../../shared/services/http.service';
import {HttpServiceHelper} from '../../utils/http-service-helper';
import {
  OperationBasicDataResponseFromRaw,
  OperationsBasicData,
  OperationsBasicDataResponseTO
} from '../model/operations/OperationBasicData';
import {OperationDetails, OperationDetailsFromRaw, OperationDetailsTO} from '../model/operations/OperationDetails';

interface CustomerOperationsParams {
  pageSize?: number;
  startCriteria?: number;
}

export const CustomerOperationsService = {
  getCustomerOperations: function(params: CustomerOperationsParams,
                                  cancelTokenSource?: CancelTokenSource | null): Promise<OperationsBasicData> {
    return HttpService
      .get<OperationsBasicDataResponseTO>(
        HttpServiceHelper.constructUrlWithSearchParams('/customer/operations', params),
        cancelTokenSource
      ).then(result => OperationBasicDataResponseFromRaw(result));
  },

  getOperationDetails: function(productId: number, transactionId: number): Promise<OperationDetails | undefined> {
    return HttpService
      .get<OperationDetailsTO>(`/customer/accounts/${productId}/operations/${transactionId}`)
      .then(result => OperationDetailsFromRaw(result));
  }
};
