import {RoutesParts} from '../../constants/routes-parts';
import {DashboardRoutesPaths} from '../../routes/routes-paths/dashboard.routes-paths';

export const BillPaymentRoutesPaths = {
  PRODUCT_SELECTION: DashboardRoutesPaths.PAY_BILLS + RoutesParts.PRODUCT_SELECTION,
  PAYMENT_DETAILS: DashboardRoutesPaths.PAY_BILLS + RoutesParts.PAYMENT_DETAILS,
  OTP: DashboardRoutesPaths.PAY_BILLS + RoutesParts.OTP,
  OPERATION_DETAILS: DashboardRoutesPaths.PAY_BILLS + RoutesParts.OPERATION_DETAILS,
  SUCCESS: DashboardRoutesPaths.PAY_BILLS + RoutesParts.SUCCESS
};
