import React, {useContext} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {MainRouterContext} from '../../App';
import ArrowBack from '../../assets/images/icons/arrow-back.svg';
import DiskarTechLogo from '../../assets/images/logos/diskartech-logo.svg';
import {mapBackLinkToRoute} from '../../routes/backlinks/backlinks';
import State from '../../store/state';
import AccountDropdown from '../account/account-dropdown/AccountDropdown';
import styles from '../header/Header.module.scss';
import NavBar from './navbar/NavBar';
import {Trans} from 'react-i18next';
import {RoutesPaths} from '../../routes/routes-paths/routes-paths';

function Header(props) {
  const backLink = mapBackLinkToRoute(props.location.pathname);
  const mainRouterContext = useContext(MainRouterContext);

  const navigateTo = (link: string) => {
    mainRouterContext.globalHistory.replace(link);
  }

  const backLinkComponent = backLink && (
    <div onClick={() => navigateTo(backLink.link)} className={styles.back}>
      <img src={ArrowBack} alt="back" className={styles['back__icon']} />
      <span className={styles['back__label']}>
        <Trans>{backLink.label}</Trans>
      </span>
    </div>
  );

  // Hide navbar when backlink provided
  const NavBarAndOptions = (
    <>
      {!backLink && <NavBar className={styles.navbar} />}
      <AccountDropdown className={styles['account-dropdown']} />
    </>
  );

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        { backLinkComponent }
        {/* navigate to login to make sure it links to correct root */}
        <div onClick={() => navigateTo(RoutesPaths.LOGIN)} className={styles['logo-wrapper']} >
          <img src={DiskarTechLogo} alt="DiskarTech" className={styles.logo} />
        </div>
        { props.isLoggedIn && NavBarAndOptions }
      </div>
    </header>
  );
}

const mapStateToProps = (state: State) => ({
  isLoggedIn: state.public.isLoggedIn
});

export default connect(mapStateToProps, null)(withRouter(Header));