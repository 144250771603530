import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {RecipientDetails} from '../../../../shared/model/Recipient';
import {HttpError} from '../../../../shared/services/http.service';
import {CardlessWithdrawalParams, WithdrawalService} from '../../../../shared/services/withdrawal.service';
import {ErrorHelper} from '../../../../utils/error-helper';
import {AlertSnackbar} from '../../alert-snackbar/AlertSnackbar';
import CurrencyFormat from '../../currency-format/CurrencyFormat';
import OperationConfirmationPopupContent
  from '../../operation/operation-confirmation-popup-content/OperationConfirmationPopupContent';
import OperationDetail from '../../operation/operation-detail/OperationDetail';
import {CardlessWithdrawalRoutesPaths} from '../cardless-withdrawal.routes-paths';
import styles from './CardlessWithdrawalConfirmation.module.scss';

interface CardlessWithdrawalConfirmationLocationProps {
  withdrawalParams: CardlessWithdrawalParams,
  fee: number,
  isItCustomerWithdrawal: boolean,
  otpError: HttpError<any>
}

export interface CardlessWithdrawalConfirmationOutput {
  operationId: number
  referenceNumber: string
}

export default function CardlessWithdrawalConfirmation() {
  const location = useLocation<CardlessWithdrawalConfirmationLocationProps>();
  const {withdrawalParams, fee, isItCustomerWithdrawal, otpError} = location.state;
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showError, setShowError] = useState(!!otpError);
  const [error, setError] = useState<HttpError<any> | undefined>(otpError);
  const {amount, recipient} = withdrawalParams;
  const [t] = useTranslation();

  const redirectToSuccessMessage = (confirmationOutput: CardlessWithdrawalConfirmationOutput) =>
    history.push(CardlessWithdrawalRoutesPaths.SUCCESS, {
      confirmationOutput,
      accountId: withdrawalParams.productId,
      fee: fee
    });

  const handleOtpError = (error) => {
    // Reload current view with error
    history.push(location.pathname, {
      ...location.state,
      otpError: error
    });
  };

  const proceed = () => {
    setIsSubmitting(true);
    WithdrawalService.performCardlessWithdrawal(isItCustomerWithdrawal, withdrawalParams)
      .then(output => {
        history.push(CardlessWithdrawalRoutesPaths.OTP, {
          ...output,
          onSuccess: redirectToSuccessMessage,
          onError: handleOtpError
        });
      })
      .catch(error => {
        setError(error);
        setShowError(true);
      })
      .finally(() => setIsSubmitting(false));
  };

  const RecipientInfo = (recipient: RecipientDetails) => (
    <>
      <OperationDetail title={'TRANSFER.LABELS.RECIPIENT_DETAILS.FULL_NAME.FIRST'}
                       value={recipient.firstName}
                       className={styles.detail} />
      {
        !recipient.middleName ? '' :
          <OperationDetail title={'TRANSFER.LABELS.RECIPIENT_DETAILS.FULL_NAME.MIDDLE'}
                           value={recipient.middleName}
                           className={styles.detail} />
      }
      <OperationDetail title={'TRANSFER.LABELS.RECIPIENT_DETAILS.FULL_NAME.LAST'}
                       value={recipient.lastName}
                       className={styles.detail} />
      <OperationDetail title={'SHARED.COMMON.STREET'}
                       value={recipient.addressStreet}
                       className={styles.detail} />
      <OperationDetail title={'TRANSFER.LABELS.RECIPIENT_DETAILS.ADDRESS.DISTRICT'}
                       value={recipient.addressBarangay}
                       className={styles.detail} />
      <OperationDetail title={'SHARED.COMMON.CITY_OR_MUNICIPALITY'}
                       value={recipient.addressCity}
                       className={styles.detail} />
      <OperationDetail title={'SHARED.COMMON.MOBILE_NO'}
                       value={recipient.mobileNo}
                       className={styles.detail} />
      <OperationDetail title={'SHARED.COMMON.EMAIL'}
                       value={recipient.email}
                       className={styles.detail} />
    </>
  );

  const OperationDetails = (
    <>
      <OperationDetail title={'CARDLESS_WITHDRAWAL.AMOUNT_TO_WITHDRAW'}
                       value={<CurrencyFormat amount={amount} />}
                       className={styles.detail} />
      <OperationDetail title={'SHARED.COMMON.SERVICE_FEE'}
                       value={<CurrencyFormat amount={fee} />}
                       className={styles.detail} />
      {recipient && RecipientInfo(recipient)}
    </>
  );

  const SnackBar = <AlertSnackbar handleClose={() => setShowError(false)}
                                  open={showError}
                                  message={ErrorHelper.getErrorMessageTranslation(t, error!)} />;

  return <OperationConfirmationPopupContent cashValue={amount + fee}
                                            isSubmitting={isSubmitting}
                                            onProceed={proceed}
                                            operationDetails={OperationDetails}
                                            snackBar={SnackBar} />;
}
