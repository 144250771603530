import {CommandOutput} from '../../shared/model/command/CommandOutput';
import HttpService from '../../shared/services/http.service';
import {HttpServiceHelper} from '../../utils/http-service-helper';

const PROSPECT_URL = '/prospect/applications';
const VALIDATE_ALIAS_URL = `${PROSPECT_URL}/validate-alias`;
const VALIDATE_NUMBER_URL = `${PROSPECT_URL}/validate-mobile-number`;
const VALIDATE_REFERRER_NUMBER_URL = `${PROSPECT_URL}/validate-referrer-number`;
const ACTIVATE_PROSPECT_ALIAS = '/prospect/auth/alias';

export enum ProspectApplicationMode {
  REGULAR = 'REGULAR',
  EXPRESS = 'EXPRESS'
}

export interface ProspectApplication {
  mobileNo: string;
  referrerNumber: string;
  alias?: string;
  email?: string;
  mode?: ProspectApplicationMode;
}

export interface ProspectAliasActivationData {
  applicationId: number;
  secret: string;
}

export const RegisterService = {
  validateProspectAlias: (alias: string) => HttpService.post(
    HttpServiceHelper.constructUrlWithSearchParams(VALIDATE_ALIAS_URL, {alias})),
  validateProspectMobileNumber: (mobileNumber: string) => HttpService.post(
    HttpServiceHelper.constructUrlWithSearchParams(VALIDATE_NUMBER_URL, {mobileNumber})),
  validateProspectReferrerNumber: (referrerNumber: string) => HttpService.post(
    HttpServiceHelper.constructUrlWithSearchParams(VALIDATE_REFERRER_NUMBER_URL, {referrerNumber})),
  proceedProspectApplication: (application: ProspectApplication) => HttpService.post<CommandOutput>(PROSPECT_URL, {
    ...application,
    mode: application.mode ?? ProspectApplicationMode.REGULAR
  }),
  activateProspectAlias: (activationData: ProspectAliasActivationData) =>
    HttpService.post<CommandOutput>(ACTIVATE_PROSPECT_ALIAS, activationData)
};