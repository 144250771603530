import {Button} from '@material-ui/core';
import React from 'react';
import Loader from '../../loader/Loader';
import styles from './ConfirmationPopupContent.module.scss';

interface ConfirmationPopupContentProps {
  query: string;
  confirmLabel: string | React.ReactElement;
  declineLabel: string | React.ReactElement;
  onConfirm: () => void;
  onDecline: () => void;
  isConfirmInProgress?: boolean
}

const ConfirmationPopupContent = ({ query, confirmLabel, declineLabel, onConfirm, onDecline, isConfirmInProgress = false}: ConfirmationPopupContentProps) => {
  return (
    <div className={styles.content}>
      <div className={styles.query}>{ query }</div>
      <div className={styles.buttons}>
        <div onClick={onDecline} className={styles.decline}>{ declineLabel }</div>
        <Button onClick={onConfirm} className={styles.confirm} disabled={isConfirmInProgress}>
          <Loader buttonSpinner loaded={!isConfirmInProgress}>
            <>{ confirmLabel }</>
          </Loader>
        </Button>
      </div>
    </div>
  );
};

export default ConfirmationPopupContent;
