export enum IncomeSourceType {
  SALARY = 'SALARY',
  BUSINESS = 'BUSINESS',
  OTHER = 'OTHER',
  ALLOWANCE = 'ALLOWANCE',
  REMITTANCE = 'REMITTANCE',
  PENSION = 'PENSION',
  DEPED = 'DEPED',
  SSS = 'SSS',
  OTHER_BANK_ACCOUNTS = 'OTHER_BANK_ACCOUNTS'
}

export interface IncomeSourceTO<T_TYPE = string> {
  description: string;
  id?: number;
  income?: number;
  natureOfWorkId?: number;
  natureOfWorkRemarks?: string;
  primary: boolean;
  type: T_TYPE;
}

export interface IncomeSource extends IncomeSourceTO<IncomeSourceType> {
}
