import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import React, {ReactNode} from 'react';
import styles from './AlertSnackbar.module.scss';

export enum Severity {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS'
}

interface AlertSnackbarProps {
  handleClose: () => void,
  mainSnackbar?: boolean,
  message: ReactNode,
  open: boolean,
  severity?: Severity,
  showCloseButton?: boolean
}

export const AlertSnackbar = (
    {handleClose, mainSnackbar, message, open, severity = Severity.ERROR, showCloseButton = false}: AlertSnackbarProps
  ) => {
  const CloseButton = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
      <CloseIcon />
    </IconButton>
  );

  return (
    <div className={styles['snackbar-wrapper']}>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        classes={{root: `${styles.snackbar} ${mainSnackbar ? styles['snackbar--main'] : '' }`}}
        ContentProps={
          {
            classes: {
              message: styles['snackbar__message'],
              root:
                `${styles['snackbar__content']} ${severity === Severity.ERROR
                  ? styles['snackbar__content--error']
                  : ''}`
            }
          }
        }
        /* if message has severity SUCCESS auto-close it after 2s */
        autoHideDuration={severity === Severity.SUCCESS ? 3600 : undefined}
        open={open}
        onClose={handleClose}
        message={message}
        action={
          <>{showCloseButton && CloseButton}</>
        }
      />
    </div>
  );
};