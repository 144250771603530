import {RoutesParts} from '../../constants/routes-parts';
import {RoutesPaths} from './routes-paths';

export const DashboardRoutesPaths = {
  BUY_LOAD: RoutesPaths.DASHBOARD + '/buy-load',
  GOVERNMENT: RoutesPaths.DASHBOARD + '/government',
  DEPOSIT: RoutesPaths.DASHBOARD + '/deposit',
  SCAN_QR: RoutesPaths.DASHBOARD + '/scan-qr',
  PAY_BILLS: RoutesPaths.DASHBOARD + '/pay-bills',
  PENDING_WITHDRAWALS: RoutesPaths.DASHBOARD + RoutesParts.WITHDRAW + '/pending-withdrawals',
  REFERRAL: RoutesPaths.DASHBOARD + '/referral',
  SETTINGS: RoutesPaths.DASHBOARD + '/settings',
  TELEMEDICINE: RoutesPaths.DASHBOARD + '/telemedicine',
  TRANSFER: RoutesPaths.DASHBOARD + '/transfer',
  WITHDRAW: RoutesPaths.DASHBOARD + RoutesParts.WITHDRAW,
  VERIFICATION: RoutesPaths.DASHBOARD + '/verification'
};
