import React from 'react';
import {Link} from 'react-router-dom';
import {DashboardRoutesPaths} from '../../../routes/routes-paths/dashboard.routes-paths';
import styles from './Banner.module.scss';
import DesktopBanner from '../../../assets/images/banner/banner-desktop.png'
import DesktopBanner2x from '../../../assets/images/banner/banner-desktop@2x.png'
import MobileBanner from '../../../assets/images/banner/banner-mobile.png'
import MobileBanner2x from '../../../assets/images/banner/banner-mobile@2x.png'


export default function Banner() {
  const highResDesktopBanner = `${DesktopBanner2x} 2x`;
  const highResMobileBanner = `${MobileBanner2x} 2x`;

  return (
    <Link className={styles.banner} to={DashboardRoutesPaths.DEPOSIT}>
      <img src={DesktopBanner}
           srcSet={highResDesktopBanner}
           alt="Banner"
           className={`${styles.image} ${styles['image--desktop']}`} />
      <img src={MobileBanner}
           srcSet={highResMobileBanner}
           alt="Banner"
           className={`${styles.image} ${styles['image--mobile']}`} />
    </Link>
  );
}
