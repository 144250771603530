import React from 'react';
import {Redirect} from 'react-router-dom';
import BillPayment from '../components/bill-payment/BillPayment';
import Dashboard from '../components/dashboard/Dashboard';
import Deposit from '../components/deposit/Deposit';
import Referral from '../components/referral/Referral';
import Settings from '../components/settings/Settings';
import {KeyedRouteProps} from '../components/shared/routes/model/Routes';
import Telemedicine from '../components/telemedicine/Telemedicine';
import Transfer from '../components/transfer/Transfer';
import Verification from '../components/verification/Verification';
import PendingWithdrawals from '../components/withdraw/pending-wihdrawals/PendingWithdrawals';
import Withdraw from '../components/withdraw/Withdraw';
import {exactRoutes} from '../utils/exact-routes';
import {DashboardRoutesPaths} from './routes-paths/dashboard.routes-paths';
import {RoutesPaths} from './routes-paths/routes-paths';

export const DASHBOARD_ROUTES: KeyedRouteProps[] = exactRoutes([
  {
    path: RoutesPaths.DASHBOARD,
    key: 'DASHBOARD',
    component: Dashboard
  },
  {
    path: DashboardRoutesPaths.SCAN_QR,
    key: 'SCAN_QR',
    isOnlyForCustomer: true,
    // TODO - change implementation to QR popup
    render: () => <Redirect to={RoutesPaths.DASHBOARD} push={true} />
  },
  {
    path: DashboardRoutesPaths.DEPOSIT,
    key: 'DEPOSIT',
    component: Deposit,
    isOnlyForCustomer: true
  },
  {
    path: DashboardRoutesPaths.BUY_LOAD,
    key: 'BUY_LOAD',
    render: () => <Dashboard withBuyLoads={true} />,
    isOnlyForCustomer: true
  },
  {
    path: DashboardRoutesPaths.PAY_BILLS,
    key: 'PAY_BILLS',
    component: BillPayment,
    isOnlyForCustomer: true
  },
  {
    path: DashboardRoutesPaths.GOVERNMENT,
    key: 'GOVERNMENT',
    render: () => <Dashboard withGovernment={true} />,
    isOnlyForCustomer: true
  },
  {
    path: DashboardRoutesPaths.TELEMEDICINE,
    key: 'TELEMEDICINE',
    component: Telemedicine,
    isOnlyForCustomer: true
  },
  {
    path: DashboardRoutesPaths.TRANSFER,
    key: 'TRANSFER',
    component: Transfer,
    isOnlyForCustomer: true
  },
  {
    path: DashboardRoutesPaths.WITHDRAW,
    key: 'WITHDRAW',
    component: Withdraw,
    isOnlyForCustomer: true
  },
  {
    path: DashboardRoutesPaths.PENDING_WITHDRAWALS,
    key: 'PENDING_WITHDRAWALS',
    component: PendingWithdrawals,
    isOnlyForCustomer: true
  },
  {
    path: DashboardRoutesPaths.REFERRAL,
    key: 'REFERRAL',
    component: Referral,
    isOnlyForCustomer: true
  },
  {
    path: DashboardRoutesPaths.SETTINGS,
    key: 'SETTINGS',
    component: Settings
  },
  {
    path: DashboardRoutesPaths.VERIFICATION,
    key: 'VERIFICATION',
    component: Verification
  }
] as KeyedRouteProps[]);
