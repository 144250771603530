enum FeeOverdraftStrategy {
  DRAIN = 'DRAIN',
  ERROR = 'ERROR',
  OVERDRAFT = 'OVERDRAFT',
  SKIP = 'SKIP'
}

export interface FeeTO<T_OVERDRAFT_STRATEGY = string> {
  feeAmount: number;
  feeDefinitionId: number,
  feeName: String,
  feeOverdraftStrategy: T_OVERDRAFT_STRATEGY
}

export interface Fee extends FeeTO<FeeOverdraftStrategy> {
}

export const fromFeeTO = (feeTO: FeeTO): Fee => ({
  ...feeTO,
  feeOverdraftStrategy: FeeOverdraftStrategy[feeTO.feeOverdraftStrategy]
});
