import React from 'react';
import {Trans} from 'react-i18next';
import styles from './OperationDetail.module.scss';

export enum Width {
  FULL = 'FULL',
  HALF = 'HALF'
}

interface OperationDetailProps {
  title: React.ReactElement | string;
  value: string | number | React.ReactElement;
  className?: string;
  summary?: boolean;
  width?: Width
}

const OperationDetail = ({title, value, className, summary = false, width = Width.HALF}: OperationDetailProps) => {
  let itemContainerClassName = `${styles.item} ${className ?? ''}`;
  itemContainerClassName += ` ${summary ? styles['item--summary'] : ''}`;
  itemContainerClassName += ` ${width === Width.FULL ? styles['item--full-width'] : ''}`;

  return (
    <div className={itemContainerClassName}>
      <div className={styles['item__title']}>
        {React.isValidElement(title) ? title : <Trans>{title}</Trans>}
      </div>
      <div className={styles['item__value']}>
        {value}
      </div>
    </div>
  );
};

export default OperationDetail;
