import {FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
import React, {useContext, useState} from 'react';
import {Trans} from 'react-i18next';
import {DictionaryCode} from '../../../shared/model/Dictionary';
import {VerificationService} from '../../../shared/services/verification.service';
import {DictionaryHelper} from '../../../utils/dictionary-helper';
import Loader from '../../shared/loader/Loader';
import {VerificationStep} from '../steps/VerificationStep';
import {VerificationStepContent} from '../steps/VerificationStepContent';
import {VerificationStepFooter} from '../steps/VerificationStepFooter';
import {VerificationContext} from '../Verification';
import styles from './VerificationEligibility.module.scss';

// enum needed as RadioGroup values can't be boolean
enum RadioValue {
  NO = 'NO',
  YES = 'YES'
}

const PH_CODE = 'PH';

export const VerificationEligibility = () => {
  const {application, dictionaries, setApplication, dictionariesError} = useContext(VerificationContext);
  const citizenshipId = application.citizenshipId;
  const countryOptions = DictionaryHelper.getDictionaryByCode(dictionaries, DictionaryCode.COUNTRY);
  const philippinesCitizenshipId = countryOptions?.find(option => option.code === PH_CODE)?.id;
  const radioButtonInitialData = citizenshipId ? citizenshipId === philippinesCitizenshipId : '';
  const [isUserFilipino, setIsUserFilipino] = useState<boolean | ''>(radioButtonInitialData);
  const [isUserNotAmericanCitizen, setIsUserNotAmericanCitizen] = useState<boolean | ''>(radioButtonInitialData);

  const handleIsFilipinoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const radioValue = (event.target as HTMLInputElement).value;
    setIsUserFilipino(radioValue === RadioValue.YES);
  };

  const handleIsAmericanCitizenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const radioValue = (event.target as HTMLInputElement).value;
    setIsUserNotAmericanCitizen(!(radioValue === RadioValue.YES));
  };

  const submit = () => {
    const applicationData = {
      ...application,
      citizenshipId: philippinesCitizenshipId
    };

    return VerificationService.updateProspectApplication(application.id, applicationData).then(() =>
      setApplication(applicationData)
    );
  };

  const getIsFilipinoRadioValue = (): RadioValue | '' =>
    isUserFilipino === '' ? '' : isUserFilipino ? RadioValue.YES : RadioValue.NO;

  const getIsAmericanCitizenRadioValue = (): RadioValue | '' =>
    isUserNotAmericanCitizen === '' ? '' : !isUserNotAmericanCitizen ? RadioValue.YES : RadioValue.NO;

  const RouteTrans = ({children}) => <Trans>{`VERIFICATION.ELIGIBILITY.${children}`}</Trans>;

  return (
    <VerificationStep>
      <VerificationStepContent description={<RouteTrans>DESCRIPTION</RouteTrans>}>
        {
          !dictionaries.length
            ? <Loader />
            : <>
              <div className={styles['radio-group-header']}>
                <RouteTrans>IS_FILIPINO</RouteTrans>
              </div>
              <RadioGroup aria-label="are you filipino"
                          value={getIsFilipinoRadioValue()}
                          onChange={handleIsFilipinoChange}>
                <FormControlLabel value={RadioValue.YES} control={<Radio />} label={<Trans>SHARED.COMMON.YES</Trans>} />
                <FormControlLabel value={RadioValue.NO} control={<Radio />} label={<Trans>SHARED.COMMON.NO</Trans>} />
              </RadioGroup>
              <div className={styles['radio-group-header']}>
                <RouteTrans>IS_AMERICAN_CITIZEN</RouteTrans>
              </div>
              <RadioGroup aria-label="are you american citizen"
                          value={getIsAmericanCitizenRadioValue()}
                          onChange={handleIsAmericanCitizenChange}>
                <FormControlLabel value={RadioValue.YES} control={<Radio />} label={<Trans>SHARED.COMMON.YES</Trans>} />
                <FormControlLabel value={RadioValue.NO} control={<Radio />} label={<Trans>SHARED.COMMON.NO</Trans>} />
              </RadioGroup>
            </>
        }
      </VerificationStepContent>
      <VerificationStepFooter submit={submit}
                              isSubmitDisabled={!isUserFilipino || !isUserNotAmericanCitizen || dictionariesError} />
    </VerificationStep>
  );
};
