import {Button, TextField} from '@material-ui/core';
import {Formik, FormikHelpers} from 'formik';
import {isEmpty} from 'lodash';
import React, {ReactElement, useContext} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {ReactComponent as ArrowBack} from '../../../../assets/images/icons/arrow-back.svg';
import {RecipientDetails, RecipientDetailsErrors} from '../../../../shared/model/Recipient';
import {WithdrawalService} from '../../../../shared/services/withdrawal.service';
import {FeeHelper} from '../../../../utils/fee-helper';
import {StringHelper} from '../../../../utils/string-helper';
import {CardlessAtmWithdrawalPopupContext} from '../../../transfer/cardless-atm-withdrawal/CardlessAtmWithdrawalPopup';
import PhoneTextField, {MOBILE_NUMBER_MASK} from '../../fields/PhoneTextField';
import Loader from '../../loader/Loader';
import styles from './CardlessWithdrawalRecipientDetails.module.scss';
import {CardlessWithdrawalRoutesPaths} from '../cardless-withdrawal.routes-paths';

interface CardlessWithdrawalRecipientDetailsProps {
  location: {
    state: {
      withdrawalParams: {
        amount: number,
        productId: number
      },
      isItCustomerWithdrawal: boolean
    }
  }
}

let formEmptyState = {
  firstName: '',
  middleName: '',
  lastName: '',
  addressStreet: '',
  addressBarangay: '',
  addressCity: '',
  mobileNo: '',
  email: ''
} as RecipientDetails;

export default function CardlessWithdrawalRecipientDetails({location}: CardlessWithdrawalRecipientDetailsProps) {
  const locationState = location.state;
  const history = useHistory();
  const {t} = useTranslation();
  const popupContext = useContext(CardlessAtmWithdrawalPopupContext);
  const formInitState = popupContext.recipientDetails || formEmptyState;

  // TODO Submit fields are not validated (except mobileNo length). Probably validation will be needed in the future
  // As we have no error messages, errors will appear without message at the moment.
  const validateForm = (values) => {
    const errors = {} as RecipientDetailsErrors;

    if (values.mobileNo.length < MOBILE_NUMBER_MASK.length) {
      // Message only used to mark field as invalid
      errors.mobileNo = t('CARDLESS_WITHDRAWAL.ERRORS.TOO_SHORT_MOBILE_NUMBER');
    }

    return errors;
  };

  const submit = (recipientDetails: RecipientDetails, actions: FormikHelpers<RecipientDetails>) => {
    const withdrawalParams = {
      ...locationState.withdrawalParams,
      recipient: {
        ...recipientDetails,
        mobileNo: StringHelper.removeWhitespaces(recipientDetails.mobileNo)
      }
    };

    popupContext.recipientDetails = recipientDetails;

    const isItCustomerWithdrawal = locationState.isItCustomerWithdrawal;

    WithdrawalService.calculateCardlessWitdrawalFees(isItCustomerWithdrawal, withdrawalParams)
      .then(fees =>
        history.push(CardlessWithdrawalRoutesPaths.CONFIRM, {
          withdrawalParams,
          fee: FeeHelper.getTotalFee(fees),
          isItCustomerWithdrawal
        })
      );
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div className={styles['arrow-back']} onClick={history.goBack}>
          <ArrowBack />
        </div>
        <Trans>TRANSFER.LABELS.RECIPIENT_DETAILS.HEADER</Trans>
      </div>
      <Formik
        <RecipientDetails>
        initialValues={formInitState}
        onSubmit={submit}
        validate={validateForm}
        children={NonCustomerAtmWithdrawalRecipientFrom}
      />
    </div>
  );
}

const NonCustomerAtmWithdrawalRecipientFrom = ({values, errors, handleChange, handleBlur, handleSubmit, isSubmitting}): ReactElement => (
  <form onSubmit={handleSubmit} className={styles.form}>
    <div className={styles['input-container']}>
      <div className={styles.section}>
        <div className={styles['section__title']}>
          <Trans>TRANSFER.LABELS.RECIPIENT_DETAILS.FULL_NAME.TITLE</Trans>
        </div>
        <div className={styles['section__description']}>
          <Trans>TRANSFER.LABELS.RECIPIENT_DETAILS.FULL_NAME.DESCRIPTION</Trans>
        </div>
        <div className={styles['section__container']}>
          <TextField
            required
            error={errors.firstName}
            label={<Trans>TRANSFER.LABELS.RECIPIENT_DETAILS.FULL_NAME.FIRST</Trans>}
            type="text"
            name="firstName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
          />
          <TextField
            error={errors.middleName}
            label={<Trans>TRANSFER.LABELS.RECIPIENT_DETAILS.FULL_NAME.MIDDLE</Trans>}
            type="text"
            name="middleName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.middleName}
          />
          <TextField
            required
            error={errors.lastName}
            label={<Trans>TRANSFER.LABELS.RECIPIENT_DETAILS.FULL_NAME.LAST</Trans>}
            type="text"
            name="lastName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
          />
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles['section__title']}>
          <Trans>TRANSFER.LABELS.RECIPIENT_DETAILS.ADDRESS.TITLE</Trans>
        </div>
        <div className={styles['section__description']}>
          <Trans>TRANSFER.LABELS.RECIPIENT_DETAILS.COMMON_DESCRIPTION</Trans>
        </div>
        <div className={styles['section__container']}>
          <TextField
            required
            error={errors.addressStreet}
            label={<Trans>SHARED.COMMON.STREET</Trans>}
            type="text"
            name="addressStreet"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.addressStreet}
          />
          <TextField
            required
            error={errors.addressBarangay}
            label={<Trans>TRANSFER.LABELS.RECIPIENT_DETAILS.ADDRESS.DISTRICT</Trans>}
            type="text"
            name="addressBarangay"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.addressBarangay}
          />
          <TextField
            required
            error={errors.addressCity}
            label={<Trans>SHARED.COMMON.CITY_OR_MUNICIPALITY</Trans>}
            type="text"
            name="addressCity"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.addressCity}
          />
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles['section__title']}>
          <Trans>TRANSFER.LABELS.RECIPIENT_DETAILS.CONTACT.TITLE</Trans>
        </div>
        <div className={styles['section__description']}>
          <Trans>TRANSFER.LABELS.RECIPIENT_DETAILS.COMMON_DESCRIPTION</Trans>
        </div>
        <div className={styles['section__container']}>
          <PhoneTextField
            required
            error={!!errors.mobileNo}
            label={<Trans>SHARED.COMMON.MOBILE_NO</Trans>}
            name="mobileNo"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.mobileNo}
          />
          <TextField
            error={errors.email}
            label={<Trans>SHARED.COMMON.EMAIL</Trans>}
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
        </div>
      </div>
    </div>
    <div className={styles.actions}>
      <Button type="submit"
              className={styles.submit}
              disabled={isSubmitting || !isEmpty(errors)}>
        <Loader buttonSpinner loaded={!isSubmitting}>
          <Trans>SHARED.COMMON.SUBMIT</Trans>
        </Loader>
      </Button>
    </div>
  </form>
);