export interface TelemedicineProductTO {
  amount: number,
  htmlDescriptionUrl: string,
  id: number,
  name: string,
  shortDescription: string
}

export interface TelemedicineProduct extends TelemedicineProductTO {
}

export const fromTelemedicineProductTO = (telemedicineProductTO: TelemedicineProductTO): TelemedicineProduct => ({
  ...telemedicineProductTO
});
