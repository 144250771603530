import UserData from '../shared/model/UserData';
import State from './state';
import {AuthenticatedSystemProperties} from '../shared/model/SystemProperties';
import {AccountDetails} from '../shared/model/Account';

export const getIsLoggedIn =
  (state: State): boolean | undefined => state.public.isLoggedIn;

export const getUserData =
  (state: State): UserData | undefined => state.sessionData;

export const getAuthenticatedSystemProperties =
  (state: State): AuthenticatedSystemProperties | undefined => state.authenticatedSystemProperties;

export const getUserAccount =
  (state: State): AccountDetails | undefined => state.userPrimaryAccount;

export const getIsProspectApplicationSealed =
  (state: State): boolean | undefined => state.prospectApplicationSealed;

export const getIsPasswordChangeRequired =
  (state: State): boolean | undefined => state.isPasswordChangeRequired;
