import React from 'react';
import {Trans} from 'react-i18next';
import { TransWithNoWrapSpan } from '../../trans/TransWithNoWrapSpan';
import { ListParagraph } from '../../list-paragraph/ListParagraph';

const TRANS_PREFIX = 'CARDLESS_WITHDRAWAL.HOW_TO';

export const CardlessWithdrawalHowTo = () => {
  const bullets = [
    `${TRANS_PREFIX}.POINT_1`,
    `${TRANS_PREFIX}.POINT_2`,
    `${TRANS_PREFIX}.POINT_3`,
    `${TRANS_PREFIX}.POINT_4`,
    `${TRANS_PREFIX}.POINT_5`
  ].map(bullet => <TransWithNoWrapSpan>{bullet}</TransWithNoWrapSpan>);

  return <ListParagraph items={bullets} title={<Trans>{`${TRANS_PREFIX}.TITLE`}</Trans>} />
};