import {Button, TextField} from '@material-ui/core';
import {Formik, FormikHelpers} from 'formik';
import React, {ReactElement, useContext, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {MainRouterContext} from '../../../App';
import {RoutesPaths} from '../../../routes/routes-paths/routes-paths';
import {HttpError} from '../../../shared/services/http.service';
import {ErrorHelper} from '../../../utils/error-helper';
import PhoneTextField from '../../shared/fields/PhoneTextField';
import Loader from '../../shared/loader/Loader';
import Popup from '../../shared/popups/Popup';
import PopupCloseButton from '../../shared/popups/PopupCloseButton';
import {ProspectApplicationMode, RegisterService} from '../register.service';
import {RegistrationRoutesPaths} from '../registration.routes-paths';
import {RegistrationSetPasswordLocationProps} from '../setPassword/RegistrationSetPassword';
import InviteCodePopup from './invite-code-popup/InviteCodePopup';
import {validateFields, ValidationResultType} from './RegistrationFormValidator';
import styles from './RegistrationUserData.module.scss';

interface RegistrationUserDataLocationProps {
  otpError?: HttpError<any>;
}

interface RegisterFormFields {
  alias: string;
  mobileNo: string;
  referrerNumber: string;
}

export interface BuyLoadConfirmationOutput {
  applicationId: number;
  applicationUuid: string;
}

export function RegistrationUserData() {
  const [invitePopupOpen, setInvitePopupOpen] = useState(false);
  const locationState = useLocation<RegistrationUserDataLocationProps>().state;
  const {t} = useTranslation();
  const history = useHistory();
  const mainRouterContext = useContext(MainRouterContext);

  const submit = async (values: RegisterFormFields, actions: FormikHelpers<RegisterFormFields>) => {
    await validateFields(values)
      .then((results) => {
        if (results.find(result => result.type === ValidationResultType.REJECTED)) {
          const errors = {};
          results.filter(result => result.type === ValidationResultType.REJECTED)
            .forEach(result => errors[result.field] = result.message);
          actions.setErrors(errors);
          return;
        }

        RegisterService.proceedProspectApplication({
          alias: values.alias,
          mobileNo: values.mobileNo,
          referrerNumber: values.referrerNumber,
          mode: ProspectApplicationMode.EXPRESS
        }).then(commandOutput => {
            history.push(RegistrationRoutesPaths.OTP, {
              ...commandOutput,
              onSuccess: (commandOutput: BuyLoadConfirmationOutput) => redirectToSetPassword(values.alias, commandOutput),
              onError: handleOtpError
            });
          }
        ).catch((reason => actions.setFieldError('server', ErrorHelper.getRawErrorMessage(reason))));
      });
  };

  const redirectToSetPassword = (alias: string, commandOutput: BuyLoadConfirmationOutput) => {
    history.push(RegistrationRoutesPaths.SET_PASSWORD, {
      alias,
      applicationId: commandOutput.applicationId
    } as RegistrationSetPasswordLocationProps);
  };

  const handleOtpError = (error: HttpError<any>) => {
    history.replace(RegistrationRoutesPaths.OTP, {
      otpError: error
    } as RegistrationUserDataLocationProps);
  };

  const RegisterForm = ({values, errors, handleChange, handleBlur, handleSubmit, isSubmitting}): ReactElement => (
    <form onSubmit={handleSubmit} className={styles.form}>
      <TextField
        required
        error={!!errors.alias}
        className={styles.input}
        label={<Trans>SHARED.COMMON.NAME</Trans>}
        type="text"
        name="alias"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.alias}
        helperText={errors.alias}
      />
      <PhoneTextField
        required
        error={!!errors.mobileNo}
        className={styles.input}
        label={<Trans>REGISTER.FORM.MOBILE_NUMBER</Trans>}
        type="text"
        name="mobileNo"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.mobileNo}
        helperText={errors.mobileNo}
      />
      <TextField
        error={!!errors.referrerNumber}
        className={styles.input}
        label={<Trans>REGISTER.FORM.INVITE_CODE</Trans>}
        type="text"
        name="referrerNumber"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.referrerNumber}
        helperText={errors.referrerNumber}
      />
      <div onClick={() => setInvitePopupOpen(true)} className={styles['invite-code-link']}>
        <Trans>REGISTER.INVITE_CODE_LINK</Trans>
      </div>
      <Button type="submit" disabled={isSubmitting}>
        <Loader buttonSpinner loaded={!isSubmitting}>
          <Trans>SHARED.COMMON.SUBMIT</Trans>
        </Loader>
      </Button>
      <div className={styles.errors}>
        <div>{/* div needed as errors wrapper */}
          { errors.server && <div className={styles.error}>{errors.server}</div> }
          { locationState?.otpError && (
              <div className={styles.error}>
                {ErrorHelper.getErrorMessageTranslation(t, locationState.otpError)}
              </div>
            )
          }
        </div>
      </div>
    </form>
  );

  const closePopup = () => setInvitePopupOpen(false);

  const goToLogin = () => mainRouterContext.globalHistory.push(RoutesPaths.LOGIN);

  return (
    <>
      <div className={styles.register}>
        <div className={styles.login}>
          <Trans>REGISTER.HAVE_ACCOUNT_MESSAGE</Trans>
          <div onClick={goToLogin} className={styles['login__link']}>
            <Trans>REGISTER.LOGIN_CALL_TO_ACTION</Trans>
          </div>
        </div>
        <div className={styles['form-container']}>
          <h1 className={styles['form-header']}><Trans>REGISTER.TITLE</Trans></h1>
          <p className={styles['form-description']}><Trans>REGISTER.DESCRIPTION</Trans></p>
          <Formik
            <RegisterFormFields>
            initialValues={{
              alias: '',
              mobileNo: '',
              referrerNumber: ''
            }}
            onSubmit={submit}
            children={RegisterForm}
          />
        </div>
      </div>
      <Popup open={invitePopupOpen}>
        <div className={styles['invite-code-popup']}>
          <PopupCloseButton handleClose={closePopup} />
          <InviteCodePopup />
        </div>
      </Popup>
    </>
  );
}
