export enum AddressType {
  PERMANENT = 'PERMANENT',
  PRESENT = 'PRESENT',
  BUSINESS = 'BUSINESS'
}

export enum AddressOwnership {
  OWNED = 'OWNED',
  RENTED = 'RENTED',
  MORTGAGED = 'MORTGAGED',
  SHARED = 'SHARED'
}

export interface AddressTO<T_TYPE = string, T_OWNERSHIP = string> {
  type: T_TYPE;
  barangay?: string;
  city?: string;
  id?: number;
  ownership?: T_OWNERSHIP;
  postalCode?: string;
  province?: string;
  regionId?: number;
  street?: string;
}

export interface Address extends AddressTO<AddressType, AddressOwnership> {
}
