import {isNil} from 'lodash';
import React from 'react';
import {Trans} from 'react-i18next';
import {DICTIONARY_OPERATION_TYPE_PREFIX} from '../../../../constants/values';
import {OperationBasicData} from '../../../../shared/model/operations/OperationBasicData';
import {OperationCategory} from '../../../../shared/model/operations/OperationCategory';
import {OperationDetails} from '../../../../shared/model/operations/OperationDetails';

interface OperationNameProps {
  operation: OperationBasicData | OperationDetails;
}

const OperationName = ({operation}: OperationNameProps) => (
  <>{
    operation.category === OperationCategory.FEE && !isNil(operation.feeName)
      ? operation.feeName
      : <Trans>{DICTIONARY_OPERATION_TYPE_PREFIX + operation.operationTranslationCode}</Trans>
  }</>
);

export default OperationName;
