import React from 'react';
import {useCustomerOperationDetailsHook} from '../../../../utils/hooks/getCustomerOperationDetailsHook';
import Loader from '../../loader/Loader';
import OperationInfo from '../../operation/operation-info/OperationInfo';
import styles from './ActivityDetails.module.scss';

interface ActivityDetailsProps {
  productId: number;
  transactionId: number;
}

export default function ActivityDetails({productId, transactionId}: ActivityDetailsProps) {
  const operationDetails = useCustomerOperationDetailsHook(productId, transactionId);

  return (
    <div className={styles.container}>
      { !!operationDetails ? <OperationInfo operationDetails={operationDetails} /> : <Loader className={styles.loader} /> }
    </div>
  );
}
