import axios, {AxiosRequestConfig, AxiosResponse, CancelTokenSource} from 'axios';
import dayjs from 'dayjs';
import {logout, passwordChangeRequired} from '../../store/actions';
import store from '../../store/store';
import {ABORT_ERROR_NAME, SESSION_RESTRICTED_ERROR_CODE} from '../../utils/error-helper';
import executeOnExpire from '../../utils/session-expiration-counter';

export type SimpleErrorBody = {
  customError: true;
  errorMessage: string;
}

export type ErrorBody = SimpleErrorBody & {
  commandId: string;
  errorCode: string;
  errorId: string;
  errorMessage: string;
  errorTimestamp: string;
  path: string;
  requestUuid: string;
  systemVersion: string;
  username: string;
}

const SESSION_EXPIRATION_TIME_HEADER = 'x-nx-session-expiration-time';
const API_HOST = process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_API_URL ?? '' : '';
const API_URL = API_HOST + '/api';

export class HttpError<T extends SimpleErrorBody> extends Error {
  constructor(public response: AxiosResponse, public error: T) {
    super(HttpError.getHttpError(response, error));
  }

  private static getHttpError = <E extends SimpleErrorBody>(response: AxiosResponse, error: E): string => {
    const statusMessage = response?.status ? '\nStatus: ' + response.status : '';
    const errorMessage = error ? '\n' + JSON.stringify(error) : '';
    return `Http Error${statusMessage}${errorMessage}`;
  };
}

axios.defaults.withCredentials = true;
axios.defaults.baseURL = API_URL;
axios.interceptors.response.use(
  (response: AxiosResponse) => {
    const expTimeHeaderValue = response?.headers?.[SESSION_EXPIRATION_TIME_HEADER];

    if (expTimeHeaderValue) {
      const expirationTime = dayjs(expTimeHeaderValue);
      executeOnExpire(expirationTime, () => store.dispatch(logout('SHARED.COMMON.SESSION_EXPIRED')));
    }

    return response;
  },
  error => {
    if (axios.isCancel(error)) {
      const error = {name: ABORT_ERROR_NAME} as Error;
      console.debug('Request aborted');
      return Promise.reject(error);
    }

    const response = error.response;
    const httpError = new HttpError(response, response.data);

    if (response.status === 403 || response.status === 500) {
      store.dispatch(logout('SHARED.COMMON.SESSION_EXPIRED'));
    }

    if (response.status === 401) {
      store.dispatch(logout());
    }

    if (response.status === 400 && (httpError.error as ErrorBody).errorCode === SESSION_RESTRICTED_ERROR_CODE) {
      store.dispatch(passwordChangeRequired());
    }

    return Promise.reject(httpError);
  }
);

class HttpService {
  async get<ResType>(url: string,
                     cancelTokenSource?: CancelTokenSource | null,
                     config?: AxiosRequestConfig): Promise<ResType> {
    const axiosResponse = await axios.get<ResType>(
      url,
      {
        cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined,
        ...config
      }
    );

    return axiosResponse.data;
  }

  async post<ResType>(url: string,
                      data?: any,
                      cancelTokenSource?: CancelTokenSource | null,
                      config?: AxiosRequestConfig): Promise<ResType> {
    const axiosResponse = await axios.post<ResType>(url, data, {
      cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined,
      ...config
    });
    return axiosResponse.data;
  }

  async postFile<ResType>(url: string, file: File): Promise<ResType> {
    const formData = new FormData();
    formData.append('file', file);
    const axiosResponse = await axios.post<ResType>(url, formData);
    return axiosResponse.data;
  }

  async put<ResType>(url: string, data: any): Promise<ResType> {
    const axiosResponse = await axios.put<ResType>(url, data);
    return axiosResponse.data;
  }

  async delete<ResType>(url: string): Promise<ResType> {
    const axiosResponse = await axios.delete<ResType>(url);
    return axiosResponse.data;
  }
}

export default new HttpService();
