import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import {AppSnackbarContext} from '../../../../App';
import {fetchUserPrimaryAccount} from '../../../../store/actions';
import State from '../../../../store/state';
import {ErrorHelper} from '../../../../utils/error-helper';
import {AlertSnackbar} from '../../../shared/alert-snackbar/AlertSnackbar';
import {AlertSnackbarActionType} from '../../../shared/alert-snackbar/alertSnackBarHook';
import ConfirmationPopupContent from '../../../shared/popups/confirmation-popup-content/ConfirmationPopupContent';

export interface PendingWithdrawalCancelConfirmationLocationProps {
  onConfirm: () => Promise<any>;
}

interface PendingWithdrawalCancelConfirmationDispatchProps {
  refreshPrimaryAccount: () => void;
}

interface PendingWithdrawalCancelConfirmationParamsProps {
  handlePopupClose: () => void;
  handleWithdrawalsRefresh: () => void;
}

type PendingWithdrawalCancelConfirmationProps =
  PendingWithdrawalCancelConfirmationParamsProps
  & PendingWithdrawalCancelConfirmationDispatchProps

const PendingWithdrawalCancelConfirmation = ({
                                               handlePopupClose,
                                               handleWithdrawalsRefresh,
                                               refreshPrimaryAccount
                                             }: PendingWithdrawalCancelConfirmationProps) => {
  const history = useHistory();
  const {t} = useTranslation();
  const appSnackbarContext = useContext(AppSnackbarContext);
  const {onConfirm} = useLocation<PendingWithdrawalCancelConfirmationLocationProps>().state;
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isConfirmInProgress, setIsConfirmInProgress] = React.useState(false);

  const cancelReferenceNumber = () => {
    setIsConfirmInProgress(true);
    onConfirm()
      .then(() => {
        refreshPrimaryAccount();
        handlePopupClose();
        handleWithdrawalsRefresh();
        appSnackbarContext.dispatchAction({
          type: AlertSnackbarActionType.SHOW,
          message: t('WITHDRAW.PENDING_WITHDRAWALS.CANCEL_CONFIRMATION.SUCCESS_MESSAGE')
        });
      })
      .catch(error => {
        setIsConfirmInProgress(false);
        setErrorMessage(ErrorHelper.getErrorMessageTranslation(t, error));
        setShowError(true);
      });
  };

  return (
    <>
      <AlertSnackbar handleClose={() => setShowError(false)} open={showError} message={errorMessage} />
      <ConfirmationPopupContent query={t('WITHDRAW.PENDING_WITHDRAWALS.CANCEL_CONFIRMATION.QUERY')}
                                onConfirm={cancelReferenceNumber}
                                onDecline={history.goBack}
                                confirmLabel={t('SHARED.COMMON.YES')}
                                declineLabel={t('SHARED.COMMON.NO')}
                                isConfirmInProgress={isConfirmInProgress} />
    </>
  );
};

const mapDispatchToProps = (dispatch): PendingWithdrawalCancelConfirmationDispatchProps => ({
  refreshPrimaryAccount: () => dispatch(fetchUserPrimaryAccount(true))
});

export default connect<{}, PendingWithdrawalCancelConfirmationDispatchProps, {}, State>(null,
  mapDispatchToProps)(
  PendingWithdrawalCancelConfirmation);
