import React, {useState} from 'react';
import ArrowRight from '../../../../assets/images/icons/arrow-right.svg';
import {SHORT_DATE_FORMAT} from '../../../../constants/date';
import {OperationBasicData} from '../../../../shared/model/operations/OperationBasicData';
import CurrencyFormat from '../../../shared/currency-format/CurrencyFormat';
import Popup from '../../popups/Popup';
import PopupCloseButton from '../../popups/PopupCloseButton';
import ActivityDetails from '../details/ActivityDetails';
import OperationName from '../operation-name/OperationName';
import styles from './ActivitiesRow.module.scss';
import {ActivitiesRowVariant} from './ActivitiesRowVariant';

interface ActivitiesRowProps {
  activity: OperationBasicData;
  variant?: ActivitiesRowVariant;
}

export default function ActivitiesRow({activity, variant = ActivitiesRowVariant.STANDARD}: ActivitiesRowProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const ActivityRow = (
    <div className={`${styles.row} ${variant === ActivitiesRowVariant.BORDERED ? styles['row--bordered'] : ''}`}
         onClick={() => setIsPopupOpen(true)}>
      <div className={styles['short-details']}>
        <div className={styles['short-details__column']}>
          <div className={`${styles.detail} ${styles['detail--transaction-type']}`}>
            <OperationName operation={activity} />
          </div>
          <div className={styles['deposit-partner']}>{activity.productName}</div>
        </div>
        <div className={styles['short-details__column']}>
          <div className={`${styles.detail} ${styles['detail--date']}`}>{activity.registeredOn.format(SHORT_DATE_FORMAT)}</div>
          <div className={`${styles.amount} ${activity.amount < 0 ? styles['amount--negative'] : ''}`}>
            <CurrencyFormat amount={activity.amount} />
          </div>
        </div>
      </div>
      {variant === ActivitiesRowVariant.BORDERED && <img src={ArrowRight} alt="operation-details" />}
    </div>
  );

  // TODO ActivitiesRow is used as a collection mapping result. So it creates N popups. Refactor needed.
  return (
    <>
      {ActivityRow}
      <Popup open={isPopupOpen}>
        <>
          <PopupCloseButton handleClose={closePopup} />
          <ActivityDetails productId={activity.productId} transactionId={activity.id} />
        </>
      </Popup>
    </>
  );
}
