import {CancelTokenSource} from 'axios';
import {PaymentProduct} from '../../../../shared/model/PaymentProduct';
import HttpService from '../../../../shared/services/http.service';
import {GetProductsParams} from '../../../../shared/services/payment.service';
import {HttpServiceHelper} from '../../../../utils/http-service-helper';
import {BrandType, CarrierType, VerifiedMobileNumber} from './model/VerifiedMobileNumber';

const VALIDATE_PHONE_URL = 'common/payments/mobile-no/breakdown';
const PAYMENT_PRODUCTS_URL = 'common/payments/products';

export interface BuyLoadProductsParams extends GetProductsParams {
  carrier?: CarrierType,
  brand?: BrandType
}

export const BuyLoadPaymentService = {
  validateMobile: (mobileNo: string, cancelTokenSource?: CancelTokenSource | null) =>
    HttpService.get<VerifiedMobileNumber>(`${VALIDATE_PHONE_URL}?mobileNo=${encodeURIComponent(mobileNo)}`,
      cancelTokenSource),
  getPaymentProducts: (params: BuyLoadProductsParams, cancelTokenSource?: CancelTokenSource | null) =>
    HttpService.get<PaymentProduct[]>(buildBuyLoadPaymentProductsUrl(params), cancelTokenSource)
};

const buildBuyLoadPaymentProductsUrl = (params: BuyLoadProductsParams) => {
  let url = HttpServiceHelper.constructUrlWithSearchParams(PAYMENT_PRODUCTS_URL, {
      group: params.group,
      category: params.category
    } as GetProductsParams
  );

  if (params.carrier) {
    url += encodeURIComponent(`/${params.carrier}`);
  }
  if (params.brand) {
    url += encodeURIComponent(`/${params.brand}`);
  }
  return url;
};