export enum DictionaryCode {
  COUNTRY = 'COUNTRY',
  TELCO_PROVIDER = 'TELCO_PROVIDER',
  GENDER = 'GENDER',
  CIVIL_STATUS = 'CIVIL_STATUS',
  CUSTOMER_TITLE = 'CUSTOMER_TITLE',
  RELATIVE_TYPE = 'RELATIVE_TYPE',
  ID_DOCUMENT_TYPE = 'ID_DOCUMENT_TYPE',
  ID_DOCUMENT_STATUS = 'ID_DOCUMENT_STATUS',
  CUSTOMER_RISK_LEVEL = 'CUSTOMER_RISK_LEVEL',
  BUSINESS_TYPE = 'BUSINESS_TYPE',
  MOBILE_PLAN = 'MOBILE_PLAN',
  MOBILE_MONTHLY_EXPENSE = 'MOBILE_MONTHLY_EXPENSE',
  NATURE_OF_WORK_BUSINESS = 'NATURE_OF_WORK_BUSINESS',
  NOWB_TYPE = 'NOWB_TYPE',
  NOWB_BUSINESS_TYPE = 'NOWB_BUSINESS_TYPE',
  WORKPLACE_CHARACTERISTICS = 'WORKPLACE_CHARACTERISTICS',
  BANK = 'BANK',
  LOAN_SECURITY = 'LOAN_SECURITY',
  LOAN_CLASS = 'LOAN_CLASS',
  LOAN_ECONOMIC_ACTIVITY = 'LOAN_ECONOMIC_ACTIVITY',
  LOAN_PURPOSE = 'LOAN_PURPOSE',
  LOAN_RATE_TYPE = 'LOAN_RATE_TYPE',
  LOAN_BORROWER_TYPE = 'LOAN_BORROWER_TYPE',
  LOAN_MICROFINANCE_CLASSIFICATION = 'LOAN_MICROFINANCE_CLASSIFICATION',
  LOAN_TRANSACTION_TYPE = 'LOAN_TRANSACTION_TYPE',
  PAWN_ITEM_STONE_SHAPE = 'PAWN_ITEM_STONE_SHAPE',
  AMLA_SUSPICION_REASON = 'AMLA_SUSPICION_REASON',
  DAC_CATEGORY = 'DAC_CATEGORY'
}

export interface Dictionary {
  code: DictionaryCode;
  entries: DictionaryEntry[];
  language: string;
}

export interface DictionaryEntry {
  code: string;
  id: number;
  value: string;
}

export interface GeoLocationsDictionaryEntry {
  code: string;
  id: number;
  level: GeoLocationLevel;
  name: string;
  parentId: number;
}

export enum GeoLocationLevel {
  CITY = "CITY",
  PROVINCE = "PROVINCE",
  REGION = "REGION"
}