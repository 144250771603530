import {CancelTokenSource} from 'axios';
import {BillCategoryCodes, mapStringToCategory} from '../../components/bill-payment/categories/BillCategoryCodes';
import {
  fromPaymentValidationTO,
  PaymentParams,
  PaymentValidation,
  PaymentValidationTO
} from '../../components/bill-payment/model/Payment';
import {HttpServiceHelper} from '../../utils/http-service-helper';
import {CommandOutput} from '../model/command/CommandOutput';
import {fromPaymentProductTO, PaymentProduct, PaymentProductTO} from '../model/PaymentProduct';
import HttpService from './http.service';

const PAYMENT_PRODUCTS_URL = `common/payments/products`;
const CUSTOMER_PAYMENTS_URL = 'customer/payments';

export interface GetProductsParams {
  group: string;
  category?: string;
}

const PaymentService = {
  getCategories: (paymentGroup: string,
                  cancelTokenSource?: CancelTokenSource | null): Promise<(BillCategoryCodes | string)[]> =>
    HttpService.get<(BillCategoryCodes | string)[]>(
      `${PAYMENT_PRODUCTS_URL}/${paymentGroup}/categories`,
      cancelTokenSource
    ).then(codes => codes.map(code => mapStringToCategory(code))),

  getPaymentProducts: (params: GetProductsParams, cancelTokenSource?: CancelTokenSource | null) =>
    HttpService.get<PaymentProduct[]>(
      HttpServiceHelper.constructUrlWithSearchParams(PAYMENT_PRODUCTS_URL, params),
      cancelTokenSource
    ),

  getPaymentProductDetails: (id: number, cancelTokenSource?: CancelTokenSource | null): Promise<PaymentProduct> =>
    HttpService.get<PaymentProductTO>(`${PAYMENT_PRODUCTS_URL}/${id}`, cancelTokenSource)
      .then(paymentProductTO => fromPaymentProductTO(paymentProductTO)),

  validatePayment: (params: PaymentParams, cancelTokenSource?: CancelTokenSource | null): Promise<PaymentValidation> =>
    HttpService.post<PaymentValidationTO>(`${CUSTOMER_PAYMENTS_URL}/validate`, params, cancelTokenSource)
      .then(paymentValidationTO => fromPaymentValidationTO(paymentValidationTO)),

  proceedPayment: (params: PaymentParams, cancelTokenSource?: CancelTokenSource | null): Promise<CommandOutput> =>
    HttpService.post<CommandOutput>(CUSTOMER_PAYMENTS_URL, params, cancelTokenSource)
};

export default PaymentService;
