import React from 'react';
import {Trans} from 'react-i18next';
import CurrencyFormat from '../../shared/currency-format/CurrencyFormat';
import ArrowRight from '../../../assets/images/icons/arrow-right.svg';
import {Withdrawal} from './model/Withdrawal';
import styles from './PendingWithdrawalRow.module.scss';

interface PendingWithdrawalRowProps {
  onClick: () => void;
  withdrawal: Withdrawal
}

export const PendingWithdrawalRow = ({onClick, withdrawal} : PendingWithdrawalRowProps) => {
  return (    
    <div className={styles.row} onClick={onClick}>
      <div className={styles.detail}>
        <div className={styles['detail__label']}>
          <Trans>REFERENCE_NUMBER.FULL_LABEL</Trans>
        </div>
        { withdrawal.referenceNo }
      </div>
      <div className={`${styles.amount} ${withdrawal.amount < 0 ? styles['amount--negative'] : ''}`}>
        <CurrencyFormat amount={withdrawal.amount} />
      </div>
      <img src={ArrowRight} alt="See details" className={styles['arrow-right-icon']}/>
    </div>
  );
};
