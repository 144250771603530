import {useEffect} from 'react';

/**
 * Custom hook to detect if user clicked outside component.
 *
 * Required params:
 * ref - reference to component which can be obtain by hook useRef (e.g. const compRef = useRef(null);) where
 *       ref is used in component (e.g. <Component ref={compRef}/>)
 * togglerRef - reference to component which is the original element that toggle ref
 * callback - function which is called when user clicked outside component on which refs indicates
 */
export function useHandleClickOutsideComponent(ref, togglerRef, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)
        && togglerRef.current && !togglerRef.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, ref, togglerRef]);
}
