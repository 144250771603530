import {MobileStepper, Step, StepLabel, Stepper} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getSteps, verificationStepsHelper} from './verification-steps';
import styles from './VerificationStepper.module.scss';

interface VerificationStepperProps {
  activeStepPath: string;
}

export const VerificationStepper = ({activeStepPath}: VerificationStepperProps) => {
  const {t} = useTranslation();
  const steps = getSteps(t);
  const getActiveStepIndex = () => verificationStepsHelper.getStepIndex(steps, activeStepPath);

  const stepIconProps = {
    classes: {
      root: styles.icon,
      active: styles['icon--active'],
      completed: styles['icon--active'],
      text: styles['icon__text']
    }
  };

  const stepLabelClasses = {
    label: styles.label,
    active: styles['label--active'],
    completed: styles['label--active']
  };

  const mobileStepperClasses = {
    root: styles['mobile-stepper'],
    dots: styles['mobile-stepper__dots'],
    dot: styles['mobile-stepper__dot'],
    dotActive: styles['mobile-stepper__dot--active']
  };

  const DesktopSteps =
    steps.map(step => <Step key={step.label}>
                        <StepLabel classes={stepLabelClasses} StepIconProps={stepIconProps}>
                          {step.label}
                        </StepLabel>
                      </Step>
    );

  return (
    <>
      <MobileStepper
        activeStep={getActiveStepIndex()}
        steps={steps.length}
        position="static"
        nextButton={<></>}
        backButton={<></>}
        variant="dots"
        classes={mobileStepperClasses}
      />
      <Stepper activeStep={getActiveStepIndex()}
               orientation="vertical"
               classes={{
                 root: styles['desktop-stepper']
               }}>
        { DesktopSteps }
      </Stepper>
    </>
  );
}
