import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {useHistory} from 'react-router';
import {useLocation} from 'react-router-dom';
import {PublicSystemProperties} from '../../../shared/model/SystemProperties';
import {HttpError} from '../../../shared/services/http.service';
import State from '../../../store/state';
import encrypt from '../../../utils/credentials-encryption';
import {ErrorHelper} from '../../../utils/error-helper';
import {AlertSnackbar} from '../../shared/alert-snackbar/AlertSnackbar';
import SetPasswordForm from '../../shared/set-password-form/SetPasswordForm';
import {ResetPasswordConfirmationType} from '../model/ResetPassword';
import {ResetPasswordRoutesPaths} from '../reset-password.routes-paths';
import {ResetPasswordService} from '../reset-password.service';

interface ResetPasswordSetPasswordProps {
  systemProperties: PublicSystemProperties;
}

export interface ResetPasswordSetPasswordLocationProps {
  confirmationType: ResetPasswordConfirmationType;
  otpErrorMessage?: string;
  resetToken: string;
}

const ResetPasswordSetPassword = ({systemProperties}: ResetPasswordSetPasswordProps) => {
  const history = useHistory();
  const location = useLocation<ResetPasswordSetPasswordLocationProps>();
  const {confirmationType, otpErrorMessage, resetToken} = location.state;
  const [t] = useTranslation();
  const [showError, setShowError] = useState(!!otpErrorMessage);
  const [errorMessage] = useState(otpErrorMessage);

  const submitNewPassword = (password: string) => {
    const resetParams = {
      confirmationType,
      resetToken,
      secret: encrypt(password, systemProperties.credentialsEncryptionRsaPublicKey)
    };

    return ResetPasswordService.resetPassword(resetParams)
      .then(commandOutput => {
        history.replace(ResetPasswordRoutesPaths.RESET_PASSWORD_OTP, {
          ...commandOutput,
          onSuccess: handleOtpSuccess,
          onError: handleOtpError
        });
      });
  }

  const handleOtpSuccess = () => {
    history.push(ResetPasswordRoutesPaths.RESET_PASSWORD_SUCCESS);
  };

  const handleOtpError = (error: HttpError<any>) => {
    // Reload current view with error
    history.push(location.pathname, {
      otpErrorMessage: ErrorHelper.getErrorMessageTranslation(t, error)
    } as ResetPasswordSetPasswordLocationProps);
  };

  return (
    <>
      <AlertSnackbar handleClose={() => setShowError(false)}
                     open={showError}
                     message={errorMessage} />
      <SetPasswordForm onSubmit={submitNewPassword}/>
    </>
  );
}

const mapStateToProps = (state: State) => ({
  systemProperties: state.public.systemProperties
});

export default connect(mapStateToProps, null)(ResetPasswordSetPassword);
