import React, {useState} from 'react';
import {Trans} from 'react-i18next';
import {connect} from 'react-redux';
import {useHistory} from 'react-router';
import HowToHero from '../../../assets/images/hero-how-to.svg';
import {CardlessWithdrawalParams, WithdrawalService} from '../../../shared/services/withdrawal.service';
import State from '../../../store/state';
import {FeeHelper} from '../../../utils/fee-helper';
import {ListParagraph} from '../list-paragraph/ListParagraph';
import Loader from '../loader/Loader';
import styles from './CardlessWithdarawal.module.scss';
import {CardlessWithdrawalRoutesPaths} from './cardless-withdrawal.routes-paths';
import {CardlessWithdrawalHowTo} from './how-to/CardlessWithdrawalHowTo';

interface CardlessWithdrawalProps {
  isItCustomerWithdrawal: boolean;
  productId: number;
}

const AMOUNTS = [100, 200, 500, 1000, 3000, 5000, 10000, 20000];

const CardlessWithdrawal = ({isItCustomerWithdrawal, productId}: CardlessWithdrawalProps) => {
  const [isAmountLoading, setIsAmountLoading] = useState<boolean>(false);
  const history = useHistory();

  const renderAmountTile = (amount: number) => {
    const amountClicked = () => {
      setIsAmountLoading(true);

      const withdrawalParams: CardlessWithdrawalParams = {amount, productId};

      if (!isItCustomerWithdrawal) {
        history.push(CardlessWithdrawalRoutesPaths.RECIPIENT_DETAILS, {
          withdrawalParams,
          isItCustomerWithdrawal
        });

        return;
      }

      WithdrawalService.calculateCardlessWitdrawalFees(isItCustomerWithdrawal, withdrawalParams).then(fees => {
        history.push(CardlessWithdrawalRoutesPaths.CONFIRM, {
          withdrawalParams,
          fee: FeeHelper.getTotalFee(fees),
          isItCustomerWithdrawal
        });
      });
    };

    return (
      <div className={styles.tile} key={amount} onClick={amountClicked}>
        {amount}
      </div>
    );
  };

  return (
    <>
      <h1 className={styles.title}>
        <Trans>CARDLESS_WITHDRAWAL.HEADER</Trans>
      </h1>
      <div className={styles.container}>
        <div className={styles.column}>
          <div className={styles.amount}>
            <div className={styles['amount__title']}>
              <Trans>CARDLESS_WITHDRAWAL.SELECT_AMOUNT_TITLE</Trans>
            </div>
            <Loader loaded={!isAmountLoading} className={styles.loader}>
              <div className={styles['amount__tiles']}>
                {AMOUNTS.map(amount => renderAmountTile(amount))}
              </div>
            </Loader>
          </div>

          <div className={styles.reminders}>
            <ListParagraph
              titleClassName={styles['reminders__title']}
              title={<Trans>CARDLESS_WITHDRAWAL.REMINDERS.TITLE</Trans>}
              items={[
                <Trans>CARDLESS_WITHDRAWAL.REMINDERS.TRANSACTION_LIMIT</Trans>,
                <Trans>CARDLESS_WITHDRAWAL.REMINDERS.DAY_LIMIT</Trans>
              ]} />
          </div>
        </div>
        <div className={`${styles['how-to']} ${styles.column}`}>
          <div className={styles[`how-to__icon`]}>
            <img src={HowToHero} alt="How to" />
          </div>
          <CardlessWithdrawalHowTo />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: State, ownProps) => ({
  productId: state.userPrimaryAccount?.id,
  ...ownProps
});

export default connect(mapStateToProps, null)(CardlessWithdrawal);
