import dayjs, {Dayjs} from 'dayjs';

let timeoutId;

const executeOnExpire = (expirationTime: Dayjs, onSessionExpired: () => void) => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  const millisToExpire = expirationTime.diff(dayjs(), 'millisecond');

  timeoutId = setTimeout(onSessionExpired, millisToExpire);
};

export default executeOnExpire;
