import {Button} from '@material-ui/core';
import React from 'react';
import {Trans} from 'react-i18next';
import {useHistory} from 'react-router';
import HeroChangePassword from '../../../assets/images/hero-change-password.svg';
import {DashboardRoutesPaths} from '../../../routes/routes-paths/dashboard.routes-paths';
import styles from './PasswordChangeRequiredPopup.module.scss';

export const PasswordChangeRequiredPopupContent = () => {
  const history = useHistory();

  const PopupTrans = ({children}: {children: string}) =>
    <Trans>{`DASHBOARD.REQUIRED_PASSWORD_CHANGE_POPUP.${children}`}</Trans>;

  return (
    <div className={styles.container}>
      <img className={styles.image} src={HeroChangePassword} alt={'verify account'} />
      <div className={styles.title}><PopupTrans>TITLE</PopupTrans></div>
      <div className={styles.description}><PopupTrans>DESCRIPTION</PopupTrans></div>
      <Button onClick={() => history.push(DashboardRoutesPaths.SETTINGS)}>
        <PopupTrans>BUTTON_LABEL</PopupTrans>
      </Button>
    </div>
  );
};
