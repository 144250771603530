import React from 'react';
import PopupWithMemoryRouter from '../../shared/popups/PopupWithMemoryRouter';
import {GoalsRoutesPaths} from '../goals.routes-paths';
import CreateForm from './CreateForm';
import CreateIntroPopupContent from './CreateIntroPopupContent';

interface CreatePopupProps {
  isPopupOpen: boolean;
  onPopupClose: () => void;
  onCreateGoalSuccess: () => void;
}

const CreatePopup = ({isPopupOpen, onPopupClose, onCreateGoalSuccess}: CreatePopupProps) => {

  const routes = [
    {
      path: GoalsRoutesPaths.CREATE_INTRO_PATH,
      key: 'CREATE_INTRO',
      render: () => <CreateIntroPopupContent onCreateGoalAbort={onPopupClose} />
    },
    {
      path: GoalsRoutesPaths.CREATE_FORM_PATH,
      key: 'CREATE_FORM',
      render: () => <CreateForm onCreateSuccess={onCreateGoalSuccess} />
    }
  ];

  return (
    <PopupWithMemoryRouter open={isPopupOpen} handleClose={onPopupClose} routes={routes} />
  );
};

export default CreatePopup;
