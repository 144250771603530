import React from 'react';
import styles from './Progress.module.scss';

interface ProgressProps {
  progress: number;
  biggerVariant?: boolean | undefined;
  className?: string;
}

const Progress = ({progress, biggerVariant = false, className = ''}: ProgressProps) => (
  <div className={`${styles.progress} ${biggerVariant ? styles['progress--bigger'] : ''} ${className}`}>
    <div className={styles.bar}></div>
    <div className={`${styles.bar} ${styles['bar--achieved']}`}
         style={{width: progress + '%'}}></div>
  </div>
);

export default Progress;
