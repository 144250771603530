import {isEmpty} from 'lodash';
import React, {useContext, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import {PaymentProduct} from '../../shared/model/PaymentProduct';
import PaymentService from '../../shared/services/payment.service';
import styles from '../dashboard/services/buy-load/confirm/BuyLoadProductConfirm.module.scss';
import {BillPaymentDetailsLocationProps} from '../shared/bill-payment/details/BillPaymentDetails';
import {BillPaymentDetailsConfirmationOutput} from '../shared/bill-payment/details/dynamic-form/BillPaymentDetailsDynamicForm';
import {BillPaymentPopupContext} from '../shared/bill-payment/popup/BillPaymentPopup';
import CurrencyFormat from '../shared/currency-format/CurrencyFormat';
import OperationConfirmationPopupContent
  from '../shared/operation/operation-confirmation-popup-content/OperationConfirmationPopupContent';
import OperationDetail, {Width} from '../shared/operation/operation-detail/OperationDetail';
import {OtpLocationProps} from '../shared/otp/Otp';
import {TransferSuccessLocationProps} from '../shared/transfer/success/TransferSuccess';
import {BillPaymentRoutesPaths} from './bill-payment.routes-paths';
import {PaymentParams} from './model/Payment';
import {AMOUNT_CODE} from '../shared/dynamic-field/DynamicFormField';

export interface BillPaymentOperationDetailsLocationParams {
  paymentParams: PaymentParams;
  productDetails: PaymentProduct;
  amountWithoutFee: number;
  fee: number;
}

export default function BillPaymentOperationDetails() {
  const cancelToken = useContext(BillPaymentPopupContext).cancelToken;
  const {paymentParams, productDetails, amountWithoutFee, fee} =
    useLocation<BillPaymentOperationDetailsLocationParams>().state;
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onConfirm = () => {
    setIsSubmitting(true);
    PaymentService.proceedPayment(paymentParams, cancelToken).then(commandOutput => {
      history.replace(BillPaymentRoutesPaths.OTP, {
        ...commandOutput,
        onSuccess: redirectToSuccess,
        onError: handleOtpError
      } as OtpLocationProps);
    });
  };

  const redirectToSuccess = (confirmationOutput: BillPaymentDetailsConfirmationOutput) => {
    history.push(BillPaymentRoutesPaths.SUCCESS, {
      ...confirmationOutput,
      sourceProductId: paymentParams.productId
    } as TransferSuccessLocationProps);
  };

  const handleOtpError = error => history.replace(BillPaymentRoutesPaths.PAYMENT_DETAILS, {
    productId: productDetails.id,
    categoryGroup: productDetails.category,
    error
  } as BillPaymentDetailsLocationProps);

  const OperationDetails = (
    <>
      {
        productDetails.dynamicFields
        .filter(field => !isEmpty(paymentParams.dynamicFieldValues[field.code]))
        .filter(field => field.code !== AMOUNT_CODE)
        .map(field => (
          <OperationDetail title={field.name}
                           value={paymentParams.dynamicFieldValues[field.code]}
                           className={styles.detail}
                           width={Width.FULL}
                           key={field.code} />
        ))
      }
      <OperationDetail title={'SHARED.COMMON.AMOUNT'}
                       value={<CurrencyFormat amount={amountWithoutFee} />}
                       className={styles.detail}
                       width={Width.FULL} />
      <OperationDetail title={'SHARED.COMMON.SERVICE_FEE'}
                       value={<CurrencyFormat amount={fee} />}
                       className={styles.detail}
                       width={Width.FULL} />
    </>
  );

  return <OperationConfirmationPopupContent operationDetails={OperationDetails}
                                            onProceed={onConfirm}
                                            isSubmitting={isSubmitting}
                                            cashValue={paymentParams?.amount} />;
}
