import {Avatar} from '@material-ui/core';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';
import {PaymentProduct} from '../../../../shared/model/PaymentProduct';
import {HttpError} from '../../../../shared/services/http.service';
import PaymentService from '../../../../shared/services/payment.service';
import {ErrorHelper} from '../../../../utils/error-helper';
import {cleanUp} from '../../../../utils/handle-abort-controller-clean-up';
import {useBillCategoryTranslationOrCodeHook} from '../../../bill-payment/getBillCategoryTranslationOrCode';
import {AlertSnackbar} from '../../alert-snackbar/AlertSnackbar';
import Loader from '../../loader/Loader';
import styles from './BillPaymentDetails.module.scss';
import BillPaymentDetailsDynamicForm from './dynamic-form/BillPaymentDetailsDynamicForm';

export interface BillPaymentDetailsLocationProps {
  productId: number;
  categoryGroup: string;
  error: HttpError<any>;
}

export default function BillPaymentDetails() {

  const [productDetails, setProductDetails] = useState<PaymentProduct>();
  const locationState = useLocation<BillPaymentDetailsLocationProps>().state;
  const productId = locationState.productId;
  const categoryGroup = useBillCategoryTranslationOrCodeHook(locationState.categoryGroup);
  const [showError, setShowError] = useState(!!locationState?.error);
  const [error, setError] = useState<HttpError<any> | undefined>(locationState?.error);
  const {t} = useTranslation();

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    PaymentService.getPaymentProductDetails(productId)
      .then(product => setProductDetails(product))
      .catch(error => {
        if (ErrorHelper.isAbortOrSessionRestrictionError(error)) {
          return;
        }
        setProductDetails({} as PaymentProduct);
      });

    return cleanUp(cancelTokenSource);
  }, [productId]);

  const getAlertMessage = () => (
    error?.error.errorMessages
      ? ErrorHelper.getMultipleErrorMessages(t, error!)
      : ErrorHelper.getErrorMessageTranslation(t, error!)
  );
  const handleFormError = error => {
    setError(error);
    setShowError(true);
  };

  return (
    <>
      <div className={styles.title}>
        {categoryGroup}
      </div>
      {
        isEmpty(productDetails) ?
          <Loader className={styles.loader} /> :
          <>
            <div className={!!error ? styles['error-bar-sticky-container'] : ''}>
              <AlertSnackbar handleClose={() => setShowError(false)}
                             open={showError}
                             message={getAlertMessage()} />
            </div>
            <div className={styles['product-info']}>
              <Avatar className={styles.avatar}>{productDetails?.name[0]}</Avatar>
              {productDetails?.name}
            </div>
            <div className={styles.container}>
              <BillPaymentDetailsDynamicForm productDetails={productDetails!} onError={handleFormError} />
            </div>
          </>
      }
    </>
  );
};