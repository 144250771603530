import {InputAdornment, TextField} from '@material-ui/core';
import React from 'react';
import {CURRENCY_CODE} from '../../../constants/currency';

export default function AmountTextField(props) {
  return (
    <TextField {...props}
               type="number"
               InputProps={{
                 startAdornment: <InputAdornment position="start">{CURRENCY_CODE}</InputAdornment>
               }}
               inputProps={{
                 step: 0.01,
                 min: 0.01
               }}
    />
  );
}
