import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {AlertSnackbar} from '../../shared/alert-snackbar/AlertSnackbar';
import ConfirmationPopupContent from '../../shared/popups/confirmation-popup-content/ConfirmationPopupContent';

interface GoalDeleteConfirmationPopupContentProps {
  isConfirmInProgress: boolean;
  onConfirm: () => void;
  showError?: boolean;
  errorMessage: string;
  handleErrorClose: () => void;
}

const GoalDeleteConfirmationPopupContent = ({isConfirmInProgress, onConfirm, showError = false, handleErrorClose, errorMessage}: GoalDeleteConfirmationPopupContentProps) => {
  const history = useHistory();
  const {t} = useTranslation();

  return (
    <>
      <AlertSnackbar handleClose={handleErrorClose} open={showError} message={t(errorMessage)} />
      <ConfirmationPopupContent query={t('GOALS.DELETE_CONFIRMATION.QUERY')}
                                onConfirm={onConfirm}
                                onDecline={history.goBack}
                                confirmLabel={t('GOALS.DELETE_CONFIRMATION.CONFIRM')}
                                declineLabel={t('GOALS.DELETE_CONFIRMATION.DECLINE')}
                                isConfirmInProgress={isConfirmInProgress} />
    </>
  );
};

export default GoalDeleteConfirmationPopupContent;
