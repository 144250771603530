import React from 'react';
import Columns from '../shared/columns/Columns';
import MainContainer from '../shared/containers/MainContainer';
import Activities from './activities/Activities'

export default function History() {
  return (
    <MainContainer>
      <Columns
        heading="HISTORY.HEADER"
        mainColumn={<Activities />}
      />
    </MainContainer>
  );
}
