import {Account, AccountDetails} from '../model/Account';
import HttpService from './http.service';

const CUSTOMER_ACCOUNTS_URL = '/customer/accounts';

class UserAccountService {
  getUserAccounts(): Promise<Account[]> {
    return HttpService.get<Account[]>(CUSTOMER_ACCOUNTS_URL);
  }

  getUserAccountDetails(id: number): Promise<AccountDetails> {
    return HttpService.get<AccountDetails>(`${CUSTOMER_ACCOUNTS_URL}/${id}`);
  }
}

export default new UserAccountService();
