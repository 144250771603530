export interface InitiatePasswordResetOutput {
  allowedConfirmationOptions: ResetSecretConfirmationOption[];
  resetToken: string;
  secretComplexityStrategy: string;
}

export interface ResetSecretConfirmationOption {
  confirmationRecipient: string;
  confirmationType: ResetPasswordConfirmationType;
}

export enum ResetPasswordConfirmationType {
  SMS = 'SMS',
  EMAIL = 'EMAIL'
}
