import React from 'react';
import Otp from '../shared/otp/Otp';
import MemoryRouterWithRedirect from '../shared/routes/MemoryRouterWithRedirect';
import ResetPasswordPersonalDataForm from './personal-data/ResetPasswordPersonalDataForm';
import {ResetPasswordRoutesPaths} from './reset-password.routes-paths';
import SingleBoxContainer from '../shared/containers/SingleBoxContainer';
import ResetPasswordSecurityCheck from './security-check/ResetPasswordSecurityCheck';
import ResetPasswordSetPassword from './set-password/ResetPasswordSetPassword';
import {ResetPasswordSuccessMessage} from './success/ResetPasswordSuccessMessage';

export default function ResetPassword() {
  const routes = [
    {
      path: ResetPasswordRoutesPaths.RESET_PASSWORD_PERSONAL_DATA_FORM,
      key: 'RESET_PASSWORD_PERSONAL_DATA_FORM',
      component: ResetPasswordPersonalDataForm
    },
    {
      path: ResetPasswordRoutesPaths.RESET_PASSWORD_SECURITY_CHECK,
      key: 'RESET_PASSWORD_SECURITY_CHECK',
      component: ResetPasswordSecurityCheck
    },
    {
      path: ResetPasswordRoutesPaths.RESET_PASSWORD_SET_PASSWORD,
      key: 'RESET_PASSWORD_SET_PASSWORD',
      component: ResetPasswordSetPassword
    },
    {
      path: ResetPasswordRoutesPaths.RESET_PASSWORD_OTP,
      key: 'RESET_PASSWORD_OTP',
      render: props => <Otp singleBoxStylesVariant {...props} />
    },
    {
      path: ResetPasswordRoutesPaths.RESET_PASSWORD_SUCCESS,
      key: 'RESET_PASSWORD_SUCCESS',
      component: ResetPasswordSuccessMessage
    }
  ];

  return (
    <SingleBoxContainer>
      <MemoryRouterWithRedirect routes={routes} />
    </SingleBoxContainer>
  );
}
