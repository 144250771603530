import React from 'react';
import {OperationBasicData} from '../../../../shared/model/operations/OperationBasicData';
import ActivitiesRow from '../../../shared/activities/row/ActivitiesRow';

interface RecentActivitiesTableProps {
  activities?: OperationBasicData[];
}

export default function RecentActivitiesTable({activities = []}: RecentActivitiesTableProps) {
  return <>
    {
      activities?.length > 0 &&
      activities.map((operationBasicData: OperationBasicData) =>
        <ActivitiesRow key={operationBasicData.id} activity={operationBasicData} />
      )
    }
  </>;
}
