import {CommandOutput} from '../../shared/model/command/CommandOutput';
import HttpService from '../../shared/services/http.service';

// Internal transfer
const INTERNAL_TRANSFER_URL = '/customer/accounts/internal-transfers';
const VALIDATE_INTERNAL_TRANSFER_URL = `${INTERNAL_TRANSFER_URL}/validate`;

export interface InternalTransferParams {
  amount: number;
  recipientAccountNumber: string;
  recipientName: string;
  sourceId: number;
  updateCustomerContacts: boolean;
  contactId?: number;
  recipientMobileNo?: string;
  remarks?: string;
}

export const TransferService = {
  makeInternalTransfer: (internalTransferParams: InternalTransferParams) =>
    postInternalTransferRequest(INTERNAL_TRANSFER_URL, internalTransferParams),
  validateInternalTransfer: (internalTransferParams: InternalTransferParams) =>
    postInternalTransferRequest(VALIDATE_INTERNAL_TRANSFER_URL, internalTransferParams)
};

const postInternalTransferRequest = (requestUrl: string, internalTransferParams: InternalTransferParams) =>
  HttpService.post<CommandOutput>(requestUrl, internalTransferParams);
