import {Button} from '@material-ui/core';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {isNumber} from 'lodash';
import React, {ReactElement, useEffect} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {PartnerMerchant} from '../../../shared/model/operations/partners/PartnerMerchant';
import {PartnerOperationsService} from '../../../shared/services/partner-operations.service';
import {fetchUserPrimaryAccount} from '../../../store/actions';
import {getUserAccount} from '../../../store/selectors';
import {ErrorHelper} from '../../../utils/error-helper';
import {FeeHelper} from '../../../utils/fee-helper';
import {AlertSnackbar} from '../../shared/alert-snackbar/AlertSnackbar';
import AmountTextField from '../../shared/fields/AmountTextField';
import Loader from '../../shared/loader/Loader';
import PartnerIcon from '../../shared/partners/partner-icon/PartnerIcon';
import {WithdrawalReferenceNumberLocationProps} from '../reference-number/WithdrawalReferenceNumber';
import {WithdrawalRoutesPaths} from '../withdrawal.routes-paths';
import styles from './WithdrawalAmount.module.scss';

interface FormFields {
  amount: number | '';
}

const formInitState = {
  amount: ''
} as FormFields;

interface WithdrawalAmountProps {
  partnerMerchant: PartnerMerchant;
}

function WithdrawalAmount({partnerMerchant}: WithdrawalAmountProps) {
  const history = useHistory();
  const {t} = useTranslation();
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const userAccount = useSelector(getUserAccount);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserPrimaryAccount());
  }, [dispatch]);

  const WithdrawAmountForm = ({
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting
                              }: FormikProps<FormFields>): ReactElement => (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles['input-container']}>
        <AmountTextField
          required
          label={<Trans>SHARED.COMMON.AMOUNT</Trans>}
          name="amount"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.amount}
          helperText={t('SHARED.COMMON.FEES_MAY_APPLY')}
        />
      </div>
      <div className={styles.actions}>
        <Button type="submit" className={styles.submit} disabled={isSubmitting}>
          <Loader buttonSpinner loaded={!isSubmitting}>
            <Trans>SHARED.COMMON.SUBMIT</Trans>
          </Loader>
        </Button>
      </div>
    </form>
  );

  function submit(values: FormFields, actions: FormikHelpers<FormFields>) {
    const withdrawalParams = {
      amount: isNumber(values.amount) ? values.amount : 0,
      partner: partnerMerchant.partner,
      productId: userAccount!.id,
      merchantId: partnerMerchant.id
    };

    PartnerOperationsService.initiatePartnerWithdrawal(withdrawalParams)
      .then(commandOutput => {
        // TODO provide valid commandOutput type
        const {id, referenceNumber, validUntil} = commandOutput.output as any;
        calculateFeesAndNavigateToReferenceNumber(id, referenceNumber, validUntil);
      })
      .catch(error => handleError(error));

    const calculateFeesAndNavigateToReferenceNumber = (operationId: number,
                                                       referenceNumber: number,
                                                       validUntil: string) => {
      PartnerOperationsService.calculatePartnerWithdrawalFees(withdrawalParams)
        .then(fees =>
          history.push(WithdrawalRoutesPaths.PARTNER_WITHDRAWAL_REFERENCE_NUMBER, {
              amount: withdrawalParams.amount,
              operationId,
              partnerMerchant,
              referenceNumber,
              validUntil,
              fee: FeeHelper.getTotalFee(fees)
            } as WithdrawalReferenceNumberLocationProps
          ));
    };

    const handleError = error => {
      setErrorMessage(ErrorHelper.getErrorMessageTranslation(t, error));
      setShowError(true);
      actions.setSubmitting(false);
    };
  }

  const PartnerIconWithLabel = partnerMerchant && (
    <div className={styles['icon-wrapper']}>
      <PartnerIcon merchantCode={partnerMerchant.merchantCode} label={partnerMerchant.merchantName} />
      {partnerMerchant.merchantName}
    </div>
  );

  return (
    !userAccount
      ? <Loader />
      : <>
        <div className={styles.title}>
          <Trans>WITHDRAW.HEADER</Trans>
        </div>
        <AlertSnackbar handleClose={() => setShowError(false)} open={showError} message={errorMessage} />
        {PartnerIconWithLabel}
        <Formik
          <FormFields>
          initialValues={formInitState}
          onSubmit={submit}
          children={WithdrawAmountForm}
        />
      </>
  );
}

export default WithdrawalAmount;
