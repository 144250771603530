import React from 'react';

import BayadIcon from '../../../../assets/images/logos/partners/bayad-center.png';
import BayadIcon2x from '../../../../assets/images/logos/partners/bayad-center@2x.png';
import CvmIcon from '../../../../assets/images/logos/partners/cvm.png';
import CvmIcon2x from '../../../../assets/images/logos/partners/cvm@2x.png';
import EbizIcon from '../../../../assets/images/logos/partners/ebiz.png';
import EbizIcon2x from '../../../../assets/images/logos/partners/ebiz@2x.png';
import EcpayIcon from '../../../../assets/images/logos/partners/ecpay.png';
import EcpayIcon2x from '../../../../assets/images/logos/partners/ecpay@2x.png';
import ExpressIcon from '../../../../assets/images/logos/partners/express-pay.png';
import ExpressIcon2x from '../../../../assets/images/logos/partners/express-pay@2x.png';
import GaisanoIcon from '../../../../assets/images/logos/partners/gaisano.png';
import GaisanoIcon2x from '../../../../assets/images/logos/partners/gaisano@2x.png';
import LhuillierIcon from '../../../../assets/images/logos/partners/lhuillier.png';
import LhuillierIcon2x from '../../../../assets/images/logos/partners/lhuillier@2x.png';
import NatccoIcon from '../../../../assets/images/logos/partners/natcco.png';
import NatccoIcon2x from '../../../../assets/images/logos/partners/natcco@2x.png';
import RdIcon from '../../../../assets/images/logos/partners/rd.png';
import RdIcon2x from '../../../../assets/images/logos/partners/rd@2x.png';
import SevenElevenIcon from '../../../../assets/images/logos/partners/seven-eleven.png';
import SevenElevenIcon2x from '../../../../assets/images/logos/partners/seven-eleven@2x.png';
import TambuntingIcon from '../../../../assets/images/logos/partners/tambunting.png';
import TambuntingIcon2x from '../../../../assets/images/logos/partners/tambunting@2x.png';
import UsscIcon from '../../../../assets/images/logos/partners/ussc.png';
import UsscIcon2x from '../../../../assets/images/logos/partners/ussc@2x.png';
import {MerchantCode} from '../../../../shared/model/operations/partners/PartnerMerchant';
import styles from './PartnerIcon.module.scss';

interface PartnerIconProps {
  merchantCode: string;
  label: string;
};

export default function PartnerIcon({merchantCode, label}: PartnerIconProps) {
  const getIcon = (highRes = false): string | undefined => {
    switch (merchantCode) {
      case MerchantCode.BAYAD_CENTER:
        return highRes ? BayadIcon2x : BayadIcon;
      case MerchantCode.CVM_PAWNSHOP:
        return highRes ? CvmIcon2x : CvmIcon;
      case MerchantCode.EBIZ:
        return highRes ? EbizIcon2x : EbizIcon;
      case MerchantCode.ECPAY:
        return highRes ? EcpayIcon2x : EcpayIcon;
      case MerchantCode.ECPAY_SEVEN_ELEVEN:
      case MerchantCode.SEVEN_ELEVEN:
        return highRes ? SevenElevenIcon2x : SevenElevenIcon;
      case MerchantCode.EXPRESSPAY:
        return highRes ? ExpressIcon2x : ExpressIcon;
      case MerchantCode.GAISANO:
        return highRes ? GaisanoIcon2x : GaisanoIcon;
      case MerchantCode.H_LHUILLIER_PAWNSHOP:
        return highRes ? LhuillierIcon2x : LhuillierIcon;
      case MerchantCode.NATCCO:
        return highRes ? NatccoIcon2x : NatccoIcon;
      case MerchantCode.RD_PAWNSHOP:
        return highRes ? RdIcon2x : RdIcon;
      case MerchantCode.TAMBUNTING_PAWNSHOP:
        return highRes ? TambuntingIcon2x : TambuntingIcon;
      case MerchantCode.USSC:
        return highRes ? UsscIcon2x : UsscIcon;
      default:
        return undefined;
    }
  };

  const lowResIcon = getIcon();
  const highResIcon = `${getIcon(true)} 2x`;

  return (
    <>
      {
        lowResIcon && highResIcon && <img src={lowResIcon} srcSet={highResIcon} alt={label} className={styles.icon} />
      }
    </>
  );
}
