import {Button} from '@material-ui/core';
import React, {useContext} from 'react';
import {Trans} from 'react-i18next';
import {MainRouterContext} from '../../App';
import Hero404 from '../../assets/images/hero-404.svg';
import DiskarTechLogo from '../../assets/images/logos/diskartech-logo.svg';
import {RoutesPaths} from '../../routes/routes-paths/routes-paths';
import styles from './PageNotFound.module.scss';

export const PageNotFound = () => {

  const mainRouter = useContext(MainRouterContext);

  return <div className={styles.container}>
    <img src={DiskarTechLogo} alt="DiskarTech" className={styles.logo} />
    <div className={styles.box}>
      <img className={styles.hero} src={Hero404} alt={'not found'} />
      <h1 className={styles.heading}>
        <Trans>NOT_FOUND.HEADING</Trans>
      </h1>
      <p className={styles.description}>
        <Trans>NOT_FOUND.DESCRIPTION</Trans>
      </p>
      <Button variant="outlined"
              onClick={() => mainRouter.globalHistory.replace(RoutesPaths.DASHBOARD)}
              className={styles.button}>
        <Trans>SHARED.BACK_LINKS.BACK_HOME</Trans>
      </Button>
    </div>
  </div>;
};
