import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {RoutesPaths} from '../../routes/routes-paths/routes-paths';
import {ErrorHelper} from '../../utils/error-helper';
import {cleanUp} from '../../utils/handle-abort-controller-clean-up';
import Columns from '../shared/columns/Columns';
import MainContainer from '../shared/containers/MainContainer';
import Loader from '../shared/loader/Loader';
import Popup from '../shared/popups/Popup';
import BuyPopup from './buy/BuyPopup';
import {
  emptyTelemedicineOperations,
  TelemedicineOperation,
  TelemedicineOperations
} from './model/TelemedicineOperation';
import {TelemedicineProduct} from './model/TelemedicineProduct';
import OperationOverviewPopupContent from './operation-overview/OperationOverviewPopupContent';
import TelemedicineRow from './telemedicine-row/TelmedicineRow';
import styles from './Telemedicine.module.scss';
import {TelemedicineService} from './telemedicine.service';

type BuyPopupState = {
  isOpen: boolean,
  product: TelemedicineProduct
}

type OperationOverviewPopupState = {
  isOpen: boolean,
  operation: TelemedicineOperation
}

export default function Telemedicine() {

  const [operations, setOperations] = useState<TelemedicineOperations>(emptyTelemedicineOperations);
  const [products, setProducts] = useState<TelemedicineProduct[]>([]);
  const [buyPopupState, setBuyPopupState] = useState<BuyPopupState>();
  const [operationPopupState, setOperationPopupState] = useState<OperationOverviewPopupState>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    (async () => {
      const operations = await TelemedicineService.getTelemedicineOperations(cancelTokenSource);
      const products = await TelemedicineService.getTelemedicineProducts(cancelTokenSource);

      setOperations(operations);
      setProducts(products);

      setIsLoaded(true);
    })().catch(error => {
      if (ErrorHelper.isAbortOrSessionRestrictionError(error)) {
        return;
      }
      setOperations(emptyTelemedicineOperations);
      setProducts([] as TelemedicineProduct[]);
      setIsLoaded(true);
    });

    return cleanUp(cancelTokenSource);
  }, []);

  const handleClick = (id: number) => {
    const productOperations = operations.result.filter(operation => operation.telemedicineProductId === id);

    if (productOperations.length > 0) {
      // Getting last operations from structure as last element is the newest one
      const operation = productOperations[productOperations.length - 1];

      setOperationPopupState({isOpen: true, operation});

      return;
    }

    const product = products.find(product => product.id === id);

    if (product) {
      setBuyPopupState({isOpen: true, product});
    }
  };

  const closeOperationPopupAndNavigateHome = () => {
    setOperationPopupState({
      ...operationPopupState!,
      isOpen: false
    });

    history.push(RoutesPaths.DASHBOARD);
  };

  const closeBuyPopupAndNavigateHome = () => {
    buyPopupState && setBuyPopupState({...buyPopupState, isOpen: false});

    history.push(RoutesPaths.DASHBOARD);
  };

  const EmptyState = <div className={styles['empty-state']}><Trans>TELEMEDICINE.EMPTY_TEXT</Trans></div>;

  const TelemedicineRows = products.length === 0 ? EmptyState
    : products.map((product, index) =>
      <TelemedicineRow telemedicine={product} onClick={handleClick} key={index} />);

  return (
    <>
      <MainContainer>
        <Columns
          heading="TELEMEDICINE.HEADER"
          mainColumn={
            !isLoaded ? <Loader /> : (
              <>
                <div className={styles['intro-text']}>
                  <Trans>TELEMEDICINE.INTRO_TEXT</Trans>
                </div>
                {TelemedicineRows}
              </>
            )
          }
        />
      </MainContainer>
      {
        buyPopupState && <BuyPopup product={buyPopupState.product}
                                   isPopupOpen={buyPopupState.isOpen}
                                   onPopupClose={() => closeBuyPopupAndNavigateHome()} />
      }
      {
        operationPopupState && (
          <Popup open={operationPopupState.isOpen}>
            <OperationOverviewPopupContent operation={operationPopupState.operation}
                                           onBackToHome={() => closeOperationPopupAndNavigateHome()} />
          </Popup>
        )
      }
    </>
  );
}
