import {Button} from '@material-ui/core';
import React from 'react';
import {Trans} from 'react-i18next';
import HeroMed from '../../../assets/images/hero-med.svg';
import ExternalLink from '../../shared/external-link/ExternalLink';
import {ListParagraph} from '../../shared/list-paragraph/ListParagraph';
import {TelemedicineOperation} from '../model/TelemedicineOperation';
import styles from './OperationOverviewPopupContent.module.scss';

const I_DOC_URL = 'https://idoc.hugsph.com/secure/registration';

interface OperationOverviewPopupContentProps {
  operation: TelemedicineOperation,
  onBackToHome: () => void
}

const OperationOverviewPopupContent = ({operation, onBackToHome}: OperationOverviewPopupContentProps) => (
  <div className={styles['main-container']}>
    <img src={HeroMed} alt="Hero med" className={styles['hero-image']} />
    <div className={styles['success-message']}>
      <Trans tOptions={{productName: operation.telemedicineProductName}}>
        TELEMEDICINE.OPERATION_OVERVIEW.SUCCESS_MESSAGE
      </Trans>
    </div>
    <div className={styles.buttons}>
      <ExternalLink href={I_DOC_URL}>
        <Button className={styles['buttons__product-provider-link']}>
          <Trans>TELEMEDICINE.OPERATION_OVERVIEW.GO_TO_PRODUCT_WEBSITE_BUTTON_LABEL</Trans>
        </Button>
      </ExternalLink>
      <div className={styles['buttons__back-link']} onClick={onBackToHome}>
        <Trans>TELEMEDICINE.OPERATION_OVERVIEW.GO_TO_HOME_BUTTON_LABEL</Trans>
      </div>
    </div>
    <div className={styles['instructions-container']}>
      <ListParagraph title={<Trans>TELEMEDICINE.OPERATION_OVERVIEW.INSTRUCTIONS.TITLE</Trans>}
                     titleClassName={styles['instructions-title']}
                     items={[
                       <Trans>TELEMEDICINE.OPERATION_OVERVIEW.INSTRUCTIONS.POINT_1</Trans>,
                       <Trans>TELEMEDICINE.OPERATION_OVERVIEW.INSTRUCTIONS.POINT_2</Trans>,
                       <Trans>TELEMEDICINE.OPERATION_OVERVIEW.INSTRUCTIONS.POINT_3</Trans>
                     ]} />
      <div className={styles['plan-code']}>
        <div className={styles['plan-code__title']}>
          <Trans>TELEMEDICINE.OPERATION_OVERVIEW.CODE_TITLE</Trans>
        </div>
        <div className={styles['plan-code__code']}>{operation.referenceNo}</div>
      </div>
    </div>
  </div>
);

export default OperationOverviewPopupContent;
