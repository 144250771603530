import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from '@material-ui/core';
import dayjs, {Dayjs} from 'dayjs';
import {Formik, FormikProps} from 'formik';
import {isNil} from 'lodash';
import React, {ReactElement, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import CameraWhiteIcon from '../../../assets/images/icons/camera-white.svg';
import CameraIcon from '../../../assets/images/icons/camera.svg';
import {API_DATE_FORMAT, FULL_PERIOD_DATE_FORMAT} from '../../../constants/date';
import {CommandOutputData} from '../../../shared/model/command/CommandOutput';
import UserData from '../../../shared/model/UserData';
import {ErrorBody, HttpError} from '../../../shared/services/http.service';
import State from '../../../store/state';
import {blobToBase64String} from '../../../utils/file-utils';
import {StringHelper} from '../../../utils/string-helper';
import {checkIfCustomer, checkIfProspect} from '../../../utils/user-group-checks';
import {AlertSnackbar} from '../../shared/alert-snackbar/AlertSnackbar';
import FileTypes from '../../shared/file-upload/FileTypes';
import FileUpload, {FileUploadError} from '../../shared/file-upload/FileUpload';
import Loader from '../../shared/loader/Loader';
import AmountPerMonth from '../amount-per-month/AmountPerMonth';
import {GoalsService} from '../goals.service';
import styles from './CreateForm.module.scss';

interface CreateFormStateProps {
  userData: UserData;
}

interface CreateFormComponentProps {
  onCreateSuccess: () => void;
}

type CreateFormProps = CreateFormStateProps & CreateFormComponentProps;

interface FormFields {
  name: string;
  amount: number | '';
  durationInMonths: number | '';
  startDate: Dayjs | '';
}

const formInitState = {name: '', amount: '', durationInMonths: '', startDate: ''} as FormFields;

type Error = {
  isShown: boolean,
  message?: string
}

const CreateForm = ({userData, onCreateSuccess}: CreateFormProps) => {

  const MAX_MONTHS_DURATION = 60;
  const MAX_POSSIBLE_MONTHS_TO_START = 3;

  const [amountValue, setAmount] = useState<number>(0);
  const [durationInMonthsValue, setDurationInMonths] = useState<number>(0);
  const [amountPerMonth, setAmountPerMonth] = useState<number>(0);
  const [imageFile, setImageFile] = useState<File>();
  const [image, setImage] = useState<string | undefined>();
  const [t] = useTranslation();
  const [showError, setShowError] = useState<Error>({isShown: false});
  const isCustomer = checkIfCustomer(userData);
  const isProspect = checkIfProspect(userData);

  const monthsDurationOptions = (): number[] => {
    const options: number[] = [];
    for (let i = 1; i <= MAX_MONTHS_DURATION; i++) {
      options.push(i);
    }
    return options;
  };

  const monthStartOptions = (): Dayjs[] => {
    const options: Dayjs[] = [];
    const currentMonthDate: Dayjs = dayjs().startOf('month');

    for (let i = 0; i < MAX_POSSIBLE_MONTHS_TO_START; i++) {
      const calculatedDate = currentMonthDate.add(i, 'month');
      const monthDate: Dayjs = calculatedDate.add(calculatedDate.utcOffset(), 'minute');

      options.push(monthDate);
    }

    return options;
  };

  const calculateAmountPerMonth = (amount: number, durationInMonths: number) => {
    const calculatedAmountPerMonth = Math.round((amount / durationInMonths) * 100) / 100;
    setAmountPerMonth(isNaN(calculatedAmountPerMonth) || !Number.isFinite(calculatedAmountPerMonth)
      ? 0
      : calculatedAmountPerMonth);
  };

  const onAmountChange = (changeEvent) => {
    const value = changeEvent.target.value;
    setAmount(value);
    calculateAmountPerMonth(value, durationInMonthsValue);
  };

  const onDurationChange = (changeEvent) => {
    const value = changeEvent.target.value;
    setDurationInMonths(value);
    calculateAmountPerMonth(amountValue, value);
  };

  const onFileAttached = async (file: File) => {
    const imageString = await blobToBase64String(file);

    setImage(imageString);
    setImageFile(file);
  };

  const onFileAttachedFailed = async (error: FileUploadError) => {
    setShowError({isShown: true, message: t(error.messageCode, error.messageParams)});
  };

  const onSubmit = async (values) => {
    if (isCustomer) {
      await submit(GoalsService.uploadCustomerGoalImage, GoalsService.createGoalForCustomer, values);
    } else if (isProspect) {
      await submit(GoalsService.uploadProspectGoalImage, GoalsService.createGoalForProspect, values);
    }

    onCreateSuccess();
  };

  const submit = async (uploadImageFunction: (File) => Promise<number | null | HttpError<ErrorBody>>,
                        submitFunction: (GoalPayload) => Promise<CommandOutputData>,
                        formValues: FormFields) => {

    const response = isNil(imageFile) ? undefined : await uploadImageFunction(imageFile);

    if (response instanceof HttpError) {
      setShowError({isShown: true, message: response.error.errorMessage});
      return Promise.reject();
    }

    await submitFunction({
      ...formValues,
      imageId: response
    });
  };

  const goalForm = ({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }: FormikProps<FormFields>): ReactElement => (
    <form onSubmit={handleSubmit}>
      <div className={styles['form-fields']}>
        <div className={styles.inputs}>
          <TextField
            required
            label={<Trans>GOALS.CREATE.NAME_LABEL</Trans>}
            type="text"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            className={`${styles['input']} ${styles['input--name']}`}
          />
          {/* TODO - Add validation or mask to input */}
          <TextField
            required
            label={<Trans>GOALS.CREATE.AMOUNT_LABEL</Trans>}
            type="number"
            name="amount"
            onChange={(event) => {
              onAmountChange(event);
              handleChange(event);
            }}
            onBlur={handleBlur}
            value={values.amount}
            helperText={<Trans>GOALS.CREATE.AMOUNT_INFO</Trans>}
            className={`${styles['input']} ${styles['input--amount']}`}
            inputProps={{min: 0, max: 50000}}
          />
          <FormControl className={`${styles['input']} ${styles['input--month-duration']}`}>
            <InputLabel id="month-duration-label">
              <Trans>GOALS.CREATE.DURATION_LABEL</Trans>
            </InputLabel>
            <Select
              required
              labelId="month-duration-label"
              type="number"
              name="durationInMonths"
              onChange={(event) => {
                onDurationChange(event);
                handleChange(event);
              }}
              displayEmpty
              onBlur={handleBlur}
              value={values.durationInMonths}>
              {
                monthsDurationOptions().map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <FormControl className={`${styles['input']} ${styles['input--start-date']}`} variant="outlined">
            <InputLabel id="start-date-label">
              <Trans>GOALS.CREATE.START_DATE_LABEL</Trans>
            </InputLabel>
            <Select
              required
              labelId="start-date-label"
              type="number"
              name="startDate"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.startDate}>
              {
                monthStartOptions().map((value, index) => (
                  <MenuItem value={value.format(API_DATE_FORMAT)} key={index}>
                    {value.format(FULL_PERIOD_DATE_FORMAT)}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </div>
        <div className={`${styles['file-upload']} ${isNil(image) ? styles['file-upload--empty'] : ''}`}>
          {
            isNil(image)
              ? <img className={styles['file-upload__icon']} src={CameraIcon} alt={''} />
              : <img className={`${styles['file-upload__icon']} ${styles['file-upload__icon--filled']}`}
                     src={CameraWhiteIcon} alt={''} />
          }
          <img className={styles['file-upload__image']} src={image} alt={''} />
          <div className={`${styles['file-upload__label']} ${isNil(image)
            ? ''
            : styles['file-upload__label--hidden-on-desktop']}`}>
            <Trans>GOALS.CREATE.IMAGE_UPLOAD_LABEL</Trans>
          </div>
          <FileUpload onFileAttached={onFileAttached}
                      onFileAttachFailed={onFileAttachedFailed}
                      acceptedFileTypes={[FileTypes.IMAGE_JPG, FileTypes.IMAGE_PNG]} />
        </div>
        <div className={styles['amount-per-month-container']}>
          <AmountPerMonth amountPerMonth={amountPerMonth} />
        </div>
      </div>
      <div className={styles.footer}>
        <Button type="submit" className={styles.submit} disabled={isSubmitting}>
          <Loader buttonSpinner loaded={!isSubmitting}>
            <Trans>SHARED.COMMON.CONFIRM</Trans>
          </Loader>
        </Button>
      </div>
    </form>
  );

  return (
    <div className={styles['main-container']}>
      <div className={styles.heading}>
        <Trans tOptions={{userName: StringHelper.capitalizeFirstLetter(userData!.shortName)}}>
          GOALS.CREATE.HEADER
        </Trans>
      </div>
      <AlertSnackbar handleClose={() => setShowError({isShown: false})}
                     open={showError.isShown}
                     message={t(showError.message ?? '')} />
      <Formik
        <FormFields>
        initialValues={formInitState}
        onSubmit={onSubmit}
        children={goalForm}
      />
    </div>
  );
};

const mapStateToProps = (state: State, ownProps: CreateFormComponentProps): CreateFormStateProps => ({
  ...ownProps,
  userData: state.sessionData ?? ({} as UserData)
});

export default connect<CreateFormStateProps, {}, CreateFormComponentProps, State>(mapStateToProps)(CreateForm);
