import {Button} from '@material-ui/core';
import React, {useContext} from 'react';
import {Trans} from 'react-i18next';
import {useLocation} from 'react-router';
import {MainRouterContext} from '../../../../App';
import {RoutesPaths} from '../../../../routes/routes-paths/routes-paths';
import {useCustomerOperationDetailsHook} from '../../../../utils/hooks/getCustomerOperationDetailsHook';
import Loader from '../../loader/Loader';
import OperationInfo from '../../operation/operation-info/OperationInfo';
import styles from './TransferSuccess.module.scss';

export interface TransferSuccessLocationProps {
  sourceProductId: number;
  operationId: number;
}

export default function TransferSuccess() {

  const location = useLocation<TransferSuccessLocationProps>();
  const operationDetails = useCustomerOperationDetailsHook(location.state.sourceProductId, location.state.operationId);
  const mainRouter = useContext(MainRouterContext);

  return (
    <div className={styles.container}>
      <Loader loaded={!!operationDetails}>
        <>
          <OperationInfo operationDetails={operationDetails!} />
          <div className={styles.actions}>
            <Button className={styles['back-link-button']}
                    onClick={() => mainRouter.globalHistory.push(RoutesPaths.DASHBOARD)}>
              <Trans>SHARED.BACK_LINKS.BACK_HOME</Trans>
            </Button>
          </div>
        </>
      </Loader>
    </div>
  );
}
