import {Button} from '@material-ui/core';
import React, {useContext} from 'react';
import {Trans} from 'react-i18next';
import {MainRouterContext} from '../../../App';
import HeroVerified from '../../../assets/images/hero-verified.svg';
import {DashboardRoutesPaths} from '../../../routes/routes-paths/dashboard.routes-paths';
import styles from './OngoingVerificationSuccessPopupContent.module.scss';

interface OngoingVerificationSuccessPopupContentProps {
  onBackButtonClick: () => void;
}

const OngoingVerificationSuccessPopupContent = ({onBackButtonClick}: OngoingVerificationSuccessPopupContentProps) => {
  const mainRouterContext = useContext(MainRouterContext);

  return (
    <div className={styles.container}>
      <img className={styles.image} src={HeroVerified} alt="hero verified" />
      <div className={styles.title}>
        <Trans>DASHBOARD.ONGOING_VERIFICATION_POPUP.SUCCESS.TITLE</Trans>
      </div>
      <div className={styles.description}>
        <Trans>DASHBOARD.ONGOING_VERIFICATION_POPUP.SUCCESS.DESCRIPTION</Trans>
      </div>
      <Button onClick={() => mainRouterContext.globalHistory.push(DashboardRoutesPaths.DEPOSIT)}>
        <Trans>DASHBOARD.ONGOING_VERIFICATION_POPUP.SUCCESS.DEPOSIT_BUTTON_LABEL</Trans>
      </Button>
      <div className={styles.link} onClick={onBackButtonClick}>
        <Trans>SHARED.BACK_LINKS.BACK_HOME</Trans>
      </div>
    </div>
  );
};

export default OngoingVerificationSuccessPopupContent;
