import {AccountDetails} from '../shared/model/Account';
import {AuthenticatedSystemProperties, PublicSystemProperties} from '../shared/model/SystemProperties';
import UserData, {UserGroup} from '../shared/model/UserData';
import AuthenticationService from '../shared/services/authentication.service';
import systemPropertiesService from '../shared/services/system-properties.service';
import userAccountService from '../shared/services/user-account.service';
import {VerificationService} from '../shared/services/verification.service';
import {checkIfProspect} from '../utils/user-group-checks';
import State from './state';
import {Actions, ActionTypes} from './types';

// Actions
function loggedIn(payload: UserData): ActionTypes {
  return {
    type: Actions.LOGGED_IN,
    payload
  };
}

function loggedOut(logoutReasonCode?: string): ActionTypes {
  return {
    type: Actions.LOGGED_OUT,
    payload: {
      reasonCode: logoutReasonCode
    }
  };
}

function userPrimaryAccountStartedLoading(): ActionTypes {
  return {
    type: Actions.USER_PRIMARY_ACCOUNT_STARTED_LOADING
  };
}

function userPrimaryAccountFetched(payload?: AccountDetails): ActionTypes {
  return {
    type: Actions.USER_PRIMARY_ACCOUNT_FETCHED,
    payload
  };
}

function systemPropertiesFetched(payload: PublicSystemProperties): ActionTypes {
  return {
    type: Actions.SYSTEM_PROPERTIES_FETCHED,
    payload
  };
}

function authenticatedSystemPropertiesStartedLoading(): ActionTypes {
  return {
    type: Actions.AUTHENTICATED_SYSTEM_PROPERTIES_STARTED_LOADING
  };
}

function authenticatedSystemPropertiesFetched(payload: AuthenticatedSystemProperties): ActionTypes {
  return {
    type: Actions.AUTHENTICATED_SYSTEM_PROPERTIES_FETCHED,
    payload
  };
}

export function applicationSealChecked(isSealed: boolean): ActionTypes {
  return {
    type: Actions.APPLICATION_SEAL_CHECKED,
    isSealed
  };
}

export function applicationUnsealed(): ActionTypes {
  return {
    type: Actions.APPLICATION_UNSEALED
  };
}

export function passwordChangeRequired(): ActionTypes {
  return {
    type: Actions.PASSWORD_CHANGE_REQUIRED
  };
}

// Thunks
export function checkSession() {
  return dispatch => AuthenticationService.checkSession()
    .then(response => dispatch(loggedIn(response)))
    .catch(() => dispatch(loggedOut()));
}

export function login(email: string, password: string) {
  return dispatch => AuthenticationService.login(email, password)
    .then(response => dispatch(loggedIn(response)));
}

export function checkIfProspectApplicationIsSealed() {
  return (dispatch, getState: () => State) => {
    const userData = getState().sessionData;

    if (!userData || !checkIfProspect(userData)) {
      return;
    }

    return VerificationService.readProspectApplication()
      .then(response => dispatch(applicationSealChecked(!!response.application.sealed)))
      .catch(() => dispatch(applicationSealChecked(false)));
  };
}

export function upgradeProspectSession() {
  return dispatch => AuthenticationService.upgradeProspectSession()
    .then(response => dispatch(loggedIn(response)));
}

export function logout(logoutReasonCode?: string) {
  return dispatch => AuthenticationService.logout().then(() => dispatch(loggedOut(logoutReasonCode)));
}

/**
 * Thunk for fetching primary account for logged user.
 * Primary account is obtained by getting first element from account list.
 * It may be changed in the future.
 */
export function fetchUserPrimaryAccount(forceUpdate = false) {
  return (dispatch, getState: () => State): Promise<ActionTypes | void> => {
    if (getState().isUserAccountLoading
      || getState().sessionData!.userGroup === UserGroup.PROSPECT
      || (getState().userPrimaryAccount && !forceUpdate)
    ) {
      return Promise.resolve();
    }

    dispatch(userPrimaryAccountStartedLoading());

    return userAccountService.getUserAccountDetails(getState().sessionData!.id)
      .then(accountDetails => dispatch(userPrimaryAccountFetched(accountDetails)))
      .catch(() => userPrimaryAccountFetched(undefined));
  };
}

export function fetchSystemProperties() {
  return dispatch => systemPropertiesService.getPublicSystemSetup()
    .then(response => dispatch(systemPropertiesFetched(response)));
}

export function fetchAuthenticatedSystemProperties() {
  return (dispatch, getState: () => State): Promise<ActionTypes | void> => {
    if (getState().isAuthenticatedSystemPropertiesLoading || getState().authenticatedSystemProperties) {
      return Promise.resolve();
    }

    dispatch(authenticatedSystemPropertiesStartedLoading());

    return systemPropertiesService.getAuthenticatedSystemSetup()
      .then(response => dispatch(authenticatedSystemPropertiesFetched(response)));
  };
}

export function initAnonymousSession() {
  return (dispatch): Promise<ActionTypes | void> => AuthenticationService.initAnonymousSession()
    .then(response => dispatch(loggedIn(response)));
}
