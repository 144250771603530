import React from 'react';
import styles from './SingleBoxContainer.module.scss';
import MainContainer from './MainContainer';

interface SingleBoxContainerProps {
  boxClassName?: string;
  children: React.ReactElement;
}

const SingleBoxContainer = ({boxClassName, children}: SingleBoxContainerProps) => (
  <MainContainer>
    <div className={`${styles.box} ${boxClassName ? boxClassName : ''}`}>
      {children}
    </div>
  </MainContainer>
);

export default SingleBoxContainer;
