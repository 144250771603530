import {MenuItem, TextField} from '@material-ui/core';
import React from 'react';
import {DynamicField, DynamicFieldOption, DynamicFieldType} from '../../../shared/model/DynamicField';
import AmountTextField from '../fields/AmountTextField';
import PhoneTextField from '../fields/PhoneTextField';
import styles from './DynamicFormField.module.scss';

export const AMOUNT_CODE = 'amount';

interface DynamicFormFieldProps {
  value: any;
  dynamicField: DynamicField;
  handleChange: any;
  handleBlur: any;
}

interface CommonFieldProps {
  className: string,
  required: boolean,
  label: string,
  name: string,
  onChange: () => void,
  onBlur: () => void,
  value: any
}

export default function DynamicFormField({value, dynamicField, handleChange, handleBlur}: DynamicFormFieldProps) {
  const commonProps: CommonFieldProps = {
    className: styles.field,
    required: dynamicField.required,
    label: dynamicField.name,
    name: dynamicField.code,
    onChange: handleChange,
    onBlur: handleBlur,
    value: value
  };

  if (dynamicField.type === DynamicFieldType.NUMERIC && dynamicField.code === AMOUNT_CODE) {
    return <AmountTextField {...commonProps} />;
  }

  switch (dynamicField.type) {
    case DynamicFieldType.LOCAL_MOBILE_NO:
    case DynamicFieldType.INTERNATIONAL_MOBILE_NO:
      return <PhoneTextField {...commonProps} />;
    case DynamicFieldType.NUMERIC:
    case DynamicFieldType.YEAR:
    case DynamicFieldType.MONTH:
      return <NumberField commonProps={commonProps} type={dynamicField.type} />;
    case DynamicFieldType.DATE:
      return <DateField {...commonProps} />;
    case DynamicFieldType.DICTIONARY:
      return <SelectField commonProps={commonProps} options={dynamicField.options!} />;
    case DynamicFieldType.ALPHANUMERIC:
    default:
      return <TextField
        {...commonProps}
        type="text"
      />;
  }
};

// TODO change to KeyboardDatePicker
const DateField = (props: CommonFieldProps) =>
  <TextField
    {...props}
    type="date"
    InputLabelProps={{
      shrink: true
    }}
  />;

interface NumberFieldProps {
  commonProps: CommonFieldProps,
  type: DynamicFieldType
}

const NumberField = ({commonProps, type}: NumberFieldProps) => {
  const MONTHS_MAX = 12;
  const YEAR_MAX = 2100;
  const YEAR_MIN = 1900;

  interface NumberFieldProps {
    min: number,
    max?: number
  }

  let inputProps = {
    min: 0
  } as NumberFieldProps;

  if (type === DynamicFieldType.MONTH) {
    inputProps = {
      ...inputProps,
      max: MONTHS_MAX
    };
  }

  if (type === DynamicFieldType.YEAR) {
    inputProps = {
      ...inputProps,
      min: YEAR_MIN,
      max: YEAR_MAX
    };
  }

  return <TextField
    {...commonProps}
    type="number"
    inputProps={{
      ...inputProps
    }}
  />;
};

interface SelectFieldProps {
  commonProps: CommonFieldProps,
  options: DynamicFieldOption[]
}

// TODO Needs to be correctly styled
const SelectField = ({commonProps, options}: SelectFieldProps) =>
  <TextField
    {...commonProps}
    select>
    {options.map(option => (
      <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
    ))}
  </TextField>;


