import {Button} from '@material-ui/core';
import React, {useEffect} from 'react';
import {Trans} from 'react-i18next';
import {connect} from 'react-redux';
import {useHistory} from 'react-router';
import HeroProgress from '../../../assets/images/hero-progress.svg';
import UserData from '../../../shared/model/UserData';
import {logout, upgradeProspectSession} from '../../../store/actions';
import State from '../../../store/state';
import {checkIfProspect} from '../../../utils/user-group-checks';
import {
  ApplicationConditionStatus,
  ProspectApplicationCondition
} from '../../../shared/model/verification/ProspectAppliactionCondition';
import {VerificationService} from '../../../shared/services/verification.service';
import {OngoingVerificationPopupRoutes} from './ongoing-verification-popup.routes-paths';
import styles from './OngoingVerificationPopupContent.module.scss';

interface OngoingVerificationPopupContentComponentProps {
  onPopupClose: () => void;
}

interface OngoingVerificationPopupContentStateProps {
  userData: UserData;
}

interface OngoingVerificationPopupContentDispatchProps {
  upgradeSession: () => Promise<any>;
  logout: () => Promise<any>;
}

type OngoingVerificationPopupContentProps =
  OngoingVerificationPopupContentComponentProps
  & OngoingVerificationPopupContentStateProps
  & OngoingVerificationPopupContentDispatchProps;

const ONGOING_VERIFICATION_TIME_INTERVAL = 3000;

const OngoingVerificationPopupContent = ({onPopupClose, userData, upgradeSession, logout}: OngoingVerificationPopupContentProps) => {
  const isProspect = checkIfProspect(userData ?? {} as UserData);
  const applicationId = userData?.applicationId;
  const history = useHistory();

  useEffect(() => {
    if (!isProspect || !applicationId) {
      return;
    }

    const intervalId = setInterval(async () => {
      const conditions = await VerificationService.validateProspectApplication(applicationId)
        .catch(() => Promise.resolve([]));

      if (isAnyConditionFailed(conditions)) {
        clearInterval(intervalId);
        history.push(
          OngoingVerificationPopupRoutes.ONGOING_VERIFICATION_FAILURE,
          {
            applicationId,
            failureMessages: mapConditionErrors(conditions)
          }
        );
      }

      if (areAllConditionsSuccessful(conditions)) {
        clearInterval(intervalId);

        const commandOutput = await VerificationService.processProspectApplication(applicationId)
          .catch(() => {
            history.push(
              OngoingVerificationPopupRoutes.ONGOING_VERIFICATION_FAILURE,
              {
                applicationId
              }
            );
          });

        commandOutput && await upgradeSession()
          .then(() => {
            history.push(OngoingVerificationPopupRoutes.ONGOING_VERIFICATION_SUCCESS);
          }).catch(() => {
            // If upgrade token failed - force login by invalidate session
            logout();
          });
      }
    }, ONGOING_VERIFICATION_TIME_INTERVAL);

    return () => clearInterval(intervalId);
  }, [applicationId, history, isProspect, logout, upgradeSession]);

  const mapConditionErrors = (conditions: ProspectApplicationCondition[]) =>
    conditions.filter(condition => condition.status === ApplicationConditionStatus.FAILURE)
      .map(condition => condition.errorMessages ?? [])
      .reduce((acc, errorMessages) => acc.concat(errorMessages), []);

  const isAnyConditionFailed = (conditions: ProspectApplicationCondition[]) =>
    conditions.filter(condition => condition.status === ApplicationConditionStatus.FAILURE).length > 0;

  const areAllConditionsSuccessful = (conditions: ProspectApplicationCondition[]) =>
    conditions.filter(condition => condition.status !== ApplicationConditionStatus.SUCCESS).length === 0;

  return (
    <div className={styles.container}>
      <img className={styles.image} src={HeroProgress} alt={'hero progress'} />
      <div className={styles.title}><Trans>DASHBOARD.ONGOING_VERIFICATION_POPUP.TITLE</Trans></div>
      <div className={styles.description}><Trans>DASHBOARD.ONGOING_VERIFICATION_POPUP.DESCRIPTION</Trans></div>
      <Button className={styles.button} variant="outlined" onClick={onPopupClose}>
        <Trans>SHARED.BACK_LINKS.BACK_HOME</Trans>
      </Button>
    </div>
  );
};

const mapStateToProps = (state: State,
                         componentProps: OngoingVerificationPopupContentComponentProps): OngoingVerificationPopupContentStateProps => ({
  userData: state.sessionData!,
  ...componentProps
});

const mapDispatchToProps = (dispatch): OngoingVerificationPopupContentDispatchProps => ({
  upgradeSession: () => dispatch(upgradeProspectSession()),
  logout: () => dispatch(logout())
});

export default connect<OngoingVerificationPopupContentStateProps,
  OngoingVerificationPopupContentDispatchProps,
  OngoingVerificationPopupContentComponentProps,
  State>(mapStateToProps, mapDispatchToProps)(OngoingVerificationPopupContent);
