import {Avatar} from '@material-ui/core';
import {toNumber} from 'lodash';
import React from 'react';
import {CURRENCY_CODE} from '../../../../../../constants/currency';
import {PaymentProduct} from '../../../../../../shared/model/PaymentProduct';
import Loader from '../../../../../shared/loader/Loader';
import styles from './BuyLoadPromosOrGamingProducts.module.scss';

interface BuyLoadPromosProductsProps {
  products: PaymentProduct[],
  isLoading: boolean,
  onProductClick: (product: PaymentProduct) => void;
}

export default function BuyLoadPromosOrGamingProducts({products, isLoading, onProductClick}: BuyLoadPromosProductsProps) {
  const getAmountClass = (amount: string): string => {
    const amountValue = toNumber(amount);
    return `${styles.amount} ${
      amountValue >= 1000 ? amountValue >= 10000 ? styles['amount--smallest-font'] : styles['amount--smaller-font'] : ''
    }`;
  }

  return (isLoading
      ? <Loader />
      : <div className={styles.products}>
        {
          products.map(product =>
            <div key={product.id} className={styles.product} onClick={() => onProductClick(product)}>
              <Avatar className={styles.avatar}>
                <div className={getAmountClass(product.maxAmount)}>
                  {product.maxAmount}
                </div>
                <div className={styles.currency}>{CURRENCY_CODE}</div>
              </Avatar>
              <div className={styles.about}>
                <div className={styles.title}>{product.name}</div>
                <div className={styles.description}>{product.description}</div>
              </div>
            </div>)
        }
      </div>
  );
}