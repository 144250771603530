import React from 'react';
import styles from '../../../../shared/bill-payment/popup/BillPaymentPopup.module.scss';
import Otp from '../../../../shared/otp/Otp';
import PopupWithMemoryRouter from '../../../../shared/popups/PopupWithMemoryRouter';
import {BuyLoadRoutesPaths} from '../buy-load.routes-paths';
import BuyLoadPhoneValidation from '../phone-validation/BuyLoadPhoneValidation';
import BuyLoadProducts from '../products/BuyLoadProducts';
import BuyLoadProductConfirm from '../confirm/BuyLoadProductConfirm';
import BuyLoadSuccess from '../success/BuyLoadSuccess';

interface BillPaymentPopupProps {
  isOpen: boolean,
  onClose: () => void
}

export default function BuyLoadPopup({isOpen, onClose}: BillPaymentPopupProps) {

  // First route is the first step of buy load process
  const buyLoadRoutes = [
    {
      path: BuyLoadRoutesPaths.PHONE_VALIDATION,
      key: 'BUY_LOAD_PHONE_VALIDATION',
      component: BuyLoadPhoneValidation
    },
    {
      path: BuyLoadRoutesPaths.PRODUCT_SELECTION,
      key: 'BUY_LOAD_PRODUCT_SELECTION',
      component: BuyLoadProducts
    },
    {
      path: BuyLoadRoutesPaths.CONFIRM,
      key: 'BUY_LOAD_PAYMENT_CONFIRM',
      component: BuyLoadProductConfirm
    },
    {
      path: BuyLoadRoutesPaths.OTP,
      key: 'BUY_LOAD_PAYMENT_OTP',
      component: Otp
    },
    {
      path: BuyLoadRoutesPaths.SUCCESS,
      key: 'BUY_LOAD_PAYMENT_SUCCESS',
      hideDefaultPopupCloseButton: true,
      component: BuyLoadSuccess
    }
  ];

  return (
    <PopupWithMemoryRouter open={isOpen}
                           handleClose={() => onClose()}
                           routes={buyLoadRoutes}
                           contentWrapperClass={styles.container} />
  );
}
