import React from 'react';
import {IconButton} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import styles from './PopupCloseButton.module.scss';
import Loader from '../loader/Loader';

interface PopupCloseButtonProps {
  isLoaded?: boolean,
  handleClose: () => void;
}

const PopupCloseButton = ({handleClose, isLoaded=true}: PopupCloseButtonProps) => {
  const CloseButtonLoader = (
    <div className={styles.loader}>
      <Loader buttonSpinner textColorVariant />
    </div>
  );

  return (
    <IconButton aria-label="close" className={styles['close-button']} onClick={handleClose} disabled={!isLoaded}>
      { isLoaded ? <CloseIcon /> : CloseButtonLoader }
    </IconButton>
  );
};

export default PopupCloseButton;