import React from 'react';
import {Trans} from 'react-i18next';
import CurrencyFormat from '../../shared/currency-format/CurrencyFormat';
import styles from './AmountPerMonth.module.scss';

interface AmountPerMonthProps {
  amountPerMonth: number;
}

const AmountPerMonth = ({amountPerMonth}: AmountPerMonthProps) => (
  <div className={styles.amount}>
    <div className={styles['amount__label']}>
      <Trans>GOALS.CREATE.AMOUNT_PER_MONTH_LABEL</Trans>
    </div>
    <div className={styles['amount__value']}>
      <CurrencyFormat amount={amountPerMonth} />
    </div>
  </div>
);

export default AmountPerMonth;
