import React, {useEffect} from 'react';
import {CrossOriginMessage} from '../../../shared/model/CrossOriginMessage';
import {JUMIO_VERIFICATION_RESULT_CROSS_ORIGIN_MESSAGE} from './JumioVerification';
import {JumioVerificationInitializationStatus} from './model/JumioVerificationInitializationStatus';

interface JumioVerificationResultProps {
  status: JumioVerificationInitializationStatus
}

// The purpose of this view is to inform our app about result of jumio verification status.
// It won't be rendered.
const JumioVerificationResult = ({status}: JumioVerificationResultProps) => {

  useEffect(() => {
    window.parent.postMessage({
        type: JUMIO_VERIFICATION_RESULT_CROSS_ORIGIN_MESSAGE,
        payload: status
      } as CrossOriginMessage<JumioVerificationInitializationStatus>,
      window.self.location.origin
    );
  }, [status]);

  return (<div />);
}

export default JumioVerificationResult;
