import {Button} from '@material-ui/core';
import React, {useContext} from 'react';
import {Trans} from 'react-i18next';
import {MainRouterContext} from '../../../App';
import HeroSuccess from '../../../assets/images/hero-success.svg';
import {RoutesPaths} from '../../../routes/routes-paths/routes-paths';
import {TransWithNoWrapSpan} from '../../shared/trans/TransWithNoWrapSpan';
import styles from './ResetPasswordSuccessMessage.module.scss';

export const ResetPasswordSuccessMessage = () => {

  const mainRouter = useContext(MainRouterContext);

  return (
    <div className={styles.container}>
      <img src={HeroSuccess} alt="hero success" />
      <div className={styles['success-title']}>
        <Trans>RESET_PASSWORD.SUCCESS.TITLE</Trans>
      </div>
      <div className={styles['success-message']}>
        <TransWithNoWrapSpan>RESET_PASSWORD.SUCCESS.MESSAGE</TransWithNoWrapSpan>
      </div>
      <Button onClick={() => mainRouter.globalHistory.replace(RoutesPaths.LOGIN)} className={styles.button}>
        <Trans>RESET_PASSWORD.SUCCESS.LOGIN</Trans>
      </Button>
    </div>
  );
};
