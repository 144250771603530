import React from 'react';
import {Trans} from 'react-i18next';
import {ListParagraph} from '../../../shared/list-paragraph/ListParagraph';
import {TransWithNoWrapSpan} from '../../../shared/trans/TransWithNoWrapSpan';

export const DepositReferenceNumberHowTo = () => {
  const bullets = [
    'DEPOSIT.HOW_TO.POINT_1',
    'DEPOSIT.HOW_TO.POINT_2',
    'DEPOSIT.HOW_TO.POINT_3',
    'DEPOSIT.HOW_TO.POINT_4',
    'DEPOSIT.HOW_TO.POINT_5'
  ].map(bullet => <TransWithNoWrapSpan>{bullet}</TransWithNoWrapSpan>);

  return <ListParagraph items={bullets} title={<Trans>DEPOSIT.HOW_TO.TITLE</Trans>} />
};