import {Link} from '@material-ui/core';
import React from 'react';
import styles from './ActivitiesEmptyState.module.scss';

interface ActivitiesEmptyStateProps {
  text: React.ReactElement;
  linkText?: React.ReactElement;
}

export default function ActivitiesEmptyState({text, linkText}: ActivitiesEmptyStateProps) {
  return (
    <div className={styles.container}>
      <div>{text}</div>
      {
        linkText && <Link className={styles.link}>{linkText}</Link>
      }
    </div>
  );
}