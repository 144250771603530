import {Button} from '@material-ui/core';
import React, {useContext} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {AppSnackbarContext} from '../../../../../App';
import {useCustomerOperationDetailsHook} from '../../../../../utils/hooks/getCustomerOperationDetailsHook';
import {AlertSnackbarActionType} from '../../../../shared/alert-snackbar/alertSnackBarHook';
import Loader from '../../../../shared/loader/Loader';
import OperationInfo from '../../../../shared/operation/operation-info/OperationInfo';
import {BuyLoadServicePopupContext} from '../../Services';
import styles from './BuyLoadSuccess.module.scss';

interface BuyLoadSuccessProps {
  location: {
    state: {
      sourceProductId: number,
      operationId: number
    }
  };
}

export default function BuyLoadSuccess({location}: BuyLoadSuccessProps) {
  const operationDetails = useCustomerOperationDetailsHook(location.state.sourceProductId, location.state.operationId);
  const popupContext = useContext(BuyLoadServicePopupContext);
  const appSnackbarContext = useContext(AppSnackbarContext);
  const {t} = useTranslation();

  const finishBuyLoadProcess = () => {
    popupContext.closePopup();
    appSnackbarContext.dispatchAction({
      type: AlertSnackbarActionType.SHOW,
      message: t('BUY_LOAD.SUCCESS_MESSAGE')
    });
  };

  return (
    <div className={styles.container}>
      <Loader loaded={!!operationDetails}>
        <>
          <OperationInfo operationDetails={operationDetails!} />
          <div className={styles.actions}>
            <Button onClick={finishBuyLoadProcess} className={styles['back-link-button']}>
              <Trans>SHARED.BACK_LINKS.BACK_HOME</Trans>
            </Button>
          </div>
        </>
      </Loader>
    </div>
  );
}