import React from 'react';
import PartnerIcon from '../partner-icon/PartnerIcon';
import styles from './PartnerTile.module.scss';
import {MerchantCode} from '../../../../shared/model/operations/partners/PartnerMerchant';

interface PartnerTileProps {
  merchantCode: MerchantCode;
  label: string;
  onClick?: () => void;
}

export default function PartnerTile({merchantCode, label, onClick}: PartnerTileProps) {
  return (
    <div className={styles.tile} onClick={onClick}>
      <div className={styles['tile__icon-wrapper']}>
        <PartnerIcon merchantCode={merchantCode} label={label} />
      </div>
      {label}
    </div>
  );
}
