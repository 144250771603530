import {Button, TextField} from '@material-ui/core';
import {Formik, FormikHelpers} from 'formik';
import React, {ReactElement, useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {RoutesPaths} from '../../routes/routes-paths/routes-paths';
import {PublicSystemProperties} from '../../shared/model/SystemProperties';
import {fetchSystemProperties, login} from '../../store/actions';
import State from '../../store/state';
import encrypt from '../../utils/credentials-encryption';
import {ErrorHelper} from '../../utils/error-helper';
import Loader from '../shared/loader/Loader';
import WelcomeScreenWrapper from '../shared/containers/WelcomeScreenWrapper';
import styles from './Login.module.scss';

interface LoginStateProps {
  logoutReasonCode: string | undefined;
  systemProperties: PublicSystemProperties;
}

interface LoginDispatchProps {
  login: (loginValue: string, passwordValue: string) => any;
  fetchSystemProperties: () => any;
}

type LoginProps = LoginStateProps & LoginDispatchProps;

interface FormFields {
  login: string;
  password: string;
}

const Login = ({logoutReasonCode, systemProperties, login, fetchSystemProperties}: LoginProps) => {
  const {t} = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [logoutReasonMessageCode, setLogoutReasonMessageCode] = useState<string | undefined>(undefined);

  useEffect(() => {
    setLogoutReasonMessageCode(logoutReasonCode);

    if (!systemProperties) {
      fetchSystemProperties();
    } else {
      setIsLoaded(true);
    }
  }, [fetchSystemProperties, logoutReasonCode, systemProperties]);

  function submit(values: FormFields, actions: FormikHelpers<FormFields>) {
    const loginValue = encrypt(values.login, systemProperties.credentialsEncryptionRsaPublicKey);
    const passwordValue = encrypt(values.password, systemProperties.credentialsEncryptionRsaPublicKey);

    setLogoutReasonMessageCode(undefined);

    login(loginValue, passwordValue)
      .catch(error => {
        // TODO Prevent setters when component is unmounted
        actions.setFieldError('server', ErrorHelper.getErrorMessageTranslation(t, error));
        actions.setSubmitting(false);
      });
  }

  const LoginForm = ({values, errors, handleChange, handleBlur, handleSubmit, isSubmitting}): ReactElement => (
    <form onSubmit={handleSubmit} className={styles.form}>
      <TextField
        label={<Trans>SHARED.COMMON.NICKNAME_OR_USERNAME</Trans>}
        type="text"
        name="login"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.login}
      />
      <TextField
        label={<Trans>SHARED.COMMON.PASSWORD</Trans>}
        type="password"
        name="password"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
      />
      <Link to={RoutesPaths.RESET_PASSWORD} className={styles['forgotten-password-link']}>
        <Trans>LOGIN.FORGOT_PASSWORD_MESSAGE</Trans>
      </Link>
      <Button type="submit" disabled={isSubmitting}>
        <Loader buttonSpinner loaded={!isSubmitting}>
          <Trans>LOGIN.LOGIN_BUTTON</Trans>
        </Loader>
      </Button>
      <div className={styles.errors}>
        <div>{/* div needed as errors wrapper */}
          { logoutReasonMessageCode && <div className={styles.error}>{t(logoutReasonMessageCode)}</div> }
          { errors.server && <div className={styles.error}>{errors.server}</div> }
        </div>
      </div>
    </form>
  );

  return (
    <WelcomeScreenWrapper>
      <div className={styles.login}>
        <div className={styles.register}>
          <Trans>LOGIN.NO_ACCOUNT_MESSAGE</Trans>
          <Link to={RoutesPaths.REGISTRATION} className={styles['register__link']}>
            <Trans>LOGIN.REGISTER_CALL_TO_ACTION</Trans>
          </Link>
        </div>
        <div className={styles['form-container']}>
          <p className={styles.welcome}>
            <Trans>LOGIN.WELCOME</Trans>
          </p>
          <h1 className={styles.title}>
            <Trans>LOGIN.LOGIN_CALL_TO_ACTION</Trans>
          </h1>
          <div className={styles[`form-wrapper`]}>
            <Loader loaded={isLoaded} className={styles.loader}>
              <Formik
                <FormFields>
                initialValues={{login: '', password: ''}}
                onSubmit={submit}
                children={LoginForm}
              />
            </Loader>
          </div>
        </div>
      </div>
    </WelcomeScreenWrapper>
  );
};

const mapStateToProps = (state: State): LoginStateProps => ({
  logoutReasonCode: state.public.logoutReasonCode,
  systemProperties: state.public.systemProperties
});

const mapDispatchToProps = (dispatch): LoginDispatchProps => ({
  login: (loginValue: string, passwordValue: string) => dispatch(login(loginValue, passwordValue)),
  fetchSystemProperties: () => dispatch(fetchSystemProperties())
});

export default connect<LoginStateProps, LoginDispatchProps, {}, State>(mapStateToProps, mapDispatchToProps)(Login);
