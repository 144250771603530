import dayjs from 'dayjs';
import {ProductGroup} from '../ProductGroup';
import {OperationCategory} from './OperationCategory';
import {OperationStatus} from './OperationStatus';

export interface OperationsBasicDataResponseTO<T_OPERATIONS = OperationBasicDataTO> {
  hasMoreItems: boolean;
  items: T_OPERATIONS[];
}

export interface OperationsBasicData extends OperationsBasicDataResponseTO<OperationBasicData> {
}

export interface OperationBasicDataTO<T_DATE = string, T_STATUS = string, T_CATEGORY = string, T_PRODUCT_GROUP = string> {
  id: number;
  uid: string;
  productId: number;
  rownum: number;
  productNumber: string;
  productName: string;
  productGroup: T_PRODUCT_GROUP;
  amount: number;
  balanceAfter: number;
  category: T_CATEGORY;
  operationCode: string;
  operationTranslationCode: string;
  registeredOn: T_DATE;
  registeredOnTimestamp: T_DATE;
  status: T_STATUS;
  feeName: string;
  externalServiceName: string;
}

export interface OperationBasicData extends OperationBasicDataTO<dayjs.Dayjs, OperationStatus, OperationCategory, ProductGroup> {
}

export function OperationBasicDataFromRaw(operationBasicDataRaw: OperationBasicDataTO): OperationBasicData {
  return {
    ...operationBasicDataRaw,
    registeredOn: dayjs(operationBasicDataRaw.registeredOn),
    registeredOnTimestamp: dayjs(operationBasicDataRaw.registeredOnTimestamp)
  } as OperationBasicData;
}

export function OperationBasicDataResponseFromRaw(operationBasicDataResponse: OperationsBasicDataResponseTO): OperationsBasicData {
  return {
    ...operationBasicDataResponse,
    items: operationBasicDataResponse.items.map(operationDataResponse => OperationBasicDataFromRaw(operationDataResponse))
  } as OperationsBasicData;
}
