import React from 'react';
import {Trans} from 'react-i18next';
import Columns from '../shared/columns/Columns';
import MainContainer from '../shared/containers/MainContainer';
import BillPaymentCategories from './categories/BillPaymentCategories';
import styles from './BillPayment.module.scss';

export default function BillPayment() {
  return (
    <MainContainer>
      <Columns
        heading="BILL_PAYMENT.HEADER"
        mainColumn={
          <>
            <div className={styles.title}>
              <Trans>BILL_PAYMENT.TITLE</Trans>
            </div>
            <BillPaymentCategories />
          </>
        }
      />
    </MainContainer>
  );
}
