export interface DynamicField {
  code: string;
  name: string;
  orderNo: number;
  required: boolean;
  type: DynamicFieldType;
  maxLength?: number;
  options?: DynamicFieldOption[];
}

export enum DynamicFieldType {
  NUMERIC = 'NUMERIC',
  ALPHANUMERIC = 'ALPHANUMERIC',
  DICTIONARY = 'DICTIONARY',
  LOCAL_MOBILE_NO = 'LOCAL_MOBILE_NO',
  INTERNATIONAL_MOBILE_NO = 'INTERNATIONAL_MOBILE_NO',
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  DATE = 'DATE'
}

export interface DynamicFieldOption {
  name: string;
  value: string;
}
