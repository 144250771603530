import {CancelTokenSource} from 'axios';
import {HttpServiceHelper} from '../../utils/http-service-helper';
import {Dictionary, DictionaryCode, GeoLocationsDictionaryEntry, GeoLocationLevel} from '../model/Dictionary';
import HttpService from './http.service';

const COMMON_URL = '/common';
const DICTIONARIES_URL = `${COMMON_URL}/dictionaries`;
const TRANSLATION_CODES = ['ERROR_CODE', 'OPERATION_NAME', 'OPERATION_ATTRIBUTE_TYPE'];

export const DictionaryService = {
  getTranslations: () =>
    HttpService.get(`${COMMON_URL}/system/translations?language=en&type=${TRANSLATION_CODES}`)
      .catch(() => ({})),

  getDictionaries: (dictCode: DictionaryCode[],
                    language = 'en',
                    cancelTokenSource?: CancelTokenSource | null): Promise<Dictionary[]> =>
    HttpService.get<Dictionary[]>(
      HttpServiceHelper.constructUrlWithSearchParams(
        DICTIONARIES_URL,
        {dictCode, language}
      ),
      cancelTokenSource
    ),

  getGeoDictionary: (level: GeoLocationLevel,
                     cancelTokenSource?: CancelTokenSource | null): Promise<GeoLocationsDictionaryEntry[]> =>
    HttpService.get<GeoLocationsDictionaryEntry[]>(
      `${DICTIONARIES_URL}/geo-locations/level/${level}`,
      cancelTokenSource
    )
};
