import React from 'react';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {RoutesPaths} from '../../../../routes/routes-paths/routes-paths';
import styles from './RecentActivitiesHeader.module.scss';

interface RecentActivitiesHeaderProps {
  linkVisible: boolean;
}

const RecentActivitiesHeader = ({linkVisible}: RecentActivitiesHeaderProps) => (
  <div className={styles.container}>
    <p className={styles.header}>
      <Trans>DASHBOARD.RECENT_ACTIVITIES.HEADER</Trans>
    </p>
    {linkVisible &&
    <Link to={RoutesPaths.HISTORY} className={styles['view-all-link']}>
      <Trans>DASHBOARD.RECENT_ACTIVITIES.VIEW_ALL_LINK</Trans>
    </Link>
    }
  </div>
);

export default RecentActivitiesHeader;
