import {CommandConfirmationParams} from '../model/command/CommandConfirmationParams';
import {CommandOutput, CommandOutputData} from '../model/command/CommandOutput';
import HttpService from './http.service';

const COMMANDS_URL = '/common/commands';
const ANONYMOUS_COMMANDS_URL = `${COMMANDS_URL}/anonymous`;
const getCommandBaseUrl = (isAnonymous: boolean) => isAnonymous ? ANONYMOUS_COMMANDS_URL : COMMANDS_URL;

export const CommandService = {
  confirm: <T_OUTPUT extends CommandOutputData>(commandConfirmationParams: CommandConfirmationParams,
                                                isAnonymous: boolean = false): Promise<CommandOutput<T_OUTPUT>> =>
    HttpService.post<CommandOutput<T_OUTPUT>>(
      `${getCommandBaseUrl(isAnonymous)}/confirmations`,
      commandConfirmationParams
    ),

  resentOtp: (commandId: number, isAnonymous: boolean = false): Promise<void> =>
    HttpService.post<void>(`${getCommandBaseUrl(isAnonymous)}/${commandId}/resent-otp`)
};
