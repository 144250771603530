export const HttpServiceHelper = {
  constructUrlWithSearchParams: (url: string, params: Object): string => {
    const urlSearchParams = constructUrlParams(params);

    if (!urlSearchParams.values().next().done) {
      url += `?${urlSearchParams.toString()}`;
    }

    return url;
  }
};

const constructUrlParams = (params: Object): URLSearchParams => {
  const urlSearchParams = new URLSearchParams();

  Object.keys(params).forEach(key => {
    if (params[key]) {
      urlSearchParams.append(key, params[key]);
    }
  });

  return urlSearchParams;
};