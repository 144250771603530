import UserData from '../model/UserData';
import HttpService from './http.service';

const CUSTOMER_AUTH_URL = '/customer/auth/sessions';
const BASE_AUTH_URL = '/common/auth/tokens';
const ANONYMOUS_SESSION_URL = `${BASE_AUTH_URL}/anonymous`;

const COMMON_AUTH_PAYLOAD = {
  channel: 'WEB'
};

const AuthenticationService = {
  checkSession: (): Promise<UserData> => HttpService.get<UserData>(`${BASE_AUTH_URL}/session`),

  initAnonymousSession: (): Promise<UserData> => HttpService.post<UserData>(ANONYMOUS_SESSION_URL, COMMON_AUTH_PAYLOAD),

  login: (username: string, password: string): Promise<UserData> => HttpService.post<UserData>(
    BASE_AUTH_URL,
    {
      ...COMMON_AUTH_PAYLOAD,
      identifier: username,
      secret: password,
      payloadType: 'USERNAME'
    }
  ),

  upgradeProspectSession: (): Promise<UserData> => HttpService.post<UserData>(`${BASE_AUTH_URL}/upgrade`),

  logout: (): Promise<void> => HttpService.post(`${CUSTOMER_AUTH_URL}/logout`)
};

export default AuthenticationService;
