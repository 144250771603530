import React from 'react';
import {useHistory} from 'react-router';
import {DashboardRoutesPaths} from '../../../routes/routes-paths/dashboard.routes-paths';
import PopupWithMemoryRouter, {PopupMemoryRouterRouteProps} from '../../shared/popups/PopupWithMemoryRouter';
import VerifyAccountRemindersPopupContent from '../verify-account-popup/VerifyAccountRemindersPopupContent';
import {VerifyAccountPopupRoutes} from './verify-account-popup.routes-paths';
import {VerifyAccountPopupContent} from './VerifyAccountPopupContent';

interface OngoingVerificationPopupProps {
  isOpen: boolean;
  onPopupClose: () => void;
}

const VerifyAccountPopup = ({isOpen, onPopupClose}: OngoingVerificationPopupProps) => {
  const history = useHistory();

  const navigateToVerificationForm = () => {
    onPopupClose();
    history.push(DashboardRoutesPaths.VERIFICATION);
  }

  const routes: PopupMemoryRouterRouteProps[] = [
    {
      path: VerifyAccountPopupRoutes.VERIFY_ACCOUNT,
      key: 'VERIFY_ACCOUNT',
      hideDefaultPopupCloseButton: true,
      render: () => <VerifyAccountPopupContent onPopupClose={onPopupClose} />
    },
    {
      path: VerifyAccountPopupRoutes.VERIFY_ACCOUNT_REMINDERS,
      key: 'VERIFY_ACCOUNT_REMINDERS',
      hideDefaultPopupCloseButton: true,
      render: () => <VerifyAccountRemindersPopupContent onSubmitButtonClick={navigateToVerificationForm} />
    }
  ];

  return <PopupWithMemoryRouter open={isOpen} handleClose={onPopupClose} routes={routes} />;
};

export default VerifyAccountPopup;
