import React from 'react';
import ArrowRight from '../../../assets/images/icons/arrow-right.svg';
import Logo from '../../../assets/images/logos/idoc-logo.png';
import {TelemedicineProduct} from '../model/TelemedicineProduct';
import styles from './TelemedicineRow.module.scss';

interface TelemedicineRowProps {
  telemedicine: TelemedicineProduct;
  onClick: (id: number) => void
}

const TelemedicineRow = ({telemedicine, onClick}: TelemedicineRowProps) => {

  return (
    <div className={styles.row} onClick={() => onClick(telemedicine.id)}>
      <div className={styles['logo-container']}>
        {/* Icon is hardcoded as long as one telemedicine provider would be available */}
        <img className={styles['logo-container__logo']} src={Logo} alt={'logo'} />
      </div>
      <div className={styles.title}>{telemedicine.name}</div>
      <div className={styles.description}>{telemedicine.shortDescription}</div>
      <div className={styles['arrow-container']}>
        <img src={ArrowRight} alt={'arrow-right'} />
      </div>
    </div>
  );
};

export default TelemedicineRow;
