import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {OperationGroup, PartnerMerchant} from '../../shared/model/operations/partners/PartnerMerchant';
import {PartnerOperationsService} from '../../shared/services/partner-operations.service';
import {WithdrawalService} from '../../shared/services/withdrawal.service';
import {fetchUserPrimaryAccount} from '../../store/actions';
import {getUserAccount} from '../../store/selectors';
import {ErrorHelper} from '../../utils/error-helper';
import {onlyPartnersWithEnabledOperation} from '../../utils/filter-partners';
import {cleanUp} from '../../utils/handle-abort-controller-clean-up';
import Columns from '../shared/columns/Columns';
import MainContainer from '../shared/containers/MainContainer';
import Loader from '../shared/loader/Loader';
import PartnerMerchants from '../shared/partners/PartnerMerchants';
import PopupWithMemoryRouter from '../shared/popups/PopupWithMemoryRouter';
import {ReferenceNumberCancelConfirmation} from '../shared/reference-number/ReferenceNumberCancelConfirmation';
import {CustomerCardlessWithdrawalOption} from './customer-cardless-withdrawal/CustomerCardlessWithdrawalOption';
import {Withdrawal} from './pending-wihdrawals/model/Withdrawal';
import {PendingWithdrawalsOption} from './pending-wihdrawals/PendingWithdrawalsOption';
import {WithdrawalReferenceNumber} from './reference-number/WithdrawalReferenceNumber';
import WithdrawalAmount from './withdrawal-amount/WithdrawalAmount';
import {WithdrawalRoutesPaths} from './withdrawal.routes-paths';

type PartnerPopupState = {
  isOpen: boolean,
  partner?: PartnerMerchant
}

function Withdraw() {
  const [partners, setPartners] = useState<PartnerMerchant[]>([]);
  const [pendingWithdrawals, setPendingWithdrawals] = useState<Withdrawal[]>([]);
  const [areOptionsLoaded, setAreOptionsLoaded] = useState<boolean>(false);
  const [arePartnersLoaded, setArePartnersLoaded] = useState<boolean>(false);
  const [partnerPopupState, setPartnerPopupState] = useState<PartnerPopupState>({isOpen: false});
  const userAccount = useSelector(getUserAccount);
  const customerId = userAccount?.id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserPrimaryAccount());

    if (!userAccount) {
      return;
    }

    const cancelTokenSource = axios.CancelToken.source();

    WithdrawalService.getPendingCardlessWithdrawals({customerId}, cancelTokenSource)
      .then(pendingWithdrawals => {
        setPendingWithdrawals(pendingWithdrawals.result);
        setAreOptionsLoaded(true);
      })
      .then(() => PartnerOperationsService.getPartnerMerchants(cancelTokenSource))
      .then(allPartners => {
        const filteredPartners = allPartners.filter(onlyPartnersWithEnabledOperation(OperationGroup.WITHDRAWAL));
        setPartners(filteredPartners);
        setArePartnersLoaded(true);
      })
      .catch(error => {
        if (ErrorHelper.isAbortOrSessionRestrictionError(error)) {
          return;
        }
        setPendingWithdrawals([]);
        setPartners([]);
        setAreOptionsLoaded(true);
        setArePartnersLoaded(true);
      });

    return cleanUp(cancelTokenSource);
  }, [customerId, dispatch, userAccount]);

  const closePopup = () => {
    setPartnerPopupState({...partnerPopupState, isOpen: false});
  };

  const openPopup = (partner: PartnerMerchant) => {
    setPartnerPopupState({partner, isOpen: true});
  };

  const partnerWithdrawalRoutes = [
    {
      path: WithdrawalRoutesPaths.PARTNER_WITHDRAWAL_AMOUNT,
      key: 'PARTNER_WITHDRAWAL_AMOUNT',
      render: () => <WithdrawalAmount partnerMerchant={partnerPopupState.partner!} />
    },
    {
      path: WithdrawalRoutesPaths.PARTNER_WITHDRAWAL_REFERENCE_NUMBER,
      key: 'PARTNER_WITHDRAWAL_REFERENCE_NUMBER',
      hideDefaultPopupCloseButton: true,
      render: props => <WithdrawalReferenceNumber handlePopupClose={closePopup} {...props} />
    },
    {
      path: WithdrawalRoutesPaths.PARTNER_WITHDRAWAL_REFERENCE_NUMBER_CANCEL,
      key: 'PARTNER_WITHDRAWAL_REFERENCE_NUMBER_CANCEL',
      hideDefaultPopupCloseButton: true,
      component: ReferenceNumberCancelConfirmation
    }
  ];

  return (
    <MainContainer>
      <Columns heading="WITHDRAW.HEADER"
               mainColumn={
                 areOptionsLoaded ?
                   <>
                     <CustomerCardlessWithdrawalOption />
                     {pendingWithdrawals.length > 0 && <PendingWithdrawalsOption withdrawals={pendingWithdrawals} />}
                     <PartnerMerchants isLoaded={arePartnersLoaded} partners={partners} onPartnerClick={openPopup} />
                     <PopupWithMemoryRouter open={partnerPopupState.isOpen}
                                            handleClose={closePopup}
                                            routes={partnerWithdrawalRoutes} />
                   </> : <Loader />
               }
      />
    </MainContainer>
  );
}

export default Withdraw;
