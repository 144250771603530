import {RoutesParts} from '../../constants/routes-parts';
import {DashboardRoutesPaths} from '../../routes/routes-paths/dashboard.routes-paths';

export const WithdrawalRoutesPaths = {
  PARTNER_WITHDRAWAL_AMOUNT: DashboardRoutesPaths.WITHDRAW + RoutesParts.AMOUNT,
  PARTNER_WITHDRAWAL_REFERENCE_NUMBER: DashboardRoutesPaths.WITHDRAW + RoutesParts.REFERENCE_NUMBER,
  PARTNER_WITHDRAWAL_REFERENCE_NUMBER_CANCEL: DashboardRoutesPaths.WITHDRAW + RoutesParts.CANCEL,
  PENDING_WITHDRAWAL_REFERENCE_NUMBER: DashboardRoutesPaths.PENDING_WITHDRAWALS + RoutesParts.REFERENCE_NUMBER,
  PENDING_WITHDRAWAL_CANCEL: DashboardRoutesPaths.PENDING_WITHDRAWALS + RoutesParts.CANCEL
};
