import Account from '../components/account/Account';
import Goals from '../components/goals/Goals';
import History from '../components/history/History';
import {JUMIO_ROUTES} from '../components/verification/jumio/jumio.routes';
import Login from '../components/login/Login';
import Registration from '../components/registration/Registration';
import ResetPassword from '../components/reset-password/ResetPassword';
import {KeyedRouteProps} from '../components/shared/routes/model/Routes';
import {DASHBOARD_ROUTES} from './dashboard.routes';
import {RoutesPaths} from './routes-paths/routes-paths';

export const ROUTES = [
  {
    path: RoutesPaths.LOGIN,
    key: 'LOGIN',
    exact: true,
    component: Login,
    public: true
  },
  {
    path: RoutesPaths.REGISTRATION,
    key: 'REGISTRATION',
    exact: true,
    component: Registration,
    public: true
  },
  {
    path: RoutesPaths.RESET_PASSWORD,
    key: 'RESET_PASSWORD',
    component: ResetPassword,
    public: true
  },
  ...DASHBOARD_ROUTES,
  {
    path: RoutesPaths.HISTORY,
    key: 'HISTORY',
    component: History
  },
  {
    path: RoutesPaths.GOALS,
    key: 'GOALS',
    component: Goals
  },
  ...JUMIO_ROUTES,
  /* Route accessible only on mobile */
  {
    path: RoutesPaths.ACCOUNT,
    key: 'ACCOUNT',
    component: Account
  }
] as KeyedRouteProps[];

export default ROUTES;
