import {Button, TextField} from '@material-ui/core';
import {Formik, FormikHelpers} from 'formik';
import React, {ReactElement, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ErrorHelper} from '../../../utils/error-helper';
import {PasswordHelper} from '../../../utils/password-helper';
import passwordInputType from '../../../utils/password-input-type';
import Loader from '../loader/Loader';
import {PasswordVisibilityButton} from './PasswordVisibilityButton';
import styles from './SetPasswordForm.module.scss';
import {SetPasswordRule} from './SetPasswordRule';

export interface SetPasswordFormProps {
  onSubmit: (password: string) => Promise<any>;
}

interface FormFields {
  password: string;
  passwordRepeat: string;
}

export default function SetPasswordForm({onSubmit}: SetPasswordFormProps) {
  const {t} = useTranslation();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordRepeatVisible, setIsPasswordRepeatVisible] = useState(false);

  function submit(values: FormFields, actions: FormikHelpers<FormFields>) {
    onSubmit(values.password)
      .catch(error => {
        // TODO Prevent setters when component is unmounted
        actions.setFieldError('server', ErrorHelper.getErrorMessageTranslation(t, error));
        actions.setSubmitting(false);
      });
  }

  const PasswordForm = ({values, errors, handleChange, handleBlur, handleSubmit, isSubmitting}): ReactElement => {
    const {checkLengthRule, checkLowerCaseRule, checkUpperCaseRule, checkNumberOrSpecialCharacterRule} = PasswordHelper;

    const checkPasswordsRules = (password: string, passwordRepeat: string) => (
      password === passwordRepeat
      && checkLengthRule(password)
      && checkLowerCaseRule(password)
      && checkUpperCaseRule(password)
      && checkNumberOrSpecialCharacterRule(password)
    );

    return (
      <form onSubmit={handleSubmit} className={styles.form}>
        <TextField
          className={styles.input}
          label={<Trans>SHARED.COMMON.PASSWORD</Trans>}
          type={passwordInputType(isPasswordVisible)}
          name="password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          InputProps={{
            endAdornment: <PasswordVisibilityButton isVisible={isPasswordVisible} setIsVisible={setIsPasswordVisible} />
          }}
        />
        <div className={styles['rules-header']}><Trans>SET_PASSWORD.RULES.TITLE</Trans></div>
        <SetPasswordRule isRuleChecked={checkLengthRule(values.password)}
                         label={<Trans>SET_PASSWORD.RULES.LENGTH_RULE</Trans>} />
        <SetPasswordRule isRuleChecked={checkLowerCaseRule(values.password)}
                         label={<Trans>SET_PASSWORD.RULES.LOWER_CASE_RULE</Trans>} />
        <SetPasswordRule isRuleChecked={checkUpperCaseRule(values.password)}
                         label={<Trans>SET_PASSWORD.RULES.UPPER_CASE_RULE</Trans>} />
        <SetPasswordRule isRuleChecked={checkNumberOrSpecialCharacterRule(values.password)}
                         label={<Trans>SET_PASSWORD.RULES.NUMBER_OR_SYMBOL_RULE</Trans>} />
        {/* TODO TextField long label comes with eye when too short. Example in mobile prospect registration form */}
        <TextField
          className={styles.input}
          label={<Trans>SET_PASSWORD.RETYPE_PASSWORD</Trans>}
          type={passwordInputType(isPasswordRepeatVisible)}
          name="passwordRepeat"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.passwordRepeat}
          InputProps={{
            endAdornment: <PasswordVisibilityButton isVisible={isPasswordRepeatVisible} setIsVisible={setIsPasswordRepeatVisible} />
          }}
        />
        <Button type="submit"
                className={styles.button}
                disabled={isSubmitting || !checkPasswordsRules(values.password, values.passwordRepeat)}>
          <Loader buttonSpinner loaded={!isSubmitting}>
            <Trans>SHARED.COMMON.CONFIRM</Trans>
          </Loader>
        </Button>
        <div className={styles.error}>
          {errors.server && <div className={styles['error__text']}>{errors.server}</div>}
        </div>
      </form>
    );
  };

  return (
    <>
      <h1>
        <Trans>SET_PASSWORD.HEADER</Trans>
      </h1>
      <p className={styles.description}>
        <Trans>SET_PASSWORD.DESCRIPTION</Trans>
      </p>
      <div className={styles[`form-wrapper`]}>
        <Formik
          <FormFields>
          initialValues={{password: '', passwordRepeat: ''}}
          onSubmit={submit}
          children={PasswordForm}
        />
      </div>
    </>
  );
}
