import React from 'react';
import {Trans} from 'react-i18next';
import StatusActive from '../../../../assets/images/icons/status-icons/status-active.svg';
import StatusInactive from '../../../../assets/images/icons/status-icons/status-inactive.svg';
import StatusWrong from '../../../../assets/images/icons/status-icons/status-wrong.svg';
import {CURRENCY_CODE} from '../../../../constants/currency';
import {DATE_WITH_TIME_FORMAT} from '../../../../constants/date';
import {DICTIONARY_OPERATION_ATTRIBUTE_TYPE_PREFIX} from '../../../../constants/values';
import {OperationDetails} from '../../../../shared/model/operations/OperationDetails';
import {OperationStatus} from '../../../../shared/model/operations/OperationStatus';
import OperationName from '../../activities/operation-name/OperationName';
import CurrencyFormat from '../../currency-format/CurrencyFormat';
import OperationDetail from '../operation-detail/OperationDetail';
import {filterExcludedAttributes} from './operation-attribute-filter';
import styles from './OperationInfo.module.scss';

interface OperationInfoProps {
  operationDetails: OperationDetails;
  specificProperties?: [{
    path: string,
    value: string
  }]
}

export default function OperationInfo({operationDetails, specificProperties}: OperationInfoProps) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles['icon-container']}>
          {getIconBasedOnStatus(operationDetails.status)}
        </div>
        <div className={styles['header__title']}>
          <OperationName operation={operationDetails} />
        </div>
        <div className={styles.description}>
          <Trans>SHARED.OPERATIONS.CHECK_DETAILS_DESCRIPTION</Trans>
        </div>
      </div>
      <div className={styles.details}>
        <OperationDetail title={<OperationName operation={operationDetails} />}
                         value={operationDetails.productName} />
        {specificProperties?.map((property, index) =>
          <OperationDetail key={index}
                           title={property!.path}
                           value={property!.value} />
        )}
        <OperationDetail title={'SHARED.COMMON.NAME'}
                         value={operationDetails.targetParty.customerEffectiveName} />
        {/*TODO at the moment 0 is hardcoded as there is no info about fee parameter from BE, fix when fee name known in TO*/}
        <OperationDetail title={'SHARED.COMMON.SERVICE_FEE'}
                         value={`${CURRENCY_CODE} ${operationDetails.fee ? operationDetails.fee : 0}`} />
        <OperationDetail title={'SHARED.COMMON.TRANSACTION_ID'} value={operationDetails.id} />
        <OperationDetail title={'SHARED.COMMON.TRANSACTION_DATE'}
                         value={operationDetails.registeredOnTimestamp.format(DATE_WITH_TIME_FORMAT)} />
        <OperationDetail title={'SHARED.COMMON.PROCESSED_DATE'}
                         value={operationDetails.processedOnTimestamp.format(DATE_WITH_TIME_FORMAT)} />
        <OperationDetail title={'SHARED.COMMON.ACCOUNT_NUMBER'} value={operationDetails.targetParty.productNumber} />
        <OperationDetail title={'SHARED.COMMON.BALANCE_AFTER'} value={operationDetails.balanceAfter} />
        {
          filterExcludedAttributes(operationDetails.attributes).map((attribute, index) =>
            <OperationDetail title={`${DICTIONARY_OPERATION_ATTRIBUTE_TYPE_PREFIX}${attribute.type}`}
                             value={attribute.value}
                             key={index} />)
        }
        <OperationDetail summary
                         title={'SHARED.COMMON.AMOUNT'}
                         value={
                           <div className={`${styles.amount} ${operationDetails.amount < 0
                             ? styles['amount--negative']
                             : ''}`}>
                             <CurrencyFormat amount={operationDetails.amount} />
                           </div>
                         } />
      </div>
    </div>
  );
}

const getIconBasedOnStatus = (status: OperationStatus) => {
  switch (status) {
    case OperationStatus.PROCESSED:
      return <img className={styles.icon} src={StatusActive} alt="icon-status-active" />;
    case OperationStatus.PENDING:
    case OperationStatus.REVERTED:
      return <img className={styles.icon} src={StatusInactive} alt="icon-status-inactive" />;
    case OperationStatus.REJECTED:
    case OperationStatus.ERROR:
      return <img className={styles.icon} src={StatusWrong} alt="icon-status-wrong" />;
    default:
      //TODO what status should be default
      return <img className={styles.icon} src={StatusInactive} alt="icon-status-inactive" />;
  }
};
