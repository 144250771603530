import React, {useEffect} from 'react';
import {Trans} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import DepositIcon from '../../../assets/images/icons/account-actions-icons/deposit.svg';
import ScanIcon from '../../../assets/images/icons/account-actions-icons/scan.svg';
import TransferIcon from '../../../assets/images/icons/account-actions-icons/transfer.svg';
import WithdrawIcon from '../../../assets/images/icons/account-actions-icons/withdraw.svg';
import {CONSTANT_INTEREST_RATE, EMPTY_DECIMAL_VALUE} from '../../../constants/values';
import {DashboardRoutesPaths} from '../../../routes/routes-paths/dashboard.routes-paths';
import UserData, {UserGroup} from '../../../shared/model/UserData';
import {fetchUserPrimaryAccount} from '../../../store/actions';
import {getUserAccount} from '../../../store/selectors';
import CurrencyFormat from '../../shared/currency-format/CurrencyFormat';
import Loader from '../../shared/loader/Loader';
import styles from './AccountBalance.module.scss';

interface AccountBalanceProps {
  userData?: UserData;
}

const AccountBalance = ({userData}: AccountBalanceProps) => {
  const userAccount = useSelector(getUserAccount);
  const dispatch = useDispatch();
  const isProspect = userData?.userGroup === UserGroup.PROSPECT;
  const accountBalance = isProspect ? 0
    : userAccount?.balance ?? EMPTY_DECIMAL_VALUE;
  const totalInterestCredited = isProspect ? 0
    : userAccount?.totalInterestCredited ?? EMPTY_DECIMAL_VALUE;
  const interestRate = isProspect ? CONSTANT_INTEREST_RATE
    : userAccount?.interestRate ?? EMPTY_DECIMAL_VALUE;

  const actionClass = styles['action-button'];
  const actionIconClass = styles['action-button__icon'];

  useEffect(() => {
    dispatch(fetchUserPrimaryAccount());
  }, [dispatch]);

  const resolveCurrencyValue = (value: String | number) =>
    typeof value === 'string' ? value : <CurrencyFormat amount={value as number} />;

  return (
    <Loader loaded={Boolean(userAccount) || isProspect}>
      <>
        <div className={styles.balance}>
          <div>
            <p className={styles['balance__header']}><Trans>DASHBOARD.ACCOUNT_BALANCE.HEADER</Trans></p>
            <h1 className={styles['balance__header-value']}>{resolveCurrencyValue(accountBalance)}</h1>
          </div>
          <div className={styles.interest}>
            <div className={styles['interest__earned']}>
              <Trans>DASHBOARD.ACCOUNT_BALANCE.EARNED_INTEREST</Trans>: {resolveCurrencyValue(totalInterestCredited)}
            </div>
            <div>
              {interestRate}% <Trans>DASHBOARD.ACCOUNT_BALANCE.PERCENTAGE</Trans>
            </div>
          </div>
        </div>
        <div className={styles.actions}>
          <Link to={DashboardRoutesPaths.SCAN_QR} className={actionClass}>
            <img src={ScanIcon} alt="scan" className={actionIconClass} />
            <Trans>DASHBOARD.ACCOUNT_BALANCE.SCAN</Trans>
          </Link>
          <Link to={DashboardRoutesPaths.DEPOSIT} className={actionClass}>
            <img src={DepositIcon} alt="deposit" className={actionIconClass} />
            <Trans>DASHBOARD.ACCOUNT_BALANCE.DEPOSIT</Trans>
          </Link>
          <Link to={DashboardRoutesPaths.WITHDRAW} className={actionClass}>
            <img src={WithdrawIcon} alt="withdraw" className={actionIconClass} />
            <Trans>DASHBOARD.ACCOUNT_BALANCE.WITHDRAW</Trans>
          </Link>
          <Link to={DashboardRoutesPaths.TRANSFER} className={actionClass}>
            <img src={TransferIcon} alt="transfer" className={actionIconClass} />
            <Trans>DASHBOARD.ACCOUNT_BALANCE.TRANSFER</Trans>
          </Link>
        </div>
      </>
    </Loader>
  );
};

export default AccountBalance;
