import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import UserData from '../../../shared/model/UserData';
import State from '../../../store/state';
import {ErrorHelper} from '../../../utils/error-helper';
import {checkIfCustomer, checkIfProspect} from '../../../utils/user-group-checks';
import PopupWithMemoryRouter from '../../shared/popups/PopupWithMemoryRouter';
import {GoalsRoutesPaths} from '../goals.routes-paths';
import {GoalsService} from '../goals.service';
import Goal from '../model/Goal';
import GoalDeleteConfirmationPopupContent from './GoalDeleteConfirmationPopupContent';
import GoalOverviewPopupContent from './GoalOverivewPopupContent';

interface GoalOverviewPopupComponentProps {
  goal: Goal;
  isPopupOpen: boolean;
  onPopupClose: () => void;
  onGoalDeleted: () => void;
}

interface GoalOverviewPopupStateProps {
  userData: UserData;
}

type GoalOverviewPopupProps = GoalOverviewPopupStateProps & GoalOverviewPopupComponentProps;

const GoalOverviewPopup = ({goal, isPopupOpen, onPopupClose, userData, onGoalDeleted}: GoalOverviewPopupProps) => {
  const {t} = useTranslation();
  const isProspect = checkIfProspect(userData);
  const isCustomer = checkIfCustomer(userData);
  const [isGoalDeleteInProgress, setIsGoalDeleteInProgress] = useState<boolean>(false);
  const [showGoalDeleteError, setShowGoalDeleteError] = React.useState(false);
  const [goalDeleteErrorMessage, setGoalDeleteErrorMessage] = React.useState('');

  const deleteGoal = () => {
    const deleteMethod = isProspect ? GoalsService.deleteProspectGoal :
      isCustomer ? GoalsService.deleteCustomerGoal : undefined;

    if (deleteMethod) {
      setIsGoalDeleteInProgress(true);
      deleteMethod(goal.id)
        .then(() => {
          setIsGoalDeleteInProgress(false);
          onGoalDeleted();
        })
        .catch(error => {
          setGoalDeleteErrorMessage(ErrorHelper.getErrorMessageTranslation(t, error));
          setIsGoalDeleteInProgress(false);
          setShowGoalDeleteError(true);
        });
      return;
    }

    setGoalDeleteErrorMessage('GOALS.OVERVIEW.NOT_SUPPORTED_FOR_USER_GROUP');
    setShowGoalDeleteError(true);
  };

  const routes = [
    {
      path: GoalsRoutesPaths.OVERVIEW,
      key: 'GOAL_OVERVIEW',
      render: () => <GoalOverviewPopupContent goal={goal} />
    },
    {
      path: GoalsRoutesPaths.DELETE_CONFIRMATION,
      key: 'GOAL_DELETE_CONFIRMATION',
      hideDefaultPopupCloseButton: true,
      render: () => <GoalDeleteConfirmationPopupContent onConfirm={deleteGoal}
                                                        isConfirmInProgress={isGoalDeleteInProgress}
                                                        showError={showGoalDeleteError}
                                                        errorMessage={goalDeleteErrorMessage}
                                                        handleErrorClose={() => setShowGoalDeleteError(false)} />
    }
  ];

  return (
    <PopupWithMemoryRouter open={isPopupOpen} handleClose={onPopupClose} routes={routes} />
  );
};

const mapStateToProps = (state: State, ownProps: GoalOverviewPopupComponentProps): GoalOverviewPopupStateProps => ({
  ...ownProps,
  userData: state.sessionData ?? ({} as UserData)
});

export default connect<GoalOverviewPopupStateProps, {}, GoalOverviewPopupComponentProps, State>(mapStateToProps)(
  GoalOverviewPopup
);
