import {isNil} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {getIsPasswordChangeRequired, getIsProspectApplicationSealed, getUserData} from '../../store/selectors';
import {checkIfProspect} from '../../utils/user-group-checks';
import Columns from '../shared/columns/Columns';
import MainContainer from '../shared/containers/MainContainer';
import Loader from '../shared/loader/Loader';
import Popup from '../shared/popups/Popup';
import AccountBalance from './account-balance/AccountBalance';
import OngoingVerificationPopup from './ongoing-verification-popup/OngoingVerificationPopup';
import {PasswordChangeRequiredPopupContent} from './password-change-required-popup/PasswordChangeRequiredPopup';
import RecentActivities from './recent-activites/RecentActivities';
import Services from './services/Services';
import servicesStyles from './services/Services.module.scss';
import VerifyAccountPopup from './verify-account-popup/VerifyAccountPopup';
import WelcomePopup from './welcome-popup/WelcomePopup';

interface DashboardProps {
  withBuyLoads?: boolean;
  withGovernment?: boolean;
}

export interface DashboardStateLocationProps {
  showWelcomePopup?: boolean;
  showVerifyAccountPopup?: boolean;
}

const Dashboard = ({withBuyLoads, withGovernment}: DashboardProps) => {
  const locationState = useLocation<DashboardStateLocationProps>().state;
  const [welcomePopupOpen, setWelcomePopupOpen] = useState<boolean>(!!locationState?.showWelcomePopup);
  const isProspectApplicationSealed = useSelector(getIsProspectApplicationSealed);
  const isPasswordChangeRequired = useSelector(getIsPasswordChangeRequired);
  const userData = useSelector(getUserData);
  const [ongoingVerificationPopupOpen, setOngoingVerificationPopupOpen] = useState<boolean>(
    !!isProspectApplicationSealed && !isPasswordChangeRequired
  );
  const [verifyAccountPopupOpen, setVerifyAccountPopupOpen] = useState<boolean>(
    !!locationState?.showVerifyAccountPopup && !isPasswordChangeRequired && !isProspectApplicationSealed
  );
  const isLoading = isNil(userData) || (checkIfProspect(userData) && isNil(isProspectApplicationSealed));

  useEffect(() => {
    setOngoingVerificationPopupOpen(!!isProspectApplicationSealed);
  }, [isProspectApplicationSealed]);

  return (
    <>
      <MainContainer>
        {
          isLoading
            ? <Loader />
            : <>
              <Columns mainColumn={<AccountBalance userData={userData} />} />
              <Columns mainColumn={<Services buyLoadPopupOpened={!!withBuyLoads}
                                             governmentPopupOpened={!!withGovernment} />}
                       mainColumnBoxClass={servicesStyles.box}
                       sideColumn={<RecentActivities />} />
            </>
        }
      </MainContainer>
      <WelcomePopup isOpen={welcomePopupOpen}
                    onExploreClick={() => setWelcomePopupOpen(false)}
                    userName={userData!.shortName} />
      <VerifyAccountPopup isOpen={verifyAccountPopupOpen}
                          onPopupClose={() => setVerifyAccountPopupOpen(false)} />
      <OngoingVerificationPopup isOpen={ongoingVerificationPopupOpen}
                                onPopupClose={() => setOngoingVerificationPopupOpen(false)} />
      <Popup open={!!isPasswordChangeRequired}>
        <PasswordChangeRequiredPopupContent />
      </Popup>
    </>
  );
};

export default Dashboard;
