import React from 'react';
import {Trans} from 'react-i18next';
import {ListParagraph, ListParagraphType} from '../shared/list-paragraph/ListParagraph';
import styles from './ProgramDetailsPopupContent.module.scss';

const ProgramDetailsPopupContent = () => (
  <div className={styles['main-container']}>
    <div className={styles['title']}><Trans>REFERRAL.PROGRAM_DETAILS.TITLE</Trans></div>
    <div className={styles['welcome-text']}><Trans>REFERRAL.PROGRAM_DETAILS.WELCOME_TEXT</Trans></div>
    <ListParagraph
      titleClassName={styles['list-title']}
      title={<Trans>REFERRAL.PROGRAM_DETAILS.RULES.TITLE</Trans>}
      listType={ListParagraphType.ORDERED}
      items={[
        <Trans>REFERRAL.PROGRAM_DETAILS.RULES.RULE_1</Trans>,
        <Trans>REFERRAL.PROGRAM_DETAILS.RULES.RULE_2</Trans>,
        <Trans>REFERRAL.PROGRAM_DETAILS.RULES.RULE_3</Trans>
      ]} />
    <ListParagraph
      titleClassName={styles['list-title']}
      title={<Trans>REFERRAL.PROGRAM_DETAILS.BULLETS.TITLE</Trans>}
      items={[
        <Trans>REFERRAL.PROGRAM_DETAILS.BULLETS.BULLET_1</Trans>,
        <Trans>REFERRAL.PROGRAM_DETAILS.BULLETS.BULLET_2</Trans>
      ]} />
  </div>
);

export default ProgramDetailsPopupContent;
