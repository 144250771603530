export interface VerifiedMobileNumber {
  brand: BrandType;
  carrier: CarrierType;
  countryCode: string;
  e164Format: string;
  input: string;
  localFormat: string;
}

export enum CarrierType {
  SMART = 'SMART',
  GLOBE = 'GLOBE',
  SUN = 'SUN'
}

export enum BrandType {
  TNT = 'TNT'
}