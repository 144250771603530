import {createMuiTheme} from '@material-ui/core/styles';
import {toNumber} from 'lodash';
import styles from './styles/Styles.module.scss';

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: toNumber(styles.breakpointSm),
      md: toNumber(styles.breakpointMd),
      lg: toNumber(styles.breakpointLg),
      xl: 1920
    }
  },
  palette: {
    primary: {
      contrastText: styles.white,
      main: styles.primaryColor
    }
  },
  props: {
    MuiButton: {
      color: 'primary',
      disableElevation: true,
      variant: 'contained'
    },
    MuiDialog: {
      maxWidth: false
    },
    MuiCheckbox: {
      color: 'primary'
    },
    MuiRadio: {
      color: 'primary'
    },
    MuiSvgIcon: {
      style: {
        width: styles.iconWidth,
        height: styles.iconHeight
      }
    },
    MuiTextField: {
      variant: 'outlined',
      InputProps: {
        style: {
          fontSize: styles.fontSizeLarge
        }
      },
      InputLabelProps: {
        style: {
          fontSize: styles.fontSizeLarge
        }
      }
    },
    MuiSelect: {
      variant: 'outlined'
    },
    MuiMenuItem: {
      style: {
        fontSize: styles.fontSizeLarge
      }
    },
    MuiInputLabel: {
      variant: 'outlined',
      style: {
        background: styles.white
      }
    }
  },
  typography: {
    fontFamily: styles.mainFontFamily,
    button: {
      fontSize: styles.fontSizeMedium,
      textTransform: 'none'
    }
  }
});
