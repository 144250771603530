import {Button} from '@material-ui/core';
import React from 'react';
import {Trans} from 'react-i18next';
import {useHistory} from 'react-router';
import {TelemedicineProduct} from '../model/TelemedicineProduct';
import styles from './PlanOverviewPopupContent.module.scss';
import {TelemedicineRoutesPaths} from '../telemedicine.routes-paths';

interface BuyPlanPopupContentProps {
  product: TelemedicineProduct
}

const PlanOverviewPopupContent = ({product}: BuyPlanPopupContentProps) => {
  const history = useHistory();

  return (
    <div className={styles['main-container']}>
      <div className={styles.title}>
        {product.name}
      </div>
      <div className={styles['iframe-container']}>
        <iframe src={product.htmlDescriptionUrl}
                width="100%"
                height="100%"
                sandbox="allow-same-origin"
                className={styles['iframe-container__iframe']}
                title={product.name}
                id="product-iframe">
        </iframe>
      </div>
      <div className={styles.footer}>
        <Button className={styles.submit}
                onClick={() => history.push(TelemedicineRoutesPaths.TELEMEDICINE_OPERATION_DETAILS)}>
          <Trans>SHARED.COMMON.SUBMIT</Trans>
        </Button>
      </div>
    </div>
  );
};

export default PlanOverviewPopupContent;
