import {Button} from '@material-ui/core';
import React from 'react';
import {Trans} from 'react-i18next';
import {useHistory} from 'react-router';
import HeroVerification from '../../../assets/images/hero-verification.svg';
import {VerifyAccountPopupRoutes} from './verify-account-popup.routes-paths';
import styles from './VerifyAccountPopupContent.module.scss';

interface VerifyAccountPopupContentProps {
  onPopupClose: () => void;
}

export const VerifyAccountPopupContent = ({onPopupClose}: VerifyAccountPopupContentProps) => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <img className={styles.image} src={HeroVerification} alt={'verify account'} />
      <div className={styles.title}><Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.TITLE</Trans></div>
      <div className={styles.description}><Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.DESCRIPTION</Trans></div>
      <Button onClick={() => history.push(VerifyAccountPopupRoutes.VERIFY_ACCOUNT_REMINDERS)}>
        <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.VERIFY_BUTTON_LABEL</Trans>
      </Button>
      <div className={styles.link} onClick={onPopupClose}>
        <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.CANCEL_VERIFICATION_LABEL</Trans>
      </div>
    </div>
  );
};
