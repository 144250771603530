import dayjs, {Dayjs} from 'dayjs';
import {OperationStatus} from '../../../../shared/model/operations/OperationStatus';
import {RecipientDetails} from '../../../../shared/model/Recipient';

export interface WithdrawalsTO<T_RESULT extends WithdrawalTO<any> = WithdrawalTO> {
  pageNo: number;
  resultCount: number;
  totalCount: number;
  result: T_RESULT[];
}

export interface Withdrawals extends WithdrawalsTO<Withdrawal> {
}

export interface WithdrawalTO<T_DATE = string> {
  amount: number;
  customerWithdrawal: boolean
  feeAmount: number;
  id: number;
  recipient: RecipientDetails;
  referenceNo: number;
  registeredOn: T_DATE;
  status: OperationStatus;
}

export interface Withdrawal extends WithdrawalTO<Dayjs> {
}

export const fromWithdrawalTO = (WithdrawalTO: WithdrawalTO): Withdrawal => ({
  ...WithdrawalTO,
  registeredOn: dayjs(WithdrawalTO.registeredOn)
});

export const fromWithdrawalsTO = (WithdrawalsTO: WithdrawalsTO): Withdrawals => ({
  ...WithdrawalsTO,
  result: WithdrawalsTO.result.map(WithdrawalTO => fromWithdrawalTO(
    WithdrawalTO))
});
