import {CancelTokenSource} from 'axios';
import {
  fromWithdrawalsTO,
  Withdrawal,
  Withdrawals,
  WithdrawalsTO
} from '../../components/withdraw/pending-wihdrawals/model/Withdrawal';
import {HttpServiceHelper} from '../../utils/http-service-helper';
import {CommandOutput} from '../model/command/CommandOutput';
import {Fee} from '../model/Fee';
import {OperationStatus} from '../model/operations/OperationStatus';
import {RecipientDetails} from '../model/Recipient';
import HttpService from './http.service';

const CARDLESS_WITHDRAWAL_URL = '/customer/addons/atm-cardless-withdrawals';
const CUSTOMER_CARDLESS_WITHDRAWAL_URL = `${CARDLESS_WITHDRAWAL_URL}/customer-withdrawal`;
const NON_CUSTOMER_CARDLESS_WITHDRAWAL_URL = `${CARDLESS_WITHDRAWAL_URL}/non-customer-withdrawal`;
const CALCULATE_FEES = '/calculate-fees';

export interface CardlessWithdrawalParams {
  amount: number;
  productId: number;
  recipient?: RecipientDetails;
}

export interface CardlessWithdrawalsSearchParams {
  customerId?: number;
  offset?: number;
  pageNo?: number;
  pageSize?: number;
  status?: OperationStatus
}

export const WithdrawalService = {

  getPendingCardlessWithdrawals: (params: CardlessWithdrawalsSearchParams,
                                  cancelTokenSource?: CancelTokenSource | null): Promise<Withdrawals> => {
    const limitedSearchParams = {
      ...params,
      pageSize: 50, // To make sure we don't get too many records
      status: OperationStatus.PENDING
    };

    return HttpService.get<WithdrawalsTO>(
      HttpServiceHelper.constructUrlWithSearchParams(CARDLESS_WITHDRAWAL_URL, limitedSearchParams),
      cancelTokenSource)
      .then(WithdrawalsTO => fromWithdrawalsTO(WithdrawalsTO))
      .catch(() => ({result: [] as Withdrawal[]}) as Withdrawals);
  },

  cancelPendingCardlessWithdrawal: (id: number): Promise<CommandOutput> =>
    HttpService.delete<CommandOutput>(
      `${CARDLESS_WITHDRAWAL_URL}/${id}`
    ),

  calculateCardlessWitdrawalFees: (isItCustomerWithdrawal: boolean,
                                   params: CardlessWithdrawalParams): Promise<Fee[]> =>
    HttpService.post<Fee[]>(
      getCarldessWithdrawalUrl(isItCustomerWithdrawal) + CALCULATE_FEES,
      params
    ),

  performCardlessWithdrawal: (isItCustomerWithdrawal: boolean,
                              params: CardlessWithdrawalParams): Promise<CommandOutput> =>
    HttpService.post<CommandOutput>(
      getCarldessWithdrawalUrl(isItCustomerWithdrawal),
      params
    )
};

const getCarldessWithdrawalUrl = (isItCustomerWithdrawal: boolean): string =>
  isItCustomerWithdrawal ? CUSTOMER_CARDLESS_WITHDRAWAL_URL : NON_CUSTOMER_CARDLESS_WITHDRAWAL_URL;
