import {Button} from '@material-ui/core';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import React, {ReactElement, useContext, useEffect} from 'react';
import {Trans} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {DynamicField} from '../../../../../shared/model/DynamicField';
import {PaymentProduct} from '../../../../../shared/model/PaymentProduct';
import {HttpError} from '../../../../../shared/services/http.service';
import PaymentService from '../../../../../shared/services/payment.service';
import {fetchUserPrimaryAccount} from '../../../../../store/actions';
import {getUserAccount} from '../../../../../store/selectors';
import {ErrorHelper} from '../../../../../utils/error-helper';
import {BillPaymentRoutesPaths} from '../../../../bill-payment/bill-payment.routes-paths';
import {BillPaymentOperationDetailsLocationParams} from '../../../../bill-payment/BillPaymentOperationDetails';
import {PaymentParams} from '../../../../bill-payment/model/Payment';
import DynamicFormField from '../../../dynamic-field/DynamicFormField';
import Loader from '../../../loader/Loader';
import {BillPaymentPopupContext, ContextFormState} from '../../popup/BillPaymentPopup';
import styles from './BillPaymentDetailsDynamicForm.module.scss';

interface BillPaymentDetailsDynamicParams {
  productDetails: PaymentProduct;
  onError: (error) => void;
}

export interface BillPaymentDetailsConfirmationOutput {
  operationId: number;
}

const BillPaymentDetailsDynamicForm = ({productDetails, onError}: BillPaymentDetailsDynamicParams) => {

  const history = useHistory();
  const sortedDynamicFields = (productDetails.dynamicFields as DynamicField[]).sort(
    (first, second) => sortDynamicFields(first, second)
  );
  const context = useContext(BillPaymentPopupContext);
  const {cancelToken, contextFormState} = context;
  const formInitState = generateInitialState(sortedDynamicFields, contextFormState);
  const userAccount = useSelector(getUserAccount);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserPrimaryAccount());
  }, [dispatch]);

  const submit = async (values: any, actions: FormikHelpers<any>) => {
    const params: PaymentParams = {
      amount: parseFloat(values.amount + productDetails.totalCharges),
      paymentGroup: productDetails.paymentGroup,
      productId: userAccount!.id,
      serviceCode: productDetails.code,
      dynamicFieldValues: {
        ...values
      }
    };

    cacheFormData(params);

    await PaymentService.validatePayment(params, cancelToken)
      .then(response => {
        actions.setSubmitting(false);
        history.push(
          BillPaymentRoutesPaths.OPERATION_DETAILS,
          {
            paymentParams: response.input,
            productDetails,
            amountWithoutFee: values.amount,
            fee: productDetails.totalCharges
          } as BillPaymentOperationDetailsLocationParams
        );
      })
      .catch(error => handleError(error));

    actions.setSubmitting(false);
  };

  const handleError = (error: HttpError<any>) => {
    if (ErrorHelper.isAbortOrSessionRestrictionError(error)) {
      return;
    }

    onError(error);
  };

  const cacheFormData = (paymentParams: PaymentParams): void => {
    context.contextFormState = {
      amount: paymentParams.amount,
      fields: paymentParams.dynamicFieldValues
    };
  };

  const renderForm = ({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                      }: FormikProps<any>): ReactElement => (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles['fields-container']}>
        {
          (sortedDynamicFields
              .map(dynamicField => (
                  <DynamicFormField key={dynamicField.code}
                                    value={values[dynamicField.code]}
                                    dynamicField={dynamicField}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur} />
                )
              )
          )
        }
      </div>
      <div className={styles.actions}>
        <Button type="submit" className={styles.submit} disabled={isSubmitting}>
          <Loader buttonSpinner loaded={!isSubmitting}>
            <Trans>SHARED.COMMON.SUBMIT</Trans>
          </Loader>
        </Button>
      </div>
    </form>
  );

  return (
    !userAccount
      ? <Loader />
      : <Formik
        initialValues={formInitState}
        onSubmit={submit}
        children={renderForm}
      />
  );
};

const sortDynamicFields = (first: DynamicField, second: DynamicField) => {
  if (first.orderNo < second.orderNo) {
    return -1;
  }
  if (first.orderNo > second.orderNo) {
    return 1;
  }
  return 0;
};

export default BillPaymentDetailsDynamicForm;

const generateInitialState = (dynamicFields: DynamicField[], contextFormState: ContextFormState): any => {
  const initState = {
    amount: contextFormState?.amount ?? ''
  };
  dynamicFields.forEach(dynamicField => {
    initState[dynamicField.code] = contextFormState?.fields?.[dynamicField.code] ?? '';
  });

  return initState;
};
