import React from 'react';
import styles from './Loader.module.scss';

interface LoaderProps {
  loaded?: boolean;
  children?: React.ReactElement;
  buttonSpinner?: boolean;
  textColorVariant?: boolean;
  primaryColorVariant?: boolean;
  className?: string;
}

export default function Loader({children, loaded = false, buttonSpinner = false, className, textColorVariant = false, primaryColorVariant = false}: LoaderProps) {
  // content loader has additional margin as default
  const contentSpinnerAdditionalClass = className ? className : styles['content-spinner--margin'];
  const contentSpinnerClass = `${styles['content-spinner']} ${contentSpinnerAdditionalClass}`;
  const fragmentClass = `${styles.fragment}  ${
      primaryColorVariant ? styles['fragment--primary'] : textColorVariant ? styles['fragment--text-color'] : ''
    }`;

  // Important!
  // https://stackoverflow.com/questions/39652686/pass-react-component-as-props
  // Direct children property (this.props.children) passes instantiated component, even if it's not rendered - it could
  // result in unchecked children propTypes and warning in the console. No idea how to solve it and to keep Loader
  // compatible with current usage
  return loaded && children ? children : (
    <div className={buttonSpinner ? styles['button-spinner'] : contentSpinnerClass}>
      {
        // generate 4 or 12 graphic spinner parts
        [...Array(buttonSpinner ? 4 : 12)].map(
          (el, index) =>
            <div key={index} className={fragmentClass} />
        )
      }
    </div>
  );
}
