import {Button, TextField} from '@material-ui/core';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import {Dayjs} from 'dayjs';
import {Formik, FormikHelpers} from 'formik';
import React, {ReactElement} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {API_DATE_FORMAT, DATE_PICKER_FORMAT} from '../../../constants/date';
import {ErrorHelper} from '../../../utils/error-helper';
import {StringHelper} from '../../../utils/string-helper';
import {AlertSnackbar} from '../../shared/alert-snackbar/AlertSnackbar';
import PhoneTextField from '../../shared/fields/PhoneTextField';
import Loader from '../../shared/loader/Loader';
import {ResetPasswordRoutesPaths} from '../reset-password.routes-paths';
import {ResetPasswordService} from '../reset-password.service';
import styles from './ResetPasswordPersonalDataForm.module.scss';
import {ResetPasswordSecurityCheckLocationProps} from '../security-check/ResetPasswordSecurityCheck';

interface FormFields {
  mobileNo: string;
  firstName: string;
  lastName: string;
  birthDate: Dayjs | null
}

export default function ResetPasswordPersonalDataForm() {
  const history = useHistory();
  const {t} = useTranslation();
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  function submit(values: FormFields, actions: FormikHelpers<FormFields>) {
    const {mobileNo, firstName, lastName, birthDate} = values;
    const initiatePasswordResetParams = {
      birthDate: birthDate ? birthDate.toString() : '',
      mobileNo: StringHelper.removeWhitespaces(mobileNo),
      firstName,
      lastName
    };

    ResetPasswordService.initiatePasswordReset(initiatePasswordResetParams)
      .then(output =>
        history.push(ResetPasswordRoutesPaths.RESET_PASSWORD_SECURITY_CHECK, {
          allowedConfirmationOptions: output.allowedConfirmationOptions,
          resetToken: output.resetToken
          } as ResetPasswordSecurityCheckLocationProps
        )
      )
      .catch(error => {
        setErrorMessage(ErrorHelper.getErrorMessageTranslation(t, error));
        setShowError(true);
        actions.setSubmitting(false);
      });
  }

  const PersonalDataForm = ({values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue}): ReactElement => {
    const handleBirthDateChange = (date: Dayjs | null) => {
      const newValue = !!date ? date.format(API_DATE_FORMAT) : '';
      setFieldValue('birthDate', newValue);
    };

    return (
      <form onSubmit={handleSubmit} className={styles.form}>
        {/* TODO fix 'Please fill this field' message missing for phone field */}
        <PhoneTextField
          required
          className={styles.input}
          label={<Trans>SHARED.COMMON.MOBILE_NO</Trans>}
          name="mobileNo"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.mobileNo}
        />
        <TextField
          required
          className={styles.input}
          label={<Trans>SHARED.COMMON.FIRST_NAME</Trans>}
          type="text"
          name="firstName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.firstName}
        />
        <TextField
          required
          className={styles.input}
          label={<Trans>SHARED.COMMON.LAST_NAME</Trans>}
          type="text"
          name="lastName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.lastName}
        />
        <MuiPickersUtilsProvider utils={DayJsUtils}>
          <KeyboardDatePicker
            required
            label={<Trans>SHARED.COMMON.DATE_OF_BIRTH</Trans>}
            disableToolbar
            variant="inline"
            format={DATE_PICKER_FORMAT}
            name="birthDate"
            value={values.birthDate}
            onChange={handleBirthDateChange}
            KeyboardButtonProps={{'aria-label': 'change date'}}
            className={styles.input}
            onBlur={handleBlur}
            PopoverProps={{
              classes: {
                root: styles['date-picker']
              }
            }}
          />
        </MuiPickersUtilsProvider>
        <Button type="submit" className={styles.button} disabled={isSubmitting}>
          <Loader buttonSpinner loaded={!isSubmitting}>
            <Trans>SHARED.COMMON.SUBMIT</Trans>
          </Loader>
        </Button>
        <div className={styles.error}>
          {errors.server && <div className={styles['error__text']}>{errors.server}</div>}
        </div>
      </form>
    );
  };

  return (
    <>
      <h1>
        <Trans>RESET_PASSWORD.PERSONAL_DATA.TITLE</Trans>
      </h1>
      <AlertSnackbar handleClose={() => setShowError(false)} open={showError} message={errorMessage} />
      <p className={styles.description}>
        <Trans>RESET_PASSWORD.PERSONAL_DATA.DESCRIPTION</Trans>
      </p>
      <div className={styles[`form-wrapper`]}>
        <Formik
          <FormFields>
          initialValues={{mobileNo: '', firstName: '', lastName: '', birthDate: null}}
          onSubmit={submit}
          children={PersonalDataForm}
        />
      </div>
    </>
  );
}
