import {TextField} from '@material-ui/core';
import {TextFieldProps} from '@material-ui/core/TextField/TextField';
import React from 'react';
import InputMask from 'react-input-mask';

const MASK_CHAR = '';
// 9 means 0-9 number in react-input-mask default mask
export const MOBILE_NUMBER_MASK = `+63 999 999 9999`;

type PhoneTextFieldProps = TextFieldProps & {
  mask?: string;
}

export default function PhoneTextField(props: PhoneTextFieldProps) {
  return (
    // InputMask passes down every prop except the following ones:
    // onChange, onPaste, onMouseDown, onFocus, onBlur, value, disabled, readOnly.
    <InputMask {...props} mask={props.mask ? props.mask : MOBILE_NUMBER_MASK} maskChar={MASK_CHAR}>
      {(passedDownProps) => <TextField {...passedDownProps} disabled={props.disabled} type="tel" />}
    </InputMask>
  );
}
