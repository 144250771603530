import React, {useState} from 'react';
import {Trans} from 'react-i18next';
import ArrowRight from '../../assets/images/icons/arrow-right.svg';
import Columns from '../shared/columns/Columns';
import MainContainer from '../shared/containers/MainContainer';
import CardlessAtmWithdrawalPopup from './cardless-atm-withdrawal/CardlessAtmWithdrawalPopup';
import InternalTransferPopup from './internal/InternalTransferPopup';
import styles from './Transfer.module.scss';

enum TransferType {
  CARDLESS_ATM,
  INTERNAL
}

interface PopupState {
  isOpen: boolean;
  transferType?: TransferType;
}

export default function Transfer() {

  const [popupState, setPopupState] = useState<PopupState>({isOpen: false});

  const transferOptions = (options: {title: string, transferType: TransferType}[]) => options.map((option, idx) => (
    <div className={styles.option} key={idx}>
      <div className={styles.container}
           onClick={() => setPopupState({isOpen: true, transferType: option.transferType})}>
        <Trans>{option.title}</Trans>
        <img src={ArrowRight} alt="operation-details" />
      </div>
    </div>
  ));

  return (
    <>
      <MainContainer>
        <Columns
          heading="TRANSFER.HEADER"
          mainColumn={
            <>
              <div className={styles.title}>
                <Trans>TRANSFER.TITLE</Trans>
              </div>
              {transferOptions([
                {title: 'TRANSFER.INNER.LABEL', transferType: TransferType.INTERNAL},
                {title: 'CARDLESS_WITHDRAWAL.LABEL', transferType: TransferType.CARDLESS_ATM}
              ])}
            </>
          }
        />
      </MainContainer>
      <CardlessAtmWithdrawalPopup isPopupOpen={popupState && popupState.transferType === TransferType.CARDLESS_ATM}
                                  onPopupClose={() => setPopupState({isOpen: false})} />
      <InternalTransferPopup isPopupOpen={popupState && popupState.transferType === TransferType.INTERNAL}
                             onPopupClose={() => setPopupState({isOpen: false})} />
    </>
  );
}
