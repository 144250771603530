import {JumioRoutesPaths} from '../../components/verification/jumio/jumio.routes-paths';
import {RegistrationRoutesPaths} from '../../components/registration/registration.routes-paths';
import {DashboardRoutesPaths} from '../routes-paths/dashboard.routes-paths';
import {RoutesPaths} from '../routes-paths/routes-paths';

export interface BackLink {
  link: string,
  label: string
}

export const mapBackLinkToRoute = (route: string): BackLink | undefined => {
  switch (route) {
    case DashboardRoutesPaths.PENDING_WITHDRAWALS:
      return {
        link: DashboardRoutesPaths.WITHDRAW,
        label: 'SHARED.BACK_LINKS.BACK'
      };
    case DashboardRoutesPaths.DEPOSIT:
    case DashboardRoutesPaths.PAY_BILLS:
    case DashboardRoutesPaths.TELEMEDICINE:
    case DashboardRoutesPaths.TRANSFER:
    case DashboardRoutesPaths.REFERRAL:
    case DashboardRoutesPaths.SETTINGS:
    case DashboardRoutesPaths.WITHDRAW:
    case DashboardRoutesPaths.VERIFICATION:
    case JumioRoutesPaths.JUMIO:
      return {
        link: RoutesPaths.DASHBOARD,
        label: 'SHARED.BACK_LINKS.BACK_HOME'
      };
    case RegistrationRoutesPaths.OTP:
    case RegistrationRoutesPaths.SET_PASSWORD:
    case RoutesPaths.RESET_PASSWORD:
      return {
        link: RoutesPaths.LOGIN,
        label: 'SHARED.BACK_LINKS.BACK_TO_LOGIN'
      };
  }
};
