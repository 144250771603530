import React from 'react';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {RoutesPaths} from '../../../routes/routes-paths/routes-paths';
import styles from './EmptyStateWithBackLink.module.scss';

interface EmptyStateWithBackLinkProps {
  emptyMessage: string;
}

export default function EmptyStateWithBackLink({emptyMessage}: EmptyStateWithBackLinkProps) {
  return (
    <div className={styles['empty-state']}>
      { emptyMessage }
      <Link to={RoutesPaths.DASHBOARD} className={styles['back-link']}>
        <Trans>SHARED.BACK_LINKS.BACK_HOME</Trans>
      </Link>
    </div>
  )
}
