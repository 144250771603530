import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {OperationGroup, PartnerMerchant} from '../../shared/model/operations/partners/PartnerMerchant';
import {PartnerOperationsService} from '../../shared/services/partner-operations.service';
import {ErrorHelper} from '../../utils/error-helper';
import {onlyPartnersWithEnabledOperation} from '../../utils/filter-partners';
import {cleanUp} from '../../utils/handle-abort-controller-clean-up';
import Columns from '../shared/columns/Columns';
import MainContainer from '../shared/containers/MainContainer';
import PartnerMerchants from '../shared/partners/PartnerMerchants';
import PopupWithMemoryRouter from '../shared/popups/PopupWithMemoryRouter';
import {ReferenceNumberCancelConfirmation} from '../shared/reference-number/ReferenceNumberCancelConfirmation';
import DepositAmount from './deposit-amount/DepositAmount';
import {DepositRoutesPaths} from './deposit.routes-paths';
import {DepositReferenceNumber} from './reference-number/DepositReferenceNumber';

export default function Deposit() {
  const [partners, setPartners] = useState<PartnerMerchant[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<PartnerMerchant>();

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    PartnerOperationsService.getPartnerMerchants(cancelTokenSource)
      .then(allPartners => {
        const filteredPartners = allPartners.filter(onlyPartnersWithEnabledOperation(OperationGroup.DEPOSIT));
        setPartners(filteredPartners);
        setIsLoaded(true);
      })
      .catch(error => {
        if (ErrorHelper.isAbortOrSessionRestrictionError(error)) {
          return;
        }
        setPartners([] as PartnerMerchant[]);
        setIsLoaded(true);
      });

    return cleanUp(cancelTokenSource);
  }, []);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const openPopup = (partner: PartnerMerchant) => {
    setSelectedPartner(partner);
    setIsPopupOpen(true);
  };

  const routes = [
    {
      path: DepositRoutesPaths.PARTNER_DEPOSIT_AMOUNT,
      key: 'PARTNER_DEPOSIT_AMOUNT',
      render: () => <DepositAmount partnerMerchant={selectedPartner!} />
    },
    {
      path: DepositRoutesPaths.PARTNER_DEPOSIT_REFERENCE_NUMBER,
      key: 'PARTNER_DEPOSIT_REFERENCE_NUMBER',
      hideDefaultPopupCloseButton: true,
      render: props => <DepositReferenceNumber handlePopupClose={closePopup} {...props} />
    },
    {
      path: DepositRoutesPaths.PARTNER_DEPOSIT_REFERENCE_NUMBER_CANCEL,
      key: 'PARTNER_DEPOSIT_REFERENCE_NUMBER_CANCEL',
      hideDefaultPopupCloseButton: true,
      component: ReferenceNumberCancelConfirmation
    }
  ];

  return (
    <MainContainer>
      <Columns heading="DEPOSIT.HEADER"
               mainColumn={
                 <>
                   <PartnerMerchants isLoaded={isLoaded} partners={partners} onPartnerClick={openPopup} />
                   <PopupWithMemoryRouter open={isPopupOpen} handleClose={closePopup} routes={routes} />
                 </>
               }
      />
    </MainContainer>
  );
}
