import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router';
import {PartnerMerchant} from '../../../shared/model/operations/partners/PartnerMerchant';
import {PartnerOperationsService} from '../../../shared/services/partner-operations.service';
import {ErrorHelper} from '../../../utils/error-helper';
import {AlertSnackbar} from '../../shared/alert-snackbar/AlertSnackbar';
import PopupCloseButton from '../../shared/popups/PopupCloseButton';
import {ReferenceNumberCancelConfirmationLocationProps} from '../../shared/reference-number/ReferenceNumberCancelConfirmation';
import ReferenceNumberHeaderPartnerIcon from '../../shared/reference-number/ReferenceNumberHeaderPartnerIcon';
import {ReferenceNumberOperationDetails} from '../../shared/reference-number/ReferenceNumberOperationDetails';
import {ReferenceNumber} from '../../shared/reference-number/ReferenceNumber';
import {OperationStatus} from '../../../shared/model/operations/OperationStatus';
import {DepositReferenceNumberHowTo} from './how-to/DepositReferenceNumberHowTo';
import {DepositRoutesPaths} from '../deposit.routes-paths';

export interface DepositReferenceNumberLocationProps {
  amount: number;
  operationId: number;
  partnerMerchant: PartnerMerchant;
  referenceNumber: number;
  validUntil: string;
  fee: number;
}

interface DepositReferenceNumberProps {
  handlePopupClose: () => void;
}

export function DepositReferenceNumber ({handlePopupClose}: DepositReferenceNumberProps) {
  const history = useHistory();
  const {t} = useTranslation();
  const locationState = useLocation<DepositReferenceNumberLocationProps>().state;
  const {amount, fee, operationId, partnerMerchant, referenceNumber, validUntil} = locationState;
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isEditLoading, setIsEditLoading] = React.useState(false);
  const [isClosePopupInProgress, setIsClosePopupInProgress] = React.useState(false);

  const closePopup = () => {
    setIsClosePopupInProgress(true);
    PartnerOperationsService.getPartnerOperationStatus(operationId)
      .then((status) => {
        setIsClosePopupInProgress(false);

        if (status !== OperationStatus.PENDING) {
          handlePopupClose();
          return;
        }

        history.push(DepositRoutesPaths.PARTNER_DEPOSIT_REFERENCE_NUMBER_CANCEL, {
          onConfirm: () => PartnerOperationsService.cancelPartnerDeposit(operationId),
          handlePopupClose
        } as ReferenceNumberCancelConfirmationLocationProps)
      })
      .catch(error => handleError(error));
  }

  const editAmount = () => {
    setIsEditLoading(true);
    PartnerOperationsService.cancelPartnerDeposit(operationId)
      .then(() => {
        setIsEditLoading(false);
        history.goBack();
      })
      .catch(error => handleError(error));
  }

  const handleError = error => {
    setErrorMessage(ErrorHelper.getErrorMessageTranslation(t, error));
    setShowError(true);
    setIsClosePopupInProgress(false);
    setIsEditLoading(false);
  };
  const OperationDetails = (
    <>
      <PopupCloseButton handleClose={closePopup} isLoaded={!isClosePopupInProgress} />
      <ReferenceNumberOperationDetails amount={amount}
                                       editAmount={editAmount}
                                       fee={fee}
                                       isEditLoading={isEditLoading}
                                       validUntil={validUntil} />
    </>
  );

  const ReferenceNumberAlertSnackbar = (
    <AlertSnackbar open={showError} message={errorMessage} handleClose={() => setShowError(false)} />
  );

  return (
    <ReferenceNumber referenceNumber={referenceNumber}
                     alertSnackbar={ReferenceNumberAlertSnackbar}
                     leftColumn={OperationDetails}
                     rightColumn={<DepositReferenceNumberHowTo />}
                     icon={<ReferenceNumberHeaderPartnerIcon partnerMerchant={partnerMerchant} />} />
  );
}
