import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import {DashboardRoutesPaths} from '../../../routes/routes-paths/dashboard.routes-paths';
import {RoutesPaths} from '../../../routes/routes-paths/routes-paths';
import UserData from '../../../shared/model/UserData';
import {getIsProspectApplicationSealed, getUserData} from '../../../store/selectors';
import {JSXHelper} from '../../../utils/jsx-helper';
import {checkIfProspect} from '../../../utils/user-group-checks';
import {PageNotFound} from '../../page-not-found/PageNotFound';
import {KeyedRouteProps} from './model/Routes';

interface RenderRoutesProps {
  routes: KeyedRouteProps[];
}

const RenderRoutes = ({routes}: RenderRoutesProps) => (
  <Switch>
    {
      routes.map(route => <RouteToRender key={route.key} {...route} />)
    }
    <Route component={PageNotFound} />
  </Switch>
);

const RouteToRender = (route: KeyedRouteProps) => {
  const userData = useSelector(getUserData);
  const isProspectApplicationSealed = useSelector(getIsProspectApplicationSealed);
  const isProspect = checkIfProspect(userData ?? {} as UserData);

  if (route.isOnlyForCustomer && isProspect && route.path !== RoutesPaths.DASHBOARD) {
    return <Redirect to={{pathname: RoutesPaths.DASHBOARD, state: {showVerifyAccountPopup: true}}} />;
  }

  const isProspectWithSealedApplication = isProspect && isProspectApplicationSealed;

  if (((isProspectWithSealedApplication) || !isProspect) && route.path === DashboardRoutesPaths.VERIFICATION) {
    return <Redirect to={{pathname: RoutesPaths.DASHBOARD}} />;
  }

  return <Route
    path={route.path}
    exact={route.exact}
    render={
      route.render ?? (props => JSXHelper.renderComponentFromType(route.component, props))
    } />;
};

export default RenderRoutes;
