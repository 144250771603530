import React from 'react';
import {exactRoutes} from '../../../utils/exact-routes';
import {JumioRoutesPaths} from './jumio.routes-paths';
import JumioVerificationResult from './JumioVerificationResult';
import {JumioVerificationInitializationStatus} from './model/JumioVerificationInitializationStatus';

export const JUMIO_ROUTES = exactRoutes([
  {
    path: JumioRoutesPaths.SUCCESS,
    key: 'VERIFICATION_STEPS_JUMIO_SUCCESS',
    render: () => <JumioVerificationResult status={JumioVerificationInitializationStatus.SUCCESS} />
  },
  {
    path: JumioRoutesPaths.ERROR,
    key: 'VERIFICATION_STEPS_JUMIO_ERROR',
    render: () => <JumioVerificationResult status={JumioVerificationInitializationStatus.ERROR} />
  }
]);
