import {useReducer} from 'react';
import {PaymentProduct} from '../../../../../shared/model/PaymentProduct';

export enum ProductStateActionType {
  LOADING = 'LOADING',
  LOADED = 'LOADED'
}

interface ProductsStateAction {
  type: ProductStateActionType,
  products?: PaymentProduct[];
}

export interface ProductsState {
  isLoaded: boolean;
  isLoading: boolean;
  products: PaymentProduct[];
}

const initialState: ProductsState = {
  isLoaded: false,
  isLoading: false,
  products: []
};

const reducer = (state: ProductsState, action: ProductsStateAction): ProductsState => {
  switch (action.type) {
    case ProductStateActionType.LOADING:
      return {
        ...state,
        isLoading: true
      };
    case ProductStateActionType.LOADED:
      return {
        isLoading: false,
        isLoaded: true,
        products: action.products ?? []
      };
    default:
      throw new Error();
  }
};

export function useProductState(): [ProductsState, (action: ProductsStateAction) => void] {
  const [productsState, dispatch] = useReducer(reducer, initialState);

  const changeState = (action: ProductsStateAction) => {
    dispatch(action);
  };

  return [productsState, changeState];
}
