import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {initAnonymousSession} from '../../store/actions';
import {exactRoutes} from '../../utils/exact-routes';
import SingleBoxContainer from '../shared/containers/SingleBoxContainer';
import Otp from '../shared/otp/Otp';
import MemoryRouterWithRedirect from '../shared/routes/MemoryRouterWithRedirect';
import WelcomeScreenWrapper from '../shared/containers/WelcomeScreenWrapper';
import {RegistrationRoutesPaths} from './registration.routes-paths';
import RegistrationSetPassword from './setPassword/RegistrationSetPassword';
import {RegistrationUserData} from './user-data/RegistrationUserData';

interface RegistrationProps {
  startAnonymousSession: () => void
}

const Registration = ({startAnonymousSession}: RegistrationProps) => {
  useEffect(() => {
    startAnonymousSession();
  }, [startAnonymousSession]);

  const routes = exactRoutes([
    {
      path: RegistrationRoutesPaths.PROSPECT_DATA,
      key: 'REGISTRATION_USER_DATA',
      render: () => (
        <WelcomeScreenWrapper>
          <RegistrationUserData />
        </WelcomeScreenWrapper>
      )
    },
    {
      path: RegistrationRoutesPaths.OTP,
      key: 'REGISTRATION_OTP',
      render: props => (
        <SingleBoxContainer>
          <Otp singleBoxStylesVariant {...props} />
        </SingleBoxContainer>
      )
    },
    {
      path: RegistrationRoutesPaths.SET_PASSWORD,
      key: 'REGISTRATION_SET_PASSWORD',
      render: () => (
        <SingleBoxContainer>
          <RegistrationSetPassword />
        </SingleBoxContainer>
      )
    }
  ]);

  return <MemoryRouterWithRedirect routes={routes} />;
};

const mapDispatchToProps = dispatch => ({
  startAnonymousSession: () => dispatch(initAnonymousSession())
});

export default connect(null, mapDispatchToProps)(Registration);

