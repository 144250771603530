import {Checkbox, FormControlLabel} from '@material-ui/core';
import {Formik, FormikProps} from 'formik';
import React, {ReactElement, useContext} from 'react';
import {Trans} from 'react-i18next';
import {VerificationService} from '../../../shared/services/verification.service';
import {VerificationStep} from '../steps/VerificationStep';
import {VerificationStepContent} from '../steps/VerificationStepContent';
import {VerificationStepFooter} from '../steps/VerificationStepFooter';
import {VerificationContext} from '../Verification';
import styles from './VerificationDataAndPersonalization.module.scss';

interface FormFields {
  creditScoringAgreement?: boolean;
  profileSharingAgreement?: boolean;
  profilingAgreement?: boolean;
  promosAndRewardsAgreement?: boolean;
  thirdPartyPromosAgreement?: boolean;
}

export const VerificationDataAndPersonalization = () => {
  const {application, setApplication} = useContext(VerificationContext);

  const submit = (values: FormFields) => {
    const applicationData = {
      ...application,
      ...values
    };

    return VerificationService.updateProspectApplication(application.id, applicationData)
      .then(() => setApplication(applicationData));
  };

  const RouteTrans = ({children}) => <Trans>{`VERIFICATION.DATA_AND_PERSONALIZATION.${children}`}</Trans>;

  const DataAndPersonalizationForm = ({values, handleChange, submitForm}: FormikProps<FormFields>): ReactElement =>
    (
      <form>
        <VerificationStepContent description={<RouteTrans>DESCRIPTION</RouteTrans>}>
          <FormControlLabel
            label={<RouteTrans>PROMOS_AND_REWARDS</RouteTrans>}
            control={
              <Checkbox checked={values.promosAndRewardsAgreement} onChange={handleChange} name="promosAndRewardsAgreement" />
            }
          />
          <p className={styles.description}>
            <RouteTrans>PROMOS_AND_REWARDS_DESCRIPTION</RouteTrans>
          </p>

          <FormControlLabel
            label={<RouteTrans>THIRD_PARTY_PROMOS</RouteTrans>}
            control={
              <Checkbox checked={values.thirdPartyPromosAgreement} onChange={handleChange} name="thirdPartyPromosAgreement" />
            } />
          <p className={styles.description}>
            <RouteTrans>THIRD_PARTY_PROMOS_DESCRIPTION</RouteTrans>
          </p>

          <FormControlLabel
            label={<RouteTrans>PROFILING</RouteTrans>}
            control={
              <Checkbox checked={values.profilingAgreement} onChange={handleChange} name="profilingAgreement" />
            } />
          <p className={styles.description}>
            <RouteTrans>DESCRIPTION</RouteTrans>
          </p>

          <FormControlLabel
            label={<RouteTrans>PROFILE_SHARING</RouteTrans>}
            control={
              <Checkbox checked={values.profileSharingAgreement} onChange={handleChange} name="profileSharingAgreement" />
            } />
          <p className={styles.description}>
            <RouteTrans>PROFILE_SHARING_DESCRIPTION</RouteTrans>
          </p>

          <FormControlLabel
            label={<RouteTrans>CREDIT_SCORING</RouteTrans>}
            control={
              <Checkbox checked={values.creditScoringAgreement} onChange={handleChange} name="creditScoringAgreement" />
            } />
          <p className={styles.description}>
            <RouteTrans>CREDIT_SCORING_DESCRIPTION</RouteTrans>
          </p>
        </VerificationStepContent>
        <VerificationStepFooter submit={submitForm} />
      </form>
    );

  return (
    <VerificationStep>
      <Formik
        <FormFields>
        onSubmit={submit}
        initialValues={{
          ...application
        }}
        children={DataAndPersonalizationForm}
      />
    </VerificationStep>
  );
};
