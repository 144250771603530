import React, {useContext} from 'react';
import {Trans} from 'react-i18next';
import {connect} from 'react-redux';
import {useLocation} from 'react-router';
import {PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL} from '../../../constants/values';
import {RoutesPaths} from '../../../routes/routes-paths/routes-paths';
import {PublicSystemProperties} from '../../../shared/model/SystemProperties';
import {login} from '../../../store/actions';
import State from '../../../store/state';
import encrypt from '../../../utils/credentials-encryption';
import {DashboardStateLocationProps} from '../../dashboard/Dashboard';
import ExternalLink from '../../shared/external-link/ExternalLink';
import SetPasswordForm from '../../shared/set-password-form/SetPasswordForm';
import {RegisterService} from '../register.service';
import styles from './RegistrationSetPassword.module.scss';
import { MainRouterContext } from '../../../App';

interface RegistrationSetPasswordStateProps {
  systemProperties: PublicSystemProperties
}

interface RegistrationSetPasswordDispatchProps {
  login: (loginValue: string, passwordValue: string) => any,
}

type RegistrationSetPasswordProps = RegistrationSetPasswordStateProps & RegistrationSetPasswordDispatchProps

export interface RegistrationSetPasswordLocationProps {
  alias: string,
  applicationId: number
}

const RegistrationSetPassword = ({systemProperties, login}: RegistrationSetPasswordProps) => {
  const {alias, applicationId} = useLocation<RegistrationSetPasswordLocationProps>().state;
  const mainRouterContext = useContext(MainRouterContext);

  const activateProspect = (password: string) => {
    const secret = encrypt(password, systemProperties.credentialsEncryptionRsaPublicKey);
    return RegisterService.activateProspectAlias({secret, applicationId})
      .then(() => {
        loginAndGoToDashboard(secret);
      });
  };

  const loginAndGoToDashboard = (secret: string) => {
    const encryptedAlias = encrypt(alias, systemProperties.credentialsEncryptionRsaPublicKey);
    login(encryptedAlias, secret).then(() => {
      mainRouterContext.globalHistory.push(RoutesPaths.DASHBOARD, {
        showWelcomePopup: true
      } as DashboardStateLocationProps);
    });
  };

  return (
    <div className={styles.container}>
      <SetPasswordForm onSubmit={activateProspect} />
      <div className={styles.agreement}>
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <Trans components={[
          <ExternalLink href={TERMS_AND_CONDITIONS_URL} />,
          <ExternalLink href={PRIVACY_POLICY_URL} />
        ]}>
          REGISTER.AGREEMENT
        </Trans>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  systemProperties: state.public.systemProperties
});

const mapDispatchToProps = dispatch => ({
  login: (loginValue: string, passwordValue: string) => dispatch(login(loginValue, passwordValue))
});

export default connect<RegistrationSetPasswordStateProps, RegistrationSetPasswordDispatchProps, {}, State>(
  mapStateToProps,
  mapDispatchToProps)(
  RegistrationSetPassword);