import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {useHistory} from 'react-router-dom';
import {PaymentProduct} from '../../../../../shared/model/PaymentProduct';
import {HttpError} from '../../../../../shared/services/http.service';
import PaymentService from '../../../../../shared/services/payment.service';
import {fetchUserPrimaryAccount} from '../../../../../store/actions';
import {getUserAccount} from '../../../../../store/selectors';
import {ErrorHelper} from '../../../../../utils/error-helper';
import {PaymentParams} from '../../../../bill-payment/model/Payment';
import {AlertSnackbar} from '../../../../shared/alert-snackbar/AlertSnackbar';
import Loader from '../../../../shared/loader/Loader';
import OperationConfirmationPopupContent
  from '../../../../shared/operation/operation-confirmation-popup-content/OperationConfirmationPopupContent';
import OperationDetail, {Width} from '../../../../shared/operation/operation-detail/OperationDetail';
import {BuyLoadServicePopupContext} from '../../Services';
import {BuyLoadRoutesPaths} from '../buy-load.routes-paths';
import styles from './BuyLoadProductConfirm.module.scss';

export interface BuyLoadProductConfirmLocationProps {
  mobileNo: string;
  mobileNoLocalFormat: string;
  amount: number,
  product: PaymentProduct,
  httpError?: HttpError<any>
}

export interface BuyLoadConfirmationOutput {
  operationId: number;
}

function BuyLoadProductConfirm() {
  const locationState = useLocation<BuyLoadProductConfirmLocationProps>().state;
  const {mobileNo, mobileNoLocalFormat, amount, product, httpError} = locationState;
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showError, setShowError] = useState(!!httpError);
  const [error, setError] = useState<HttpError<any> | undefined>(httpError);
  const {t} = useTranslation();
  const cancelToken = useContext(BuyLoadServicePopupContext).cancelToken;
  const userAccount = useSelector(getUserAccount);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserPrimaryAccount());
  }, [dispatch]);

  const proceed = () => {
    setIsSubmitting(true);
    const params: PaymentParams = {
      amount,
      paymentGroup: product.paymentGroup,
      productId: userAccount!.id,
      serviceCode: product.code,
      dynamicFieldValues: {
        mobileNo: mobileNoLocalFormat
      }
    };

    PaymentService.validatePayment(params, cancelToken)
      .then(() =>
        PaymentService.proceedPayment(params, cancelToken)
          .then(commandOutput => {
            setIsSubmitting(false);
            history.replace(BuyLoadRoutesPaths.OTP, {
              ...commandOutput,
              onSuccess: redirectToSuccess,
              onError: handleOtpError
            });
          })
          .catch(error => handleError(error))
      )
      .catch(error => handleError(error));
  };

  const handleError = (error: HttpError<any>) => {
    if (ErrorHelper.isAbortOrSessionRestrictionError(error)) {
      return;
    }

    setError(error);
    setShowError(true);
  };

  const redirectToSuccess = (confirmationOutput: BuyLoadConfirmationOutput) => {
    history.push(BuyLoadRoutesPaths.SUCCESS, {
      ...confirmationOutput,
      sourceProductId: userAccount!.id
    });
  };

  const handleOtpError = (error: HttpError<any>) => {
    history.replace(BuyLoadRoutesPaths.CONFIRM, {
      ...locationState,
      httpError: error
    } as BuyLoadProductConfirmLocationProps);
  };

  const PaymentDetails = <>
    <OperationDetail title={'SHARED.COMMON.MOBILE_NO'}
                     value={mobileNo}
                     className={styles.detail}
                     width={Width.FULL} />
    <OperationDetail title={'BUY_LOAD.CONFIRM.PRODUCT_NAME'}
                     value={product.name}
                     className={styles.detail}
                     width={Width.FULL} />
    {
      product.description &&
      <OperationDetail title={'BUY_LOAD.CONFIRM.DETAILS'}
                       value={product.description}
                       className={styles.detail}
                       width={Width.FULL} />
    }
  </>;

  const SnackBar = <AlertSnackbar handleClose={() => setShowError(false)}
                                  open={showError}
                                  message={ErrorHelper.getErrorMessageTranslation(t, error!)} />;

  return (
    !userAccount
      ? <Loader />
      : <OperationConfirmationPopupContent cashValue={amount}
                                           isSubmitting={isSubmitting}
                                           onProceed={proceed}
                                           operationDetails={PaymentDetails}
                                           snackBar={SnackBar} />
  );
}

export default BuyLoadProductConfirm;
