import React from 'react';
import styles from './VerificationStepContent.module.scss';

interface VerificationStepContentProps {
  children: React.ReactElement | React.ReactElement[];
  description?: React.ReactElement;
}

export const VerificationStepContent = ({children, description}: VerificationStepContentProps) => (
  <div className={styles['content-wrapper']}>
    <div className={styles.content}>
      {
        description && <p className={styles.description}>{description}</p>
      }
      {children}
    </div>
  </div>
);
