import {isNil} from 'lodash';
import React, {ChangeEvent} from 'react';
import FileTypes from './FileTypes';
import styles from './FileUpload.module.scss';

export const DEFAULT_MAXIMUM_FILE_SIZE = 4194304;

export enum FileUploadErrorCode {
  FILE_IS_NULL,
  FILE_TOO_LARGE
}

export type FileUploadError = {
  errorCode: FileUploadErrorCode,
  messageCode: string,
  messageParams?: object
}

interface FileUploadProps {
  onFileAttached: (file: File) => Promise<void> | void;
  onFileAttachFailed: (error: FileUploadError) => void;
  acceptedFileTypes?: FileTypes[];
  maximumFileSize?: number;
}

const FileUpload = ({
                      onFileAttached,
                      onFileAttachFailed,
                      acceptedFileTypes,
                      maximumFileSize = DEFAULT_MAXIMUM_FILE_SIZE
                    }: FileUploadProps) => {

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] ?? null;

    if (isNil(file)) {
      onFileAttachFailed({
        errorCode: FileUploadErrorCode.FILE_IS_NULL,
        messageCode: 'FILE_UPLOAD.ERROR.FILE_IS_NULL'
      });
      return;
    }

    if (file.size > maximumFileSize) {
      onFileAttachFailed({
        errorCode: FileUploadErrorCode.FILE_TOO_LARGE,
        messageCode: 'FILE_UPLOAD.ERROR.FILE_TOO_LARGE',
        messageParams: {
          // Change bytes to MB
          maximumSize: `${maximumFileSize / 1024 / 1024} MB`
        }
      });
      return;
    }

    onFileAttached(file);
  };

  return (
    <input type="file"
           className={styles.input}
           multiple={false}
           accept={acceptedFileTypes?.toString() ?? ''}
           onChange={onInputChange} />
  );
};

export default FileUpload;
