import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router';
import {ErrorHelper} from '../../../utils/error-helper';
import ConfirmationPopupContent from '../popups/confirmation-popup-content/ConfirmationPopupContent';
import {AlertSnackbar} from '../alert-snackbar/AlertSnackbar';

export interface ReferenceNumberCancelConfirmationLocationProps {
  handlePopupClose: () => void;
  onConfirm: () => Promise<any>;
}

export const ReferenceNumberCancelConfirmation = () => {
  const history = useHistory();
  const {t} = useTranslation();
  const {handlePopupClose, onConfirm} = useLocation<ReferenceNumberCancelConfirmationLocationProps>().state;
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isConfirmInProgress, setIsConfirmInProgress] = React.useState(false);

  const cancelReferenceNumber = () => {
    setIsConfirmInProgress(true);
    onConfirm()
      .then(() => {
        handlePopupClose();
      })
      .catch(error => {
        setIsConfirmInProgress(false);
        setErrorMessage(ErrorHelper.getErrorMessageTranslation(t, error));
        setShowError(true);
      });
  };

  return (
    <>
      <AlertSnackbar handleClose={() => setShowError(false)} open={showError} message={errorMessage} />
      <ConfirmationPopupContent query={t('REFERENCE_NUMBER.CANCEL_CONFIRMATION.QUERY')}
                                onConfirm={cancelReferenceNumber}
                                onDecline={history.goBack}
                                confirmLabel={t('SHARED.COMMON.YES')}
                                declineLabel={t('SHARED.COMMON.NO')}
                                isConfirmInProgress={isConfirmInProgress} />
    </>
  );
};
