import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {HttpError} from '../../../shared/services/http.service';
import {fetchUserPrimaryAccount} from '../../../store/actions';
import {getUserAccount} from '../../../store/selectors';
import {ErrorHelper} from '../../../utils/error-helper';
import {AlertSnackbar} from '../../shared/alert-snackbar/AlertSnackbar';
import Loader from '../../shared/loader/Loader';
import OperationConfirmationPopupContent
  from '../../shared/operation/operation-confirmation-popup-content/OperationConfirmationPopupContent';
import OperationDetail, {Width} from '../../shared/operation/operation-detail/OperationDetail';
import {TelemedicineCommandOutputData} from '../model/TelemedicineCommandOutput.data';
import {TelemedicineProduct} from '../model/TelemedicineProduct';
import {TelemedicineRoutesPaths} from '../telemedicine.routes-paths';
import {TelemedicineService} from '../telemedicine.service';
import styles from './OperationDetailsPopupContent.module.scss';

interface OperationDetailsPopupContentLocationProps {
  otpError?: HttpError<any>
}

interface OperationDetailsPopupContentProps {
  product: TelemedicineProduct;
}

const OperationDetailsPopupContent = ({product}: OperationDetailsPopupContentProps) => {

  const location = useLocation<OperationDetailsPopupContentLocationProps>();
  const {otpError} = location.state ?? {};
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showError, setShowError] = useState(!!otpError);
  const [error, setError] = useState<HttpError<any> | undefined>(otpError);
  const [t] = useTranslation();
  const history = useHistory();
  const userAccount = useSelector(getUserAccount);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserPrimaryAccount());
  }, [dispatch]);

  const handleOtpError = (error) => {
    // Reload current view with error
    history.push(location.pathname, {
      ...location.state,
      otpError: error
    });
  };

  const handleOtpSuccess = async (confirmationOutput: TelemedicineCommandOutputData) => {
    const operations = await TelemedicineService.getTelemedicineOperations();
    const operation = operations.result.find(operation =>
      operation.referenceNo === confirmationOutput.referenceNumber);

    operation && history.push(TelemedicineRoutesPaths.TELEMEDICINE_OPERATION_OVERVIEW, {operation});
  };

  const submit = () => {
    setIsSubmitting(true);
    TelemedicineService.processTelemedicinePayment(userAccount!.id, product.id)
      .then(output => {
        history.push(TelemedicineRoutesPaths.TELEMEDICINE_OTP, {
          ...output,
          onSuccess: handleOtpSuccess,
          onError: handleOtpError
        });
      })
      .catch(error => {
        setError(error);
        setShowError(true);
      })
      .finally(() => setIsSubmitting(false));
  };

  const OperationDetails = (
    <>
      <OperationDetail title={'TELEMEDICINE.TRANSACTION_DETAILS.PRODUCT_NAME'}
                       value={product.name}
                       width={Width.FULL}
                       className={styles.detail} />
      <OperationDetail title={'TELEMEDICINE.TRANSACTION_DETAILS.DESCRIPTION'}
                       value={product.shortDescription}
                       width={Width.FULL}
                       className={styles.detail} />
    </>
  );

  const SnackBar = <AlertSnackbar handleClose={() => setShowError(false)}
                                  open={showError}
                                  message={ErrorHelper.getErrorMessageTranslation(t, error!)} />;

  return (
    !userAccount
      ? <Loader />
      : <OperationConfirmationPopupContent cashValue={product.amount}
                                           isSubmitting={isSubmitting}
                                           onProceed={submit}
                                           operationDetails={OperationDetails}
                                           snackBar={SnackBar}
                                           amountTranslationCode={'SHARED.COMMON.AMOUNT'} />
  );
};

export default OperationDetailsPopupContent;
