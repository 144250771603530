import React from 'react';
import styles from './ListParagraph.module.scss';

export enum ListParagraphType {
  BULLET, ORDERED
}

interface ListParagraphProps {
  title: React.ReactElement | string;
  titleClassName?: string;
  listItemClassName?: string;
  items: (React.ReactElement | string)[];
  listType?: ListParagraphType;
}

export const ListParagraph = ({title, titleClassName, items, listType = ListParagraphType.BULLET, listItemClassName}: ListParagraphProps) => {

  const listItems = items.map((bullet, index) => (
    <li key={index} className={`${styles['list__item']} ${listItemClassName}`}>
      {bullet}
    </li>
  ));

  return (
    <>
      <div className={`${styles.title} ${titleClassName}`}>{title}</div>
      {
        listType === ListParagraphType.BULLET
          ? <ul className={styles.list}>{listItems}</ul>
          : <ol className={styles.list}>{listItems}</ol>
      }
    </>
  );
};
