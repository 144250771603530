import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import CardlessWithdarawalIcon from '../../../assets/images/icons/withdrawal-icons/cardless-withdrawal.svg';
import {CardlessWithdrawalRoutes} from '../../shared/cardless-withdrawal/cardless-withdrawal.routes';
import PopupWithMemoryRouter from '../../shared/popups/PopupWithMemoryRouter';
import {OptionRow} from '../../shared/option-row/OptionRow';

export const CustomerCardlessWithdrawalOption = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [t] = useTranslation();

  const closePopup = () => {
    setIsPopupOpen(false);
  }

  const routes = [
    CardlessWithdrawalRoutes.CUSTOMER_CARDLESS_WITHDRAWAL,
    CardlessWithdrawalRoutes.CARDLESS_WITHDRAWAL_CONFIRM,
    CardlessWithdrawalRoutes.CARDLESS_WITHDRAWAL_OTP,
    CardlessWithdrawalRoutes.CARDLESS_WITHDRAWAL_SUCCESS
  ];

  return (
    <>
      <OptionRow onClick={()=>setIsPopupOpen(true)}
                 iconSrc={CardlessWithdarawalIcon}
                 title={t('WITHDRAW.CARDLESS_WITHDRAWAL.TITLE')}
                 description={t('WITHDRAW.CARDLESS_WITHDRAWAL.DESCRIPTION')} />
      <PopupWithMemoryRouter open={isPopupOpen} handleClose={closePopup} routes={routes} />
    </>
  );
};
