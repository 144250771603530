import axios from 'axios';
import React, {createContext, useEffect, useState} from 'react';
import {Dictionary, DictionaryCode, GeoLocationLevel, GeoLocationsDictionaryEntry} from '../../shared/model/Dictionary';
import {Application} from '../../shared/model/verification/ProspectAppliaction';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {VerificationService} from '../../shared/services/verification.service';
import {ErrorHelper} from '../../utils/error-helper';
import {cleanUp} from '../../utils/handle-abort-controller-clean-up';
import MainContainer from '../shared/containers/MainContainer';
import Loader from '../shared/loader/Loader';
import MemoryRouterWithRedirect from '../shared/routes/MemoryRouterWithRedirect';
import {KeyedRouteProps} from '../shared/routes/model/Routes';
import {VerificationAdditionalDetails} from './additional-details/VerificationAdditionalDetails';
import {VerificationAddress} from './address/VerificationAddress';
import {VerificationDataAndPersonalization} from './data-and-personalization/VerificationDataAndPersonalization';
import {VerificationEligibility} from './eligibility/VerificationEligibility';
import {VerificationIdDocument} from './id-document/VerificationIdDocument';
import JumioVerification from './jumio/JumioVerification';
import VerificationPersonalDetails from './personal-details/VerificationPersonalDetails';
import {VerificationStepsSummary} from './steps-summary/VerificationStepsSummary';
import {VerificationTermsAndConditions} from './terms-and-conditions/VerificationTermsAndConditions';
import styles from './Verification.module.scss';
import {VerificationRoutesPaths} from './verification.routes-paths';

export interface VerificationContextType {
  application: Application;
  setApplication: (application: Application) => void;
  dictionaries: Dictionary[];
  citiesGeoDictionary: GeoLocationsDictionaryEntry[];
  provincesGeoDictionary: GeoLocationsDictionaryEntry[];
  dictionariesError: boolean;
}

export const VerificationContext = createContext<VerificationContextType>({
  application: {} as Application
} as VerificationContextType);

export default function Verification() {

  const [application, setApplication] = useState<Application>();
  const [dictionaries, setDictionaries] = useState<Dictionary[]>();
  const [citiesGeoDictionary, setCitiesGeoDictionary] = useState<GeoLocationsDictionaryEntry[]>();
  const [provincesGeoDictionary, setProvincesGeoDictionary] = useState<GeoLocationsDictionaryEntry[]>();
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>();
  const [apiRequestOccurred, setApiRequestOccurred] = useState<boolean>(false);

  useEffect(() => {
    const dictionariesCodes = [
      DictionaryCode.COUNTRY,
      DictionaryCode.GENDER,
      DictionaryCode.ID_DOCUMENT_TYPE,
      DictionaryCode.NATURE_OF_WORK_BUSINESS
    ];

    const cancelTokenSource = axios.CancelToken.source();

    const dataRetrievePromises = [
      VerificationService.readProspectApplication(cancelTokenSource).then(value => setApplication(value.application)),
      DictionaryService.getGeoDictionary(GeoLocationLevel.CITY, cancelTokenSource)
        .then(value => setCitiesGeoDictionary(value)),
      DictionaryService.getGeoDictionary(GeoLocationLevel.PROVINCE, cancelTokenSource)
        .then(value => setProvincesGeoDictionary(value)),
      DictionaryService.getDictionaries(dictionariesCodes, undefined, cancelTokenSource)
        .then(value => setDictionaries(value))
    ];

    Promise.all(dataRetrievePromises)
      .then(() => setIsDataLoaded(true))
      .catch(error => {
        if (ErrorHelper.isAbortOrSessionRestrictionError(error)) {
          return;
        }
        setApiRequestOccurred(true);
        setIsDataLoaded(true);
      });

    return cleanUp(cancelTokenSource);
  }, []);

  // First route is the first step of verification process
  const routes: KeyedRouteProps[] = [
    {
      path: VerificationRoutesPaths.ELIGIBILITY,
      key: 'VERIFICATION_ELIGIBILITY',
      component: VerificationEligibility
    },
    {
      path: VerificationRoutesPaths.PERSONAL_DETAILS,
      key: 'VERIFICATION_PERSONAL_DETAILS',
      component: VerificationPersonalDetails
    },
    {
      path: VerificationRoutesPaths.ADDRESS,
      key: 'VERIFICATION_ADDRESS',
      component: VerificationAddress
    }, {
      path: VerificationRoutesPaths.ADDITIONAL_DETAILS,
      key: 'VERIFICATION_ADDITIONAL_DETAILS',
      component: VerificationAdditionalDetails
    },
    {
      path: VerificationRoutesPaths.TERMS_AND_CONDITIONS,
      key: 'VERIFICATION_TERMS_AND_CONDITIONS,',
      component: VerificationTermsAndConditions
    },
    {
      path: VerificationRoutesPaths.DATA_AND_PERSONALIZATION,
      key: 'VERIFICATION_DATA_AND_PERSONALIZATION',
      component: VerificationDataAndPersonalization
    },
    {
      path: VerificationRoutesPaths.ID_DOCUMENT,
      key: 'VERIFICATION_ID_DOCUMENT',
      component: VerificationIdDocument
    },
    {
      path: VerificationRoutesPaths.STEPS_SUMMARY,
      key: 'VERIFICATION_STEPS_SUMMARY',
      component: VerificationStepsSummary
    },
    {
      path: VerificationRoutesPaths.JUMIO,
      key: 'VERIFICATION_STEPS_JUMIO',
      component: JumioVerification
    }
  ];

  return (
    <MainContainer className={styles.container}>
      <VerificationContext.Provider value={{
        application: application ?? {} as Application,
        setApplication,
        dictionaries: dictionaries ?? [],
        citiesGeoDictionary: citiesGeoDictionary ?? [],
        provincesGeoDictionary: provincesGeoDictionary ?? [],
        dictionariesError: apiRequestOccurred
      }}>
        {
          isDataLoaded
            ? <MemoryRouterWithRedirect routes={routes} />
            : <Loader />
        }
      </VerificationContext.Provider>
    </MainContainer>
  );
}
