import {DynamicField} from './DynamicField';

export enum PaymentGroup {
  BILL = 'BILL',
  EPIN = 'EPIN',
  PREPAID = 'PREPAID',
  TOP_UP = 'TOP_UP'
}

export interface PaymentProductTO<T_PAYMENT_GROUP = string> {
  category: string;
  code: string;
  id: number;
  name: string;
  paymentGroup: T_PAYMENT_GROUP;
  totalCharges: number;
  bankCharge?: number;
  description?: string;
  dynamicFields: DynamicField[];
  logoUrl: string;
  maxAmount: string;
  minAmount: string;
  serviceCharge: number;
}

export interface PaymentProduct extends PaymentProductTO<PaymentGroup> {
}

export const fromPaymentProductTO = (paymentProductTO: PaymentProductTO): PaymentProduct => ({
  ...paymentProductTO,
  paymentGroup: PaymentGroup[paymentProductTO.paymentGroup]
});
