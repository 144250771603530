import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {PartnerMerchant} from '../../../shared/model/operations/partners/PartnerMerchant';
import EmptyStateWithBackLink from '../empty-state/EmptyStateWithBackLink';
import Loader from '../loader/Loader';
import PartnerTile from './partner-tile/PartnerTile';
import styles from './PartnerMerchants.module.scss';

interface PartnersContainerProps {
  partners: PartnerMerchant[];
  isLoaded?: boolean;
  onPartnerClick: (partner: PartnerMerchant) => void;
}

export default function PartnerMerchants({partners, isLoaded = true, onPartnerClick}: PartnersContainerProps) {
  const {t} = useTranslation();
  const EmptyState = <EmptyStateWithBackLink emptyMessage={t('SHARED.PARTNERS.EMPTY_STATE')} />

  const PartnersTiles = partners.length === 0
    ? EmptyState
    : <div className={styles.partners}>
        { partners.map(
            partner => <PartnerTile label={partner.merchantName}
                                    merchantCode={partner.merchantCode}
                                    key={partner.merchantCode}
                                    onClick={() => onPartnerClick(partner)}/>)}
      </div>;

  return (
    <>
      <h2>
        <Trans>SHARED.PARTNERS.HEADER</Trans>
      </h2>
      { isLoaded ? PartnersTiles: <Loader className={styles.loader} /> }
    </>
  )
}
