import {Button} from '@material-ui/core';
import {FormikErrors} from 'formik/dist/types';
import isEmpty from 'lodash/isEmpty';
import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router';
import Loader from '../../shared/loader/Loader';
import {VerificationRoutesPaths} from '../verification.routes-paths';
import {getSteps, verificationStepsHelper} from './verification-steps';
import styles from './VerificationStepFooter.module.scss';

interface VerificationStepFooterProps {
  submit?: () => Promise<any>;
  isSubmitDisabled?: boolean;
  bottomText?: React.ReactElement;
  validateForm?: (values?: any) => Promise<FormikErrors<any>>;
}

export const VerificationStepFooter = ({validateForm, submit, isSubmitDisabled = false, bottomText}: VerificationStepFooterProps) => {
  const {t} = useTranslation();
  const history = useHistory();
  const [isSubmittingByBackButton, setIsSubmittingByBackButton] = useState(false);
  const [isSubmittingBySubmitButton, setIsSubmittingBySubmitButton] = useState(false);
  const stepPath = useLocation().pathname;
  const steps = getSteps(t);
  const stepIndex = verificationStepsHelper.getStepIndex(steps, stepPath);
  const previousStepPath = stepIndex > 0 ? steps[stepIndex - 1].path : '';
  const nextStepPath = stepIndex < steps.length - 1 ? steps[stepIndex + 1].path : VerificationRoutesPaths.STEPS_SUMMARY;

  const saveAndGoBack = async (): Promise<void> => {
    await saveFormAndGoToPath(previousStepPath, (value: boolean) => setIsSubmittingByBackButton(value));
  };

  const saveAndGoNext = async (): Promise<void> => {
    await saveFormAndGoToPath(nextStepPath, (value: boolean) => setIsSubmittingBySubmitButton(value));
  };

  const saveFormAndGoToPath = async (path: string, setIsSubmitting: (value: boolean) => void): Promise<void> => {
    if (!submit) {
      history.push(path);
      return;
    }

    setIsSubmitting(true);
    const errors = await validateForm?.();

    if (isEmpty(errors) || path === previousStepPath) {
      await submit();
      history.push(path);
    }

    setIsSubmitting(false);
  };

  return (
    <div className={`${styles.footer} ${!!previousStepPath ? styles['footer--two-buttons'] : ''}`}>
      <div className={styles.buttons}>
        <Button className={styles.button}
                onClick={saveAndGoNext}
                disabled={isSubmittingBySubmitButton || isSubmittingByBackButton || isSubmitDisabled}>
          <Loader buttonSpinner loaded={!isSubmittingBySubmitButton}>
            <Trans>SHARED.COMMON.SUBMIT</Trans>
          </Loader>
        </Button>
        {
          !!previousStepPath && (
            <Button className={`${styles.button} ${styles['button--back']} ${
              bottomText ? styles['button--layout-with-bottom-text'] : ''
            }`}
                    variant="outlined"
                    onClick={saveAndGoBack}
                    disabled={isSubmittingBySubmitButton || isSubmittingByBackButton}>
              <Loader buttonSpinner textColorVariant loaded={!isSubmittingByBackButton}>
                <Trans>SHARED.COMMON.BACK</Trans>
              </Loader>
            </Button>
          )
        }
      </div>
      {bottomText && <div className={styles['bottom-text']}>{bottomText}</div>}
    </div>
  );
};
