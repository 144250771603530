import {isNil} from "lodash";

export const blobToBase64String = (blobFile: Blob): Promise<string> => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {

    if (isNil(blobFile)) {
      reject('Blob file is null or undefined!')
      return;
    }

    reader.readAsDataURL(blobFile);
    reader.onloadend = () => {
      const base64String = reader.result;
      resolve(base64String?.toString() ?? '');
    };
  });
};