import React from 'react';

interface ExternalLinkProps {
  children?: React.ReactElement;
  className?: string;
  href: string;
}

const ExternalLink = ({children, href, className}: ExternalLinkProps) => (
  <a href={href} target="_blank" rel="noopener noreferrer" className={className}>
    {children}
  </a>
);

export default ExternalLink;
