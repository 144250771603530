import {CancelTokenSource} from 'axios';
import {HttpServiceHelper} from '../../utils/http-service-helper';
import {CommandOutput} from '../model/command/CommandOutput';
import {
  Application,
  EkycStatus,
  ProspectApplication,
  ProspectApplicationProcessCommandData,
  ProspectApplicationStatus,
  ProspectApplicationTO
} from '../model/verification/ProspectAppliaction';
import {
  ApplicationConditionStatus,
  ProspectApplicationCondition,
  ProspectApplicationConditionTO
} from '../model/verification/ProspectAppliactionCondition';
import HttpService from './http.service';

const PROSPECT_APPLICATION_URL = '/prospect/applications';

export const VerificationService = {
  readProspectApplication: (cancelTokenSource?: CancelTokenSource | null): Promise<ProspectApplication> =>
    HttpService.get<ProspectApplicationTO>(PROSPECT_APPLICATION_URL, cancelTokenSource)
      .then(application => application as ProspectApplication),

  updateProspectApplication: (applicationId: number, applicationData: Application): Promise<CommandOutput> =>
    HttpService.put<CommandOutput>(`${PROSPECT_APPLICATION_URL}/${applicationId}`, applicationData),

  readProspectApplicationStatus: (applicationUuid: string,
                                  cancelTokenSource?: CancelTokenSource | null): Promise<ProspectApplicationStatus> =>
    HttpService.get<ProspectApplicationStatus>(
      HttpServiceHelper.constructUrlWithSearchParams(
        `${PROSPECT_APPLICATION_URL}/status`,
        {uuid: applicationUuid}
      ),
      cancelTokenSource
    ).then(status => ProspectApplicationStatus[status]),

  validateProspectApplication: (applicationId: number): Promise<ProspectApplicationCondition[]> =>
    HttpService.post<ProspectApplicationConditionTO[]>(
      `${PROSPECT_APPLICATION_URL}/${applicationId}/validate-conditions`
    ).then(conditions => conditions.map(condition => (
      {
        ...condition,
        status: ApplicationConditionStatus[condition.status]
      } as ProspectApplicationCondition))
    ),

  submitProspectApplicationEkycStatus: (applicationId: number,
                                        status: EkycStatus = EkycStatus.COMPLETED): Promise<void> =>
    HttpService.post<void>(HttpServiceHelper.constructUrlWithSearchParams(
      `${PROSPECT_APPLICATION_URL}/${applicationId}/submit-ekyc-status`,
      {status})
    ),

  retryProspectApplication: (applicationId: number, cleanCustomAttributes: boolean = true): Promise<void> =>
    HttpService.post<void>(HttpServiceHelper.constructUrlWithSearchParams(
      `${PROSPECT_APPLICATION_URL}/${applicationId}/retry`,
      {cleanCustomAttributes})
    ),

  processProspectApplication: (applicationId: number): Promise<CommandOutput<ProspectApplicationProcessCommandData>> =>
    HttpService.post<CommandOutput<ProspectApplicationProcessCommandData>>(
      `${PROSPECT_APPLICATION_URL}/process/${applicationId}`
    ),

  getVerificationTermsAndConditions: function(cancelTokenSource?: CancelTokenSource | null): Promise<string> {
    return HttpService.get<string>('/common/system/sites?code=TERMS_AND_CONDITIONS&mode=WEB', cancelTokenSource);
  }
};
