import dayjs, {Dayjs} from 'dayjs';

export interface GoalPayload {
  name: string;
  amount: number;
  durationInMonths: number;
  startDate: string;
  imageId?: number;
}

export interface GoalTO<T_DATE = string> {
  amount: number;
  customerId: number;
  durationInMonths: number;
  endDate: T_DATE;
  id: number;
  imageId: number;
  monthlyAmount: number;
  name: string;
  progress: number;
  prospectApplicationId: number;
  startDate: T_DATE,
  imageFile?: Blob | null
}

interface Goal extends GoalTO<Dayjs> {
}

export const goalFromGoalTO = (goalTo: GoalTO) => ({
  ...goalTo,
  startDate: dayjs(goalTo.startDate),
  endDate: dayjs(goalTo.endDate),
  progress: Math.trunc(goalTo.progress)
});

export default Goal;
