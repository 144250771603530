import {Button} from '@material-ui/core';
import React from 'react';
import {Trans} from 'react-i18next';
import {useHistory} from 'react-router';
import {DATE_WITH_TIME_FORMAT} from '../../../../constants/date';
import {WithdrawalService} from '../../../../shared/services/withdrawal.service';
import CurrencyFormat from '../../../shared/currency-format/CurrencyFormat';
import OperationDetail from '../../../shared/operation/operation-detail/OperationDetail';
import {PendingWithdrawalCancelConfirmationLocationProps} from '../cancel-confirmation/PendingWithdrawalCancelConfirmation';
import {Withdrawal} from '../model/Withdrawal';
import {ReferenceNumber} from '../../../shared/reference-number/ReferenceNumber';
import PendingWithdrawalsIcon from '../../../../assets/images/icons/withdrawal-icons/pending-withdrawals.svg';
import styles from './PendingWithdrawalReferenceNumber.module.scss';
import {WithdrawalRoutesPaths} from '../../withdrawal.routes-paths';
import { CardlessWithdrawalHowTo } from '../../../shared/cardless-withdrawal/how-to/CardlessWithdrawalHowTo';

interface PendingWithdrawalReferenceNumberProps {
  withdrawal: Withdrawal;
}

export function PendingWithdrawalReferenceNumber ({withdrawal}: PendingWithdrawalReferenceNumberProps) {
  const history = useHistory();
  const {amount, id, recipient, referenceNo, registeredOn} = withdrawal;

  const cancelWithdrawal = () => {
    history.push(WithdrawalRoutesPaths.PENDING_WITHDRAWAL_CANCEL, {
      onConfirm: () => WithdrawalService.cancelPendingCardlessWithdrawal(id)
    } as PendingWithdrawalCancelConfirmationLocationProps);
  }

  const getRecipientsName = () => {
    const middleName = recipient.middleName ? `${recipient.middleName} ` : '';
    return `${recipient.firstName} ${middleName}${recipient.lastName}`;
  }

  const TRANS_PREFIX = 'WITHDRAW.PENDING_WITHDRAWALS.LABELS';

  const OperationDetails = (
    <>
      <OperationDetail title={`${TRANS_PREFIX}.AMOUNT_TO_BE_SENT`}
                       value={<CurrencyFormat amount={amount} />}
                       className={styles.detail} />
      <OperationDetail title={`${TRANS_PREFIX}.RECIPIENTS_NAME`}
                       value={getRecipientsName()}
                       className={styles.detail} />
      <OperationDetail title={'SHARED.COMMON.TRANSACTION_DATE'}
                       value={registeredOn.format(DATE_WITH_TIME_FORMAT)}
                       className={styles.detail} />
      <Button className={styles.abort} variant="outlined" onClick={cancelWithdrawal} classes={{root: styles.cancel}}>
        <Trans>{`${TRANS_PREFIX}.CANCEL_WITHDRAWAL`}</Trans>
      </Button>
    </>
  );

  return (
    <ReferenceNumber referenceNumber={referenceNo}
                     leftColumn={OperationDetails}
                     rightColumn={<CardlessWithdrawalHowTo />}
                     icon={<img src={PendingWithdrawalsIcon} alt="pending withdrawal" className={styles.icon} />}/>
  );
}
