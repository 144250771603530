import React from 'react';
import {Trans} from 'react-i18next';
import HowToHero from '../../../assets/images/hero-how-to.svg';
import styles from './ReferenceNumber.module.scss';

interface ReferenceNumberProps {
  alertSnackbar?: React.ReactElement;
  icon: React.ReactElement;
  leftColumn: React.ReactElement;
  rightColumn: React.ReactElement;
  referenceNumber: number;
}

export const ReferenceNumber = ({alertSnackbar, icon, leftColumn, referenceNumber, rightColumn}: ReferenceNumberProps) => (
  <>
    <div className={styles.container}>
      <div className={styles.column}>
        { alertSnackbar }
        <div className={styles['icon-wrapper']}>
          { icon }
        </div>
        <div className={styles['reference-number']}>
          <div className={styles['reference-number__label']}>
            <Trans>REFERENCE_NUMBER.SHORT_LABEL</Trans>
          </div>
          <div className={styles['reference-number__value']}>
            { referenceNumber }
          </div>
        </div>
        <div className={styles['column__content-wrapper']}>
        { leftColumn }
        </div>
      </div>
      <div className={`${styles['how-to']} ${styles.column}`}>
        <div className={styles['how-to__icon']}>
          <img src={HowToHero} alt="How to" />
        </div>
        { rightColumn }
      </div>
    </div>
  </>
);

