import {Button} from '@material-ui/core';
import React from 'react';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ReactComponent as ActionCheckIcon} from '../../../assets/images/icons/action-check.svg';
import HeroProgress from '../../../assets/images/hero-progress.svg';
import {JumioRoutesPaths} from '../jumio/jumio.routes-paths';
import styles from './VerificationStepsSummary.module.scss';

export const VerificationStepsSummary = () => {
  const RouteTrans = ({children}) => <Trans>{`VERIFICATION.STEPS_SUMMARY.${children}`}</Trans>;

  return (
    <div className={styles.box}>
      <div className={styles['image-wrapper']}>
        <img src={HeroProgress} alt={'hero progress'} />
      </div>
      <div className={styles.content}>
        <h1 className={styles.title}>
          <RouteTrans>TITLE</RouteTrans>
        </h1>
        <p className={styles.description}>
          <RouteTrans>DESCRIPTION</RouteTrans>
        </p>
        <div className={`${styles.point} ${styles['point--checked']}`}>
          <ActionCheckIcon />
          <span className={styles['point__label']}>
            <RouteTrans>ACCOUNT_INFORMATION</RouteTrans>
          </span>
        </div>
        <div className={styles.point}>
          <ActionCheckIcon />
          <span className={styles['point__label']}>
            <RouteTrans>VALID_ID</RouteTrans>
          </span>
        </div>
        <div className={styles.point}>
          <ActionCheckIcon />
          <span className={styles['point__label']}>
            <RouteTrans>VIDEO_SELFIE</RouteTrans>
          </span>
        </div>
        <Link to={JumioRoutesPaths.JUMIO}>
          <Button className={styles.button}>
            <Trans>SHARED.COMMON.SUBMIT</Trans>
          </Button>
        </Link>
      </div>
    </div>
  );
};
