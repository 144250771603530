import React from 'react';
import ArrowRight from '../../../assets/images/icons/arrow-right.svg';
import styles from './OptionRow.module.scss';

interface OptionRowProps {
  description: string;
  iconSrc: string;
  onClick: () => void;
  title: string;
}

export const OptionRow = ({description, iconSrc, onClick, title}: OptionRowProps) => (
  <div className={styles.row} onClick={onClick}>
    <div className={styles.option}>
      <img src={iconSrc} alt={title} className={styles['option__icon']}/>
      <div className={styles.details}>
        {title}
        <span className={styles.description}>
          {description}
        </span>
      </div>
    </div>
    <img src={ArrowRight} alt="Choose option" className={styles['arrow-right-icon']}/>
  </div>
);