interface UserData {
  userGroup: UserGroup;
  shortName: string;
  // Determines that prospect is anonymous, not whole session. Cannot be used to determine global anonymous session.
  anonymous: boolean;
  applicationId: number;
  applicationUuid: string;
  id: number;
  customerNumber: string;
  initials: string;
  effectiveName: string;
  firstLogin: false;
}

export enum UserGroup {
  ADMIN = 'ADMIN',
  CUSTOMER = 'CUSTOMER',
  PROSPECT = 'PROSPECT',
  PARTNER = 'PARTNER'
}

export default UserData;
