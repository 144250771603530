import React from 'react';
import NumberFormat from 'react-number-format';
import {CURRENCY_CODE} from '../../../constants/currency';

interface CurrencyFormatProps {
  amount: number;
}

export default function CurrencyFormat({amount}: CurrencyFormatProps) {
  return (
    <NumberFormat value={amount}
                  fixedDecimalScale={true}
                  thousandSeparator
                  prefix={`${CURRENCY_CODE} `}
                  displayType={'text'}
                  decimalScale={2}
                  allowNegative={false} />
  );
}
