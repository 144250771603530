import React from 'react';
import Banner from '../banner/Banner';
import Heading from '../heading/Heading';
import styles from './Columns.module.scss';

interface ColumnsProps {
  mainColumn: React.ReactElement;
  mainColumnBoxClass?: string;
  sideColumn?: React.ReactElement;
  heading?: string;
}

const Columns = ({mainColumn, mainColumnBoxClass, heading, sideColumn}: ColumnsProps) => (
  <>
    {heading && <Heading title={heading} />}
    {/* Add top margin if there is no heading provided */}
    <div className={`${styles.row} ${heading ? '' : styles['row--margin-top']}`}>
      <div className={styles['main-column']}>
        <div className={`${styles['main-column__box']} ${mainColumnBoxClass ? mainColumnBoxClass : ''}`}>
          {mainColumn}
        </div>
      </div>
      {/* Show Banner if there is no side column template provided */}
      {!sideColumn ? <Banner /> : <div className={styles['side-column']}>{sideColumn}</div>}
    </div>
  </>
);

export default Columns;
