import axios, {CancelTokenSource} from 'axios';
import React, {createContext, useState} from 'react';
import {BillPaymentRoutesPaths} from '../../../bill-payment/bill-payment.routes-paths';
import BillPaymentOperationDetails from '../../../bill-payment/BillPaymentOperationDetails';
import Otp from '../../otp/Otp';
import PopupWithMemoryRouter from '../../popups/PopupWithMemoryRouter';
import TransferSuccess from '../../transfer/success/TransferSuccess';
import BillPaymentDetails from '../details/BillPaymentDetails';
import BillPaymentProducts from '../products/BillPaymentProducts';
import styles from './BillPaymentPopup.module.scss';

interface BillPaymentPopupProps {
  isOpen: boolean;
  onClose: () => void;
  categoryGroup: string;
}

export interface ContextFormState {
  amount?: number;
  fields?: object;
}

interface BillPaymentPopupContextProps {
  cancelToken?: CancelTokenSource;
  contextFormState: ContextFormState;
}

export const BillPaymentPopupContext = createContext<BillPaymentPopupContextProps>({contextFormState: {}});

export default function BillPaymentPopup({isOpen, onClose, categoryGroup}: BillPaymentPopupProps) {

  const cancelTokenSource = axios.CancelToken.source();
  const [contextFormState, setContextFormState] = useState<ContextFormState>({});

  // First route is the first step of bill payment process
  const billPaymentRoutes = [
    {
      path: BillPaymentRoutesPaths.PRODUCT_SELECTION,
      key: 'BILL_PAYMENT_PRODUCT_SELECTION',
      render: () => <BillPaymentProducts categoryGroup={categoryGroup} />
    },
    {
      path: BillPaymentRoutesPaths.PAYMENT_DETAILS,
      key: 'BILL_PAYMENT_PAYMENT_DETAILS',
      component: BillPaymentDetails
    },
    {
      path: BillPaymentRoutesPaths.OPERATION_DETAILS,
      key: 'BILL_PAYMENT_OPERATION_DETAILS',
      component: BillPaymentOperationDetails
    },
    {
      path: BillPaymentRoutesPaths.OTP,
      key: 'BILL_PAYMENT_PAYMENT_OTP',
      component: Otp
    },
    {
      path: BillPaymentRoutesPaths.SUCCESS,
      key: 'BILL_PAYMENT_PAYMENT_SUCCESS',
      component: TransferSuccess
    }
  ];

  const closePopup = () => {
    setContextFormState({});
    cancelTokenSource.cancel('Popup closed');
    onClose();
  };

  return (

    <BillPaymentPopupContext.Provider value={{cancelToken: cancelTokenSource, contextFormState}}>
      <PopupWithMemoryRouter open={isOpen}
                             handleClose={closePopup}
                             routes={billPaymentRoutes}
                             contentWrapperClass={styles.container} />
    </BillPaymentPopupContext.Provider>
  );
}
