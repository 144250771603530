import {Button} from '@material-ui/core';
import React from 'react';
import {Trans} from 'react-i18next';
import {useHistory} from 'react-router';
import HeroPositiveVerification from '../../../assets/images/hero-positive-verification.svg';
import {ReactComponent as ArrowBack} from '../../../assets/images/icons/arrow-back.svg';
import {ListParagraph} from '../../shared/list-paragraph/ListParagraph';
import styles from './VerifyAccountRemindersPopupContent.module.scss';

interface VerifyAccountPopupContentProps {
  onSubmitButtonClick: () => void;
}

const VerifyAccountRemindersPopupContent = ({onSubmitButtonClick}: VerifyAccountPopupContentProps) => {
  const history = useHistory();

  const FirstStepPoints = (
    <div className={styles['step-points']}>
      <div className={styles['step-points__point']}>
        <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.STEP_1_POINTS.POINT_1</Trans>
      </div>
      <div className={styles['step-points__point']}>
        <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.STEP_1_POINTS.POINT_2</Trans>
      </div>
      <div className={styles['step-points__point']}>
        <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.STEP_1_POINTS.POINT_3</Trans>
      </div>
      <div className={styles['step-points__point']}>
        <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.STEP_1_POINTS.POINT_4</Trans>
      </div>
      <div className={styles['step-points__point']}>
        <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.STEP_1_POINTS.POINT_5</Trans>
      </div>
      <div className={styles['step-points__point']}>
        <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.STEP_1_POINTS.POINT_6</Trans>
      </div>
      <div className={styles['step-points__point']}>
        <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.STEP_1_POINTS.POINT_7</Trans>
      </div>
      <div className={styles['step-points__point']}>
        <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.STEP_1_POINTS.POINT_8</Trans>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles['arrow-back']} onClick={history.goBack}>
        <ArrowBack />
      </div>
      <div className={styles.hero}>
        <img className={styles['hero__image']} src={HeroPositiveVerification} alt="verification"/>
        <h2 className={styles['hero__title']}>
          <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.REMINDERS_TITLE</Trans>
        </h2>
      </div>
      <div className={styles.reminders}>
        <ListParagraph
          title={<Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.TERMS.TITLE</Trans>}
          titleClassName={styles['list-title']}
          items={[
           <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.TERMS.POINT_1</Trans>,
           <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.TERMS.POINT_2</Trans>
          ]} />
        <ListParagraph
          title={<Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.STEPS_TITLE</Trans>}
          titleClassName={styles['list-title']}
          items={[
           <>
             <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.STEP_1</Trans>
             {FirstStepPoints}
           </>,
           <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.STEP_2</Trans>,
           <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.STEP_3</Trans>,
           <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.STEP_4</Trans>,
           <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.STEP_5</Trans>,
           <Trans>DASHBOARD.VERIFY_ACCOUNT_POPUP.STEP_6</Trans>
          ]} />
      </div>
      <div className={styles.submit}>
        <Button className={styles['submit__button']} onClick={onSubmitButtonClick}>
          <Trans>SHARED.COMMON.SUBMIT</Trans>
        </Button>
      </div>
    </div>
  );
}

export default VerifyAccountRemindersPopupContent;
