import dayjs from 'dayjs';
import {OperationBasicDataTO} from './OperationBasicData';
import {OperationCategory} from './OperationCategory';
import {OperationStatus} from './OperationStatus';

export interface CustomerDetails {
  customerEffectiveName: string;
  productNumber: string;
}

export interface OperationAttribute {
  type: string;
  value: string;
}

export interface OperationDetailsTO<T_DATE = string, T_STATUS = string, T_CATEGORY = string, T_PRODUCT_GROUP = string>
  extends OperationBasicDataTO<T_DATE, T_STATUS, T_CATEGORY, T_PRODUCT_GROUP> {
  attributes: OperationAttribute[];
  sourceParty?: CustomerDetails;
  targetParty: CustomerDetails;
  processedOn: T_DATE;
  processedOnTimestamp: T_DATE;
  //TODO check what field name is returned from backend as in tested operation there is no fee
  fee?: number;
}

export interface OperationDetails extends OperationDetailsTO<dayjs.Dayjs, OperationStatus, OperationCategory> {
}

export function OperationDetailsFromRaw(operationDetailsTO: OperationDetailsTO): OperationDetails {
  return {
    ...operationDetailsTO,
    registeredOn: dayjs(operationDetailsTO.registeredOn),
    registeredOnTimestamp: dayjs(operationDetailsTO.registeredOnTimestamp),
    processedOn: dayjs(operationDetailsTO.processedOn),
    processedOnTimestamp: dayjs(operationDetailsTO.processedOnTimestamp)
  } as OperationDetails;
}
