import axios from 'axios';
import React, {useEffect, useState} from 'react';
import PaymentService from '../../../shared/services/payment.service';
import {ErrorHelper} from '../../../utils/error-helper';
import {cleanUp} from '../../../utils/handle-abort-controller-clean-up';
import BillPaymentPopup from '../../shared/bill-payment/popup/BillPaymentPopup';
import Loader from '../../shared/loader/Loader';
import {BillCategoryCodes} from './BillCategoryCodes';
import styles from './BillPaymentCategories.module.scss';
import BillCategoryTile from './tile/BillCategoryTile';

const BILL_PAYMENT_GROUP = 'BILL';

export default function BillPaymentCategories() {
  const [categoryCodes, setCategoryCodes] = useState<(BillCategoryCodes | string)[]>([]);
  // TODO provide type for selected group
  const [selectedGroup, setSelectedGroup] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchCategories = () => {
    const cancelTokenSource = axios.CancelToken.source();

    PaymentService.getCategories(BILL_PAYMENT_GROUP, cancelTokenSource)
      .then(categoryCodes => {
        setIsLoading(false);
        setCategoryCodes(categoryCodes);
      })
      .catch(error => {
        if (ErrorHelper.isAbortOrSessionRestrictionError(error)) {
          return;
        }
        setIsLoading(false);
        setCategoryCodes([]);
      });

    return cleanUp(cancelTokenSource);
  };

  useEffect(fetchCategories, []);

  return (
    <>
      {
        isLoading ? <Loader /> :
          <div className={styles.categories}>
            {
              categoryCodes.map(code =>
                <BillCategoryTile key={code} categoryCode={code} onClick={() => setSelectedGroup(code)} />
              )
            }
          </div>
      }
      <BillPaymentPopup isOpen={!!selectedGroup}
                        onClose={() => setSelectedGroup(undefined)}
                        categoryGroup={selectedGroup} />
    </>);
};
