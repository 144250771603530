export const ZERO_DECIMAL_VALUE = '0.00';
export const EMPTY_DECIMAL_VALUE = '-';

export const CONSTANT_INTEREST_RATE = 3.25;

export const APP_LOCALE_CODE = 'en-PH';

export const DICTIONARY_OPERATION_TYPE_PREFIX = 'OPERATION_NAME.';
export const DICTIONARY_ERROR_CODE_PREFIX = 'ERROR_CODE.';
export const DICTIONARY_OPERATION_ATTRIBUTE_TYPE_PREFIX = 'OPERATION_ATTRIBUTE_TYPE.';

export const REQUIRED_FIELD_MESSAGE_CODE = 'SHARED.ERROR.FIELD_IS_REQUIRED';
export const INCORRECT_FORMAT_MESSAGE_CODE = 'SHARED.ERROR.INCORRECT_FORMAT';

export const PRIVACY_POLICY_URL = 'https://www.rcbc.com/data-privacy-notice';
export const TERMS_AND_CONDITIONS_URL = 'https://diskartech.ph/terms-conditions/';
export const FAQ_URL = 'https://diskartech.ph/faqs/';
export const FACEBOOK_URL = 'https://www.facebook.com/DiskarTech';
export const TWITTER_URL = 'https://twitter.com/diskartech';
export const INSTAGRAM_URL = 'https://www.instagram.com/diskartech/';

export const POSTAL_CODE_REGEXP_PATTERN = '^[0-9]{3,4}$';