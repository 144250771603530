import {Button, TextField} from '@material-ui/core';
import {Formik} from 'formik';
import React, {ReactElement, useState} from 'react';
import {Trans} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import passwordInputType from '../../../utils/password-input-type';
import Loader from '../../shared/loader/Loader';
import {PasswordVisibilityButton} from '../../shared/set-password-form/PasswordVisibilityButton';
import {SettingsRoutesPaths} from '../settings.routes-paths';
import styles from './OldPasswordPopupContent.module.scss';

interface FormFields {
  oldPassword: string;
}

const formInitState = {oldPassword: ''} as FormFields;

const OldPasswordPopupContent = () => {

  const history = useHistory();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const onSubmit = (values: FormFields) => {
    history.push(SettingsRoutesPaths.CHANGE_PASSWORD_NEW_PASSWORD_FORM, values);
  };

  const passwordForm = ({values, errors, handleChange, handleBlur, handleSubmit, isSubmitting}): ReactElement => (
    <form onSubmit={handleSubmit}>
      <div>
        <div className={styles['input-container']}>
          <TextField
            required
            label={<Trans>SETTINGS.CHANGE_PASSWORD.OLD_PASSWORD.INPUT_LABEL</Trans>}
            classes={{root: styles['input-container__input']}}
            type={passwordInputType(isPasswordVisible)}
            name="oldPassword"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.oldSecret}
            InputProps={{
              endAdornment: <PasswordVisibilityButton isVisible={isPasswordVisible}
                                                      setIsVisible={setIsPasswordVisible} />
            }}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button type="submit" className={styles.submit} disabled={isSubmitting}>
          <Loader buttonSpinner loaded={!isSubmitting}>
            <Trans>SHARED.COMMON.SUBMIT</Trans>
          </Loader>
        </Button>
      </div>
    </form>
  );

  return (
    <div className={styles['main-container']}>
      <div className={styles.heading}>
        <Trans>SETTINGS.CHANGE_PASSWORD.OLD_PASSWORD.TITLE</Trans>
      </div>
      <div className={styles['message-title']}>
        <Trans>SETTINGS.CHANGE_PASSWORD.OLD_PASSWORD.MESSAGE_TITLE</Trans>
      </div>
      <div className={styles['message-text']}>
        <Trans>SETTINGS.CHANGE_PASSWORD.OLD_PASSWORD.MESSAGE_TEXT</Trans>
      </div>
      <Formik
        <FormFields>
        initialValues={formInitState}
        onSubmit={onSubmit}
        children={passwordForm}
      />
    </div>
  );
};

export default OldPasswordPopupContent;
