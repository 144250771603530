import {isNil} from 'lodash';
import React, {useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {VerificationService} from '../../../shared/services/verification.service';
import Loader from '../../shared/loader/Loader';
import {VerificationStep} from '../steps/VerificationStep';
import {VerificationStepContent} from '../steps/VerificationStepContent';
import {VerificationStepFooter} from '../steps/VerificationStepFooter';
import styles from './VerificationTermsAndConditions.module.scss';

export const VerificationTermsAndConditions = () => {

  const [termsAndConditions, seTermsAndConditions] = useState<string>();

  useEffect(() => {
    VerificationService.getVerificationTermsAndConditions().then(
      value => seTermsAndConditions(value)
    ).catch(() => seTermsAndConditions(''));
  }, []);

  return (
    <VerificationStep>
      <VerificationStepContent>
        {
          isNil(termsAndConditions)
            ? <Loader />
            : <div className={styles.terms} dangerouslySetInnerHTML={{__html: termsAndConditions ?? ''}} />
        }
      </VerificationStepContent>
      <VerificationStepFooter bottomText={<Trans>VERIFICATION.TERMS_AND_CONDITIONS.AGREE_BY_CLICKING_SUBMIT</Trans>} />
    </VerificationStep>
  );
};
