import {CancelTokenSource} from 'axios';
import {CommandOutput} from '../model/command/CommandOutput';
import {Fee, FeeTO, fromFeeTO} from '../model/Fee';
import {OperationStatus} from '../model/operations/OperationStatus';
import {
  fromPartnerMerchantTO,
  Partner,
  PartnerMerchant,
  PartnerMerchantTO
} from '../model/operations/partners/PartnerMerchant';
import HttpService from './http.service';

const PARTNER_OPERATIONS_URL = '/customer/accounts/partner-operations';
const CALCULATE_FEES = '/calculate-fees';
const PARTNER_DEPOSIT_URL = `${PARTNER_OPERATIONS_URL}/deposit`;
const PARTNER_WITHDRAWAL_URL = `${PARTNER_OPERATIONS_URL}/withdrawal`;

export interface PartnerWithdrawalParams {
  amount: number;
  partner: Partner;
  productId: number;
  merchantId?: number;
}

export const PartnerOperationsService = {
  getPartnerMerchants: (cancelTokenSource?: CancelTokenSource | null): Promise<PartnerMerchant[]> =>
    HttpService.get<PartnerMerchantTO[]>(
      `${PARTNER_OPERATIONS_URL}/merchants`,
      cancelTokenSource
      )
      .then(partnerMerchantTOs => partnerMerchantTOs.map(partnerMerchantTO => fromPartnerMerchantTO(partnerMerchantTO))),

  getPartnerOperationStatus: (id: number): Promise<OperationStatus> =>
    HttpService.get<string>(`${PARTNER_OPERATIONS_URL}/${id}/status`)
      .then(status => OperationStatus[status]),

  calculatePartnerDepositFees: (params: PartnerWithdrawalParams): Promise<Fee[]> =>
    HttpService.post<FeeTO[]>(PARTNER_DEPOSIT_URL + CALCULATE_FEES, params)
      .then(feeTOs => feeTOs.map(feeTO => fromFeeTO(feeTO))),

  calculatePartnerWithdrawalFees: (params: PartnerWithdrawalParams): Promise<Fee[]> =>
    HttpService.post<FeeTO[]>(PARTNER_WITHDRAWAL_URL + CALCULATE_FEES, params)
      .then(feeTOs => feeTOs.map(feeTO => fromFeeTO(feeTO))),

  initiatePartnerDeposit: (params: PartnerWithdrawalParams): Promise<CommandOutput> =>
    HttpService.post<CommandOutput>(PARTNER_DEPOSIT_URL, params),

  initiatePartnerWithdrawal: (params: PartnerWithdrawalParams): Promise<CommandOutput> =>
    HttpService.post<CommandOutput>(PARTNER_WITHDRAWAL_URL, params),

  cancelPartnerDeposit: (id: number): Promise<CommandOutput> =>
    HttpService.delete<CommandOutput>(`${PARTNER_DEPOSIT_URL}/${id}`),

  cancelPartnerWithdrawal: (id: number): Promise<CommandOutput> =>
    HttpService.delete<CommandOutput>(`${PARTNER_WITHDRAWAL_URL}/${id}`)
};
