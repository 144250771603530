import React from 'react';
import Otp from '../../shared/otp/Otp';
import PopupWithMemoryRouter, {PopupMemoryRouterRouteProps} from '../../shared/popups/PopupWithMemoryRouter';
import {SettingsRoutesPaths} from '../settings.routes-paths';
import PasswordChangeFormPopupContent from './NewPasswordPopupContent';
import OldPasswordPopupContent from './OldPasswordPopupContent';
import PasswordChangeSuccessPopupContent from './PasswordChangeSuccessPopupContent';

interface PasswordChangePopupProps {
  isPopupOpen: boolean;
  onPopupClose: () => void;
}

const PasswordChangePopup = ({isPopupOpen, onPopupClose}: PasswordChangePopupProps) => {

  const routes: PopupMemoryRouterRouteProps[] = [
    {
      path: SettingsRoutesPaths.CHANGE_PASSWORD_OLD_PASSWORD_FORM,
      key: 'CHANGE_PASSWORD_OLD_PASSWORD_FORM',
      render: () => <OldPasswordPopupContent />
    },
    {
      path: SettingsRoutesPaths.CHANGE_PASSWORD_NEW_PASSWORD_FORM,
      key: 'CHANGE_PASSWORD_NEW_PASSWORD_FORM',
      render: () => <PasswordChangeFormPopupContent />
    },
    {
      path: SettingsRoutesPaths.CHANGE_PASSWORD_OTP,
      key: 'CHANGE_PASSWORD_OTP',
      render: () => <Otp />
    },
    {
      path: SettingsRoutesPaths.CHANGE_PASSWORD_SUCCESS,
      key: 'CHANGE_PASSWORD_SUCCESS',
      hideDefaultPopupCloseButton: true,
      render: () => <PasswordChangeSuccessPopupContent />
    }
  ];

  return (
    <PopupWithMemoryRouter open={isPopupOpen} handleClose={onPopupClose} routes={routes} />
  );
};

export default PasswordChangePopup;
