import {Button} from '@material-ui/core';
import React from 'react';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import HeroSuccess from '../../../assets/images/hero-success.svg';
import {RoutesPaths} from '../../../routes/routes-paths/routes-paths';
import {StringHelper} from '../../../utils/string-helper';
import {GoalsLocationProps} from '../../goals/Goals';
import Popup from '../../shared/popups/Popup';
import styles from './WelcomePopup.module.scss';

interface WelcomePopupProps {
  userName: string,
  isOpen: boolean,
  onExploreClick: () => void
}

export default function WelcomePopup ({userName, isOpen, onExploreClick}: WelcomePopupProps) {
  return (
    <Popup open={isOpen} mobileFullScreen={false}>
      <div className={styles.container}>
        <img src={HeroSuccess} alt="hero success" />
        <div className={styles.title}>
          <Trans tOptions={{userName: StringHelper.capitalizeFirstLetter(userName)}}>
            DASHBOARD.WELCOME_POPUP.TITLE
          </Trans>
        </div>
        <div className={styles.description}>
          <Trans>DASHBOARD.WELCOME_POPUP.DESCRIPTION</Trans>
        </div>
        <Button variant="contained" className={styles.action} onClick={onExploreClick}>
          <Trans>DASHBOARD.WELCOME_POPUP.EXPLORE</Trans>
        </Button>
        <Link to={{
          pathname: RoutesPaths.GOALS,
          state: {isCreateStarted: true} as GoalsLocationProps
        }} className={styles.action}>
          <Button variant="outlined" className={styles.button}>
            <Trans>DASHBOARD.WELCOME_POPUP.GOALS</Trans>
          </Button>
        </Link>
      </div>
    </Popup>
  );
};
