import {CommandOutput} from '../../shared/model/command/CommandOutput';
import HttpService from '../../shared/services/http.service';

const PROSPECT_URL = '/prospect';
const CUSTOMER_URL = '/customer';
const CHANGE_PASSWORD_URL = '/auth/secrets';

export interface PasswordChangePayload {
  newSecret: string;
  oldSecret: string;
}

export const SettingsService = {
  changeProspectPassword: (passwordData: PasswordChangePayload): Promise<CommandOutput<void>> =>
    HttpService.put<CommandOutput<void>>(`${PROSPECT_URL}${CHANGE_PASSWORD_URL}`, passwordData),

  changeCustomerPassword: (passwordData: PasswordChangePayload): Promise<CommandOutput<void>> =>
    HttpService.put<CommandOutput<void>>(`${CUSTOMER_URL}${CHANGE_PASSWORD_URL}`, passwordData)
};
