import React from 'react';
import {Dialog, useTheme, useMediaQuery} from "@material-ui/core";
import styles from './Popup.module.scss';

interface PopupProps {
  children: React.ReactElement;
  mobileFullScreen?: boolean;
  open: boolean;
}

const Popup = ({children, open, mobileFullScreen = true}: PopupProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(mobileFullScreen ? theme.breakpoints.down('sm') : 'false');

  return (
    <Dialog fullScreen={fullScreen} open={open}>
      <div className={styles.content}>{ children }</div>
    </Dialog>
  );
};

export default Popup;