import axios, {CancelTokenSource} from 'axios';
import React, {createContext} from 'react';
import {Trans} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import BillsIcon from '../../../assets/images/icons/services-icons/bills.svg';
import GovernmentIcon from '../../../assets/images/icons/services-icons/government.svg';
import LoadIcon from '../../../assets/images/icons/services-icons/load.svg';
import TelemedIcon from '../../../assets/images/icons/services-icons/telemed.svg';
import {DashboardRoutesPaths} from '../../../routes/routes-paths/dashboard.routes-paths';
import {RoutesPaths} from '../../../routes/routes-paths/routes-paths';
import BillPaymentPopup from '../../shared/bill-payment/popup/BillPaymentPopup';
import BuyLoadPopup from './buy-load/popup/BuyLoadPopup';
import styles from './Services.module.scss';

interface BuyLoadServicePopupContextProps {
  closePopup: () => void;
  cancelToken?: CancelTokenSource;
}

interface ServicesProps {
  buyLoadPopupOpened: boolean;
  governmentPopupOpened: boolean;
}

export const BuyLoadServicePopupContext = createContext<BuyLoadServicePopupContextProps>({
  closePopup: () => {
  }
});

const GOVERNMENT_CATEGORY_GROUP = 'GOVERNMENT';

export default function Services({buyLoadPopupOpened, governmentPopupOpened}: ServicesProps) {
  const cancelTokenSource = axios.CancelToken.source();
  const history = useHistory();

  const closePopup = () => {
    cancelTokenSource.cancel('Popup closed');
    history.push(RoutesPaths.DASHBOARD);
  };

  return (
    <div className={styles.container}>
      <p className={styles.header}><Trans>DASHBOARD.SERVICES.HEADER</Trans></p>
      <div className={styles.services}>
        <Link to={DashboardRoutesPaths.BUY_LOAD} className={styles.service}>
          <div className={styles['icon-wrapper']}>
            <img src={LoadIcon} alt="buy load" className={styles.icon} />
          </div>
          <span className={styles.label}>
            <Trans>DASHBOARD.SERVICES.BUY_LOAD</Trans>
          </span>
        </Link>
        <Link to={DashboardRoutesPaths.PAY_BILLS} className={styles.service}>
          <div className={styles['icon-wrapper']}>
            <img src={BillsIcon} alt="pay bills" className={styles.icon} />
          </div>
          <span className={styles.label}>
            <Trans>DASHBOARD.SERVICES.PAY_BILLS</Trans>
          </span>
        </Link>
        <Link to={DashboardRoutesPaths.GOVERNMENT} className={styles.service}>
          <div className={styles['icon-wrapper']}>
            <img src={GovernmentIcon} alt="government" className={styles.icon} />
          </div>
          <span className={styles.label}>
            <Trans>DASHBOARD.SERVICES.GOVERNMENT</Trans>
          </span>
        </Link>
        <Link to={DashboardRoutesPaths.TELEMEDICINE} className={styles.service}>
          <div className={styles['icon-wrapper']}>
            <img src={TelemedIcon} alt="telemedicine" className={styles.icon} />
          </div>
          <span className={styles.label}>
            <Trans>DASHBOARD.SERVICES.TELEMEDICINE</Trans>
          </span>
        </Link>
      </div>
      <BuyLoadServicePopupContext.Provider value={{closePopup, cancelToken: cancelTokenSource}}>
        <BuyLoadPopup isOpen={buyLoadPopupOpened}
                      onClose={() => closePopup()} />
      </BuyLoadServicePopupContext.Provider>
      <BillPaymentPopup isOpen={governmentPopupOpened}
                        onClose={() => closePopup()}
                        categoryGroup={GOVERNMENT_CATEGORY_GROUP} />
    </div>
  );
};
