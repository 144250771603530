import dayjs, {Dayjs} from 'dayjs';

export interface TelemedicineOperationsTO<T_RESULT extends TelemedicineOperationTO<any> = TelemedicineOperationTO> {
  pageNo: number;
  resultCount: number;
  totalCount: number;
  result: T_RESULT[];
}

export interface TelemedicineOperations extends TelemedicineOperationsTO<TelemedicineOperation> {
}

export const emptyTelemedicineOperations = {result: [] as TelemedicineOperation[]} as TelemedicineOperations;

export interface TelemedicineOperationTO<T_DATE = string> {
  amount: number;
  id: number;
  referenceNo: string;
  registeredOn: T_DATE;
  telemedicineProductId: number;
  telemedicineProductName: string;
}

export interface TelemedicineOperation extends TelemedicineOperationTO<Dayjs> {
}

export const fromTelemedicineOperationTO = (telemedicineOperationTO: TelemedicineOperationTO): TelemedicineOperation => ({
  ...telemedicineOperationTO,
  registeredOn: dayjs(telemedicineOperationTO.registeredOn)
});

export const fromTelemedicineOperationsTO = (telemedicineOperationsTO: TelemedicineOperationsTO): TelemedicineOperations => ({
  ...telemedicineOperationsTO,
  result: telemedicineOperationsTO.result.map(telemedicineOperationTO => fromTelemedicineOperationTO(
    telemedicineOperationTO))
});
