import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from '@material-ui/core';
import {Formik, FormikProps} from 'formik';
import {toNumber} from 'lodash';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import * as Yup from 'yup';
import {REQUIRED_FIELD_MESSAGE_CODE} from '../../../constants/values';
import {DictionaryCode, DictionaryEntry} from '../../../shared/model/Dictionary';
import SystemPropertiesService from '../../../shared/services/system-properties.service';
import {VerificationService} from '../../../shared/services/verification.service';
import {DictionaryHelper} from '../../../utils/dictionary-helper';
import Loader from '../../shared/loader/Loader';
import styles from '../personal-details/VerificationPersonalDetails.module.scss';
import {VerificationStep} from '../steps/VerificationStep';
import {VerificationStepContent} from '../steps/VerificationStepContent';
import {VerificationStepFooter} from '../steps/VerificationStepFooter';
import {VerificationContext} from '../Verification';

const ValidationSchema = Yup.object({
  documentTypeId: Yup.string().required(REQUIRED_FIELD_MESSAGE_CODE)
}).defined();

type FormFields = Yup.InferType<typeof ValidationSchema>;

export const VerificationIdDocument = () => {
  const {application, setApplication, dictionaries} = useContext(VerificationContext);
  const [isLoading, setIsLoading] = useState(true);
  const [documentTypeIdOptions, setDocumentTypeIdOptions] = useState<DictionaryEntry[]>([]);
  const documentTypeIdDictionary = DictionaryHelper.getDictionaryByCode(dictionaries, DictionaryCode.ID_DOCUMENT_TYPE);

  useEffect(() => {
    SystemPropertiesService.getAuthenticatedSystemSetup()
      .then(value => {
          const allowedIdTypes = value.allowedProspectApplicationIdDocumentTypeCodes ?? [];
          const idOptions = documentTypeIdDictionary.filter(entry => allowedIdTypes.includes(entry.code));
          setDocumentTypeIdOptions(idOptions);
          setIsLoading(false);
        }
      );
  }, [documentTypeIdDictionary]);

  const submit = (values: FormFields) => {
    const applicationData = {
      ...application,
      documentTypeId: values.documentTypeId ? toNumber(values.documentTypeId) : undefined
    };

    return VerificationService.updateProspectApplication(application.id, applicationData)
      .then(() => setApplication(applicationData));
  };

  const RouteTrans = ({children}) => <Trans>{`VERIFICATION.ID_DOCUMENT.${children}`}</Trans>;

  const IdDocumentForm = ({validateForm, values, errors, handleChange, handleBlur, submitForm, isValid}: FormikProps<FormFields>): ReactElement =>
    (
      <form>
        <VerificationStepContent description={<RouteTrans>DESCRIPTION</RouteTrans>}>
          {
            isLoading ? <Loader /> :
              <FormControl className={styles.input} error={!!errors.documentTypeId}>
                <InputLabel id="type-of-id">
                  <RouteTrans>TYPE_OF_ID</RouteTrans>
                </InputLabel>
                <Select
                  required
                  labelId="type-of-id"
                  name="documentTypeId"
                  displayEmpty
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={documentTypeIdOptions ? values.documentTypeId : ''}>
                  {
                    documentTypeIdOptions?.map((option, index) => (
                      <MenuItem value={String(option.id)} key={index}>
                        {option.value}
                      </MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText><Trans>{errors.documentTypeId}</Trans></FormHelperText>
              </FormControl>
          }
        </VerificationStepContent>
        <VerificationStepFooter validateForm={validateForm} submit={submitForm} isSubmitDisabled={!isValid} />
      </form>
    );

  return (
    <VerificationStep>
      <Formik
        <FormFields>
        onSubmit={submit}
        validationSchema={ValidationSchema}
        initialValues={
          {
            documentTypeId: String(application.documentTypeId ?? '')
          }
        }
        children={IdDocumentForm}
      />
    </VerificationStep>
  );
};
