import React from 'react';
import Heading from '../shared/heading/Heading';
import MainContainer from '../shared/containers/MainContainer';
import AccountOptions from './account-options/AccountOptions';
import styles from './Account.module.scss';

/* Component accessible only on mobile */
export default function Account() {
  return (
    <MainContainer>
      <>
        <Heading title="ACCOUNT.HEADER" />
        <div className={styles.box}>
          <AccountOptions />
        </div>
      </>
    </MainContainer>
  );
}
