import {CommandOutput} from '../../shared/model/command/CommandOutput';
import HttpService from '../../shared/services/http.service';
import {InitiatePasswordResetOutput, ResetPasswordConfirmationType} from './model/ResetPassword';

const SECRETS_AUTH_URL = '/common/auth/secrets';

export interface InitiatePasswordResetParams {
  birthDate: string;
  firstName: string;
  lastName: string;
  mobileNo: string;
}

export interface ResetPasswordParams {
  confirmationType: ResetPasswordConfirmationType,
  resetToken: string,
  secret: string
}

export const ResetPasswordService = {
  initiatePasswordReset: (params: InitiatePasswordResetParams): Promise<InitiatePasswordResetOutput> =>
    HttpService.post<InitiatePasswordResetOutput>(
      `${SECRETS_AUTH_URL}/init-reset-by-personal-data?channel=WEB`, params),

  resetPassword: (params: ResetPasswordParams): Promise<CommandOutput> =>
    HttpService.post<CommandOutput>(`${SECRETS_AUTH_URL}/reset`, params)
}
