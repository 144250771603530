import {InputAdornment, TextField} from '@material-ui/core';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import React, {useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import ArrowRight from '../../../../assets/images/icons/arrow-right.svg';
import SearchIcon from '../../../../assets/images/icons/search.svg';
import {PaymentProduct} from '../../../../shared/model/PaymentProduct';
import PaymentService from '../../../../shared/services/payment.service';
import {ErrorHelper} from '../../../../utils/error-helper';
import {cleanUp} from '../../../../utils/handle-abort-controller-clean-up';
import {BillPaymentRoutesPaths} from '../../../bill-payment/bill-payment.routes-paths';
import {BILL_PAYMENT_GROUP} from '../../../bill-payment/constants';
import {useBillCategoryTranslationOrCodeHook} from '../../../bill-payment/getBillCategoryTranslationOrCode';
import Loader from '../../loader/Loader';
import styles from './BillPaymentProducts.module.scss';

interface BillPaymentProductsProps {
  categoryGroup: string;
}

export default function BillPaymentProducts({categoryGroup}: BillPaymentProductsProps) {
  const [products, setProducts] = useState<PaymentProduct[]>([]);
  const [searchedText, setSearchedText] = useState<string>('');
  const history = useHistory();
  const {t} = useTranslation();
  const translatedCategoryGroup = useBillCategoryTranslationOrCodeHook(categoryGroup);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    PaymentService.getPaymentProducts({group: BILL_PAYMENT_GROUP, category: categoryGroup}, cancelTokenSource)
      .then(products => setProducts(products))
      .catch(error => {
        if (ErrorHelper.isAbortOrSessionRestrictionError(error)) {
          return;
        }
        setProducts([]);
      });

    return cleanUp(cancelTokenSource);
  }, [categoryGroup]);

  const BillerRow = ({billerName, billerId}: BillerRowTypes) => {
    const handleRowClick = () => {
      history.push(BillPaymentRoutesPaths.PAYMENT_DETAILS, {
        productId: billerId,
        categoryGroup
      });
    };

    return (
      <div className={styles['product-row']} onClick={handleRowClick}>
        <span className={styles['product-row__label']}>{billerName}</span>
        <img src={ArrowRight} alt={`select-${billerName}-biller`} className={styles['arrow-icon']} />
      </div>
    );
  };

  interface BillerRowTypes {
    billerName: string;
    billerId: number;
  }

  return (
    <>
      <div className={styles.title}>
        {translatedCategoryGroup}
      </div>
      <div className={styles.container}>
        <TextField
          value={searchedText}
          onChange={(event) => {
            setSearchedText(event.target.value);
          }}
          inputProps={{
            placeholder:
              t('BILL_PAYMENT.SELECT_PRODUCT.SEARCH_BOX_PLACEHOLDER', {billerName: translatedCategoryGroup})
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={SearchIcon} alt="Search" />
              </InputAdornment>
            ),
            classes: {
              root: styles['search-box'],
              // TODO should be variant filled - but some refactor will be needed
              notchedOutline: styles['no-border-override']
            }
          }} />
        <div className={styles.description}>
          <Trans>BILL_PAYMENT.SELECT_PRODUCT.DESCRIPTION</Trans>
        </div>
        {
          isEmpty(products) ? <Loader /> : (
            <div className={styles.categories}>
              {
                products
                  .filter(product => product.name.toUpperCase().includes(searchedText.toUpperCase()))
                  .map(product => <BillerRow key={product.id} billerName={product.name} billerId={product.id} />)
              }
            </div>
          )
        }
      </div>
    </>
  );
}
