import {Button} from '@material-ui/core';
import {Formik} from 'formik';
import React, {ReactElement, useContext, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {ErrorHelper} from '../../../../../utils/error-helper';
import {StringHelper} from '../../../../../utils/string-helper';
import {AlertSnackbar} from '../../../../shared/alert-snackbar/AlertSnackbar';
import PhoneTextField from '../../../../shared/fields/PhoneTextField';
import Loader from '../../../../shared/loader/Loader';
import {BuyLoadServicePopupContext} from '../../Services';
import {BuyLoadPaymentService} from '../buy-load-payment.service';
import styles from './BuyLoadPhoneValidation.module.scss';
import {BuyLoadRoutesPaths} from '../buy-load.routes-paths';

interface FormFields {
  mobileNo: string;
}

const formInitState = {
  mobileNo: ''
} as FormFields;

export default function BuyLoadPhoneValidation() {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const {t} = useTranslation();
  const history = useHistory();
  const cancelToken = useContext(BuyLoadServicePopupContext).cancelToken;

  const submit = (values: FormFields) =>
    BuyLoadPaymentService.validateMobile(StringHelper.removeWhitespaces(values.mobileNo), cancelToken)
      .then(result =>
        history.push(BuyLoadRoutesPaths.PRODUCT_SELECTION, {
          ...result
        })
      )
      .catch(error => {
        setErrorMessage(ErrorHelper.getErrorMessageTranslation(t, error));
        setShowError(true);
      });

  const MobileNoValidationFrom = ({values, errors, handleChange, handleBlur, handleSubmit, isSubmitting}): ReactElement => (
    <form className={styles.form}>
      <div className={styles.container}>
        {/*Div required to keep phone field same width as text*/}
        <div>
          <div className={styles.description}>
            <Trans>BUY_LOAD.PHONE_VALIDATION_DESCRIPTION</Trans>
          </div>
          <PhoneTextField
            className={styles['full-width']}
            label={<Trans>SHARED.COMMON.MOBILE_NO</Trans>}
            name="mobileNo"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.mobileNo} />
        </div>
      </div>
      <div className={styles.actions}>
        <Button type="submit" className={styles.submit} disabled={isSubmitting} onClick={handleSubmit}>
          <Loader buttonSpinner loaded={!isSubmitting}>
            <Trans>SHARED.COMMON.SUBMIT</Trans>
          </Loader>
        </Button>
      </div>
    </form>
  );

  return (<>
    <div className={styles.title}>
      <Trans>BUY_LOAD.HEADER</Trans>
    </div>
    <AlertSnackbar handleClose={() => setShowError(false)} message={errorMessage} open={showError} />
    <Formik
      <FormFields>
      initialValues={formInitState}
      onSubmit={submit}
      children={MobileNoValidationFrom}
    />
  </>);
}