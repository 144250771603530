import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import Airline from '../../../../assets/images/icons/bill-icons/airline.svg';
import Cable from '../../../../assets/images/icons/bill-icons/cable.svg';
import Contribution from '../../../../assets/images/icons/bill-icons/contribution.svg';
import Electricity from '../../../../assets/images/icons/bill-icons/electricity.svg';
import Government from '../../../../assets/images/icons/bill-icons/government.svg';
import Insurance from '../../../../assets/images/icons/bill-icons/insurance.svg';
import Others from '../../../../assets/images/icons/bill-icons/others.svg';
import Utilities from '../../../../assets/images/icons/bill-icons/utilities.svg';
import Water from '../../../../assets/images/icons/bill-icons/water.svg';
import {BILL_CATEGORY_TRANSLATION_PATH} from '../../constants';
import {BillCategoryCodes} from '../BillCategoryCodes';
import styles from './BillCategoryTile.module.scss';

interface BillCategoryTileProps {
  categoryCode: string;
  onClick: () => void;
}

export default function BillCategoryTile({categoryCode, onClick}: BillCategoryTileProps) {
  const {i18n} = useTranslation();

  const renderTranslationOrCode = () => {
    const categoryTranslationPath = `${BILL_CATEGORY_TRANSLATION_PATH}.${categoryCode}`;
    return i18n.exists(categoryTranslationPath)
      ? <Trans>{categoryTranslationPath}</Trans>
      : categoryCode;
  };

  return (
    <div className={styles.category}>
      <img src={getIconByCategoryCode(categoryCode)}
           alt={categoryCode}
           className={styles['category__icon']}
           onClick={onClick} />
      <div className={styles['category__name']}>
        {renderTranslationOrCode()}
      </div>
    </div>
  );
};

const getIconByCategoryCode = (categoryCode: BillCategoryCodes | string) => {
  switch (categoryCode) {
    case BillCategoryCodes.AIRLINES:
      return Airline;
    case BillCategoryCodes.CABLE:
      return Cable;
    case BillCategoryCodes.SSS_CONTRIBUTION:
      return Contribution;
    case BillCategoryCodes.ELECTRICITY:
      return Electricity;
    case BillCategoryCodes.GOVERNMENT:
      return Government;
    case BillCategoryCodes.INSURANCE:
      return Insurance;
    case BillCategoryCodes.UTILITIES:
      return Utilities;
    case BillCategoryCodes.WATER:
      return Water;
    case BillCategoryCodes.OTHERS:
      return Others;
    default:
      return Others;
  }
};
