import axios, {CancelTokenSource} from 'axios';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {WithdrawalService} from '../../../shared/services/withdrawal.service';
import {fetchUserPrimaryAccount} from '../../../store/actions';
import {getUserAccount} from '../../../store/selectors';
import {ErrorHelper} from '../../../utils/error-helper';
import {cleanUp} from '../../../utils/handle-abort-controller-clean-up';
import Columns from '../../shared/columns/Columns';
import MainContainer from '../../shared/containers/MainContainer';
import EmptyStateWithBackLink from '../../shared/empty-state/EmptyStateWithBackLink';
import Loader from '../../shared/loader/Loader';
import PopupWithMemoryRouter from '../../shared/popups/PopupWithMemoryRouter';
import {WithdrawalRoutesPaths} from '../withdrawal.routes-paths';
import PendingWithdrawalCancelConfirmation from './cancel-confirmation/PendingWithdrawalCancelConfirmation';
import {Withdrawal} from './model/Withdrawal';
import {PendingWithdrawalRow} from './PendingWithdrawalRow';
import {PendingWithdrawalReferenceNumber} from './reference-number/PendingWithdrawalReferenceNumber';

type WithdrawalPopupState = {
  isOpen: boolean,
  withdrawal?: Withdrawal
}

const PendingWithdrawals = () => {
  const {t} = useTranslation();
  const [withdrawalPopupState, setWithdrawalPopupState] = useState<WithdrawalPopupState>({isOpen: false});
  const [pendingWithdrawals, setPendingWithdrawals] = useState<Withdrawal[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const userAccount = useSelector(getUserAccount);
  const customerId = userAccount?.id;
  const dispatch = useDispatch();

  const getPendingWithdrawals = useCallback((cancelTokenSource?: CancelTokenSource | null): Promise<void> => {
      setIsLoaded(false);

      return WithdrawalService.getPendingCardlessWithdrawals({customerId}, cancelTokenSource)
        .then(pendingWithdrawals => {
          setPendingWithdrawals(pendingWithdrawals.result);
          setIsLoaded(true);
        })
        .catch(error => {
          if (ErrorHelper.isAbortOrSessionRestrictionError(error)) {
            return;
          }
          setPendingWithdrawals([]);
          setIsLoaded(true);
        });
    }, [customerId]
  );

  useEffect(() => {
    dispatch(fetchUserPrimaryAccount());

    if (!userAccount) {
      return;
    }

    const cancelTokenSource = axios.CancelToken.source();

    getPendingWithdrawals(cancelTokenSource);

    return cleanUp(cancelTokenSource);
  }, [dispatch, getPendingWithdrawals, userAccount]);

  const closePopup = () => {
    setWithdrawalPopupState({...withdrawalPopupState, isOpen: false});
  };

  const openPopup = (withdrawal: Withdrawal) => {
    setWithdrawalPopupState({withdrawal, isOpen: true});
  };

  const pendingWithdrawalRoutes = [
    {
      path: WithdrawalRoutesPaths.PENDING_WITHDRAWAL_REFERENCE_NUMBER,
      key: 'PENDING_WITHDRAWAL_REFERENCE_NUMBER',
      render: () => <PendingWithdrawalReferenceNumber withdrawal={withdrawalPopupState.withdrawal!} />
    },
    {
      path: WithdrawalRoutesPaths.PENDING_WITHDRAWAL_CANCEL,
      key: 'PENDING_WITHDRAWAL_REFERENCE_NUMBER_CANCEL',
      hideDefaultPopupCloseButton: true,
      render: props => <PendingWithdrawalCancelConfirmation handlePopupClose={closePopup}
                                                            handleWithdrawalsRefresh={getPendingWithdrawals}
                                                            {...props} />
    }
  ];

  const EmptyState = <EmptyStateWithBackLink emptyMessage={t('WITHDRAW.PENDING_WITHDRAWALS.EMPTY_STATE')} />;

  const PendingWithdrawals = pendingWithdrawals.length === 0
    ? EmptyState
    : pendingWithdrawals.map(withdrawal => <PendingWithdrawalRow onClick={() => openPopup(withdrawal)}
                                                                 withdrawal={withdrawal}
                                                                 key={withdrawal.id} />);

  return (
    <MainContainer>
      <Columns
        heading="WITHDRAW.PENDING_WITHDRAWALS.TITLE"
        mainColumn={
          <>
            {
              isLoaded ? PendingWithdrawals : <Loader />
            }
            <PopupWithMemoryRouter open={withdrawalPopupState.isOpen}
                                   handleClose={closePopup}
                                   routes={pendingWithdrawalRoutes} />
          </>
        }
      />
    </MainContainer>
  );
};

export default PendingWithdrawals;
