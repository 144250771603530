import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import {Formik, FormikProps} from 'formik';
import {mapValues, toNumber} from 'lodash';
import isEqual from 'lodash/isEqual';
import React, {ReactElement, useContext} from 'react';
import {Trans} from 'react-i18next';
import * as Yup from 'yup';
import {
  INCORRECT_FORMAT_MESSAGE_CODE,
  POSTAL_CODE_REGEXP_PATTERN,
  REQUIRED_FIELD_MESSAGE_CODE
} from '../../../constants/values';
import {GeoLocationsDictionaryEntry} from '../../../shared/model/Dictionary';
import {AddressType} from '../../../shared/model/verification/Address';
import {Application} from '../../../shared/model/verification/ProspectAppliaction';
import {VerificationService} from '../../../shared/services/verification.service';
import {getUndefinedIfEmpty} from '../../../utils/undefined-if-empty';
import {VerificationStep} from '../steps/VerificationStep';
import {VerificationStepContent} from '../steps/VerificationStepContent';
import {VerificationStepFooter} from '../steps/VerificationStepFooter';
import {VerificationContext} from '../Verification';
import styles from './VerificationAddress.module.scss';

const addressSameRule = {
  is: false,
  then: Yup.string().required(REQUIRED_FIELD_MESSAGE_CODE)
};

const ValidationSchema = Yup.object({
  primaryAddressProvince: Yup.string().required(REQUIRED_FIELD_MESSAGE_CODE),
  primaryAddressCity: Yup.string().required(REQUIRED_FIELD_MESSAGE_CODE),
  primaryAddressBarangay: Yup.string().required(REQUIRED_FIELD_MESSAGE_CODE),
  primaryAddressStreet: Yup.string().required(REQUIRED_FIELD_MESSAGE_CODE),
  primaryAddressPostalCode: Yup.string()
    .required(REQUIRED_FIELD_MESSAGE_CODE)
    .matches(new RegExp(POSTAL_CODE_REGEXP_PATTERN), INCORRECT_FORMAT_MESSAGE_CODE),
  areAddressesSame: Yup.boolean(),
  secondaryAddressProvince: Yup.string().when('areAddressesSame', addressSameRule),
  secondaryAddressCity: Yup.string().when('areAddressesSame', addressSameRule),
  secondaryAddressBarangay: Yup.string().when('areAddressesSame', addressSameRule),
  secondaryAddressStreet: Yup.string().when('areAddressesSame', addressSameRule),
  secondaryAddressPostalCode: Yup.string()
    .when('areAddressesSame', addressSameRule)
    .matches(new RegExp(POSTAL_CODE_REGEXP_PATTERN), INCORRECT_FORMAT_MESSAGE_CODE)
}).defined();

type FormFields = Yup.InferType<typeof ValidationSchema>;

// TODO add input masks for postal code
export const VerificationAddress = () => {
  const {application, setApplication, citiesGeoDictionary, provincesGeoDictionary} = useContext(VerificationContext);

  const submit = (values: FormFields) => {

    // Prevent from sending empty strings to the backend
    const mappedValues = mapValues(values, value => getUndefinedIfEmpty(value));

    const primaryAddress = {
      province: mappedValues.primaryAddressProvince,
      city: mappedValues.primaryAddressCity,
      barangay: mappedValues.primaryAddressBarangay,
      street: mappedValues.primaryAddressStreet,
      postalCode: mappedValues.primaryAddressPostalCode
    };

    const secondaryAddress = {
      province: mappedValues.secondaryAddressProvince,
      city: mappedValues.secondaryAddressCity,
      barangay: mappedValues.secondaryAddressBarangay,
      street: mappedValues.secondaryAddressStreet,
      postalCode: mappedValues.secondaryAddressPostalCode
    };

    const applicationData: Application = {
      ...application,
      primaryAddress: {
        ...primaryAddress,
        type: AddressType.PRESENT
      },
      secondaryAddress: {
        ...values.areAddressesSame ? primaryAddress : secondaryAddress,
        type: AddressType.PERMANENT
      }
    };

    return VerificationService.updateProspectApplication(application.id, applicationData)
      .then(() => setApplication(applicationData));
  };

  const getFilteredCitiesDictionary = (provinceId?: string): GeoLocationsDictionaryEntry[] =>
    citiesGeoDictionary.filter(entry => entry.parentId === toNumber(provinceId));

  const RouteTrans = ({children}) => <Trans>{`VERIFICATION.ADDRESS.${children}`}</Trans>;

  const AddressForm = ({validateForm, values, errors, handleChange, handleBlur, submitForm, isValid}: FormikProps<FormFields>): ReactElement =>
    (
      <form>
        <VerificationStepContent description={<RouteTrans>DESCRIPTION</RouteTrans>}>
          <p className={styles['form-group-label']}>
            <RouteTrans>PRESENT_ADDRESS</RouteTrans>
          </p>
          <FormControl className={styles.input}>
            <InputLabel id="country-label">
              <RouteTrans>COUNTRY</RouteTrans>
            </InputLabel>
            {/* Select always disabled */}
            <Select
              disabled
              labelId="country-label"
              name="startDate"
              value={1}>
              {
                <MenuItem value={1}>
                  <RouteTrans>PHILIPPINES</RouteTrans>
                </MenuItem>
              }
            </Select>
          </FormControl>
          <div className={styles.row}>
            <FormControl required
                         className={`${styles.input} ${styles['input--half']}`}
                         error={!!errors.primaryAddressProvince}>
              <InputLabel id="primary-address-province-label">
                <RouteTrans>STATE_OR_PROVINCE</RouteTrans>
              </InputLabel>
              <Select
                labelId="primary-address-province-label"
                name="primaryAddressProvince"
                displayEmpty
                onBlur={handleBlur}
                onChange={e => {
                  values.primaryAddressCity = '';
                  handleChange(e);
                }}
                value={values.primaryAddressProvince}>
                {
                  provincesGeoDictionary.map((province, index) => (
                    <MenuItem value={String(province.id)} key={index}>{province.name}</MenuItem>
                  ))
                }
              </Select>
              <FormHelperText>
                <Trans>{errors.primaryAddressProvince}</Trans>
              </FormHelperText>
            </FormControl>
            <FormControl required
                         className={`${styles.input} ${styles['input--half']}`}
                         error={!!errors.primaryAddressCity}>
              <InputLabel id="primary-address-city-label">
                <Trans>SHARED.COMMON.CITY_OR_MUNICIPALITY</Trans>
              </InputLabel>
              <Select
                disabled={!values.primaryAddressProvince}
                labelId="primary-address-city-label"
                type="number"
                name="primaryAddressCity"
                displayEmpty
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.primaryAddressCity}>
                {
                  getFilteredCitiesDictionary(values.primaryAddressProvince)
                    .map((city, index) => (
                      <MenuItem value={String(city.id)} key={index}>{city.name}</MenuItem>
                    ))
                }
              </Select>
              <FormHelperText>
                <Trans>{errors.primaryAddressCity}</Trans>
              </FormHelperText>
            </FormControl>
          </div>
          <TextField
            required
            className={styles.input}
            label={<RouteTrans>BARANGAY</RouteTrans>}
            type="text"
            name="primaryAddressBarangay"
            error={!!errors.primaryAddressBarangay}
            helperText={errors.primaryAddressBarangay && <Trans>{errors.primaryAddressBarangay}</Trans>}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.primaryAddressBarangay}
          />
          <TextField
            required
            multiline
            className={styles.input}
            label={<Trans>SHARED.COMMON.STREET</Trans>}
            type="text"
            name="primaryAddressStreet"
            error={!!errors.primaryAddressStreet}
            helperText={errors.primaryAddressStreet && <Trans>{errors.primaryAddressStreet}</Trans>}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.primaryAddressStreet}
          />
          <TextField
            required
            className={styles.input}
            label={<RouteTrans>POSTAL_CODE</RouteTrans>}
            type="text"
            name="primaryAddressPostalCode"
            error={!!errors.primaryAddressPostalCode}
            helperText={errors.primaryAddressPostalCode && <Trans>{errors.primaryAddressPostalCode}</Trans>}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.primaryAddressPostalCode}
          />
          <p className={styles['form-group-label']}>
            <RouteTrans>PERMANENT_ADDRESS</RouteTrans>
          </p>
          <FormControlLabel
            classes={{root: styles.checkbox, label: styles['checkbox__label']}}
            label={<RouteTrans>SAME_AS_PRESENT_ADDRESS</RouteTrans>}
            control={<Checkbox checked={values.areAddressesSame} onChange={handleChange} name="areAddressesSame" />}
          />
          {
            // Formik accepts only components here
            values.areAddressesSame ? <></> :
              <>
                <div className={styles.row}>
                  <FormControl required={!values.areAddressesSame}
                               className={`${styles.input} ${styles['input--half']}`}
                               error={!!errors.secondaryAddressProvince}>
                    <InputLabel id="secondary-address-province-label">
                      <RouteTrans>STATE_OR_PROVINCE</RouteTrans>
                    </InputLabel>
                    <Select
                      labelId="secondary-address-province-label"
                      name="secondaryAddressProvince"
                      displayEmpty
                      onBlur={handleBlur}
                      onChange={e => {
                        values.secondaryAddressCity = '';
                        handleChange(e);
                      }}
                      value={values.secondaryAddressProvince}>
                      {
                        provincesGeoDictionary.map((province, index) => (
                          <MenuItem value={province.id} key={index}>{province.name}</MenuItem>
                        ))
                      }
                    </Select>
                    <FormHelperText><Trans>{errors.secondaryAddressProvince}</Trans></FormHelperText>
                  </FormControl>
                  <FormControl required={!values.areAddressesSame}
                               className={`${styles.input} ${styles['input--half']}`}
                               error={!!errors.secondaryAddressCity}>
                    <InputLabel id="secondary-address-city-label">
                      <Trans>SHARED.COMMON.CITY_OR_MUNICIPALITY</Trans>
                    </InputLabel>
                    <Select
                      disabled={!values.secondaryAddressProvince}
                      labelId="secondary-address-city-label"
                      type="number"
                      name="secondaryAddressCity"
                      displayEmpty
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.secondaryAddressCity}>
                      {
                        getFilteredCitiesDictionary(values.secondaryAddressProvince)
                          .map((city, index) => (
                            <MenuItem value={city.id} key={index}>{city.name}</MenuItem>
                          ))
                      }
                    </Select>
                    <FormHelperText>
                      <Trans>{errors.secondaryAddressCity}</Trans>
                    </FormHelperText>
                  </FormControl>
                </div>
                <TextField
                  required={!values.areAddressesSame}
                  className={styles.input}
                  label={<RouteTrans>BARANGAY</RouteTrans>}
                  type="text"
                  name="secondaryAddressBarangay"
                  error={!!errors.secondaryAddressBarangay}
                  helperText={errors.secondaryAddressBarangay && <Trans>{errors.secondaryAddressBarangay}</Trans>}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.secondaryAddressBarangay}
                />
                <TextField
                  required={!values.areAddressesSame}
                  multiline
                  className={styles.input}
                  label={<Trans>SHARED.COMMON.STREET</Trans>}
                  type="text"
                  name="secondaryAddressStreet"
                  error={!!errors.secondaryAddressStreet}
                  helperText={errors.secondaryAddressStreet && <Trans>{errors.secondaryAddressStreet}</Trans>}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.secondaryAddressStreet}
                />
                <TextField
                  required={!values.areAddressesSame}
                  className={styles.input}
                  label={<RouteTrans>POSTAL_CODE</RouteTrans>}
                  type="text"
                  name="secondaryAddressPostalCode"
                  error={!!errors.secondaryAddressPostalCode}
                  helperText={<Trans>{errors.secondaryAddressPostalCode}</Trans>}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.secondaryAddressPostalCode}
                />
              </>
          }
        </VerificationStepContent>
        <VerificationStepFooter validateForm={validateForm} submit={submitForm} isSubmitDisabled={!isValid} />
      </form>
    );

  const getInitialFormValues = () => {
    const {primaryAddress, secondaryAddress} = application;
    const areAddressesEmpty = !primaryAddress || !secondaryAddress;
    const areAddressesSame = isEqual(
      {...primaryAddress, type: ''},
      {...secondaryAddress, type: ''}
    );

    const emptyStringIfNoAddresses = value => areAddressesEmpty || !value ? '' : value;

    return {
      areAddressesSame: areAddressesEmpty || areAddressesSame,
      primaryAddressProvince: emptyStringIfNoAddresses(primaryAddress?.province),
      primaryAddressCity: emptyStringIfNoAddresses(primaryAddress?.city),
      primaryAddressBarangay: emptyStringIfNoAddresses(primaryAddress?.barangay),
      primaryAddressStreet: emptyStringIfNoAddresses(primaryAddress?.street),
      primaryAddressPostalCode: emptyStringIfNoAddresses(primaryAddress?.postalCode),
      secondaryAddressProvince: emptyStringIfNoAddresses(secondaryAddress?.province),
      secondaryAddressCity: emptyStringIfNoAddresses(secondaryAddress?.city),
      secondaryAddressBarangay: emptyStringIfNoAddresses(secondaryAddress?.barangay),
      secondaryAddressStreet: emptyStringIfNoAddresses(secondaryAddress?.street),
      secondaryAddressPostalCode: emptyStringIfNoAddresses(secondaryAddress?.postalCode)
    };
  };

  return (
    <VerificationStep>
      <Formik
        <FormFields>
        onSubmit={submit}
        validationSchema={ValidationSchema}
        initialValues={{...getInitialFormValues()}}
        children={AddressForm}
      />
    </VerificationStep>
  );
};
