import React, {useContext, useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {MainRouterContext} from '../../../App';
import {RoutesPaths} from '../../../routes/routes-paths/routes-paths';
import {CrossOriginEvent} from '../../../shared/model/CrossOriginMessage';
import UserData from '../../../shared/model/UserData';
import {VerificationService} from '../../../shared/services/verification.service';
import {applicationSealChecked} from '../../../store/actions';
import State from '../../../store/state';
import {JumioHelper} from '../../../utils/jumio-helper';
import Loader from '../../shared/loader/Loader';
import {JumioService} from './JumioService';
import styles from './JumioVerification.module.scss';
import {JumioVerificationInitializationStatus} from './model/JumioVerificationInitializationStatus';

export const JUMIO_VERIFICATION_RESULT_CROSS_ORIGIN_MESSAGE = 'JUMIO_VERIFICATION_RESULT';

interface JumioVerificationStateProps {
  userData: UserData;
}

// TODO needs to be placed in verification process correctly also remove hardcoded document type in effect
const JumioVerification = ({userData}: JumioVerificationStateProps) => {
  const [iframeSrc, setIframeSrc] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const mainRouterContext = useContext(MainRouterContext);
  const dispatch = useDispatch();
  const applicationId = userData.applicationId;

  // Jumio on success will redirect to our success page. Redirect happens inside iframe(child window).
  // Then we are sending (in JumioVerificationResult) a message to parent window to inform to perform an action.
  // In our case it will be redirecting to dashboard with proper popup open. The same flow is for error.
  // Unfortunately this is the best and official solution to communicate between iframe and parent code.
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
  useEffect(() => {
    // Redirect if applicationId missing (f.e. due to logout)
    if (!applicationId) {
      mainRouterContext.globalHistory.replace(RoutesPaths.DASHBOARD);
    }

    const messageHandler = (event: CrossOriginEvent<JumioVerificationInitializationStatus>) => {
      if (event.data.type === JUMIO_VERIFICATION_RESULT_CROSS_ORIGIN_MESSAGE) {
        handleJumioVerificationResult(event.data.payload);
      }
    };

    const handleJumioVerificationResult = (result: JumioVerificationInitializationStatus) => {
      if (result === JumioVerificationInitializationStatus.SUCCESS) {
        VerificationService.submitProspectApplicationEkycStatus(applicationId)
          .then(() => {
            dispatch(applicationSealChecked(true));
            mainRouterContext.globalHistory.replace(RoutesPaths.DASHBOARD);
          });

        return;
      }

      // TODO redirect on verification error page
      // mainRouterContext.globalHistory.replace(ERROR);
    };

    window.addEventListener('message', messageHandler);

    return () => window.removeEventListener('message', messageHandler);
  }, [mainRouterContext, applicationId, dispatch]);

  useEffect(() => {
    VerificationService.readProspectApplication()
      .then(prospectApplication => {
        const jumioDocumentType = JumioHelper.mapDocumentTypeIdToJumioDocumentType(
          prospectApplication.application.documentTypeId
        );

        JumioService.initVerificationProcess(jumioDocumentType)
          .then(redirectUrl => {
            setIframeSrc(redirectUrl);
            setIsLoading(false);
          });
      });
  }, []);

  return isLoading ?
    <Loader className={styles.loader} /> :
      <iframe className={styles.jumio}
              sandbox="allow-same-origin allow-scripts"
              title="Jumio identity verification frame"
              src={iframeSrc}
              allow="camera" />
};

const mapStateToProps = (state: State): JumioVerificationStateProps => ({
  userData: state.sessionData!
});

export default connect<JumioVerificationStateProps, {}, {}, State>(mapStateToProps)(JumioVerification);
