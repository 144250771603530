import React from 'react';
import {RoutesPaths} from "../../../routes/routes-paths/routes-paths";
import Otp from '../otp/Otp';
import {CardlessWithdrawalRoutesPaths} from './cardless-withdrawal.routes-paths';
import CardlessWithdrawal from './CardlessWithdarawal';
import CardlessWithdrawalConfirmation from './confirmation/CardlessWithdrawalConfirmation';
import CardlessWithdrawalRecipientDetails from './recipient-details/CardlessWithdrawalRecipientDetails';
import CardlessWithdrawalSuccess from './success/CardlessWithdrawalSuccess';

export const CardlessWithdrawalRoutes = {
  CUSTOMER_CARDLESS_WITHDRAWAL: {
    path: RoutesPaths.CARDLESS_WITHDRAWAL,
    key: 'CUSTOMER_CARDLESS_TRANSFER',
    render: props => <CardlessWithdrawal isItCustomerWithdrawal={true} {...props} />
  },
  NON_CUSTOMER_CARDLESS_WITHDRAWAL: {
    path: RoutesPaths.CARDLESS_WITHDRAWAL,
    key: 'NON_CUSTOMER_CARDLESS_TRANSFER',
    render: props => <CardlessWithdrawal isItCustomerWithdrawal={false} {...props} />
  },
  CARDLESS_WITHDRAWAL_CONFIRM: {
    path: CardlessWithdrawalRoutesPaths.CONFIRM,
    key: 'CARDLESS_WITHDRAWAL_CONFIRM',
    component: CardlessWithdrawalConfirmation
  },
  CARDLESS_WITHDRAWAL_OTP: {
    path: CardlessWithdrawalRoutesPaths.OTP,
    key: 'CARDLESS_WITHDRAWAL_OTP',
    component: Otp
  },
  CARDLESS_WITHDRAWAL_SUCCESS: {
    path: CardlessWithdrawalRoutesPaths.SUCCESS,
    key: 'CARDLESS_WITHDRAWAL_SUCCESS',
    component: CardlessWithdrawalSuccess
  },
  CARDLESS_WITHDRAWAL_RECIPIENT_DETAILS: {
    path: CardlessWithdrawalRoutesPaths.RECIPIENT_DETAILS,
    key: 'CARDLESS_WITHDRAWAL_RECIPIENT_DETAILS',
    component: CardlessWithdrawalRecipientDetails
  }
};
